export const homepageURL = 'https://timelinear.onrender.com'
export const API_BASE_URL = 'https://timelinear-server.onrender.com/api/v1'
// export const homepageURL = 'http://localhost:3000'
// export const API_BASE_URL = 'http://localhost:8080/api/v1'
export const SIGN_UP_URL = 'auth/register'
export const SIGN_IN_URL = 'auth/authenticate'
export const CHANGE_PASSWORD_URL = 'users/changePassword'
export const REFRESH_TOKEN_URL = 'auth/refresh-token'
export const TIMELINE_URL = 'timelines'
export const TIMELINE_BULK_URL = 'timelines/bulk'
export const TIMELINE_EVENTS_URL = 'timelines/events'
export const TIMELINE_OWNED = 'timelines/owned'
export const TIMELINE_BROWSE_GROUP = 'timelines/browseGroup'
export const TIME_EVENT_URL = 'timeEvents'
export const TIME_EVENTS_BULK_URL = 'timeEvents/bulk'
export const TIME_EVENTS_OWNED = 'timeEvents/owned'
export const GROUPS_URL = 'groups'
export const GROUPS_OWNED_URL = 'groups/owned'
export const GROUPS_BULK_URL = 'groups/bulk'
export const GROUPS_ADD_USERS = 'groups/addUsers'
export const GROUPS_REMOVE_USERS = 'groups/removeUsers'
export const USERS_URL = 'users'
export const USERS_CHANGE_AVATAR_URL = 'users/changeAvatar'
export const CATEGORY_URL = 'categories'
export const CATEGORIES_OWNED_URL = 'categories/owned'
