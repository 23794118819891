import * as icons from '@mui/icons-material'

interface IconsMapped {
    [key: string]: JSX.Element
}

export const iconMapping: IconsMapped = {
    Abc: <icons.Abc />,
    AbcOutlined: <icons.AbcOutlined />,
    AbcRounded: <icons.AbcRounded />,
    AbcSharp: <icons.AbcSharp />,
    AbcTwoTone: <icons.AbcTwoTone />,
    AcUnit: <icons.AcUnit />,
    AcUnitOutlined: <icons.AcUnitOutlined />,
    AcUnitRounded: <icons.AcUnitRounded />,
    AcUnitSharp: <icons.AcUnitSharp />,
    AcUnitTwoTone: <icons.AcUnitTwoTone />,
    AccessAlarm: <icons.AccessAlarm />,
    AccessAlarmOutlined: <icons.AccessAlarmOutlined />,
    AccessAlarmRounded: <icons.AccessAlarmRounded />,
    AccessAlarmSharp: <icons.AccessAlarmSharp />,
    AccessAlarmTwoTone: <icons.AccessAlarmTwoTone />,
    AccessAlarms: <icons.AccessAlarms />,
    AccessAlarmsOutlined: <icons.AccessAlarmsOutlined />,
    AccessAlarmsRounded: <icons.AccessAlarmsRounded />,
    AccessAlarmsSharp: <icons.AccessAlarmsSharp />,
    AccessAlarmsTwoTone: <icons.AccessAlarmsTwoTone />,
    AccessTime: <icons.AccessTime />,
    AccessTimeFilled: <icons.AccessTimeFilled />,
    AccessTimeFilledOutlined: <icons.AccessTimeFilledOutlined />,
    AccessTimeFilledRounded: <icons.AccessTimeFilledRounded />,
    AccessTimeFilledSharp: <icons.AccessTimeFilledSharp />,
    AccessTimeFilledTwoTone: <icons.AccessTimeFilledTwoTone />,
    AccessTimeOutlined: <icons.AccessTimeOutlined />,
    AccessTimeRounded: <icons.AccessTimeRounded />,
    AccessTimeSharp: <icons.AccessTimeSharp />,
    AccessTimeTwoTone: <icons.AccessTimeTwoTone />,
    Accessibility: <icons.Accessibility />,
    AccessibilityNew: <icons.AccessibilityNew />,
    AccessibilityNewOutlined: <icons.AccessibilityNewOutlined />,
    AccessibilityNewRounded: <icons.AccessibilityNewRounded />,
    AccessibilityNewSharp: <icons.AccessibilityNewSharp />,
    AccessibilityNewTwoTone: <icons.AccessibilityNewTwoTone />,
    AccessibilityOutlined: <icons.AccessibilityOutlined />,
    AccessibilityRounded: <icons.AccessibilityRounded />,
    AccessibilitySharp: <icons.AccessibilitySharp />,
    AccessibilityTwoTone: <icons.AccessibilityTwoTone />,
    Accessible: <icons.Accessible />,
    AccessibleForward: <icons.AccessibleForward />,
    AccessibleForwardOutlined: <icons.AccessibleForwardOutlined />,
    AccessibleForwardRounded: <icons.AccessibleForwardRounded />,
    AccessibleForwardSharp: <icons.AccessibleForwardSharp />,
    AccessibleForwardTwoTone: <icons.AccessibleForwardTwoTone />,
    AccessibleOutlined: <icons.AccessibleOutlined />,
    AccessibleRounded: <icons.AccessibleRounded />,
    AccessibleSharp: <icons.AccessibleSharp />,
    AccessibleTwoTone: <icons.AccessibleTwoTone />,
    AccountBalance: <icons.AccountBalance />,
    AccountBalanceOutlined: <icons.AccountBalanceOutlined />,
    AccountBalanceRounded: <icons.AccountBalanceRounded />,
    AccountBalanceSharp: <icons.AccountBalanceSharp />,
    AccountBalanceTwoTone: <icons.AccountBalanceTwoTone />,
    AccountBalanceWallet: <icons.AccountBalanceWallet />,
    AccountBalanceWalletOutlined: <icons.AccountBalanceWalletOutlined />,
    AccountBalanceWalletRounded: <icons.AccountBalanceWalletRounded />,
    AccountBalanceWalletSharp: <icons.AccountBalanceWalletSharp />,
    AccountBalanceWalletTwoTone: <icons.AccountBalanceWalletTwoTone />,
    AccountBox: <icons.AccountBox />,
    AccountBoxOutlined: <icons.AccountBoxOutlined />,
    AccountBoxRounded: <icons.AccountBoxRounded />,
    AccountBoxSharp: <icons.AccountBoxSharp />,
    AccountBoxTwoTone: <icons.AccountBoxTwoTone />,
    AccountCircle: <icons.AccountCircle />,
    AccountCircleOutlined: <icons.AccountCircleOutlined />,
    AccountCircleRounded: <icons.AccountCircleRounded />,
    AccountCircleSharp: <icons.AccountCircleSharp />,
    AccountCircleTwoTone: <icons.AccountCircleTwoTone />,
    AccountTree: <icons.AccountTree />,
    AccountTreeOutlined: <icons.AccountTreeOutlined />,
    AccountTreeRounded: <icons.AccountTreeRounded />,
    AccountTreeSharp: <icons.AccountTreeSharp />,
    AccountTreeTwoTone: <icons.AccountTreeTwoTone />,
    AdUnits: <icons.AdUnits />,
    AdUnitsOutlined: <icons.AdUnitsOutlined />,
    AdUnitsRounded: <icons.AdUnitsRounded />,
    AdUnitsSharp: <icons.AdUnitsSharp />,
    AdUnitsTwoTone: <icons.AdUnitsTwoTone />,
    Adb: <icons.Adb />,
    AdbOutlined: <icons.AdbOutlined />,
    AdbRounded: <icons.AdbRounded />,
    AdbSharp: <icons.AdbSharp />,
    AdbTwoTone: <icons.AdbTwoTone />,
    Add: <icons.Add />,
    AddAPhoto: <icons.AddAPhoto />,
    AddAPhotoOutlined: <icons.AddAPhotoOutlined />,
    AddAPhotoRounded: <icons.AddAPhotoRounded />,
    AddAPhotoSharp: <icons.AddAPhotoSharp />,
    AddAPhotoTwoTone: <icons.AddAPhotoTwoTone />,
    AddAlarm: <icons.AddAlarm />,
    AddAlarmOutlined: <icons.AddAlarmOutlined />,
    AddAlarmRounded: <icons.AddAlarmRounded />,
    AddAlarmSharp: <icons.AddAlarmSharp />,
    AddAlarmTwoTone: <icons.AddAlarmTwoTone />,
    AddAlert: <icons.AddAlert />,
    AddAlertOutlined: <icons.AddAlertOutlined />,
    AddAlertRounded: <icons.AddAlertRounded />,
    AddAlertSharp: <icons.AddAlertSharp />,
    AddAlertTwoTone: <icons.AddAlertTwoTone />,
    AddBox: <icons.AddBox />,
    AddBoxOutlined: <icons.AddBoxOutlined />,
    AddBoxRounded: <icons.AddBoxRounded />,
    AddBoxSharp: <icons.AddBoxSharp />,
    AddBoxTwoTone: <icons.AddBoxTwoTone />,
    AddBusiness: <icons.AddBusiness />,
    AddBusinessOutlined: <icons.AddBusinessOutlined />,
    AddBusinessRounded: <icons.AddBusinessRounded />,
    AddBusinessSharp: <icons.AddBusinessSharp />,
    AddBusinessTwoTone: <icons.AddBusinessTwoTone />,
    AddCard: <icons.AddCard />,
    AddCardOutlined: <icons.AddCardOutlined />,
    AddCardRounded: <icons.AddCardRounded />,
    AddCardSharp: <icons.AddCardSharp />,
    AddCardTwoTone: <icons.AddCardTwoTone />,
    AddCircle: <icons.AddCircle />,
    AddCircleOutline: <icons.AddCircleOutline />,
    AddCircleOutlineOutlined: <icons.AddCircleOutlineOutlined />,
    AddCircleOutlineRounded: <icons.AddCircleOutlineRounded />,
    AddCircleOutlineSharp: <icons.AddCircleOutlineSharp />,
    AddCircleOutlineTwoTone: <icons.AddCircleOutlineTwoTone />,
    AddCircleOutlined: <icons.AddCircleOutlined />,
    AddCircleRounded: <icons.AddCircleRounded />,
    AddCircleSharp: <icons.AddCircleSharp />,
    AddCircleTwoTone: <icons.AddCircleTwoTone />,
    AddComment: <icons.AddComment />,
    AddCommentOutlined: <icons.AddCommentOutlined />,
    AddCommentRounded: <icons.AddCommentRounded />,
    AddCommentSharp: <icons.AddCommentSharp />,
    AddCommentTwoTone: <icons.AddCommentTwoTone />,
    AddHome: <icons.AddHome />,
    AddHomeOutlined: <icons.AddHomeOutlined />,
    AddHomeRounded: <icons.AddHomeRounded />,
    AddHomeSharp: <icons.AddHomeSharp />,
    AddHomeTwoTone: <icons.AddHomeTwoTone />,
    AddHomeWork: <icons.AddHomeWork />,
    AddHomeWorkOutlined: <icons.AddHomeWorkOutlined />,
    AddHomeWorkRounded: <icons.AddHomeWorkRounded />,
    AddHomeWorkSharp: <icons.AddHomeWorkSharp />,
    AddHomeWorkTwoTone: <icons.AddHomeWorkTwoTone />,
    AddIcCall: <icons.AddIcCall />,
    AddIcCallOutlined: <icons.AddIcCallOutlined />,
    AddIcCallRounded: <icons.AddIcCallRounded />,
    AddIcCallSharp: <icons.AddIcCallSharp />,
    AddIcCallTwoTone: <icons.AddIcCallTwoTone />,
    AddLink: <icons.AddLink />,
    AddLinkOutlined: <icons.AddLinkOutlined />,
    AddLinkRounded: <icons.AddLinkRounded />,
    AddLinkSharp: <icons.AddLinkSharp />,
    AddLinkTwoTone: <icons.AddLinkTwoTone />,
    AddLocation: <icons.AddLocation />,
    AddLocationAlt: <icons.AddLocationAlt />,
    AddLocationAltOutlined: <icons.AddLocationAltOutlined />,
    AddLocationAltRounded: <icons.AddLocationAltRounded />,
    AddLocationAltSharp: <icons.AddLocationAltSharp />,
    AddLocationAltTwoTone: <icons.AddLocationAltTwoTone />,
    AddLocationOutlined: <icons.AddLocationOutlined />,
    AddLocationRounded: <icons.AddLocationRounded />,
    AddLocationSharp: <icons.AddLocationSharp />,
    AddLocationTwoTone: <icons.AddLocationTwoTone />,
    AddModerator: <icons.AddModerator />,
    AddModeratorOutlined: <icons.AddModeratorOutlined />,
    AddModeratorRounded: <icons.AddModeratorRounded />,
    AddModeratorSharp: <icons.AddModeratorSharp />,
    AddModeratorTwoTone: <icons.AddModeratorTwoTone />,
    AddOutlined: <icons.AddOutlined />,
    AddPhotoAlternate: <icons.AddPhotoAlternate />,
    AddPhotoAlternateOutlined: <icons.AddPhotoAlternateOutlined />,
    AddPhotoAlternateRounded: <icons.AddPhotoAlternateRounded />,
    AddPhotoAlternateSharp: <icons.AddPhotoAlternateSharp />,
    AddPhotoAlternateTwoTone: <icons.AddPhotoAlternateTwoTone />,
    AddReaction: <icons.AddReaction />,
    AddReactionOutlined: <icons.AddReactionOutlined />,
    AddReactionRounded: <icons.AddReactionRounded />,
    AddReactionSharp: <icons.AddReactionSharp />,
    AddReactionTwoTone: <icons.AddReactionTwoTone />,
    AddRoad: <icons.AddRoad />,
    AddRoadOutlined: <icons.AddRoadOutlined />,
    AddRoadRounded: <icons.AddRoadRounded />,
    AddRoadSharp: <icons.AddRoadSharp />,
    AddRoadTwoTone: <icons.AddRoadTwoTone />,
    AddRounded: <icons.AddRounded />,
    AddSharp: <icons.AddSharp />,
    AddShoppingCart: <icons.AddShoppingCart />,
    AddShoppingCartOutlined: <icons.AddShoppingCartOutlined />,
    AddShoppingCartRounded: <icons.AddShoppingCartRounded />,
    AddShoppingCartSharp: <icons.AddShoppingCartSharp />,
    AddShoppingCartTwoTone: <icons.AddShoppingCartTwoTone />,
    AddTask: <icons.AddTask />,
    AddTaskOutlined: <icons.AddTaskOutlined />,
    AddTaskRounded: <icons.AddTaskRounded />,
    AddTaskSharp: <icons.AddTaskSharp />,
    AddTaskTwoTone: <icons.AddTaskTwoTone />,
    AddToDrive: <icons.AddToDrive />,
    AddToDriveOutlined: <icons.AddToDriveOutlined />,
    AddToDriveRounded: <icons.AddToDriveRounded />,
    AddToDriveSharp: <icons.AddToDriveSharp />,
    AddToDriveTwoTone: <icons.AddToDriveTwoTone />,
    AddToHomeScreen: <icons.AddToHomeScreen />,
    AddToHomeScreenOutlined: <icons.AddToHomeScreenOutlined />,
    AddToHomeScreenRounded: <icons.AddToHomeScreenRounded />,
    AddToHomeScreenSharp: <icons.AddToHomeScreenSharp />,
    AddToHomeScreenTwoTone: <icons.AddToHomeScreenTwoTone />,
    AddToPhotos: <icons.AddToPhotos />,
    AddToPhotosOutlined: <icons.AddToPhotosOutlined />,
    AddToPhotosRounded: <icons.AddToPhotosRounded />,
    AddToPhotosSharp: <icons.AddToPhotosSharp />,
    AddToPhotosTwoTone: <icons.AddToPhotosTwoTone />,
    AddToQueue: <icons.AddToQueue />,
    AddToQueueOutlined: <icons.AddToQueueOutlined />,
    AddToQueueRounded: <icons.AddToQueueRounded />,
    AddToQueueSharp: <icons.AddToQueueSharp />,
    AddToQueueTwoTone: <icons.AddToQueueTwoTone />,
    AddTwoTone: <icons.AddTwoTone />,
    Addchart: <icons.Addchart />,
    AddchartOutlined: <icons.AddchartOutlined />,
    AddchartRounded: <icons.AddchartRounded />,
    AddchartSharp: <icons.AddchartSharp />,
    AddchartTwoTone: <icons.AddchartTwoTone />,
    AdfScanner: <icons.AdfScanner />,
    AdfScannerOutlined: <icons.AdfScannerOutlined />,
    AdfScannerRounded: <icons.AdfScannerRounded />,
    AdfScannerSharp: <icons.AdfScannerSharp />,
    AdfScannerTwoTone: <icons.AdfScannerTwoTone />,
    Adjust: <icons.Adjust />,
    AdjustOutlined: <icons.AdjustOutlined />,
    AdjustRounded: <icons.AdjustRounded />,
    AdjustSharp: <icons.AdjustSharp />,
    AdjustTwoTone: <icons.AdjustTwoTone />,
    AdminPanelSettings: <icons.AdminPanelSettings />,
    AdminPanelSettingsOutlined: <icons.AdminPanelSettingsOutlined />,
    AdminPanelSettingsRounded: <icons.AdminPanelSettingsRounded />,
    AdminPanelSettingsSharp: <icons.AdminPanelSettingsSharp />,
    AdminPanelSettingsTwoTone: <icons.AdminPanelSettingsTwoTone />,
    AdsClick: <icons.AdsClick />,
    AdsClickOutlined: <icons.AdsClickOutlined />,
    AdsClickRounded: <icons.AdsClickRounded />,
    AdsClickSharp: <icons.AdsClickSharp />,
    AdsClickTwoTone: <icons.AdsClickTwoTone />,
    Agriculture: <icons.Agriculture />,
    AgricultureOutlined: <icons.AgricultureOutlined />,
    AgricultureRounded: <icons.AgricultureRounded />,
    AgricultureSharp: <icons.AgricultureSharp />,
    AgricultureTwoTone: <icons.AgricultureTwoTone />,
    Air: <icons.Air />,
    AirOutlined: <icons.AirOutlined />,
    AirRounded: <icons.AirRounded />,
    AirSharp: <icons.AirSharp />,
    AirTwoTone: <icons.AirTwoTone />,
    AirlineSeatFlat: <icons.AirlineSeatFlat />,
    AirlineSeatFlatAngled: <icons.AirlineSeatFlatAngled />,
    AirlineSeatFlatAngledOutlined: <icons.AirlineSeatFlatAngledOutlined />,
    AirlineSeatFlatAngledRounded: <icons.AirlineSeatFlatAngledRounded />,
    AirlineSeatFlatAngledSharp: <icons.AirlineSeatFlatAngledSharp />,
    AirlineSeatFlatAngledTwoTone: <icons.AirlineSeatFlatAngledTwoTone />,
    AirlineSeatFlatOutlined: <icons.AirlineSeatFlatOutlined />,
    AirlineSeatFlatRounded: <icons.AirlineSeatFlatRounded />,
    AirlineSeatFlatSharp: <icons.AirlineSeatFlatSharp />,
    AirlineSeatFlatTwoTone: <icons.AirlineSeatFlatTwoTone />,
    AirlineSeatIndividualSuite: <icons.AirlineSeatIndividualSuite />,
    AirlineSeatIndividualSuiteOutlined: (
        <icons.AirlineSeatIndividualSuiteOutlined />
    ),
    AirlineSeatIndividualSuiteRounded: (
        <icons.AirlineSeatIndividualSuiteRounded />
    ),
    AirlineSeatIndividualSuiteSharp: <icons.AirlineSeatIndividualSuiteSharp />,
    AirlineSeatIndividualSuiteTwoTone: (
        <icons.AirlineSeatIndividualSuiteTwoTone />
    ),
    AirlineSeatLegroomExtra: <icons.AirlineSeatLegroomExtra />,
    AirlineSeatLegroomExtraOutlined: <icons.AirlineSeatLegroomExtraOutlined />,
    AirlineSeatLegroomExtraRounded: <icons.AirlineSeatLegroomExtraRounded />,
    AirlineSeatLegroomExtraSharp: <icons.AirlineSeatLegroomExtraSharp />,
    AirlineSeatLegroomExtraTwoTone: <icons.AirlineSeatLegroomExtraTwoTone />,
    AirlineSeatLegroomNormal: <icons.AirlineSeatLegroomNormal />,
    AirlineSeatLegroomNormalOutlined: (
        <icons.AirlineSeatLegroomNormalOutlined />
    ),
    AirlineSeatLegroomNormalRounded: <icons.AirlineSeatLegroomNormalRounded />,
    AirlineSeatLegroomNormalSharp: <icons.AirlineSeatLegroomNormalSharp />,
    AirlineSeatLegroomNormalTwoTone: <icons.AirlineSeatLegroomNormalTwoTone />,
    AirlineSeatLegroomReduced: <icons.AirlineSeatLegroomReduced />,
    AirlineSeatLegroomReducedOutlined: (
        <icons.AirlineSeatLegroomReducedOutlined />
    ),
    AirlineSeatLegroomReducedRounded: (
        <icons.AirlineSeatLegroomReducedRounded />
    ),
    AirlineSeatLegroomReducedSharp: <icons.AirlineSeatLegroomReducedSharp />,
    AirlineSeatLegroomReducedTwoTone: (
        <icons.AirlineSeatLegroomReducedTwoTone />
    ),
    AirlineSeatReclineExtra: <icons.AirlineSeatReclineExtra />,
    AirlineSeatReclineExtraOutlined: <icons.AirlineSeatReclineExtraOutlined />,
    AirlineSeatReclineExtraRounded: <icons.AirlineSeatReclineExtraRounded />,
    AirlineSeatReclineExtraSharp: <icons.AirlineSeatReclineExtraSharp />,
    AirlineSeatReclineExtraTwoTone: <icons.AirlineSeatReclineExtraTwoTone />,
    AirlineSeatReclineNormal: <icons.AirlineSeatReclineNormal />,
    AirlineSeatReclineNormalOutlined: (
        <icons.AirlineSeatReclineNormalOutlined />
    ),
    AirlineSeatReclineNormalRounded: <icons.AirlineSeatReclineNormalRounded />,
    AirlineSeatReclineNormalSharp: <icons.AirlineSeatReclineNormalSharp />,
    AirlineSeatReclineNormalTwoTone: <icons.AirlineSeatReclineNormalTwoTone />,
    AirlineStops: <icons.AirlineStops />,
    AirlineStopsOutlined: <icons.AirlineStopsOutlined />,
    AirlineStopsRounded: <icons.AirlineStopsRounded />,
    AirlineStopsSharp: <icons.AirlineStopsSharp />,
    AirlineStopsTwoTone: <icons.AirlineStopsTwoTone />,
    Airlines: <icons.Airlines />,
    AirlinesOutlined: <icons.AirlinesOutlined />,
    AirlinesRounded: <icons.AirlinesRounded />,
    AirlinesSharp: <icons.AirlinesSharp />,
    AirlinesTwoTone: <icons.AirlinesTwoTone />,
    AirplaneTicket: <icons.AirplaneTicket />,
    AirplaneTicketOutlined: <icons.AirplaneTicketOutlined />,
    AirplaneTicketRounded: <icons.AirplaneTicketRounded />,
    AirplaneTicketSharp: <icons.AirplaneTicketSharp />,
    AirplaneTicketTwoTone: <icons.AirplaneTicketTwoTone />,
    AirplanemodeActive: <icons.AirplanemodeActive />,
    AirplanemodeActiveOutlined: <icons.AirplanemodeActiveOutlined />,
    AirplanemodeActiveRounded: <icons.AirplanemodeActiveRounded />,
    AirplanemodeActiveSharp: <icons.AirplanemodeActiveSharp />,
    AirplanemodeActiveTwoTone: <icons.AirplanemodeActiveTwoTone />,
    AirplanemodeInactive: <icons.AirplanemodeInactive />,
    AirplanemodeInactiveOutlined: <icons.AirplanemodeInactiveOutlined />,
    AirplanemodeInactiveRounded: <icons.AirplanemodeInactiveRounded />,
    AirplanemodeInactiveSharp: <icons.AirplanemodeInactiveSharp />,
    AirplanemodeInactiveTwoTone: <icons.AirplanemodeInactiveTwoTone />,
    Airplay: <icons.Airplay />,
    AirplayOutlined: <icons.AirplayOutlined />,
    AirplayRounded: <icons.AirplayRounded />,
    AirplaySharp: <icons.AirplaySharp />,
    AirplayTwoTone: <icons.AirplayTwoTone />,
    AirportShuttle: <icons.AirportShuttle />,
    AirportShuttleOutlined: <icons.AirportShuttleOutlined />,
    AirportShuttleRounded: <icons.AirportShuttleRounded />,
    AirportShuttleSharp: <icons.AirportShuttleSharp />,
    AirportShuttleTwoTone: <icons.AirportShuttleTwoTone />,
    Alarm: <icons.Alarm />,
    AlarmAdd: <icons.AlarmAdd />,
    AlarmAddOutlined: <icons.AlarmAddOutlined />,
    AlarmAddRounded: <icons.AlarmAddRounded />,
    AlarmAddSharp: <icons.AlarmAddSharp />,
    AlarmAddTwoTone: <icons.AlarmAddTwoTone />,
    AlarmOff: <icons.AlarmOff />,
    AlarmOffOutlined: <icons.AlarmOffOutlined />,
    AlarmOffRounded: <icons.AlarmOffRounded />,
    AlarmOffSharp: <icons.AlarmOffSharp />,
    AlarmOffTwoTone: <icons.AlarmOffTwoTone />,
    AlarmOn: <icons.AlarmOn />,
    AlarmOnOutlined: <icons.AlarmOnOutlined />,
    AlarmOnRounded: <icons.AlarmOnRounded />,
    AlarmOnSharp: <icons.AlarmOnSharp />,
    AlarmOnTwoTone: <icons.AlarmOnTwoTone />,
    AlarmOutlined: <icons.AlarmOutlined />,
    AlarmRounded: <icons.AlarmRounded />,
    AlarmSharp: <icons.AlarmSharp />,
    AlarmTwoTone: <icons.AlarmTwoTone />,
    Album: <icons.Album />,
    AlbumOutlined: <icons.AlbumOutlined />,
    AlbumRounded: <icons.AlbumRounded />,
    AlbumSharp: <icons.AlbumSharp />,
    AlbumTwoTone: <icons.AlbumTwoTone />,
    AlignHorizontalCenter: <icons.AlignHorizontalCenter />,
    AlignHorizontalCenterOutlined: <icons.AlignHorizontalCenterOutlined />,
    AlignHorizontalCenterRounded: <icons.AlignHorizontalCenterRounded />,
    AlignHorizontalCenterSharp: <icons.AlignHorizontalCenterSharp />,
    AlignHorizontalCenterTwoTone: <icons.AlignHorizontalCenterTwoTone />,
    AlignHorizontalLeft: <icons.AlignHorizontalLeft />,
    AlignHorizontalLeftOutlined: <icons.AlignHorizontalLeftOutlined />,
    AlignHorizontalLeftRounded: <icons.AlignHorizontalLeftRounded />,
    AlignHorizontalLeftSharp: <icons.AlignHorizontalLeftSharp />,
    AlignHorizontalLeftTwoTone: <icons.AlignHorizontalLeftTwoTone />,
    AlignHorizontalRight: <icons.AlignHorizontalRight />,
    AlignHorizontalRightOutlined: <icons.AlignHorizontalRightOutlined />,
    AlignHorizontalRightRounded: <icons.AlignHorizontalRightRounded />,
    AlignHorizontalRightSharp: <icons.AlignHorizontalRightSharp />,
    AlignHorizontalRightTwoTone: <icons.AlignHorizontalRightTwoTone />,
    AlignVerticalBottom: <icons.AlignVerticalBottom />,
    AlignVerticalBottomOutlined: <icons.AlignVerticalBottomOutlined />,
    AlignVerticalBottomRounded: <icons.AlignVerticalBottomRounded />,
    AlignVerticalBottomSharp: <icons.AlignVerticalBottomSharp />,
    AlignVerticalBottomTwoTone: <icons.AlignVerticalBottomTwoTone />,
    AlignVerticalCenter: <icons.AlignVerticalCenter />,
    AlignVerticalCenterOutlined: <icons.AlignVerticalCenterOutlined />,
    AlignVerticalCenterRounded: <icons.AlignVerticalCenterRounded />,
    AlignVerticalCenterSharp: <icons.AlignVerticalCenterSharp />,
    AlignVerticalCenterTwoTone: <icons.AlignVerticalCenterTwoTone />,
    AlignVerticalTop: <icons.AlignVerticalTop />,
    AlignVerticalTopOutlined: <icons.AlignVerticalTopOutlined />,
    AlignVerticalTopRounded: <icons.AlignVerticalTopRounded />,
    AlignVerticalTopSharp: <icons.AlignVerticalTopSharp />,
    AlignVerticalTopTwoTone: <icons.AlignVerticalTopTwoTone />,
    AllInbox: <icons.AllInbox />,
    AllInboxOutlined: <icons.AllInboxOutlined />,
    AllInboxRounded: <icons.AllInboxRounded />,
    AllInboxSharp: <icons.AllInboxSharp />,
    AllInboxTwoTone: <icons.AllInboxTwoTone />,
    AllInclusive: <icons.AllInclusive />,
    AllInclusiveOutlined: <icons.AllInclusiveOutlined />,
    AllInclusiveRounded: <icons.AllInclusiveRounded />,
    AllInclusiveSharp: <icons.AllInclusiveSharp />,
    AllInclusiveTwoTone: <icons.AllInclusiveTwoTone />,
    AllOut: <icons.AllOut />,
    AllOutOutlined: <icons.AllOutOutlined />,
    AllOutRounded: <icons.AllOutRounded />,
    AllOutSharp: <icons.AllOutSharp />,
    AllOutTwoTone: <icons.AllOutTwoTone />,
    AltRoute: <icons.AltRoute />,
    AltRouteOutlined: <icons.AltRouteOutlined />,
    AltRouteRounded: <icons.AltRouteRounded />,
    AltRouteSharp: <icons.AltRouteSharp />,
    AltRouteTwoTone: <icons.AltRouteTwoTone />,
    AlternateEmail: <icons.AlternateEmail />,
    AlternateEmailOutlined: <icons.AlternateEmailOutlined />,
    AlternateEmailRounded: <icons.AlternateEmailRounded />,
    AlternateEmailSharp: <icons.AlternateEmailSharp />,
    AlternateEmailTwoTone: <icons.AlternateEmailTwoTone />,
    Analytics: <icons.Analytics />,
    AnalyticsOutlined: <icons.AnalyticsOutlined />,
    AnalyticsRounded: <icons.AnalyticsRounded />,
    AnalyticsSharp: <icons.AnalyticsSharp />,
    AnalyticsTwoTone: <icons.AnalyticsTwoTone />,
    Anchor: <icons.Anchor />,
    AnchorOutlined: <icons.AnchorOutlined />,
    AnchorRounded: <icons.AnchorRounded />,
    AnchorSharp: <icons.AnchorSharp />,
    AnchorTwoTone: <icons.AnchorTwoTone />,
    Android: <icons.Android />,
    AndroidOutlined: <icons.AndroidOutlined />,
    AndroidRounded: <icons.AndroidRounded />,
    AndroidSharp: <icons.AndroidSharp />,
    AndroidTwoTone: <icons.AndroidTwoTone />,
    Animation: <icons.Animation />,
    AnimationOutlined: <icons.AnimationOutlined />,
    AnimationRounded: <icons.AnimationRounded />,
    AnimationSharp: <icons.AnimationSharp />,
    AnimationTwoTone: <icons.AnimationTwoTone />,
    Announcement: <icons.Announcement />,
    AnnouncementOutlined: <icons.AnnouncementOutlined />,
    AnnouncementRounded: <icons.AnnouncementRounded />,
    AnnouncementSharp: <icons.AnnouncementSharp />,
    AnnouncementTwoTone: <icons.AnnouncementTwoTone />,
    Aod: <icons.Aod />,
    AodOutlined: <icons.AodOutlined />,
    AodRounded: <icons.AodRounded />,
    AodSharp: <icons.AodSharp />,
    AodTwoTone: <icons.AodTwoTone />,
    Apartment: <icons.Apartment />,
    ApartmentOutlined: <icons.ApartmentOutlined />,
    ApartmentRounded: <icons.ApartmentRounded />,
    ApartmentSharp: <icons.ApartmentSharp />,
    ApartmentTwoTone: <icons.ApartmentTwoTone />,
    Api: <icons.Api />,
    ApiOutlined: <icons.ApiOutlined />,
    ApiRounded: <icons.ApiRounded />,
    ApiSharp: <icons.ApiSharp />,
    ApiTwoTone: <icons.ApiTwoTone />,
    AppBlocking: <icons.AppBlocking />,
    AppBlockingOutlined: <icons.AppBlockingOutlined />,
    AppBlockingRounded: <icons.AppBlockingRounded />,
    AppBlockingSharp: <icons.AppBlockingSharp />,
    AppBlockingTwoTone: <icons.AppBlockingTwoTone />,
    AppRegistration: <icons.AppRegistration />,
    AppRegistrationOutlined: <icons.AppRegistrationOutlined />,
    AppRegistrationRounded: <icons.AppRegistrationRounded />,
    AppRegistrationSharp: <icons.AppRegistrationSharp />,
    AppRegistrationTwoTone: <icons.AppRegistrationTwoTone />,
    AppSettingsAlt: <icons.AppSettingsAlt />,
    AppSettingsAltOutlined: <icons.AppSettingsAltOutlined />,
    AppSettingsAltRounded: <icons.AppSettingsAltRounded />,
    AppSettingsAltSharp: <icons.AppSettingsAltSharp />,
    AppSettingsAltTwoTone: <icons.AppSettingsAltTwoTone />,
    AppShortcut: <icons.AppShortcut />,
    AppShortcutOutlined: <icons.AppShortcutOutlined />,
    AppShortcutRounded: <icons.AppShortcutRounded />,
    AppShortcutSharp: <icons.AppShortcutSharp />,
    AppShortcutTwoTone: <icons.AppShortcutTwoTone />,
    Apple: <icons.Apple />,
    Approval: <icons.Approval />,
    ApprovalOutlined: <icons.ApprovalOutlined />,
    ApprovalRounded: <icons.ApprovalRounded />,
    ApprovalSharp: <icons.ApprovalSharp />,
    ApprovalTwoTone: <icons.ApprovalTwoTone />,
    Apps: <icons.Apps />,
    AppsOutage: <icons.AppsOutage />,
    AppsOutageOutlined: <icons.AppsOutageOutlined />,
    AppsOutageRounded: <icons.AppsOutageRounded />,
    AppsOutageSharp: <icons.AppsOutageSharp />,
    AppsOutageTwoTone: <icons.AppsOutageTwoTone />,
    AppsOutlined: <icons.AppsOutlined />,
    AppsRounded: <icons.AppsRounded />,
    AppsSharp: <icons.AppsSharp />,
    AppsTwoTone: <icons.AppsTwoTone />,
    Architecture: <icons.Architecture />,
    ArchitectureOutlined: <icons.ArchitectureOutlined />,
    ArchitectureRounded: <icons.ArchitectureRounded />,
    ArchitectureSharp: <icons.ArchitectureSharp />,
    ArchitectureTwoTone: <icons.ArchitectureTwoTone />,
    Archive: <icons.Archive />,
    ArchiveOutlined: <icons.ArchiveOutlined />,
    ArchiveRounded: <icons.ArchiveRounded />,
    ArchiveSharp: <icons.ArchiveSharp />,
    ArchiveTwoTone: <icons.ArchiveTwoTone />,
    ArrowBack: <icons.ArrowBack />,
    ArrowBackIos: <icons.ArrowBackIos />,
    ArrowBackIosNew: <icons.ArrowBackIosNew />,
    ArrowBackIosNewOutlined: <icons.ArrowBackIosNewOutlined />,
    ArrowBackIosNewRounded: <icons.ArrowBackIosNewRounded />,
    ArrowBackIosNewSharp: <icons.ArrowBackIosNewSharp />,
    ArrowBackIosNewTwoTone: <icons.ArrowBackIosNewTwoTone />,
    ArrowBackIosOutlined: <icons.ArrowBackIosOutlined />,
    ArrowBackIosRounded: <icons.ArrowBackIosRounded />,
    ArrowBackIosSharp: <icons.ArrowBackIosSharp />,
    ArrowBackIosTwoTone: <icons.ArrowBackIosTwoTone />,
    ArrowBackOutlined: <icons.ArrowBackOutlined />,
    ArrowBackRounded: <icons.ArrowBackRounded />,
    ArrowBackSharp: <icons.ArrowBackSharp />,
    ArrowBackTwoTone: <icons.ArrowBackTwoTone />,
    ArrowCircleDown: <icons.ArrowCircleDown />,
    ArrowCircleDownOutlined: <icons.ArrowCircleDownOutlined />,
    ArrowCircleDownRounded: <icons.ArrowCircleDownRounded />,
    ArrowCircleDownSharp: <icons.ArrowCircleDownSharp />,
    ArrowCircleDownTwoTone: <icons.ArrowCircleDownTwoTone />,
    ArrowCircleLeft: <icons.ArrowCircleLeft />,
    ArrowCircleLeftOutlined: <icons.ArrowCircleLeftOutlined />,
    ArrowCircleLeftRounded: <icons.ArrowCircleLeftRounded />,
    ArrowCircleLeftSharp: <icons.ArrowCircleLeftSharp />,
    ArrowCircleLeftTwoTone: <icons.ArrowCircleLeftTwoTone />,
    ArrowCircleRight: <icons.ArrowCircleRight />,
    ArrowCircleRightOutlined: <icons.ArrowCircleRightOutlined />,
    ArrowCircleRightRounded: <icons.ArrowCircleRightRounded />,
    ArrowCircleRightSharp: <icons.ArrowCircleRightSharp />,
    ArrowCircleRightTwoTone: <icons.ArrowCircleRightTwoTone />,
    ArrowCircleUp: <icons.ArrowCircleUp />,
    ArrowCircleUpOutlined: <icons.ArrowCircleUpOutlined />,
    ArrowCircleUpRounded: <icons.ArrowCircleUpRounded />,
    ArrowCircleUpSharp: <icons.ArrowCircleUpSharp />,
    ArrowCircleUpTwoTone: <icons.ArrowCircleUpTwoTone />,
    ArrowDownward: <icons.ArrowDownward />,
    ArrowDownwardOutlined: <icons.ArrowDownwardOutlined />,
    ArrowDownwardRounded: <icons.ArrowDownwardRounded />,
    ArrowDownwardSharp: <icons.ArrowDownwardSharp />,
    ArrowDownwardTwoTone: <icons.ArrowDownwardTwoTone />,
    ArrowDropDown: <icons.ArrowDropDown />,
    ArrowDropDownCircle: <icons.ArrowDropDownCircle />,
    ArrowDropDownCircleOutlined: <icons.ArrowDropDownCircleOutlined />,
    ArrowDropDownCircleRounded: <icons.ArrowDropDownCircleRounded />,
    ArrowDropDownCircleSharp: <icons.ArrowDropDownCircleSharp />,
    ArrowDropDownCircleTwoTone: <icons.ArrowDropDownCircleTwoTone />,
    ArrowDropDownOutlined: <icons.ArrowDropDownOutlined />,
    ArrowDropDownRounded: <icons.ArrowDropDownRounded />,
    ArrowDropDownSharp: <icons.ArrowDropDownSharp />,
    ArrowDropDownTwoTone: <icons.ArrowDropDownTwoTone />,
    ArrowDropUp: <icons.ArrowDropUp />,
    ArrowDropUpOutlined: <icons.ArrowDropUpOutlined />,
    ArrowDropUpRounded: <icons.ArrowDropUpRounded />,
    ArrowDropUpSharp: <icons.ArrowDropUpSharp />,
    ArrowDropUpTwoTone: <icons.ArrowDropUpTwoTone />,
    ArrowForward: <icons.ArrowForward />,
    ArrowForwardIos: <icons.ArrowForwardIos />,
    ArrowForwardIosOutlined: <icons.ArrowForwardIosOutlined />,
    ArrowForwardIosRounded: <icons.ArrowForwardIosRounded />,
    ArrowForwardIosSharp: <icons.ArrowForwardIosSharp />,
    ArrowForwardIosTwoTone: <icons.ArrowForwardIosTwoTone />,
    ArrowForwardOutlined: <icons.ArrowForwardOutlined />,
    ArrowForwardRounded: <icons.ArrowForwardRounded />,
    ArrowForwardSharp: <icons.ArrowForwardSharp />,
    ArrowForwardTwoTone: <icons.ArrowForwardTwoTone />,
    ArrowLeft: <icons.ArrowLeft />,
    ArrowLeftOutlined: <icons.ArrowLeftOutlined />,
    ArrowLeftRounded: <icons.ArrowLeftRounded />,
    ArrowLeftSharp: <icons.ArrowLeftSharp />,
    ArrowLeftTwoTone: <icons.ArrowLeftTwoTone />,
    ArrowOutward: <icons.ArrowOutward />,
    ArrowOutwardOutlined: <icons.ArrowOutwardOutlined />,
    ArrowOutwardRounded: <icons.ArrowOutwardRounded />,
    ArrowOutwardSharp: <icons.ArrowOutwardSharp />,
    ArrowOutwardTwoTone: <icons.ArrowOutwardTwoTone />,
    ArrowRight: <icons.ArrowRight />,
    ArrowRightAlt: <icons.ArrowRightAlt />,
    ArrowRightAltOutlined: <icons.ArrowRightAltOutlined />,
    ArrowRightAltRounded: <icons.ArrowRightAltRounded />,
    ArrowRightAltSharp: <icons.ArrowRightAltSharp />,
    ArrowRightAltTwoTone: <icons.ArrowRightAltTwoTone />,
    ArrowRightOutlined: <icons.ArrowRightOutlined />,
    ArrowRightRounded: <icons.ArrowRightRounded />,
    ArrowRightSharp: <icons.ArrowRightSharp />,
    ArrowRightTwoTone: <icons.ArrowRightTwoTone />,
    ArrowUpward: <icons.ArrowUpward />,
    ArrowUpwardOutlined: <icons.ArrowUpwardOutlined />,
    ArrowUpwardRounded: <icons.ArrowUpwardRounded />,
    ArrowUpwardSharp: <icons.ArrowUpwardSharp />,
    ArrowUpwardTwoTone: <icons.ArrowUpwardTwoTone />,
    ArtTrack: <icons.ArtTrack />,
    ArtTrackOutlined: <icons.ArtTrackOutlined />,
    ArtTrackRounded: <icons.ArtTrackRounded />,
    ArtTrackSharp: <icons.ArtTrackSharp />,
    ArtTrackTwoTone: <icons.ArtTrackTwoTone />,
    Article: <icons.Article />,
    ArticleOutlined: <icons.ArticleOutlined />,
    ArticleRounded: <icons.ArticleRounded />,
    ArticleSharp: <icons.ArticleSharp />,
    ArticleTwoTone: <icons.ArticleTwoTone />,
    AspectRatio: <icons.AspectRatio />,
    AspectRatioOutlined: <icons.AspectRatioOutlined />,
    AspectRatioRounded: <icons.AspectRatioRounded />,
    AspectRatioSharp: <icons.AspectRatioSharp />,
    AspectRatioTwoTone: <icons.AspectRatioTwoTone />,
    Assessment: <icons.Assessment />,
    AssessmentOutlined: <icons.AssessmentOutlined />,
    AssessmentRounded: <icons.AssessmentRounded />,
    AssessmentSharp: <icons.AssessmentSharp />,
    AssessmentTwoTone: <icons.AssessmentTwoTone />,
    Assignment: <icons.Assignment />,
    AssignmentInd: <icons.AssignmentInd />,
    AssignmentIndOutlined: <icons.AssignmentIndOutlined />,
    AssignmentIndRounded: <icons.AssignmentIndRounded />,
    AssignmentIndSharp: <icons.AssignmentIndSharp />,
    AssignmentIndTwoTone: <icons.AssignmentIndTwoTone />,
    AssignmentLate: <icons.AssignmentLate />,
    AssignmentLateOutlined: <icons.AssignmentLateOutlined />,
    AssignmentLateRounded: <icons.AssignmentLateRounded />,
    AssignmentLateSharp: <icons.AssignmentLateSharp />,
    AssignmentLateTwoTone: <icons.AssignmentLateTwoTone />,
    AssignmentOutlined: <icons.AssignmentOutlined />,
    AssignmentReturn: <icons.AssignmentReturn />,
    AssignmentReturnOutlined: <icons.AssignmentReturnOutlined />,
    AssignmentReturnRounded: <icons.AssignmentReturnRounded />,
    AssignmentReturnSharp: <icons.AssignmentReturnSharp />,
    AssignmentReturnTwoTone: <icons.AssignmentReturnTwoTone />,
    AssignmentReturned: <icons.AssignmentReturned />,
    AssignmentReturnedOutlined: <icons.AssignmentReturnedOutlined />,
    AssignmentReturnedRounded: <icons.AssignmentReturnedRounded />,
    AssignmentReturnedSharp: <icons.AssignmentReturnedSharp />,
    AssignmentReturnedTwoTone: <icons.AssignmentReturnedTwoTone />,
    AssignmentRounded: <icons.AssignmentRounded />,
    AssignmentSharp: <icons.AssignmentSharp />,
    AssignmentTurnedIn: <icons.AssignmentTurnedIn />,
    AssignmentTurnedInOutlined: <icons.AssignmentTurnedInOutlined />,
    AssignmentTurnedInRounded: <icons.AssignmentTurnedInRounded />,
    AssignmentTurnedInSharp: <icons.AssignmentTurnedInSharp />,
    AssignmentTurnedInTwoTone: <icons.AssignmentTurnedInTwoTone />,
    AssignmentTwoTone: <icons.AssignmentTwoTone />,
    AssistWalker: <icons.AssistWalker />,
    AssistWalkerOutlined: <icons.AssistWalkerOutlined />,
    AssistWalkerRounded: <icons.AssistWalkerRounded />,
    AssistWalkerSharp: <icons.AssistWalkerSharp />,
    AssistWalkerTwoTone: <icons.AssistWalkerTwoTone />,
    Assistant: <icons.Assistant />,
    AssistantDirection: <icons.AssistantDirection />,
    AssistantDirectionOutlined: <icons.AssistantDirectionOutlined />,
    AssistantDirectionRounded: <icons.AssistantDirectionRounded />,
    AssistantDirectionSharp: <icons.AssistantDirectionSharp />,
    AssistantDirectionTwoTone: <icons.AssistantDirectionTwoTone />,
    AssistantOutlined: <icons.AssistantOutlined />,
    AssistantPhoto: <icons.AssistantPhoto />,
    AssistantPhotoOutlined: <icons.AssistantPhotoOutlined />,
    AssistantPhotoRounded: <icons.AssistantPhotoRounded />,
    AssistantPhotoSharp: <icons.AssistantPhotoSharp />,
    AssistantPhotoTwoTone: <icons.AssistantPhotoTwoTone />,
    AssistantRounded: <icons.AssistantRounded />,
    AssistantSharp: <icons.AssistantSharp />,
    AssistantTwoTone: <icons.AssistantTwoTone />,
    AssuredWorkload: <icons.AssuredWorkload />,
    AssuredWorkloadOutlined: <icons.AssuredWorkloadOutlined />,
    AssuredWorkloadRounded: <icons.AssuredWorkloadRounded />,
    AssuredWorkloadSharp: <icons.AssuredWorkloadSharp />,
    AssuredWorkloadTwoTone: <icons.AssuredWorkloadTwoTone />,
    Atm: <icons.Atm />,
    AtmOutlined: <icons.AtmOutlined />,
    AtmRounded: <icons.AtmRounded />,
    AtmSharp: <icons.AtmSharp />,
    AtmTwoTone: <icons.AtmTwoTone />,
    AttachEmail: <icons.AttachEmail />,
    AttachEmailOutlined: <icons.AttachEmailOutlined />,
    AttachEmailRounded: <icons.AttachEmailRounded />,
    AttachEmailSharp: <icons.AttachEmailSharp />,
    AttachEmailTwoTone: <icons.AttachEmailTwoTone />,
    AttachFile: <icons.AttachFile />,
    AttachFileOutlined: <icons.AttachFileOutlined />,
    AttachFileRounded: <icons.AttachFileRounded />,
    AttachFileSharp: <icons.AttachFileSharp />,
    AttachFileTwoTone: <icons.AttachFileTwoTone />,
    AttachMoney: <icons.AttachMoney />,
    AttachMoneyOutlined: <icons.AttachMoneyOutlined />,
    AttachMoneyRounded: <icons.AttachMoneyRounded />,
    AttachMoneySharp: <icons.AttachMoneySharp />,
    AttachMoneyTwoTone: <icons.AttachMoneyTwoTone />,
    Attachment: <icons.Attachment />,
    AttachmentOutlined: <icons.AttachmentOutlined />,
    AttachmentRounded: <icons.AttachmentRounded />,
    AttachmentSharp: <icons.AttachmentSharp />,
    AttachmentTwoTone: <icons.AttachmentTwoTone />,
    Attractions: <icons.Attractions />,
    AttractionsOutlined: <icons.AttractionsOutlined />,
    AttractionsRounded: <icons.AttractionsRounded />,
    AttractionsSharp: <icons.AttractionsSharp />,
    AttractionsTwoTone: <icons.AttractionsTwoTone />,
    Attribution: <icons.Attribution />,
    AttributionOutlined: <icons.AttributionOutlined />,
    AttributionRounded: <icons.AttributionRounded />,
    AttributionSharp: <icons.AttributionSharp />,
    AttributionTwoTone: <icons.AttributionTwoTone />,
    AudioFile: <icons.AudioFile />,
    AudioFileOutlined: <icons.AudioFileOutlined />,
    AudioFileRounded: <icons.AudioFileRounded />,
    AudioFileSharp: <icons.AudioFileSharp />,
    AudioFileTwoTone: <icons.AudioFileTwoTone />,
    Audiotrack: <icons.Audiotrack />,
    AudiotrackOutlined: <icons.AudiotrackOutlined />,
    AudiotrackRounded: <icons.AudiotrackRounded />,
    AudiotrackSharp: <icons.AudiotrackSharp />,
    AudiotrackTwoTone: <icons.AudiotrackTwoTone />,
    AutoAwesome: <icons.AutoAwesome />,
    AutoAwesomeMosaic: <icons.AutoAwesomeMosaic />,
    AutoAwesomeMosaicOutlined: <icons.AutoAwesomeMosaicOutlined />,
    AutoAwesomeMosaicRounded: <icons.AutoAwesomeMosaicRounded />,
    AutoAwesomeMosaicSharp: <icons.AutoAwesomeMosaicSharp />,
    AutoAwesomeMosaicTwoTone: <icons.AutoAwesomeMosaicTwoTone />,
    AutoAwesomeMotion: <icons.AutoAwesomeMotion />,
    AutoAwesomeMotionOutlined: <icons.AutoAwesomeMotionOutlined />,
    AutoAwesomeMotionRounded: <icons.AutoAwesomeMotionRounded />,
    AutoAwesomeMotionSharp: <icons.AutoAwesomeMotionSharp />,
    AutoAwesomeMotionTwoTone: <icons.AutoAwesomeMotionTwoTone />,
    AutoAwesomeOutlined: <icons.AutoAwesomeOutlined />,
    AutoAwesomeRounded: <icons.AutoAwesomeRounded />,
    AutoAwesomeSharp: <icons.AutoAwesomeSharp />,
    AutoAwesomeTwoTone: <icons.AutoAwesomeTwoTone />,
    AutoDelete: <icons.AutoDelete />,
    AutoDeleteOutlined: <icons.AutoDeleteOutlined />,
    AutoDeleteRounded: <icons.AutoDeleteRounded />,
    AutoDeleteSharp: <icons.AutoDeleteSharp />,
    AutoDeleteTwoTone: <icons.AutoDeleteTwoTone />,
    AutoFixHigh: <icons.AutoFixHigh />,
    AutoFixHighOutlined: <icons.AutoFixHighOutlined />,
    AutoFixHighRounded: <icons.AutoFixHighRounded />,
    AutoFixHighSharp: <icons.AutoFixHighSharp />,
    AutoFixHighTwoTone: <icons.AutoFixHighTwoTone />,
    AutoFixNormal: <icons.AutoFixNormal />,
    AutoFixNormalOutlined: <icons.AutoFixNormalOutlined />,
    AutoFixNormalRounded: <icons.AutoFixNormalRounded />,
    AutoFixNormalSharp: <icons.AutoFixNormalSharp />,
    AutoFixNormalTwoTone: <icons.AutoFixNormalTwoTone />,
    AutoFixOff: <icons.AutoFixOff />,
    AutoFixOffOutlined: <icons.AutoFixOffOutlined />,
    AutoFixOffRounded: <icons.AutoFixOffRounded />,
    AutoFixOffSharp: <icons.AutoFixOffSharp />,
    AutoFixOffTwoTone: <icons.AutoFixOffTwoTone />,
    AutoGraph: <icons.AutoGraph />,
    AutoGraphOutlined: <icons.AutoGraphOutlined />,
    AutoGraphRounded: <icons.AutoGraphRounded />,
    AutoGraphSharp: <icons.AutoGraphSharp />,
    AutoGraphTwoTone: <icons.AutoGraphTwoTone />,
    AutoMode: <icons.AutoMode />,
    AutoModeOutlined: <icons.AutoModeOutlined />,
    AutoModeRounded: <icons.AutoModeRounded />,
    AutoModeSharp: <icons.AutoModeSharp />,
    AutoModeTwoTone: <icons.AutoModeTwoTone />,
    AutoStories: <icons.AutoStories />,
    AutoStoriesOutlined: <icons.AutoStoriesOutlined />,
    AutoStoriesRounded: <icons.AutoStoriesRounded />,
    AutoStoriesSharp: <icons.AutoStoriesSharp />,
    AutoStoriesTwoTone: <icons.AutoStoriesTwoTone />,
    AutofpsSelect: <icons.AutofpsSelect />,
    AutofpsSelectOutlined: <icons.AutofpsSelectOutlined />,
    AutofpsSelectRounded: <icons.AutofpsSelectRounded />,
    AutofpsSelectSharp: <icons.AutofpsSelectSharp />,
    AutofpsSelectTwoTone: <icons.AutofpsSelectTwoTone />,
    Autorenew: <icons.Autorenew />,
    AutorenewOutlined: <icons.AutorenewOutlined />,
    AutorenewRounded: <icons.AutorenewRounded />,
    AutorenewSharp: <icons.AutorenewSharp />,
    AutorenewTwoTone: <icons.AutorenewTwoTone />,
    AvTimer: <icons.AvTimer />,
    AvTimerOutlined: <icons.AvTimerOutlined />,
    AvTimerRounded: <icons.AvTimerRounded />,
    AvTimerSharp: <icons.AvTimerSharp />,
    AvTimerTwoTone: <icons.AvTimerTwoTone />,
    BabyChangingStation: <icons.BabyChangingStation />,
    BabyChangingStationOutlined: <icons.BabyChangingStationOutlined />,
    BabyChangingStationRounded: <icons.BabyChangingStationRounded />,
    BabyChangingStationSharp: <icons.BabyChangingStationSharp />,
    BabyChangingStationTwoTone: <icons.BabyChangingStationTwoTone />,
    BackHand: <icons.BackHand />,
    BackHandOutlined: <icons.BackHandOutlined />,
    BackHandRounded: <icons.BackHandRounded />,
    BackHandSharp: <icons.BackHandSharp />,
    BackHandTwoTone: <icons.BackHandTwoTone />,
    Backpack: <icons.Backpack />,
    BackpackOutlined: <icons.BackpackOutlined />,
    BackpackRounded: <icons.BackpackRounded />,
    BackpackSharp: <icons.BackpackSharp />,
    BackpackTwoTone: <icons.BackpackTwoTone />,
    Backspace: <icons.Backspace />,
    BackspaceOutlined: <icons.BackspaceOutlined />,
    BackspaceRounded: <icons.BackspaceRounded />,
    BackspaceSharp: <icons.BackspaceSharp />,
    BackspaceTwoTone: <icons.BackspaceTwoTone />,
    Backup: <icons.Backup />,
    BackupOutlined: <icons.BackupOutlined />,
    BackupRounded: <icons.BackupRounded />,
    BackupSharp: <icons.BackupSharp />,
    BackupTable: <icons.BackupTable />,
    BackupTableOutlined: <icons.BackupTableOutlined />,
    BackupTableRounded: <icons.BackupTableRounded />,
    BackupTableSharp: <icons.BackupTableSharp />,
    BackupTableTwoTone: <icons.BackupTableTwoTone />,
    BackupTwoTone: <icons.BackupTwoTone />,
    Badge: <icons.Badge />,
    BadgeOutlined: <icons.BadgeOutlined />,
    BadgeRounded: <icons.BadgeRounded />,
    BadgeSharp: <icons.BadgeSharp />,
    BadgeTwoTone: <icons.BadgeTwoTone />,
    BakeryDining: <icons.BakeryDining />,
    BakeryDiningOutlined: <icons.BakeryDiningOutlined />,
    BakeryDiningRounded: <icons.BakeryDiningRounded />,
    BakeryDiningSharp: <icons.BakeryDiningSharp />,
    BakeryDiningTwoTone: <icons.BakeryDiningTwoTone />,
    Balance: <icons.Balance />,
    BalanceOutlined: <icons.BalanceOutlined />,
    BalanceRounded: <icons.BalanceRounded />,
    BalanceSharp: <icons.BalanceSharp />,
    BalanceTwoTone: <icons.BalanceTwoTone />,
    Balcony: <icons.Balcony />,
    BalconyOutlined: <icons.BalconyOutlined />,
    BalconyRounded: <icons.BalconyRounded />,
    BalconySharp: <icons.BalconySharp />,
    BalconyTwoTone: <icons.BalconyTwoTone />,
    Ballot: <icons.Ballot />,
    BallotOutlined: <icons.BallotOutlined />,
    BallotRounded: <icons.BallotRounded />,
    BallotSharp: <icons.BallotSharp />,
    BallotTwoTone: <icons.BallotTwoTone />,
    BarChart: <icons.BarChart />,
    BarChartOutlined: <icons.BarChartOutlined />,
    BarChartRounded: <icons.BarChartRounded />,
    BarChartSharp: <icons.BarChartSharp />,
    BarChartTwoTone: <icons.BarChartTwoTone />,
    BatchPrediction: <icons.BatchPrediction />,
    BatchPredictionOutlined: <icons.BatchPredictionOutlined />,
    BatchPredictionRounded: <icons.BatchPredictionRounded />,
    BatchPredictionSharp: <icons.BatchPredictionSharp />,
    BatchPredictionTwoTone: <icons.BatchPredictionTwoTone />,
    Bathroom: <icons.Bathroom />,
    BathroomOutlined: <icons.BathroomOutlined />,
    BathroomRounded: <icons.BathroomRounded />,
    BathroomSharp: <icons.BathroomSharp />,
    BathroomTwoTone: <icons.BathroomTwoTone />,
    Bathtub: <icons.Bathtub />,
    BathtubOutlined: <icons.BathtubOutlined />,
    BathtubRounded: <icons.BathtubRounded />,
    BathtubSharp: <icons.BathtubSharp />,
    BathtubTwoTone: <icons.BathtubTwoTone />,
    Battery0Bar: <icons.Battery0Bar />,
    Battery0BarOutlined: <icons.Battery0BarOutlined />,
    Battery0BarRounded: <icons.Battery0BarRounded />,
    Battery0BarSharp: <icons.Battery0BarSharp />,
    Battery0BarTwoTone: <icons.Battery0BarTwoTone />,
    Battery1Bar: <icons.Battery1Bar />,
    Battery1BarOutlined: <icons.Battery1BarOutlined />,
    Battery1BarRounded: <icons.Battery1BarRounded />,
    Battery1BarSharp: <icons.Battery1BarSharp />,
    Battery1BarTwoTone: <icons.Battery1BarTwoTone />,
    Battery20: <icons.Battery20 />,
    Battery20Outlined: <icons.Battery20Outlined />,
    Battery20Rounded: <icons.Battery20Rounded />,
    Battery20Sharp: <icons.Battery20Sharp />,
    Battery20TwoTone: <icons.Battery20TwoTone />,
    Battery2Bar: <icons.Battery2Bar />,
    Battery2BarOutlined: <icons.Battery2BarOutlined />,
    Battery2BarRounded: <icons.Battery2BarRounded />,
    Battery2BarSharp: <icons.Battery2BarSharp />,
    Battery2BarTwoTone: <icons.Battery2BarTwoTone />,
    Battery30: <icons.Battery30 />,
    Battery30Outlined: <icons.Battery30Outlined />,
    Battery30Rounded: <icons.Battery30Rounded />,
    Battery30Sharp: <icons.Battery30Sharp />,
    Battery30TwoTone: <icons.Battery30TwoTone />,
    Battery3Bar: <icons.Battery3Bar />,
    Battery3BarOutlined: <icons.Battery3BarOutlined />,
    Battery3BarRounded: <icons.Battery3BarRounded />,
    Battery3BarSharp: <icons.Battery3BarSharp />,
    Battery3BarTwoTone: <icons.Battery3BarTwoTone />,
    Battery4Bar: <icons.Battery4Bar />,
    Battery4BarOutlined: <icons.Battery4BarOutlined />,
    Battery4BarRounded: <icons.Battery4BarRounded />,
    Battery4BarSharp: <icons.Battery4BarSharp />,
    Battery4BarTwoTone: <icons.Battery4BarTwoTone />,
    Battery50: <icons.Battery50 />,
    Battery50Outlined: <icons.Battery50Outlined />,
    Battery50Rounded: <icons.Battery50Rounded />,
    Battery50Sharp: <icons.Battery50Sharp />,
    Battery50TwoTone: <icons.Battery50TwoTone />,
    Battery5Bar: <icons.Battery5Bar />,
    Battery5BarOutlined: <icons.Battery5BarOutlined />,
    Battery5BarRounded: <icons.Battery5BarRounded />,
    Battery5BarSharp: <icons.Battery5BarSharp />,
    Battery5BarTwoTone: <icons.Battery5BarTwoTone />,
    Battery60: <icons.Battery60 />,
    Battery60Outlined: <icons.Battery60Outlined />,
    Battery60Rounded: <icons.Battery60Rounded />,
    Battery60Sharp: <icons.Battery60Sharp />,
    Battery60TwoTone: <icons.Battery60TwoTone />,
    Battery6Bar: <icons.Battery6Bar />,
    Battery6BarOutlined: <icons.Battery6BarOutlined />,
    Battery6BarRounded: <icons.Battery6BarRounded />,
    Battery6BarSharp: <icons.Battery6BarSharp />,
    Battery6BarTwoTone: <icons.Battery6BarTwoTone />,
    Battery80: <icons.Battery80 />,
    Battery80Outlined: <icons.Battery80Outlined />,
    Battery80Rounded: <icons.Battery80Rounded />,
    Battery80Sharp: <icons.Battery80Sharp />,
    Battery80TwoTone: <icons.Battery80TwoTone />,
    Battery90: <icons.Battery90 />,
    Battery90Outlined: <icons.Battery90Outlined />,
    Battery90Rounded: <icons.Battery90Rounded />,
    Battery90Sharp: <icons.Battery90Sharp />,
    Battery90TwoTone: <icons.Battery90TwoTone />,
    BatteryAlert: <icons.BatteryAlert />,
    BatteryAlertOutlined: <icons.BatteryAlertOutlined />,
    BatteryAlertRounded: <icons.BatteryAlertRounded />,
    BatteryAlertSharp: <icons.BatteryAlertSharp />,
    BatteryAlertTwoTone: <icons.BatteryAlertTwoTone />,
    BatteryCharging20: <icons.BatteryCharging20 />,
    BatteryCharging20Outlined: <icons.BatteryCharging20Outlined />,
    BatteryCharging20Rounded: <icons.BatteryCharging20Rounded />,
    BatteryCharging20Sharp: <icons.BatteryCharging20Sharp />,
    BatteryCharging20TwoTone: <icons.BatteryCharging20TwoTone />,
    BatteryCharging30: <icons.BatteryCharging30 />,
    BatteryCharging30Outlined: <icons.BatteryCharging30Outlined />,
    BatteryCharging30Rounded: <icons.BatteryCharging30Rounded />,
    BatteryCharging30Sharp: <icons.BatteryCharging30Sharp />,
    BatteryCharging30TwoTone: <icons.BatteryCharging30TwoTone />,
    BatteryCharging50: <icons.BatteryCharging50 />,
    BatteryCharging50Outlined: <icons.BatteryCharging50Outlined />,
    BatteryCharging50Rounded: <icons.BatteryCharging50Rounded />,
    BatteryCharging50Sharp: <icons.BatteryCharging50Sharp />,
    BatteryCharging50TwoTone: <icons.BatteryCharging50TwoTone />,
    BatteryCharging60: <icons.BatteryCharging60 />,
    BatteryCharging60Outlined: <icons.BatteryCharging60Outlined />,
    BatteryCharging60Rounded: <icons.BatteryCharging60Rounded />,
    BatteryCharging60Sharp: <icons.BatteryCharging60Sharp />,
    BatteryCharging60TwoTone: <icons.BatteryCharging60TwoTone />,
    BatteryCharging80: <icons.BatteryCharging80 />,
    BatteryCharging80Outlined: <icons.BatteryCharging80Outlined />,
    BatteryCharging80Rounded: <icons.BatteryCharging80Rounded />,
    BatteryCharging80Sharp: <icons.BatteryCharging80Sharp />,
    BatteryCharging80TwoTone: <icons.BatteryCharging80TwoTone />,
    BatteryCharging90: <icons.BatteryCharging90 />,
    BatteryCharging90Outlined: <icons.BatteryCharging90Outlined />,
    BatteryCharging90Rounded: <icons.BatteryCharging90Rounded />,
    BatteryCharging90Sharp: <icons.BatteryCharging90Sharp />,
    BatteryCharging90TwoTone: <icons.BatteryCharging90TwoTone />,
    BatteryChargingFull: <icons.BatteryChargingFull />,
    BatteryChargingFullOutlined: <icons.BatteryChargingFullOutlined />,
    BatteryChargingFullRounded: <icons.BatteryChargingFullRounded />,
    BatteryChargingFullSharp: <icons.BatteryChargingFullSharp />,
    BatteryChargingFullTwoTone: <icons.BatteryChargingFullTwoTone />,
    BatteryFull: <icons.BatteryFull />,
    BatteryFullOutlined: <icons.BatteryFullOutlined />,
    BatteryFullRounded: <icons.BatteryFullRounded />,
    BatteryFullSharp: <icons.BatteryFullSharp />,
    BatteryFullTwoTone: <icons.BatteryFullTwoTone />,
    BatterySaver: <icons.BatterySaver />,
    BatterySaverOutlined: <icons.BatterySaverOutlined />,
    BatterySaverRounded: <icons.BatterySaverRounded />,
    BatterySaverSharp: <icons.BatterySaverSharp />,
    BatterySaverTwoTone: <icons.BatterySaverTwoTone />,
    BatteryStd: <icons.BatteryStd />,
    BatteryStdOutlined: <icons.BatteryStdOutlined />,
    BatteryStdRounded: <icons.BatteryStdRounded />,
    BatteryStdSharp: <icons.BatteryStdSharp />,
    BatteryStdTwoTone: <icons.BatteryStdTwoTone />,
    BatteryUnknown: <icons.BatteryUnknown />,
    BatteryUnknownOutlined: <icons.BatteryUnknownOutlined />,
    BatteryUnknownRounded: <icons.BatteryUnknownRounded />,
    BatteryUnknownSharp: <icons.BatteryUnknownSharp />,
    BatteryUnknownTwoTone: <icons.BatteryUnknownTwoTone />,
    BeachAccess: <icons.BeachAccess />,
    BeachAccessOutlined: <icons.BeachAccessOutlined />,
    BeachAccessRounded: <icons.BeachAccessRounded />,
    BeachAccessSharp: <icons.BeachAccessSharp />,
    BeachAccessTwoTone: <icons.BeachAccessTwoTone />,
    Bed: <icons.Bed />,
    BedOutlined: <icons.BedOutlined />,
    BedRounded: <icons.BedRounded />,
    BedSharp: <icons.BedSharp />,
    BedTwoTone: <icons.BedTwoTone />,
    BedroomBaby: <icons.BedroomBaby />,
    BedroomBabyOutlined: <icons.BedroomBabyOutlined />,
    BedroomBabyRounded: <icons.BedroomBabyRounded />,
    BedroomBabySharp: <icons.BedroomBabySharp />,
    BedroomBabyTwoTone: <icons.BedroomBabyTwoTone />,
    BedroomChild: <icons.BedroomChild />,
    BedroomChildOutlined: <icons.BedroomChildOutlined />,
    BedroomChildRounded: <icons.BedroomChildRounded />,
    BedroomChildSharp: <icons.BedroomChildSharp />,
    BedroomChildTwoTone: <icons.BedroomChildTwoTone />,
    BedroomParent: <icons.BedroomParent />,
    BedroomParentOutlined: <icons.BedroomParentOutlined />,
    BedroomParentRounded: <icons.BedroomParentRounded />,
    BedroomParentSharp: <icons.BedroomParentSharp />,
    BedroomParentTwoTone: <icons.BedroomParentTwoTone />,
    Bedtime: <icons.Bedtime />,
    BedtimeOff: <icons.BedtimeOff />,
    BedtimeOffOutlined: <icons.BedtimeOffOutlined />,
    BedtimeOffRounded: <icons.BedtimeOffRounded />,
    BedtimeOffSharp: <icons.BedtimeOffSharp />,
    BedtimeOffTwoTone: <icons.BedtimeOffTwoTone />,
    BedtimeOutlined: <icons.BedtimeOutlined />,
    BedtimeRounded: <icons.BedtimeRounded />,
    BedtimeSharp: <icons.BedtimeSharp />,
    BedtimeTwoTone: <icons.BedtimeTwoTone />,
    Beenhere: <icons.Beenhere />,
    BeenhereOutlined: <icons.BeenhereOutlined />,
    BeenhereRounded: <icons.BeenhereRounded />,
    BeenhereSharp: <icons.BeenhereSharp />,
    BeenhereTwoTone: <icons.BeenhereTwoTone />,
    Bento: <icons.Bento />,
    BentoOutlined: <icons.BentoOutlined />,
    BentoRounded: <icons.BentoRounded />,
    BentoSharp: <icons.BentoSharp />,
    BentoTwoTone: <icons.BentoTwoTone />,
    BikeScooter: <icons.BikeScooter />,
    BikeScooterOutlined: <icons.BikeScooterOutlined />,
    BikeScooterRounded: <icons.BikeScooterRounded />,
    BikeScooterSharp: <icons.BikeScooterSharp />,
    BikeScooterTwoTone: <icons.BikeScooterTwoTone />,
    Biotech: <icons.Biotech />,
    BiotechOutlined: <icons.BiotechOutlined />,
    BiotechRounded: <icons.BiotechRounded />,
    BiotechSharp: <icons.BiotechSharp />,
    BiotechTwoTone: <icons.BiotechTwoTone />,
    Blender: <icons.Blender />,
    BlenderOutlined: <icons.BlenderOutlined />,
    BlenderRounded: <icons.BlenderRounded />,
    BlenderSharp: <icons.BlenderSharp />,
    BlenderTwoTone: <icons.BlenderTwoTone />,
    Blind: <icons.Blind />,
    BlindOutlined: <icons.BlindOutlined />,
    BlindRounded: <icons.BlindRounded />,
    BlindSharp: <icons.BlindSharp />,
    BlindTwoTone: <icons.BlindTwoTone />,
    Blinds: <icons.Blinds />,
    BlindsClosed: <icons.BlindsClosed />,
    BlindsClosedOutlined: <icons.BlindsClosedOutlined />,
    BlindsClosedRounded: <icons.BlindsClosedRounded />,
    BlindsClosedSharp: <icons.BlindsClosedSharp />,
    BlindsClosedTwoTone: <icons.BlindsClosedTwoTone />,
    BlindsOutlined: <icons.BlindsOutlined />,
    BlindsRounded: <icons.BlindsRounded />,
    BlindsSharp: <icons.BlindsSharp />,
    BlindsTwoTone: <icons.BlindsTwoTone />,
    Block: <icons.Block />,
    BlockOutlined: <icons.BlockOutlined />,
    BlockRounded: <icons.BlockRounded />,
    BlockSharp: <icons.BlockSharp />,
    BlockTwoTone: <icons.BlockTwoTone />,
    Bloodtype: <icons.Bloodtype />,
    BloodtypeOutlined: <icons.BloodtypeOutlined />,
    BloodtypeRounded: <icons.BloodtypeRounded />,
    BloodtypeSharp: <icons.BloodtypeSharp />,
    BloodtypeTwoTone: <icons.BloodtypeTwoTone />,
    Bluetooth: <icons.Bluetooth />,
    BluetoothAudio: <icons.BluetoothAudio />,
    BluetoothAudioOutlined: <icons.BluetoothAudioOutlined />,
    BluetoothAudioRounded: <icons.BluetoothAudioRounded />,
    BluetoothAudioSharp: <icons.BluetoothAudioSharp />,
    BluetoothAudioTwoTone: <icons.BluetoothAudioTwoTone />,
    BluetoothConnected: <icons.BluetoothConnected />,
    BluetoothConnectedOutlined: <icons.BluetoothConnectedOutlined />,
    BluetoothConnectedRounded: <icons.BluetoothConnectedRounded />,
    BluetoothConnectedSharp: <icons.BluetoothConnectedSharp />,
    BluetoothConnectedTwoTone: <icons.BluetoothConnectedTwoTone />,
    BluetoothDisabled: <icons.BluetoothDisabled />,
    BluetoothDisabledOutlined: <icons.BluetoothDisabledOutlined />,
    BluetoothDisabledRounded: <icons.BluetoothDisabledRounded />,
    BluetoothDisabledSharp: <icons.BluetoothDisabledSharp />,
    BluetoothDisabledTwoTone: <icons.BluetoothDisabledTwoTone />,
    BluetoothDrive: <icons.BluetoothDrive />,
    BluetoothDriveOutlined: <icons.BluetoothDriveOutlined />,
    BluetoothDriveRounded: <icons.BluetoothDriveRounded />,
    BluetoothDriveSharp: <icons.BluetoothDriveSharp />,
    BluetoothDriveTwoTone: <icons.BluetoothDriveTwoTone />,
    BluetoothOutlined: <icons.BluetoothOutlined />,
    BluetoothRounded: <icons.BluetoothRounded />,
    BluetoothSearching: <icons.BluetoothSearching />,
    BluetoothSearchingOutlined: <icons.BluetoothSearchingOutlined />,
    BluetoothSearchingRounded: <icons.BluetoothSearchingRounded />,
    BluetoothSearchingSharp: <icons.BluetoothSearchingSharp />,
    BluetoothSearchingTwoTone: <icons.BluetoothSearchingTwoTone />,
    BluetoothSharp: <icons.BluetoothSharp />,
    BluetoothTwoTone: <icons.BluetoothTwoTone />,
    BlurCircular: <icons.BlurCircular />,
    BlurCircularOutlined: <icons.BlurCircularOutlined />,
    BlurCircularRounded: <icons.BlurCircularRounded />,
    BlurCircularSharp: <icons.BlurCircularSharp />,
    BlurCircularTwoTone: <icons.BlurCircularTwoTone />,
    BlurLinear: <icons.BlurLinear />,
    BlurLinearOutlined: <icons.BlurLinearOutlined />,
    BlurLinearRounded: <icons.BlurLinearRounded />,
    BlurLinearSharp: <icons.BlurLinearSharp />,
    BlurLinearTwoTone: <icons.BlurLinearTwoTone />,
    BlurOff: <icons.BlurOff />,
    BlurOffOutlined: <icons.BlurOffOutlined />,
    BlurOffRounded: <icons.BlurOffRounded />,
    BlurOffSharp: <icons.BlurOffSharp />,
    BlurOffTwoTone: <icons.BlurOffTwoTone />,
    BlurOn: <icons.BlurOn />,
    BlurOnOutlined: <icons.BlurOnOutlined />,
    BlurOnRounded: <icons.BlurOnRounded />,
    BlurOnSharp: <icons.BlurOnSharp />,
    BlurOnTwoTone: <icons.BlurOnTwoTone />,
    Bolt: <icons.Bolt />,
    BoltOutlined: <icons.BoltOutlined />,
    BoltRounded: <icons.BoltRounded />,
    BoltSharp: <icons.BoltSharp />,
    BoltTwoTone: <icons.BoltTwoTone />,
    Book: <icons.Book />,
    BookOnline: <icons.BookOnline />,
    BookOnlineOutlined: <icons.BookOnlineOutlined />,
    BookOnlineRounded: <icons.BookOnlineRounded />,
    BookOnlineSharp: <icons.BookOnlineSharp />,
    BookOnlineTwoTone: <icons.BookOnlineTwoTone />,
    BookOutlined: <icons.BookOutlined />,
    BookRounded: <icons.BookRounded />,
    BookSharp: <icons.BookSharp />,
    BookTwoTone: <icons.BookTwoTone />,
    Bookmark: <icons.Bookmark />,
    BookmarkAdd: <icons.BookmarkAdd />,
    BookmarkAddOutlined: <icons.BookmarkAddOutlined />,
    BookmarkAddRounded: <icons.BookmarkAddRounded />,
    BookmarkAddSharp: <icons.BookmarkAddSharp />,
    BookmarkAddTwoTone: <icons.BookmarkAddTwoTone />,
    BookmarkAdded: <icons.BookmarkAdded />,
    BookmarkAddedOutlined: <icons.BookmarkAddedOutlined />,
    BookmarkAddedRounded: <icons.BookmarkAddedRounded />,
    BookmarkAddedSharp: <icons.BookmarkAddedSharp />,
    BookmarkAddedTwoTone: <icons.BookmarkAddedTwoTone />,
    BookmarkBorder: <icons.BookmarkBorder />,
    BookmarkBorderOutlined: <icons.BookmarkBorderOutlined />,
    BookmarkBorderRounded: <icons.BookmarkBorderRounded />,
    BookmarkBorderSharp: <icons.BookmarkBorderSharp />,
    BookmarkBorderTwoTone: <icons.BookmarkBorderTwoTone />,
    BookmarkOutlined: <icons.BookmarkOutlined />,
    BookmarkRemove: <icons.BookmarkRemove />,
    BookmarkRemoveOutlined: <icons.BookmarkRemoveOutlined />,
    BookmarkRemoveRounded: <icons.BookmarkRemoveRounded />,
    BookmarkRemoveSharp: <icons.BookmarkRemoveSharp />,
    BookmarkRemoveTwoTone: <icons.BookmarkRemoveTwoTone />,
    BookmarkRounded: <icons.BookmarkRounded />,
    BookmarkSharp: <icons.BookmarkSharp />,
    BookmarkTwoTone: <icons.BookmarkTwoTone />,
    Bookmarks: <icons.Bookmarks />,
    BookmarksOutlined: <icons.BookmarksOutlined />,
    BookmarksRounded: <icons.BookmarksRounded />,
    BookmarksSharp: <icons.BookmarksSharp />,
    BookmarksTwoTone: <icons.BookmarksTwoTone />,
    BorderAll: <icons.BorderAll />,
    BorderAllOutlined: <icons.BorderAllOutlined />,
    BorderAllRounded: <icons.BorderAllRounded />,
    BorderAllSharp: <icons.BorderAllSharp />,
    BorderAllTwoTone: <icons.BorderAllTwoTone />,
    BorderBottom: <icons.BorderBottom />,
    BorderBottomOutlined: <icons.BorderBottomOutlined />,
    BorderBottomRounded: <icons.BorderBottomRounded />,
    BorderBottomSharp: <icons.BorderBottomSharp />,
    BorderBottomTwoTone: <icons.BorderBottomTwoTone />,
    BorderClear: <icons.BorderClear />,
    BorderClearOutlined: <icons.BorderClearOutlined />,
    BorderClearRounded: <icons.BorderClearRounded />,
    BorderClearSharp: <icons.BorderClearSharp />,
    BorderClearTwoTone: <icons.BorderClearTwoTone />,
    BorderColor: <icons.BorderColor />,
    BorderColorOutlined: <icons.BorderColorOutlined />,
    BorderColorRounded: <icons.BorderColorRounded />,
    BorderColorSharp: <icons.BorderColorSharp />,
    BorderColorTwoTone: <icons.BorderColorTwoTone />,
    BorderHorizontal: <icons.BorderHorizontal />,
    BorderHorizontalOutlined: <icons.BorderHorizontalOutlined />,
    BorderHorizontalRounded: <icons.BorderHorizontalRounded />,
    BorderHorizontalSharp: <icons.BorderHorizontalSharp />,
    BorderHorizontalTwoTone: <icons.BorderHorizontalTwoTone />,
    BorderInner: <icons.BorderInner />,
    BorderInnerOutlined: <icons.BorderInnerOutlined />,
    BorderInnerRounded: <icons.BorderInnerRounded />,
    BorderInnerSharp: <icons.BorderInnerSharp />,
    BorderInnerTwoTone: <icons.BorderInnerTwoTone />,
    BorderLeft: <icons.BorderLeft />,
    BorderLeftOutlined: <icons.BorderLeftOutlined />,
    BorderLeftRounded: <icons.BorderLeftRounded />,
    BorderLeftSharp: <icons.BorderLeftSharp />,
    BorderLeftTwoTone: <icons.BorderLeftTwoTone />,
    BorderOuter: <icons.BorderOuter />,
    BorderOuterOutlined: <icons.BorderOuterOutlined />,
    BorderOuterRounded: <icons.BorderOuterRounded />,
    BorderOuterSharp: <icons.BorderOuterSharp />,
    BorderOuterTwoTone: <icons.BorderOuterTwoTone />,
    BorderRight: <icons.BorderRight />,
    BorderRightOutlined: <icons.BorderRightOutlined />,
    BorderRightRounded: <icons.BorderRightRounded />,
    BorderRightSharp: <icons.BorderRightSharp />,
    BorderRightTwoTone: <icons.BorderRightTwoTone />,
    BorderStyle: <icons.BorderStyle />,
    BorderStyleOutlined: <icons.BorderStyleOutlined />,
    BorderStyleRounded: <icons.BorderStyleRounded />,
    BorderStyleSharp: <icons.BorderStyleSharp />,
    BorderStyleTwoTone: <icons.BorderStyleTwoTone />,
    BorderTop: <icons.BorderTop />,
    BorderTopOutlined: <icons.BorderTopOutlined />,
    BorderTopRounded: <icons.BorderTopRounded />,
    BorderTopSharp: <icons.BorderTopSharp />,
    BorderTopTwoTone: <icons.BorderTopTwoTone />,
    BorderVertical: <icons.BorderVertical />,
    BorderVerticalOutlined: <icons.BorderVerticalOutlined />,
    BorderVerticalRounded: <icons.BorderVerticalRounded />,
    BorderVerticalSharp: <icons.BorderVerticalSharp />,
    BorderVerticalTwoTone: <icons.BorderVerticalTwoTone />,
    Boy: <icons.Boy />,
    BoyOutlined: <icons.BoyOutlined />,
    BoyRounded: <icons.BoyRounded />,
    BoySharp: <icons.BoySharp />,
    BoyTwoTone: <icons.BoyTwoTone />,
    BrandingWatermark: <icons.BrandingWatermark />,
    BrandingWatermarkOutlined: <icons.BrandingWatermarkOutlined />,
    BrandingWatermarkRounded: <icons.BrandingWatermarkRounded />,
    BrandingWatermarkSharp: <icons.BrandingWatermarkSharp />,
    BrandingWatermarkTwoTone: <icons.BrandingWatermarkTwoTone />,
    BreakfastDining: <icons.BreakfastDining />,
    BreakfastDiningOutlined: <icons.BreakfastDiningOutlined />,
    BreakfastDiningRounded: <icons.BreakfastDiningRounded />,
    BreakfastDiningSharp: <icons.BreakfastDiningSharp />,
    BreakfastDiningTwoTone: <icons.BreakfastDiningTwoTone />,
    Brightness1: <icons.Brightness1 />,
    Brightness1Outlined: <icons.Brightness1Outlined />,
    Brightness1Rounded: <icons.Brightness1Rounded />,
    Brightness1Sharp: <icons.Brightness1Sharp />,
    Brightness1TwoTone: <icons.Brightness1TwoTone />,
    Brightness2: <icons.Brightness2 />,
    Brightness2Outlined: <icons.Brightness2Outlined />,
    Brightness2Rounded: <icons.Brightness2Rounded />,
    Brightness2Sharp: <icons.Brightness2Sharp />,
    Brightness2TwoTone: <icons.Brightness2TwoTone />,
    Brightness3: <icons.Brightness3 />,
    Brightness3Outlined: <icons.Brightness3Outlined />,
    Brightness3Rounded: <icons.Brightness3Rounded />,
    Brightness3Sharp: <icons.Brightness3Sharp />,
    Brightness3TwoTone: <icons.Brightness3TwoTone />,
    Brightness4: <icons.Brightness4 />,
    Brightness4Outlined: <icons.Brightness4Outlined />,
    Brightness4Rounded: <icons.Brightness4Rounded />,
    Brightness4Sharp: <icons.Brightness4Sharp />,
    Brightness4TwoTone: <icons.Brightness4TwoTone />,
    Brightness5: <icons.Brightness5 />,
    Brightness5Outlined: <icons.Brightness5Outlined />,
    Brightness5Rounded: <icons.Brightness5Rounded />,
    Brightness5Sharp: <icons.Brightness5Sharp />,
    Brightness5TwoTone: <icons.Brightness5TwoTone />,
    Brightness6: <icons.Brightness6 />,
    Brightness6Outlined: <icons.Brightness6Outlined />,
    Brightness6Rounded: <icons.Brightness6Rounded />,
    Brightness6Sharp: <icons.Brightness6Sharp />,
    Brightness6TwoTone: <icons.Brightness6TwoTone />,
    Brightness7: <icons.Brightness7 />,
    Brightness7Outlined: <icons.Brightness7Outlined />,
    Brightness7Rounded: <icons.Brightness7Rounded />,
    Brightness7Sharp: <icons.Brightness7Sharp />,
    Brightness7TwoTone: <icons.Brightness7TwoTone />,
    BrightnessAuto: <icons.BrightnessAuto />,
    BrightnessAutoOutlined: <icons.BrightnessAutoOutlined />,
    BrightnessAutoRounded: <icons.BrightnessAutoRounded />,
    BrightnessAutoSharp: <icons.BrightnessAutoSharp />,
    BrightnessAutoTwoTone: <icons.BrightnessAutoTwoTone />,
    BrightnessHigh: <icons.BrightnessHigh />,
    BrightnessHighOutlined: <icons.BrightnessHighOutlined />,
    BrightnessHighRounded: <icons.BrightnessHighRounded />,
    BrightnessHighSharp: <icons.BrightnessHighSharp />,
    BrightnessHighTwoTone: <icons.BrightnessHighTwoTone />,
    BrightnessLow: <icons.BrightnessLow />,
    BrightnessLowOutlined: <icons.BrightnessLowOutlined />,
    BrightnessLowRounded: <icons.BrightnessLowRounded />,
    BrightnessLowSharp: <icons.BrightnessLowSharp />,
    BrightnessLowTwoTone: <icons.BrightnessLowTwoTone />,
    BrightnessMedium: <icons.BrightnessMedium />,
    BrightnessMediumOutlined: <icons.BrightnessMediumOutlined />,
    BrightnessMediumRounded: <icons.BrightnessMediumRounded />,
    BrightnessMediumSharp: <icons.BrightnessMediumSharp />,
    BrightnessMediumTwoTone: <icons.BrightnessMediumTwoTone />,
    BroadcastOnHome: <icons.BroadcastOnHome />,
    BroadcastOnHomeOutlined: <icons.BroadcastOnHomeOutlined />,
    BroadcastOnHomeRounded: <icons.BroadcastOnHomeRounded />,
    BroadcastOnHomeSharp: <icons.BroadcastOnHomeSharp />,
    BroadcastOnHomeTwoTone: <icons.BroadcastOnHomeTwoTone />,
    BroadcastOnPersonal: <icons.BroadcastOnPersonal />,
    BroadcastOnPersonalOutlined: <icons.BroadcastOnPersonalOutlined />,
    BroadcastOnPersonalRounded: <icons.BroadcastOnPersonalRounded />,
    BroadcastOnPersonalSharp: <icons.BroadcastOnPersonalSharp />,
    BroadcastOnPersonalTwoTone: <icons.BroadcastOnPersonalTwoTone />,
    BrokenImage: <icons.BrokenImage />,
    BrokenImageOutlined: <icons.BrokenImageOutlined />,
    BrokenImageRounded: <icons.BrokenImageRounded />,
    BrokenImageSharp: <icons.BrokenImageSharp />,
    BrokenImageTwoTone: <icons.BrokenImageTwoTone />,
    BrowseGallery: <icons.BrowseGallery />,
    BrowseGalleryOutlined: <icons.BrowseGalleryOutlined />,
    BrowseGalleryRounded: <icons.BrowseGalleryRounded />,
    BrowseGallerySharp: <icons.BrowseGallerySharp />,
    BrowseGalleryTwoTone: <icons.BrowseGalleryTwoTone />,
    BrowserNotSupported: <icons.BrowserNotSupported />,
    BrowserNotSupportedOutlined: <icons.BrowserNotSupportedOutlined />,
    BrowserNotSupportedRounded: <icons.BrowserNotSupportedRounded />,
    BrowserNotSupportedSharp: <icons.BrowserNotSupportedSharp />,
    BrowserNotSupportedTwoTone: <icons.BrowserNotSupportedTwoTone />,
    BrowserUpdated: <icons.BrowserUpdated />,
    BrowserUpdatedOutlined: <icons.BrowserUpdatedOutlined />,
    BrowserUpdatedRounded: <icons.BrowserUpdatedRounded />,
    BrowserUpdatedSharp: <icons.BrowserUpdatedSharp />,
    BrowserUpdatedTwoTone: <icons.BrowserUpdatedTwoTone />,
    BrunchDining: <icons.BrunchDining />,
    BrunchDiningOutlined: <icons.BrunchDiningOutlined />,
    BrunchDiningRounded: <icons.BrunchDiningRounded />,
    BrunchDiningSharp: <icons.BrunchDiningSharp />,
    BrunchDiningTwoTone: <icons.BrunchDiningTwoTone />,
    Brush: <icons.Brush />,
    BrushOutlined: <icons.BrushOutlined />,
    BrushRounded: <icons.BrushRounded />,
    BrushSharp: <icons.BrushSharp />,
    BrushTwoTone: <icons.BrushTwoTone />,
    BubbleChart: <icons.BubbleChart />,
    BubbleChartOutlined: <icons.BubbleChartOutlined />,
    BubbleChartRounded: <icons.BubbleChartRounded />,
    BubbleChartSharp: <icons.BubbleChartSharp />,
    BubbleChartTwoTone: <icons.BubbleChartTwoTone />,
    BugReport: <icons.BugReport />,
    BugReportOutlined: <icons.BugReportOutlined />,
    BugReportRounded: <icons.BugReportRounded />,
    BugReportSharp: <icons.BugReportSharp />,
    BugReportTwoTone: <icons.BugReportTwoTone />,
    Build: <icons.Build />,
    BuildCircle: <icons.BuildCircle />,
    BuildCircleOutlined: <icons.BuildCircleOutlined />,
    BuildCircleRounded: <icons.BuildCircleRounded />,
    BuildCircleSharp: <icons.BuildCircleSharp />,
    BuildCircleTwoTone: <icons.BuildCircleTwoTone />,
    BuildOutlined: <icons.BuildOutlined />,
    BuildRounded: <icons.BuildRounded />,
    BuildSharp: <icons.BuildSharp />,
    BuildTwoTone: <icons.BuildTwoTone />,
    Bungalow: <icons.Bungalow />,
    BungalowOutlined: <icons.BungalowOutlined />,
    BungalowRounded: <icons.BungalowRounded />,
    BungalowSharp: <icons.BungalowSharp />,
    BungalowTwoTone: <icons.BungalowTwoTone />,
    BurstMode: <icons.BurstMode />,
    BurstModeOutlined: <icons.BurstModeOutlined />,
    BurstModeRounded: <icons.BurstModeRounded />,
    BurstModeSharp: <icons.BurstModeSharp />,
    BurstModeTwoTone: <icons.BurstModeTwoTone />,
    BusAlert: <icons.BusAlert />,
    BusAlertOutlined: <icons.BusAlertOutlined />,
    BusAlertRounded: <icons.BusAlertRounded />,
    BusAlertSharp: <icons.BusAlertSharp />,
    BusAlertTwoTone: <icons.BusAlertTwoTone />,
    Business: <icons.Business />,
    BusinessCenter: <icons.BusinessCenter />,
    BusinessCenterOutlined: <icons.BusinessCenterOutlined />,
    BusinessCenterRounded: <icons.BusinessCenterRounded />,
    BusinessCenterSharp: <icons.BusinessCenterSharp />,
    BusinessCenterTwoTone: <icons.BusinessCenterTwoTone />,
    BusinessOutlined: <icons.BusinessOutlined />,
    BusinessRounded: <icons.BusinessRounded />,
    BusinessSharp: <icons.BusinessSharp />,
    BusinessTwoTone: <icons.BusinessTwoTone />,
    Cabin: <icons.Cabin />,
    CabinOutlined: <icons.CabinOutlined />,
    CabinRounded: <icons.CabinRounded />,
    CabinSharp: <icons.CabinSharp />,
    CabinTwoTone: <icons.CabinTwoTone />,
    Cable: <icons.Cable />,
    CableOutlined: <icons.CableOutlined />,
    CableRounded: <icons.CableRounded />,
    CableSharp: <icons.CableSharp />,
    CableTwoTone: <icons.CableTwoTone />,
    Cached: <icons.Cached />,
    CachedOutlined: <icons.CachedOutlined />,
    CachedRounded: <icons.CachedRounded />,
    CachedSharp: <icons.CachedSharp />,
    CachedTwoTone: <icons.CachedTwoTone />,
    Cake: <icons.Cake />,
    CakeOutlined: <icons.CakeOutlined />,
    CakeRounded: <icons.CakeRounded />,
    CakeSharp: <icons.CakeSharp />,
    CakeTwoTone: <icons.CakeTwoTone />,
    Calculate: <icons.Calculate />,
    CalculateOutlined: <icons.CalculateOutlined />,
    CalculateRounded: <icons.CalculateRounded />,
    CalculateSharp: <icons.CalculateSharp />,
    CalculateTwoTone: <icons.CalculateTwoTone />,
    CalendarMonth: <icons.CalendarMonth />,
    CalendarMonthOutlined: <icons.CalendarMonthOutlined />,
    CalendarMonthRounded: <icons.CalendarMonthRounded />,
    CalendarMonthSharp: <icons.CalendarMonthSharp />,
    CalendarMonthTwoTone: <icons.CalendarMonthTwoTone />,
    CalendarToday: <icons.CalendarToday />,
    CalendarTodayOutlined: <icons.CalendarTodayOutlined />,
    CalendarTodayRounded: <icons.CalendarTodayRounded />,
    CalendarTodaySharp: <icons.CalendarTodaySharp />,
    CalendarTodayTwoTone: <icons.CalendarTodayTwoTone />,
    CalendarViewDay: <icons.CalendarViewDay />,
    CalendarViewDayOutlined: <icons.CalendarViewDayOutlined />,
    CalendarViewDayRounded: <icons.CalendarViewDayRounded />,
    CalendarViewDaySharp: <icons.CalendarViewDaySharp />,
    CalendarViewDayTwoTone: <icons.CalendarViewDayTwoTone />,
    CalendarViewMonth: <icons.CalendarViewMonth />,
    CalendarViewMonthOutlined: <icons.CalendarViewMonthOutlined />,
    CalendarViewMonthRounded: <icons.CalendarViewMonthRounded />,
    CalendarViewMonthSharp: <icons.CalendarViewMonthSharp />,
    CalendarViewMonthTwoTone: <icons.CalendarViewMonthTwoTone />,
    CalendarViewWeek: <icons.CalendarViewWeek />,
    CalendarViewWeekOutlined: <icons.CalendarViewWeekOutlined />,
    CalendarViewWeekRounded: <icons.CalendarViewWeekRounded />,
    CalendarViewWeekSharp: <icons.CalendarViewWeekSharp />,
    CalendarViewWeekTwoTone: <icons.CalendarViewWeekTwoTone />,
    Call: <icons.Call />,
    CallEnd: <icons.CallEnd />,
    CallEndOutlined: <icons.CallEndOutlined />,
    CallEndRounded: <icons.CallEndRounded />,
    CallEndSharp: <icons.CallEndSharp />,
    CallEndTwoTone: <icons.CallEndTwoTone />,
    CallMade: <icons.CallMade />,
    CallMadeOutlined: <icons.CallMadeOutlined />,
    CallMadeRounded: <icons.CallMadeRounded />,
    CallMadeSharp: <icons.CallMadeSharp />,
    CallMadeTwoTone: <icons.CallMadeTwoTone />,
    CallMerge: <icons.CallMerge />,
    CallMergeOutlined: <icons.CallMergeOutlined />,
    CallMergeRounded: <icons.CallMergeRounded />,
    CallMergeSharp: <icons.CallMergeSharp />,
    CallMergeTwoTone: <icons.CallMergeTwoTone />,
    CallMissed: <icons.CallMissed />,
    CallMissedOutgoing: <icons.CallMissedOutgoing />,
    CallMissedOutgoingOutlined: <icons.CallMissedOutgoingOutlined />,
    CallMissedOutgoingRounded: <icons.CallMissedOutgoingRounded />,
    CallMissedOutgoingSharp: <icons.CallMissedOutgoingSharp />,
    CallMissedOutgoingTwoTone: <icons.CallMissedOutgoingTwoTone />,
    CallMissedOutlined: <icons.CallMissedOutlined />,
    CallMissedRounded: <icons.CallMissedRounded />,
    CallMissedSharp: <icons.CallMissedSharp />,
    CallMissedTwoTone: <icons.CallMissedTwoTone />,
    CallOutlined: <icons.CallOutlined />,
    CallReceived: <icons.CallReceived />,
    CallReceivedOutlined: <icons.CallReceivedOutlined />,
    CallReceivedRounded: <icons.CallReceivedRounded />,
    CallReceivedSharp: <icons.CallReceivedSharp />,
    CallReceivedTwoTone: <icons.CallReceivedTwoTone />,
    CallRounded: <icons.CallRounded />,
    CallSharp: <icons.CallSharp />,
    CallSplit: <icons.CallSplit />,
    CallSplitOutlined: <icons.CallSplitOutlined />,
    CallSplitRounded: <icons.CallSplitRounded />,
    CallSplitSharp: <icons.CallSplitSharp />,
    CallSplitTwoTone: <icons.CallSplitTwoTone />,
    CallToAction: <icons.CallToAction />,
    CallToActionOutlined: <icons.CallToActionOutlined />,
    CallToActionRounded: <icons.CallToActionRounded />,
    CallToActionSharp: <icons.CallToActionSharp />,
    CallToActionTwoTone: <icons.CallToActionTwoTone />,
    CallTwoTone: <icons.CallTwoTone />,
    Camera: <icons.Camera />,
    CameraAlt: <icons.CameraAlt />,
    CameraAltOutlined: <icons.CameraAltOutlined />,
    CameraAltRounded: <icons.CameraAltRounded />,
    CameraAltSharp: <icons.CameraAltSharp />,
    CameraAltTwoTone: <icons.CameraAltTwoTone />,
    CameraEnhance: <icons.CameraEnhance />,
    CameraEnhanceOutlined: <icons.CameraEnhanceOutlined />,
    CameraEnhanceRounded: <icons.CameraEnhanceRounded />,
    CameraEnhanceSharp: <icons.CameraEnhanceSharp />,
    CameraEnhanceTwoTone: <icons.CameraEnhanceTwoTone />,
    CameraFront: <icons.CameraFront />,
    CameraFrontOutlined: <icons.CameraFrontOutlined />,
    CameraFrontRounded: <icons.CameraFrontRounded />,
    CameraFrontSharp: <icons.CameraFrontSharp />,
    CameraFrontTwoTone: <icons.CameraFrontTwoTone />,
    CameraIndoor: <icons.CameraIndoor />,
    CameraIndoorOutlined: <icons.CameraIndoorOutlined />,
    CameraIndoorRounded: <icons.CameraIndoorRounded />,
    CameraIndoorSharp: <icons.CameraIndoorSharp />,
    CameraIndoorTwoTone: <icons.CameraIndoorTwoTone />,
    CameraOutdoor: <icons.CameraOutdoor />,
    CameraOutdoorOutlined: <icons.CameraOutdoorOutlined />,
    CameraOutdoorRounded: <icons.CameraOutdoorRounded />,
    CameraOutdoorSharp: <icons.CameraOutdoorSharp />,
    CameraOutdoorTwoTone: <icons.CameraOutdoorTwoTone />,
    CameraOutlined: <icons.CameraOutlined />,
    CameraRear: <icons.CameraRear />,
    CameraRearOutlined: <icons.CameraRearOutlined />,
    CameraRearRounded: <icons.CameraRearRounded />,
    CameraRearSharp: <icons.CameraRearSharp />,
    CameraRearTwoTone: <icons.CameraRearTwoTone />,
    CameraRoll: <icons.CameraRoll />,
    CameraRollOutlined: <icons.CameraRollOutlined />,
    CameraRollRounded: <icons.CameraRollRounded />,
    CameraRollSharp: <icons.CameraRollSharp />,
    CameraRollTwoTone: <icons.CameraRollTwoTone />,
    CameraRounded: <icons.CameraRounded />,
    CameraSharp: <icons.CameraSharp />,
    CameraTwoTone: <icons.CameraTwoTone />,
    Cameraswitch: <icons.Cameraswitch />,
    CameraswitchOutlined: <icons.CameraswitchOutlined />,
    CameraswitchRounded: <icons.CameraswitchRounded />,
    CameraswitchSharp: <icons.CameraswitchSharp />,
    CameraswitchTwoTone: <icons.CameraswitchTwoTone />,
    Campaign: <icons.Campaign />,
    CampaignOutlined: <icons.CampaignOutlined />,
    CampaignRounded: <icons.CampaignRounded />,
    CampaignSharp: <icons.CampaignSharp />,
    CampaignTwoTone: <icons.CampaignTwoTone />,
    Cancel: <icons.Cancel />,
    CancelOutlined: <icons.CancelOutlined />,
    CancelPresentation: <icons.CancelPresentation />,
    CancelPresentationOutlined: <icons.CancelPresentationOutlined />,
    CancelPresentationRounded: <icons.CancelPresentationRounded />,
    CancelPresentationSharp: <icons.CancelPresentationSharp />,
    CancelPresentationTwoTone: <icons.CancelPresentationTwoTone />,
    CancelRounded: <icons.CancelRounded />,
    CancelScheduleSend: <icons.CancelScheduleSend />,
    CancelScheduleSendOutlined: <icons.CancelScheduleSendOutlined />,
    CancelScheduleSendRounded: <icons.CancelScheduleSendRounded />,
    CancelScheduleSendSharp: <icons.CancelScheduleSendSharp />,
    CancelScheduleSendTwoTone: <icons.CancelScheduleSendTwoTone />,
    CancelSharp: <icons.CancelSharp />,
    CancelTwoTone: <icons.CancelTwoTone />,
    CandlestickChart: <icons.CandlestickChart />,
    CandlestickChartOutlined: <icons.CandlestickChartOutlined />,
    CandlestickChartRounded: <icons.CandlestickChartRounded />,
    CandlestickChartSharp: <icons.CandlestickChartSharp />,
    CandlestickChartTwoTone: <icons.CandlestickChartTwoTone />,
    CarCrash: <icons.CarCrash />,
    CarCrashOutlined: <icons.CarCrashOutlined />,
    CarCrashRounded: <icons.CarCrashRounded />,
    CarCrashSharp: <icons.CarCrashSharp />,
    CarCrashTwoTone: <icons.CarCrashTwoTone />,
    CarRental: <icons.CarRental />,
    CarRentalOutlined: <icons.CarRentalOutlined />,
    CarRentalRounded: <icons.CarRentalRounded />,
    CarRentalSharp: <icons.CarRentalSharp />,
    CarRentalTwoTone: <icons.CarRentalTwoTone />,
    CarRepair: <icons.CarRepair />,
    CarRepairOutlined: <icons.CarRepairOutlined />,
    CarRepairRounded: <icons.CarRepairRounded />,
    CarRepairSharp: <icons.CarRepairSharp />,
    CarRepairTwoTone: <icons.CarRepairTwoTone />,
    CardGiftcard: <icons.CardGiftcard />,
    CardGiftcardOutlined: <icons.CardGiftcardOutlined />,
    CardGiftcardRounded: <icons.CardGiftcardRounded />,
    CardGiftcardSharp: <icons.CardGiftcardSharp />,
    CardGiftcardTwoTone: <icons.CardGiftcardTwoTone />,
    CardMembership: <icons.CardMembership />,
    CardMembershipOutlined: <icons.CardMembershipOutlined />,
    CardMembershipRounded: <icons.CardMembershipRounded />,
    CardMembershipSharp: <icons.CardMembershipSharp />,
    CardMembershipTwoTone: <icons.CardMembershipTwoTone />,
    CardTravel: <icons.CardTravel />,
    CardTravelOutlined: <icons.CardTravelOutlined />,
    CardTravelRounded: <icons.CardTravelRounded />,
    CardTravelSharp: <icons.CardTravelSharp />,
    CardTravelTwoTone: <icons.CardTravelTwoTone />,
    Carpenter: <icons.Carpenter />,
    CarpenterOutlined: <icons.CarpenterOutlined />,
    CarpenterRounded: <icons.CarpenterRounded />,
    CarpenterSharp: <icons.CarpenterSharp />,
    CarpenterTwoTone: <icons.CarpenterTwoTone />,
    Cases: <icons.Cases />,
    CasesOutlined: <icons.CasesOutlined />,
    CasesRounded: <icons.CasesRounded />,
    CasesSharp: <icons.CasesSharp />,
    CasesTwoTone: <icons.CasesTwoTone />,
    Casino: <icons.Casino />,
    CasinoOutlined: <icons.CasinoOutlined />,
    CasinoRounded: <icons.CasinoRounded />,
    CasinoSharp: <icons.CasinoSharp />,
    CasinoTwoTone: <icons.CasinoTwoTone />,
    Cast: <icons.Cast />,
    CastConnected: <icons.CastConnected />,
    CastConnectedOutlined: <icons.CastConnectedOutlined />,
    CastConnectedRounded: <icons.CastConnectedRounded />,
    CastConnectedSharp: <icons.CastConnectedSharp />,
    CastConnectedTwoTone: <icons.CastConnectedTwoTone />,
    CastForEducation: <icons.CastForEducation />,
    CastForEducationOutlined: <icons.CastForEducationOutlined />,
    CastForEducationRounded: <icons.CastForEducationRounded />,
    CastForEducationSharp: <icons.CastForEducationSharp />,
    CastForEducationTwoTone: <icons.CastForEducationTwoTone />,
    CastOutlined: <icons.CastOutlined />,
    CastRounded: <icons.CastRounded />,
    CastSharp: <icons.CastSharp />,
    CastTwoTone: <icons.CastTwoTone />,
    Castle: <icons.Castle />,
    CastleOutlined: <icons.CastleOutlined />,
    CastleRounded: <icons.CastleRounded />,
    CastleSharp: <icons.CastleSharp />,
    CastleTwoTone: <icons.CastleTwoTone />,
    CatchingPokemon: <icons.CatchingPokemon />,
    CatchingPokemonOutlined: <icons.CatchingPokemonOutlined />,
    CatchingPokemonRounded: <icons.CatchingPokemonRounded />,
    CatchingPokemonSharp: <icons.CatchingPokemonSharp />,
    CatchingPokemonTwoTone: <icons.CatchingPokemonTwoTone />,
    Category: <icons.Category />,
    CategoryOutlined: <icons.CategoryOutlined />,
    CategoryRounded: <icons.CategoryRounded />,
    CategorySharp: <icons.CategorySharp />,
    CategoryTwoTone: <icons.CategoryTwoTone />,
    Celebration: <icons.Celebration />,
    CelebrationOutlined: <icons.CelebrationOutlined />,
    CelebrationRounded: <icons.CelebrationRounded />,
    CelebrationSharp: <icons.CelebrationSharp />,
    CelebrationTwoTone: <icons.CelebrationTwoTone />,
    CellTower: <icons.CellTower />,
    CellTowerOutlined: <icons.CellTowerOutlined />,
    CellTowerRounded: <icons.CellTowerRounded />,
    CellTowerSharp: <icons.CellTowerSharp />,
    CellTowerTwoTone: <icons.CellTowerTwoTone />,
    CellWifi: <icons.CellWifi />,
    CellWifiOutlined: <icons.CellWifiOutlined />,
    CellWifiRounded: <icons.CellWifiRounded />,
    CellWifiSharp: <icons.CellWifiSharp />,
    CellWifiTwoTone: <icons.CellWifiTwoTone />,
    CenterFocusStrong: <icons.CenterFocusStrong />,
    CenterFocusStrongOutlined: <icons.CenterFocusStrongOutlined />,
    CenterFocusStrongRounded: <icons.CenterFocusStrongRounded />,
    CenterFocusStrongSharp: <icons.CenterFocusStrongSharp />,
    CenterFocusStrongTwoTone: <icons.CenterFocusStrongTwoTone />,
    CenterFocusWeak: <icons.CenterFocusWeak />,
    CenterFocusWeakOutlined: <icons.CenterFocusWeakOutlined />,
    CenterFocusWeakRounded: <icons.CenterFocusWeakRounded />,
    CenterFocusWeakSharp: <icons.CenterFocusWeakSharp />,
    CenterFocusWeakTwoTone: <icons.CenterFocusWeakTwoTone />,
    Chair: <icons.Chair />,
    ChairAlt: <icons.ChairAlt />,
    ChairAltOutlined: <icons.ChairAltOutlined />,
    ChairAltRounded: <icons.ChairAltRounded />,
    ChairAltSharp: <icons.ChairAltSharp />,
    ChairAltTwoTone: <icons.ChairAltTwoTone />,
    ChairOutlined: <icons.ChairOutlined />,
    ChairRounded: <icons.ChairRounded />,
    ChairSharp: <icons.ChairSharp />,
    ChairTwoTone: <icons.ChairTwoTone />,
    Chalet: <icons.Chalet />,
    ChaletOutlined: <icons.ChaletOutlined />,
    ChaletRounded: <icons.ChaletRounded />,
    ChaletSharp: <icons.ChaletSharp />,
    ChaletTwoTone: <icons.ChaletTwoTone />,
    ChangeCircle: <icons.ChangeCircle />,
    ChangeCircleOutlined: <icons.ChangeCircleOutlined />,
    ChangeCircleRounded: <icons.ChangeCircleRounded />,
    ChangeCircleSharp: <icons.ChangeCircleSharp />,
    ChangeCircleTwoTone: <icons.ChangeCircleTwoTone />,
    ChangeHistory: <icons.ChangeHistory />,
    ChangeHistoryOutlined: <icons.ChangeHistoryOutlined />,
    ChangeHistoryRounded: <icons.ChangeHistoryRounded />,
    ChangeHistorySharp: <icons.ChangeHistorySharp />,
    ChangeHistoryTwoTone: <icons.ChangeHistoryTwoTone />,
    ChargingStation: <icons.ChargingStation />,
    ChargingStationOutlined: <icons.ChargingStationOutlined />,
    ChargingStationRounded: <icons.ChargingStationRounded />,
    ChargingStationSharp: <icons.ChargingStationSharp />,
    ChargingStationTwoTone: <icons.ChargingStationTwoTone />,
    Chat: <icons.Chat />,
    ChatBubble: <icons.ChatBubble />,
    ChatBubbleOutline: <icons.ChatBubbleOutline />,
    ChatBubbleOutlineOutlined: <icons.ChatBubbleOutlineOutlined />,
    ChatBubbleOutlineRounded: <icons.ChatBubbleOutlineRounded />,
    ChatBubbleOutlineSharp: <icons.ChatBubbleOutlineSharp />,
    ChatBubbleOutlineTwoTone: <icons.ChatBubbleOutlineTwoTone />,
    ChatBubbleOutlined: <icons.ChatBubbleOutlined />,
    ChatBubbleRounded: <icons.ChatBubbleRounded />,
    ChatBubbleSharp: <icons.ChatBubbleSharp />,
    ChatBubbleTwoTone: <icons.ChatBubbleTwoTone />,
    ChatOutlined: <icons.ChatOutlined />,
    ChatRounded: <icons.ChatRounded />,
    ChatSharp: <icons.ChatSharp />,
    ChatTwoTone: <icons.ChatTwoTone />,
    Check: <icons.Check />,
    CheckBox: <icons.CheckBox />,
    CheckBoxOutlineBlank: <icons.CheckBoxOutlineBlank />,
    CheckBoxOutlineBlankOutlined: <icons.CheckBoxOutlineBlankOutlined />,
    CheckBoxOutlineBlankRounded: <icons.CheckBoxOutlineBlankRounded />,
    CheckBoxOutlineBlankSharp: <icons.CheckBoxOutlineBlankSharp />,
    CheckBoxOutlineBlankTwoTone: <icons.CheckBoxOutlineBlankTwoTone />,
    CheckBoxOutlined: <icons.CheckBoxOutlined />,
    CheckBoxRounded: <icons.CheckBoxRounded />,
    CheckBoxSharp: <icons.CheckBoxSharp />,
    CheckBoxTwoTone: <icons.CheckBoxTwoTone />,
    CheckCircle: <icons.CheckCircle />,
    CheckCircleOutline: <icons.CheckCircleOutline />,
    CheckCircleOutlineOutlined: <icons.CheckCircleOutlineOutlined />,
    CheckCircleOutlineRounded: <icons.CheckCircleOutlineRounded />,
    CheckCircleOutlineSharp: <icons.CheckCircleOutlineSharp />,
    CheckCircleOutlineTwoTone: <icons.CheckCircleOutlineTwoTone />,
    CheckCircleOutlined: <icons.CheckCircleOutlined />,
    CheckCircleRounded: <icons.CheckCircleRounded />,
    CheckCircleSharp: <icons.CheckCircleSharp />,
    CheckCircleTwoTone: <icons.CheckCircleTwoTone />,
    CheckOutlined: <icons.CheckOutlined />,
    CheckRounded: <icons.CheckRounded />,
    CheckSharp: <icons.CheckSharp />,
    CheckTwoTone: <icons.CheckTwoTone />,
    Checklist: <icons.Checklist />,
    ChecklistOutlined: <icons.ChecklistOutlined />,
    ChecklistRounded: <icons.ChecklistRounded />,
    ChecklistRtl: <icons.ChecklistRtl />,
    ChecklistRtlOutlined: <icons.ChecklistRtlOutlined />,
    ChecklistRtlRounded: <icons.ChecklistRtlRounded />,
    ChecklistRtlSharp: <icons.ChecklistRtlSharp />,
    ChecklistRtlTwoTone: <icons.ChecklistRtlTwoTone />,
    ChecklistSharp: <icons.ChecklistSharp />,
    ChecklistTwoTone: <icons.ChecklistTwoTone />,
    Checkroom: <icons.Checkroom />,
    CheckroomOutlined: <icons.CheckroomOutlined />,
    CheckroomRounded: <icons.CheckroomRounded />,
    CheckroomSharp: <icons.CheckroomSharp />,
    CheckroomTwoTone: <icons.CheckroomTwoTone />,
    ChevronLeft: <icons.ChevronLeft />,
    ChevronLeftOutlined: <icons.ChevronLeftOutlined />,
    ChevronLeftRounded: <icons.ChevronLeftRounded />,
    ChevronLeftSharp: <icons.ChevronLeftSharp />,
    ChevronLeftTwoTone: <icons.ChevronLeftTwoTone />,
    ChevronRight: <icons.ChevronRight />,
    ChevronRightOutlined: <icons.ChevronRightOutlined />,
    ChevronRightRounded: <icons.ChevronRightRounded />,
    ChevronRightSharp: <icons.ChevronRightSharp />,
    ChevronRightTwoTone: <icons.ChevronRightTwoTone />,
    ChildCare: <icons.ChildCare />,
    ChildCareOutlined: <icons.ChildCareOutlined />,
    ChildCareRounded: <icons.ChildCareRounded />,
    ChildCareSharp: <icons.ChildCareSharp />,
    ChildCareTwoTone: <icons.ChildCareTwoTone />,
    ChildFriendly: <icons.ChildFriendly />,
    ChildFriendlyOutlined: <icons.ChildFriendlyOutlined />,
    ChildFriendlyRounded: <icons.ChildFriendlyRounded />,
    ChildFriendlySharp: <icons.ChildFriendlySharp />,
    ChildFriendlyTwoTone: <icons.ChildFriendlyTwoTone />,
    ChromeReaderMode: <icons.ChromeReaderMode />,
    ChromeReaderModeOutlined: <icons.ChromeReaderModeOutlined />,
    ChromeReaderModeRounded: <icons.ChromeReaderModeRounded />,
    ChromeReaderModeSharp: <icons.ChromeReaderModeSharp />,
    ChromeReaderModeTwoTone: <icons.ChromeReaderModeTwoTone />,
    Church: <icons.Church />,
    ChurchOutlined: <icons.ChurchOutlined />,
    ChurchRounded: <icons.ChurchRounded />,
    ChurchSharp: <icons.ChurchSharp />,
    ChurchTwoTone: <icons.ChurchTwoTone />,
    Circle: <icons.Circle />,
    CircleNotifications: <icons.CircleNotifications />,
    CircleNotificationsOutlined: <icons.CircleNotificationsOutlined />,
    CircleNotificationsRounded: <icons.CircleNotificationsRounded />,
    CircleNotificationsSharp: <icons.CircleNotificationsSharp />,
    CircleNotificationsTwoTone: <icons.CircleNotificationsTwoTone />,
    CircleOutlined: <icons.CircleOutlined />,
    CircleRounded: <icons.CircleRounded />,
    CircleSharp: <icons.CircleSharp />,
    CircleTwoTone: <icons.CircleTwoTone />,
    Class: <icons.Class />,
    ClassOutlined: <icons.ClassOutlined />,
    ClassRounded: <icons.ClassRounded />,
    ClassSharp: <icons.ClassSharp />,
    ClassTwoTone: <icons.ClassTwoTone />,
    CleanHands: <icons.CleanHands />,
    CleanHandsOutlined: <icons.CleanHandsOutlined />,
    CleanHandsRounded: <icons.CleanHandsRounded />,
    CleanHandsSharp: <icons.CleanHandsSharp />,
    CleanHandsTwoTone: <icons.CleanHandsTwoTone />,
    CleaningServices: <icons.CleaningServices />,
    CleaningServicesOutlined: <icons.CleaningServicesOutlined />,
    CleaningServicesRounded: <icons.CleaningServicesRounded />,
    CleaningServicesSharp: <icons.CleaningServicesSharp />,
    CleaningServicesTwoTone: <icons.CleaningServicesTwoTone />,
    Clear: <icons.Clear />,
    ClearAll: <icons.ClearAll />,
    ClearAllOutlined: <icons.ClearAllOutlined />,
    ClearAllRounded: <icons.ClearAllRounded />,
    ClearAllSharp: <icons.ClearAllSharp />,
    ClearAllTwoTone: <icons.ClearAllTwoTone />,
    ClearOutlined: <icons.ClearOutlined />,
    ClearRounded: <icons.ClearRounded />,
    ClearSharp: <icons.ClearSharp />,
    ClearTwoTone: <icons.ClearTwoTone />,
    Close: <icons.Close />,
    CloseFullscreen: <icons.CloseFullscreen />,
    CloseFullscreenOutlined: <icons.CloseFullscreenOutlined />,
    CloseFullscreenRounded: <icons.CloseFullscreenRounded />,
    CloseFullscreenSharp: <icons.CloseFullscreenSharp />,
    CloseFullscreenTwoTone: <icons.CloseFullscreenTwoTone />,
    CloseOutlined: <icons.CloseOutlined />,
    CloseRounded: <icons.CloseRounded />,
    CloseSharp: <icons.CloseSharp />,
    CloseTwoTone: <icons.CloseTwoTone />,
    ClosedCaption: <icons.ClosedCaption />,
    ClosedCaptionDisabled: <icons.ClosedCaptionDisabled />,
    ClosedCaptionDisabledOutlined: <icons.ClosedCaptionDisabledOutlined />,
    ClosedCaptionDisabledRounded: <icons.ClosedCaptionDisabledRounded />,
    ClosedCaptionDisabledSharp: <icons.ClosedCaptionDisabledSharp />,
    ClosedCaptionDisabledTwoTone: <icons.ClosedCaptionDisabledTwoTone />,
    ClosedCaptionOff: <icons.ClosedCaptionOff />,
    ClosedCaptionOffOutlined: <icons.ClosedCaptionOffOutlined />,
    ClosedCaptionOffRounded: <icons.ClosedCaptionOffRounded />,
    ClosedCaptionOffSharp: <icons.ClosedCaptionOffSharp />,
    ClosedCaptionOffTwoTone: <icons.ClosedCaptionOffTwoTone />,
    ClosedCaptionOutlined: <icons.ClosedCaptionOutlined />,
    ClosedCaptionRounded: <icons.ClosedCaptionRounded />,
    ClosedCaptionSharp: <icons.ClosedCaptionSharp />,
    ClosedCaptionTwoTone: <icons.ClosedCaptionTwoTone />,
    Cloud: <icons.Cloud />,
    CloudCircle: <icons.CloudCircle />,
    CloudCircleOutlined: <icons.CloudCircleOutlined />,
    CloudCircleRounded: <icons.CloudCircleRounded />,
    CloudCircleSharp: <icons.CloudCircleSharp />,
    CloudCircleTwoTone: <icons.CloudCircleTwoTone />,
    CloudDone: <icons.CloudDone />,
    CloudDoneOutlined: <icons.CloudDoneOutlined />,
    CloudDoneRounded: <icons.CloudDoneRounded />,
    CloudDoneSharp: <icons.CloudDoneSharp />,
    CloudDoneTwoTone: <icons.CloudDoneTwoTone />,
    CloudDownload: <icons.CloudDownload />,
    CloudDownloadOutlined: <icons.CloudDownloadOutlined />,
    CloudDownloadRounded: <icons.CloudDownloadRounded />,
    CloudDownloadSharp: <icons.CloudDownloadSharp />,
    CloudDownloadTwoTone: <icons.CloudDownloadTwoTone />,
    CloudOff: <icons.CloudOff />,
    CloudOffOutlined: <icons.CloudOffOutlined />,
    CloudOffRounded: <icons.CloudOffRounded />,
    CloudOffSharp: <icons.CloudOffSharp />,
    CloudOffTwoTone: <icons.CloudOffTwoTone />,
    CloudOutlined: <icons.CloudOutlined />,
    CloudQueue: <icons.CloudQueue />,
    CloudQueueOutlined: <icons.CloudQueueOutlined />,
    CloudQueueRounded: <icons.CloudQueueRounded />,
    CloudQueueSharp: <icons.CloudQueueSharp />,
    CloudQueueTwoTone: <icons.CloudQueueTwoTone />,
    CloudRounded: <icons.CloudRounded />,
    CloudSharp: <icons.CloudSharp />,
    CloudSync: <icons.CloudSync />,
    CloudSyncOutlined: <icons.CloudSyncOutlined />,
    CloudSyncRounded: <icons.CloudSyncRounded />,
    CloudSyncSharp: <icons.CloudSyncSharp />,
    CloudSyncTwoTone: <icons.CloudSyncTwoTone />,
    CloudTwoTone: <icons.CloudTwoTone />,
    CloudUpload: <icons.CloudUpload />,
    CloudUploadOutlined: <icons.CloudUploadOutlined />,
    CloudUploadRounded: <icons.CloudUploadRounded />,
    CloudUploadSharp: <icons.CloudUploadSharp />,
    CloudUploadTwoTone: <icons.CloudUploadTwoTone />,
    Co2: <icons.Co2 />,
    Co2Outlined: <icons.Co2Outlined />,
    Co2Rounded: <icons.Co2Rounded />,
    Co2Sharp: <icons.Co2Sharp />,
    Co2TwoTone: <icons.Co2TwoTone />,
    CoPresent: <icons.CoPresent />,
    CoPresentOutlined: <icons.CoPresentOutlined />,
    CoPresentRounded: <icons.CoPresentRounded />,
    CoPresentSharp: <icons.CoPresentSharp />,
    CoPresentTwoTone: <icons.CoPresentTwoTone />,
    Code: <icons.Code />,
    CodeOff: <icons.CodeOff />,
    CodeOffOutlined: <icons.CodeOffOutlined />,
    CodeOffRounded: <icons.CodeOffRounded />,
    CodeOffSharp: <icons.CodeOffSharp />,
    CodeOffTwoTone: <icons.CodeOffTwoTone />,
    CodeOutlined: <icons.CodeOutlined />,
    CodeRounded: <icons.CodeRounded />,
    CodeSharp: <icons.CodeSharp />,
    CodeTwoTone: <icons.CodeTwoTone />,
    Coffee: <icons.Coffee />,
    CoffeeMaker: <icons.CoffeeMaker />,
    CoffeeMakerOutlined: <icons.CoffeeMakerOutlined />,
    CoffeeMakerRounded: <icons.CoffeeMakerRounded />,
    CoffeeMakerSharp: <icons.CoffeeMakerSharp />,
    CoffeeMakerTwoTone: <icons.CoffeeMakerTwoTone />,
    CoffeeOutlined: <icons.CoffeeOutlined />,
    CoffeeRounded: <icons.CoffeeRounded />,
    CoffeeSharp: <icons.CoffeeSharp />,
    CoffeeTwoTone: <icons.CoffeeTwoTone />,
    Collections: <icons.Collections />,
    CollectionsBookmark: <icons.CollectionsBookmark />,
    CollectionsBookmarkOutlined: <icons.CollectionsBookmarkOutlined />,
    CollectionsBookmarkRounded: <icons.CollectionsBookmarkRounded />,
    CollectionsBookmarkSharp: <icons.CollectionsBookmarkSharp />,
    CollectionsBookmarkTwoTone: <icons.CollectionsBookmarkTwoTone />,
    CollectionsOutlined: <icons.CollectionsOutlined />,
    CollectionsRounded: <icons.CollectionsRounded />,
    CollectionsSharp: <icons.CollectionsSharp />,
    CollectionsTwoTone: <icons.CollectionsTwoTone />,
    ColorLens: <icons.ColorLens />,
    ColorLensOutlined: <icons.ColorLensOutlined />,
    ColorLensRounded: <icons.ColorLensRounded />,
    ColorLensSharp: <icons.ColorLensSharp />,
    ColorLensTwoTone: <icons.ColorLensTwoTone />,
    Colorize: <icons.Colorize />,
    ColorizeOutlined: <icons.ColorizeOutlined />,
    ColorizeRounded: <icons.ColorizeRounded />,
    ColorizeSharp: <icons.ColorizeSharp />,
    ColorizeTwoTone: <icons.ColorizeTwoTone />,
    Comment: <icons.Comment />,
    CommentBank: <icons.CommentBank />,
    CommentBankOutlined: <icons.CommentBankOutlined />,
    CommentBankRounded: <icons.CommentBankRounded />,
    CommentBankSharp: <icons.CommentBankSharp />,
    CommentBankTwoTone: <icons.CommentBankTwoTone />,
    CommentOutlined: <icons.CommentOutlined />,
    CommentRounded: <icons.CommentRounded />,
    CommentSharp: <icons.CommentSharp />,
    CommentTwoTone: <icons.CommentTwoTone />,
    CommentsDisabled: <icons.CommentsDisabled />,
    CommentsDisabledOutlined: <icons.CommentsDisabledOutlined />,
    CommentsDisabledRounded: <icons.CommentsDisabledRounded />,
    CommentsDisabledSharp: <icons.CommentsDisabledSharp />,
    CommentsDisabledTwoTone: <icons.CommentsDisabledTwoTone />,
    Commit: <icons.Commit />,
    CommitOutlined: <icons.CommitOutlined />,
    CommitRounded: <icons.CommitRounded />,
    CommitSharp: <icons.CommitSharp />,
    CommitTwoTone: <icons.CommitTwoTone />,
    Commute: <icons.Commute />,
    CommuteOutlined: <icons.CommuteOutlined />,
    CommuteRounded: <icons.CommuteRounded />,
    CommuteSharp: <icons.CommuteSharp />,
    CommuteTwoTone: <icons.CommuteTwoTone />,
    Compare: <icons.Compare />,
    CompareArrows: <icons.CompareArrows />,
    CompareArrowsOutlined: <icons.CompareArrowsOutlined />,
    CompareArrowsRounded: <icons.CompareArrowsRounded />,
    CompareArrowsSharp: <icons.CompareArrowsSharp />,
    CompareArrowsTwoTone: <icons.CompareArrowsTwoTone />,
    CompareOutlined: <icons.CompareOutlined />,
    CompareRounded: <icons.CompareRounded />,
    CompareSharp: <icons.CompareSharp />,
    CompareTwoTone: <icons.CompareTwoTone />,
    CompassCalibration: <icons.CompassCalibration />,
    CompassCalibrationOutlined: <icons.CompassCalibrationOutlined />,
    CompassCalibrationRounded: <icons.CompassCalibrationRounded />,
    CompassCalibrationSharp: <icons.CompassCalibrationSharp />,
    CompassCalibrationTwoTone: <icons.CompassCalibrationTwoTone />,
    Compress: <icons.Compress />,
    CompressOutlined: <icons.CompressOutlined />,
    CompressRounded: <icons.CompressRounded />,
    CompressSharp: <icons.CompressSharp />,
    CompressTwoTone: <icons.CompressTwoTone />,
    Computer: <icons.Computer />,
    ComputerOutlined: <icons.ComputerOutlined />,
    ComputerRounded: <icons.ComputerRounded />,
    ComputerSharp: <icons.ComputerSharp />,
    ComputerTwoTone: <icons.ComputerTwoTone />,
    ConfirmationNumber: <icons.ConfirmationNumber />,
    ConfirmationNumberOutlined: <icons.ConfirmationNumberOutlined />,
    ConfirmationNumberRounded: <icons.ConfirmationNumberRounded />,
    ConfirmationNumberSharp: <icons.ConfirmationNumberSharp />,
    ConfirmationNumberTwoTone: <icons.ConfirmationNumberTwoTone />,
    ConnectWithoutContact: <icons.ConnectWithoutContact />,
    ConnectWithoutContactOutlined: <icons.ConnectWithoutContactOutlined />,
    ConnectWithoutContactRounded: <icons.ConnectWithoutContactRounded />,
    ConnectWithoutContactSharp: <icons.ConnectWithoutContactSharp />,
    ConnectWithoutContactTwoTone: <icons.ConnectWithoutContactTwoTone />,
    ConnectedTv: <icons.ConnectedTv />,
    ConnectedTvOutlined: <icons.ConnectedTvOutlined />,
    ConnectedTvRounded: <icons.ConnectedTvRounded />,
    ConnectedTvSharp: <icons.ConnectedTvSharp />,
    ConnectedTvTwoTone: <icons.ConnectedTvTwoTone />,
    ConnectingAirports: <icons.ConnectingAirports />,
    ConnectingAirportsOutlined: <icons.ConnectingAirportsOutlined />,
    ConnectingAirportsRounded: <icons.ConnectingAirportsRounded />,
    ConnectingAirportsSharp: <icons.ConnectingAirportsSharp />,
    ConnectingAirportsTwoTone: <icons.ConnectingAirportsTwoTone />,
    Construction: <icons.Construction />,
    ConstructionOutlined: <icons.ConstructionOutlined />,
    ConstructionRounded: <icons.ConstructionRounded />,
    ConstructionSharp: <icons.ConstructionSharp />,
    ConstructionTwoTone: <icons.ConstructionTwoTone />,
    ContactEmergency: <icons.ContactEmergency />,
    ContactEmergencyOutlined: <icons.ContactEmergencyOutlined />,
    ContactEmergencyRounded: <icons.ContactEmergencyRounded />,
    ContactEmergencySharp: <icons.ContactEmergencySharp />,
    ContactEmergencyTwoTone: <icons.ContactEmergencyTwoTone />,
    ContactMail: <icons.ContactMail />,
    ContactMailOutlined: <icons.ContactMailOutlined />,
    ContactMailRounded: <icons.ContactMailRounded />,
    ContactMailSharp: <icons.ContactMailSharp />,
    ContactMailTwoTone: <icons.ContactMailTwoTone />,
    ContactPage: <icons.ContactPage />,
    ContactPageOutlined: <icons.ContactPageOutlined />,
    ContactPageRounded: <icons.ContactPageRounded />,
    ContactPageSharp: <icons.ContactPageSharp />,
    ContactPageTwoTone: <icons.ContactPageTwoTone />,
    ContactPhone: <icons.ContactPhone />,
    ContactPhoneOutlined: <icons.ContactPhoneOutlined />,
    ContactPhoneRounded: <icons.ContactPhoneRounded />,
    ContactPhoneSharp: <icons.ContactPhoneSharp />,
    ContactPhoneTwoTone: <icons.ContactPhoneTwoTone />,
    ContactSupport: <icons.ContactSupport />,
    ContactSupportOutlined: <icons.ContactSupportOutlined />,
    ContactSupportRounded: <icons.ContactSupportRounded />,
    ContactSupportSharp: <icons.ContactSupportSharp />,
    ContactSupportTwoTone: <icons.ContactSupportTwoTone />,
    Contactless: <icons.Contactless />,
    ContactlessOutlined: <icons.ContactlessOutlined />,
    ContactlessRounded: <icons.ContactlessRounded />,
    ContactlessSharp: <icons.ContactlessSharp />,
    ContactlessTwoTone: <icons.ContactlessTwoTone />,
    Contacts: <icons.Contacts />,
    ContactsOutlined: <icons.ContactsOutlined />,
    ContactsRounded: <icons.ContactsRounded />,
    ContactsSharp: <icons.ContactsSharp />,
    ContactsTwoTone: <icons.ContactsTwoTone />,
    ContentCopy: <icons.ContentCopy />,
    ContentCopyOutlined: <icons.ContentCopyOutlined />,
    ContentCopyRounded: <icons.ContentCopyRounded />,
    ContentCopySharp: <icons.ContentCopySharp />,
    ContentCopyTwoTone: <icons.ContentCopyTwoTone />,
    ContentCut: <icons.ContentCut />,
    ContentCutOutlined: <icons.ContentCutOutlined />,
    ContentCutRounded: <icons.ContentCutRounded />,
    ContentCutSharp: <icons.ContentCutSharp />,
    ContentCutTwoTone: <icons.ContentCutTwoTone />,
    ContentPaste: <icons.ContentPaste />,
    ContentPasteGo: <icons.ContentPasteGo />,
    ContentPasteGoOutlined: <icons.ContentPasteGoOutlined />,
    ContentPasteGoRounded: <icons.ContentPasteGoRounded />,
    ContentPasteGoSharp: <icons.ContentPasteGoSharp />,
    ContentPasteGoTwoTone: <icons.ContentPasteGoTwoTone />,
    ContentPasteOff: <icons.ContentPasteOff />,
    ContentPasteOffOutlined: <icons.ContentPasteOffOutlined />,
    ContentPasteOffRounded: <icons.ContentPasteOffRounded />,
    ContentPasteOffSharp: <icons.ContentPasteOffSharp />,
    ContentPasteOffTwoTone: <icons.ContentPasteOffTwoTone />,
    ContentPasteOutlined: <icons.ContentPasteOutlined />,
    ContentPasteRounded: <icons.ContentPasteRounded />,
    ContentPasteSearch: <icons.ContentPasteSearch />,
    ContentPasteSearchOutlined: <icons.ContentPasteSearchOutlined />,
    ContentPasteSearchRounded: <icons.ContentPasteSearchRounded />,
    ContentPasteSearchSharp: <icons.ContentPasteSearchSharp />,
    ContentPasteSearchTwoTone: <icons.ContentPasteSearchTwoTone />,
    ContentPasteSharp: <icons.ContentPasteSharp />,
    ContentPasteTwoTone: <icons.ContentPasteTwoTone />,
    Contrast: <icons.Contrast />,
    ContrastOutlined: <icons.ContrastOutlined />,
    ContrastRounded: <icons.ContrastRounded />,
    ContrastSharp: <icons.ContrastSharp />,
    ContrastTwoTone: <icons.ContrastTwoTone />,
    ControlCamera: <icons.ControlCamera />,
    ControlCameraOutlined: <icons.ControlCameraOutlined />,
    ControlCameraRounded: <icons.ControlCameraRounded />,
    ControlCameraSharp: <icons.ControlCameraSharp />,
    ControlCameraTwoTone: <icons.ControlCameraTwoTone />,
    ControlPoint: <icons.ControlPoint />,
    ControlPointDuplicate: <icons.ControlPointDuplicate />,
    ControlPointDuplicateOutlined: <icons.ControlPointDuplicateOutlined />,
    ControlPointDuplicateRounded: <icons.ControlPointDuplicateRounded />,
    ControlPointDuplicateSharp: <icons.ControlPointDuplicateSharp />,
    ControlPointDuplicateTwoTone: <icons.ControlPointDuplicateTwoTone />,
    ControlPointOutlined: <icons.ControlPointOutlined />,
    ControlPointRounded: <icons.ControlPointRounded />,
    ControlPointSharp: <icons.ControlPointSharp />,
    ControlPointTwoTone: <icons.ControlPointTwoTone />,
    Cookie: <icons.Cookie />,
    CookieOutlined: <icons.CookieOutlined />,
    CookieRounded: <icons.CookieRounded />,
    CookieSharp: <icons.CookieSharp />,
    CookieTwoTone: <icons.CookieTwoTone />,
    CopyAll: <icons.CopyAll />,
    CopyAllOutlined: <icons.CopyAllOutlined />,
    CopyAllRounded: <icons.CopyAllRounded />,
    CopyAllSharp: <icons.CopyAllSharp />,
    CopyAllTwoTone: <icons.CopyAllTwoTone />,
    Copyright: <icons.Copyright />,
    CopyrightOutlined: <icons.CopyrightOutlined />,
    CopyrightRounded: <icons.CopyrightRounded />,
    CopyrightSharp: <icons.CopyrightSharp />,
    CopyrightTwoTone: <icons.CopyrightTwoTone />,
    Coronavirus: <icons.Coronavirus />,
    CoronavirusOutlined: <icons.CoronavirusOutlined />,
    CoronavirusRounded: <icons.CoronavirusRounded />,
    CoronavirusSharp: <icons.CoronavirusSharp />,
    CoronavirusTwoTone: <icons.CoronavirusTwoTone />,
    CorporateFare: <icons.CorporateFare />,
    CorporateFareOutlined: <icons.CorporateFareOutlined />,
    CorporateFareRounded: <icons.CorporateFareRounded />,
    CorporateFareSharp: <icons.CorporateFareSharp />,
    CorporateFareTwoTone: <icons.CorporateFareTwoTone />,
    Cottage: <icons.Cottage />,
    CottageOutlined: <icons.CottageOutlined />,
    CottageRounded: <icons.CottageRounded />,
    CottageSharp: <icons.CottageSharp />,
    CottageTwoTone: <icons.CottageTwoTone />,
    Countertops: <icons.Countertops />,
    CountertopsOutlined: <icons.CountertopsOutlined />,
    CountertopsRounded: <icons.CountertopsRounded />,
    CountertopsSharp: <icons.CountertopsSharp />,
    CountertopsTwoTone: <icons.CountertopsTwoTone />,
    Create: <icons.Create />,
    CreateNewFolder: <icons.CreateNewFolder />,
    CreateNewFolderOutlined: <icons.CreateNewFolderOutlined />,
    CreateNewFolderRounded: <icons.CreateNewFolderRounded />,
    CreateNewFolderSharp: <icons.CreateNewFolderSharp />,
    CreateNewFolderTwoTone: <icons.CreateNewFolderTwoTone />,
    CreateOutlined: <icons.CreateOutlined />,
    CreateRounded: <icons.CreateRounded />,
    CreateSharp: <icons.CreateSharp />,
    CreateTwoTone: <icons.CreateTwoTone />,
    CreditCard: <icons.CreditCard />,
    CreditCardOff: <icons.CreditCardOff />,
    CreditCardOffOutlined: <icons.CreditCardOffOutlined />,
    CreditCardOffRounded: <icons.CreditCardOffRounded />,
    CreditCardOffSharp: <icons.CreditCardOffSharp />,
    CreditCardOffTwoTone: <icons.CreditCardOffTwoTone />,
    CreditCardOutlined: <icons.CreditCardOutlined />,
    CreditCardRounded: <icons.CreditCardRounded />,
    CreditCardSharp: <icons.CreditCardSharp />,
    CreditCardTwoTone: <icons.CreditCardTwoTone />,
    CreditScore: <icons.CreditScore />,
    CreditScoreOutlined: <icons.CreditScoreOutlined />,
    CreditScoreRounded: <icons.CreditScoreRounded />,
    CreditScoreSharp: <icons.CreditScoreSharp />,
    CreditScoreTwoTone: <icons.CreditScoreTwoTone />,
    Crib: <icons.Crib />,
    CribOutlined: <icons.CribOutlined />,
    CribRounded: <icons.CribRounded />,
    CribSharp: <icons.CribSharp />,
    CribTwoTone: <icons.CribTwoTone />,
    CrisisAlert: <icons.CrisisAlert />,
    CrisisAlertOutlined: <icons.CrisisAlertOutlined />,
    CrisisAlertRounded: <icons.CrisisAlertRounded />,
    CrisisAlertSharp: <icons.CrisisAlertSharp />,
    CrisisAlertTwoTone: <icons.CrisisAlertTwoTone />,
    Crop: <icons.Crop />,
    Crop169: <icons.Crop169 />,
    Crop169Outlined: <icons.Crop169Outlined />,
    Crop169Rounded: <icons.Crop169Rounded />,
    Crop169Sharp: <icons.Crop169Sharp />,
    Crop169TwoTone: <icons.Crop169TwoTone />,
    Crop32: <icons.Crop32 />,
    Crop32Outlined: <icons.Crop32Outlined />,
    Crop32Rounded: <icons.Crop32Rounded />,
    Crop32Sharp: <icons.Crop32Sharp />,
    Crop32TwoTone: <icons.Crop32TwoTone />,
    Crop54: <icons.Crop54 />,
    Crop54Outlined: <icons.Crop54Outlined />,
    Crop54Rounded: <icons.Crop54Rounded />,
    Crop54Sharp: <icons.Crop54Sharp />,
    Crop54TwoTone: <icons.Crop54TwoTone />,
    Crop75: <icons.Crop75 />,
    Crop75Outlined: <icons.Crop75Outlined />,
    Crop75Rounded: <icons.Crop75Rounded />,
    Crop75Sharp: <icons.Crop75Sharp />,
    Crop75TwoTone: <icons.Crop75TwoTone />,
    CropDin: <icons.CropDin />,
    CropDinOutlined: <icons.CropDinOutlined />,
    CropDinRounded: <icons.CropDinRounded />,
    CropDinSharp: <icons.CropDinSharp />,
    CropDinTwoTone: <icons.CropDinTwoTone />,
    CropFree: <icons.CropFree />,
    CropFreeOutlined: <icons.CropFreeOutlined />,
    CropFreeRounded: <icons.CropFreeRounded />,
    CropFreeSharp: <icons.CropFreeSharp />,
    CropFreeTwoTone: <icons.CropFreeTwoTone />,
    CropLandscape: <icons.CropLandscape />,
    CropLandscapeOutlined: <icons.CropLandscapeOutlined />,
    CropLandscapeRounded: <icons.CropLandscapeRounded />,
    CropLandscapeSharp: <icons.CropLandscapeSharp />,
    CropLandscapeTwoTone: <icons.CropLandscapeTwoTone />,
    CropOriginal: <icons.CropOriginal />,
    CropOriginalOutlined: <icons.CropOriginalOutlined />,
    CropOriginalRounded: <icons.CropOriginalRounded />,
    CropOriginalSharp: <icons.CropOriginalSharp />,
    CropOriginalTwoTone: <icons.CropOriginalTwoTone />,
    CropOutlined: <icons.CropOutlined />,
    CropPortrait: <icons.CropPortrait />,
    CropPortraitOutlined: <icons.CropPortraitOutlined />,
    CropPortraitRounded: <icons.CropPortraitRounded />,
    CropPortraitSharp: <icons.CropPortraitSharp />,
    CropPortraitTwoTone: <icons.CropPortraitTwoTone />,
    CropRotate: <icons.CropRotate />,
    CropRotateOutlined: <icons.CropRotateOutlined />,
    CropRotateRounded: <icons.CropRotateRounded />,
    CropRotateSharp: <icons.CropRotateSharp />,
    CropRotateTwoTone: <icons.CropRotateTwoTone />,
    CropRounded: <icons.CropRounded />,
    CropSharp: <icons.CropSharp />,
    CropSquare: <icons.CropSquare />,
    CropSquareOutlined: <icons.CropSquareOutlined />,
    CropSquareRounded: <icons.CropSquareRounded />,
    CropSquareSharp: <icons.CropSquareSharp />,
    CropSquareTwoTone: <icons.CropSquareTwoTone />,
    CropTwoTone: <icons.CropTwoTone />,
    Css: <icons.Css />,
    CssOutlined: <icons.CssOutlined />,
    CssRounded: <icons.CssRounded />,
    CssSharp: <icons.CssSharp />,
    CssTwoTone: <icons.CssTwoTone />,
    CurrencyBitcoin: <icons.CurrencyBitcoin />,
    CurrencyBitcoinOutlined: <icons.CurrencyBitcoinOutlined />,
    CurrencyBitcoinRounded: <icons.CurrencyBitcoinRounded />,
    CurrencyBitcoinSharp: <icons.CurrencyBitcoinSharp />,
    CurrencyBitcoinTwoTone: <icons.CurrencyBitcoinTwoTone />,
    CurrencyExchange: <icons.CurrencyExchange />,
    CurrencyExchangeOutlined: <icons.CurrencyExchangeOutlined />,
    CurrencyExchangeRounded: <icons.CurrencyExchangeRounded />,
    CurrencyExchangeSharp: <icons.CurrencyExchangeSharp />,
    CurrencyExchangeTwoTone: <icons.CurrencyExchangeTwoTone />,
    CurrencyFranc: <icons.CurrencyFranc />,
    CurrencyFrancOutlined: <icons.CurrencyFrancOutlined />,
    CurrencyFrancRounded: <icons.CurrencyFrancRounded />,
    CurrencyFrancSharp: <icons.CurrencyFrancSharp />,
    CurrencyFrancTwoTone: <icons.CurrencyFrancTwoTone />,
    CurrencyLira: <icons.CurrencyLira />,
    CurrencyLiraOutlined: <icons.CurrencyLiraOutlined />,
    CurrencyLiraRounded: <icons.CurrencyLiraRounded />,
    CurrencyLiraSharp: <icons.CurrencyLiraSharp />,
    CurrencyLiraTwoTone: <icons.CurrencyLiraTwoTone />,
    CurrencyPound: <icons.CurrencyPound />,
    CurrencyPoundOutlined: <icons.CurrencyPoundOutlined />,
    CurrencyPoundRounded: <icons.CurrencyPoundRounded />,
    CurrencyPoundSharp: <icons.CurrencyPoundSharp />,
    CurrencyPoundTwoTone: <icons.CurrencyPoundTwoTone />,
    CurrencyRuble: <icons.CurrencyRuble />,
    CurrencyRubleOutlined: <icons.CurrencyRubleOutlined />,
    CurrencyRubleRounded: <icons.CurrencyRubleRounded />,
    CurrencyRubleSharp: <icons.CurrencyRubleSharp />,
    CurrencyRubleTwoTone: <icons.CurrencyRubleTwoTone />,
    CurrencyRupee: <icons.CurrencyRupee />,
    CurrencyRupeeOutlined: <icons.CurrencyRupeeOutlined />,
    CurrencyRupeeRounded: <icons.CurrencyRupeeRounded />,
    CurrencyRupeeSharp: <icons.CurrencyRupeeSharp />,
    CurrencyRupeeTwoTone: <icons.CurrencyRupeeTwoTone />,
    CurrencyYen: <icons.CurrencyYen />,
    CurrencyYenOutlined: <icons.CurrencyYenOutlined />,
    CurrencyYenRounded: <icons.CurrencyYenRounded />,
    CurrencyYenSharp: <icons.CurrencyYenSharp />,
    CurrencyYenTwoTone: <icons.CurrencyYenTwoTone />,
    CurrencyYuan: <icons.CurrencyYuan />,
    CurrencyYuanOutlined: <icons.CurrencyYuanOutlined />,
    CurrencyYuanRounded: <icons.CurrencyYuanRounded />,
    CurrencyYuanSharp: <icons.CurrencyYuanSharp />,
    CurrencyYuanTwoTone: <icons.CurrencyYuanTwoTone />,
    Curtains: <icons.Curtains />,
    CurtainsClosed: <icons.CurtainsClosed />,
    CurtainsClosedOutlined: <icons.CurtainsClosedOutlined />,
    CurtainsClosedRounded: <icons.CurtainsClosedRounded />,
    CurtainsClosedSharp: <icons.CurtainsClosedSharp />,
    CurtainsClosedTwoTone: <icons.CurtainsClosedTwoTone />,
    CurtainsOutlined: <icons.CurtainsOutlined />,
    CurtainsRounded: <icons.CurtainsRounded />,
    CurtainsSharp: <icons.CurtainsSharp />,
    CurtainsTwoTone: <icons.CurtainsTwoTone />,
    Cyclone: <icons.Cyclone />,
    CycloneOutlined: <icons.CycloneOutlined />,
    CycloneRounded: <icons.CycloneRounded />,
    CycloneSharp: <icons.CycloneSharp />,
    CycloneTwoTone: <icons.CycloneTwoTone />,
    Dangerous: <icons.Dangerous />,
    DangerousOutlined: <icons.DangerousOutlined />,
    DangerousRounded: <icons.DangerousRounded />,
    DangerousSharp: <icons.DangerousSharp />,
    DangerousTwoTone: <icons.DangerousTwoTone />,
    DarkMode: <icons.DarkMode />,
    DarkModeOutlined: <icons.DarkModeOutlined />,
    DarkModeRounded: <icons.DarkModeRounded />,
    DarkModeSharp: <icons.DarkModeSharp />,
    DarkModeTwoTone: <icons.DarkModeTwoTone />,
    Dashboard: <icons.Dashboard />,
    DashboardCustomize: <icons.DashboardCustomize />,
    DashboardCustomizeOutlined: <icons.DashboardCustomizeOutlined />,
    DashboardCustomizeRounded: <icons.DashboardCustomizeRounded />,
    DashboardCustomizeSharp: <icons.DashboardCustomizeSharp />,
    DashboardCustomizeTwoTone: <icons.DashboardCustomizeTwoTone />,
    DashboardOutlined: <icons.DashboardOutlined />,
    DashboardRounded: <icons.DashboardRounded />,
    DashboardSharp: <icons.DashboardSharp />,
    DashboardTwoTone: <icons.DashboardTwoTone />,
    DataArray: <icons.DataArray />,
    DataArrayOutlined: <icons.DataArrayOutlined />,
    DataArrayRounded: <icons.DataArrayRounded />,
    DataArraySharp: <icons.DataArraySharp />,
    DataArrayTwoTone: <icons.DataArrayTwoTone />,
    DataObject: <icons.DataObject />,
    DataObjectOutlined: <icons.DataObjectOutlined />,
    DataObjectRounded: <icons.DataObjectRounded />,
    DataObjectSharp: <icons.DataObjectSharp />,
    DataObjectTwoTone: <icons.DataObjectTwoTone />,
    DataSaverOff: <icons.DataSaverOff />,
    DataSaverOffOutlined: <icons.DataSaverOffOutlined />,
    DataSaverOffRounded: <icons.DataSaverOffRounded />,
    DataSaverOffSharp: <icons.DataSaverOffSharp />,
    DataSaverOffTwoTone: <icons.DataSaverOffTwoTone />,
    DataSaverOn: <icons.DataSaverOn />,
    DataSaverOnOutlined: <icons.DataSaverOnOutlined />,
    DataSaverOnRounded: <icons.DataSaverOnRounded />,
    DataSaverOnSharp: <icons.DataSaverOnSharp />,
    DataSaverOnTwoTone: <icons.DataSaverOnTwoTone />,
    DataThresholding: <icons.DataThresholding />,
    DataThresholdingOutlined: <icons.DataThresholdingOutlined />,
    DataThresholdingRounded: <icons.DataThresholdingRounded />,
    DataThresholdingSharp: <icons.DataThresholdingSharp />,
    DataThresholdingTwoTone: <icons.DataThresholdingTwoTone />,
    DataUsage: <icons.DataUsage />,
    DataUsageOutlined: <icons.DataUsageOutlined />,
    DataUsageRounded: <icons.DataUsageRounded />,
    DataUsageSharp: <icons.DataUsageSharp />,
    DataUsageTwoTone: <icons.DataUsageTwoTone />,
    Dataset: <icons.Dataset />,
    DatasetLinked: <icons.DatasetLinked />,
    DatasetLinkedOutlined: <icons.DatasetLinkedOutlined />,
    DatasetLinkedRounded: <icons.DatasetLinkedRounded />,
    DatasetLinkedSharp: <icons.DatasetLinkedSharp />,
    DatasetLinkedTwoTone: <icons.DatasetLinkedTwoTone />,
    DatasetOutlined: <icons.DatasetOutlined />,
    DatasetRounded: <icons.DatasetRounded />,
    DatasetSharp: <icons.DatasetSharp />,
    DatasetTwoTone: <icons.DatasetTwoTone />,
    DateRange: <icons.DateRange />,
    DateRangeOutlined: <icons.DateRangeOutlined />,
    DateRangeRounded: <icons.DateRangeRounded />,
    DateRangeSharp: <icons.DateRangeSharp />,
    DateRangeTwoTone: <icons.DateRangeTwoTone />,
    Deblur: <icons.Deblur />,
    DeblurOutlined: <icons.DeblurOutlined />,
    DeblurRounded: <icons.DeblurRounded />,
    DeblurSharp: <icons.DeblurSharp />,
    DeblurTwoTone: <icons.DeblurTwoTone />,
    Deck: <icons.Deck />,
    DeckOutlined: <icons.DeckOutlined />,
    DeckRounded: <icons.DeckRounded />,
    DeckSharp: <icons.DeckSharp />,
    DeckTwoTone: <icons.DeckTwoTone />,
    Dehaze: <icons.Dehaze />,
    DehazeOutlined: <icons.DehazeOutlined />,
    DehazeRounded: <icons.DehazeRounded />,
    DehazeSharp: <icons.DehazeSharp />,
    DehazeTwoTone: <icons.DehazeTwoTone />,
    Delete: <icons.Delete />,
    DeleteForever: <icons.DeleteForever />,
    DeleteForeverOutlined: <icons.DeleteForeverOutlined />,
    DeleteForeverRounded: <icons.DeleteForeverRounded />,
    DeleteForeverSharp: <icons.DeleteForeverSharp />,
    DeleteForeverTwoTone: <icons.DeleteForeverTwoTone />,
    DeleteOutline: <icons.DeleteOutline />,
    DeleteOutlineOutlined: <icons.DeleteOutlineOutlined />,
    DeleteOutlineRounded: <icons.DeleteOutlineRounded />,
    DeleteOutlineSharp: <icons.DeleteOutlineSharp />,
    DeleteOutlineTwoTone: <icons.DeleteOutlineTwoTone />,
    DeleteOutlined: <icons.DeleteOutlined />,
    DeleteRounded: <icons.DeleteRounded />,
    DeleteSharp: <icons.DeleteSharp />,
    DeleteSweep: <icons.DeleteSweep />,
    DeleteSweepOutlined: <icons.DeleteSweepOutlined />,
    DeleteSweepRounded: <icons.DeleteSweepRounded />,
    DeleteSweepSharp: <icons.DeleteSweepSharp />,
    DeleteSweepTwoTone: <icons.DeleteSweepTwoTone />,
    DeleteTwoTone: <icons.DeleteTwoTone />,
    DeliveryDining: <icons.DeliveryDining />,
    DeliveryDiningOutlined: <icons.DeliveryDiningOutlined />,
    DeliveryDiningRounded: <icons.DeliveryDiningRounded />,
    DeliveryDiningSharp: <icons.DeliveryDiningSharp />,
    DeliveryDiningTwoTone: <icons.DeliveryDiningTwoTone />,
    DensityLarge: <icons.DensityLarge />,
    DensityLargeOutlined: <icons.DensityLargeOutlined />,
    DensityLargeRounded: <icons.DensityLargeRounded />,
    DensityLargeSharp: <icons.DensityLargeSharp />,
    DensityLargeTwoTone: <icons.DensityLargeTwoTone />,
    DensityMedium: <icons.DensityMedium />,
    DensityMediumOutlined: <icons.DensityMediumOutlined />,
    DensityMediumRounded: <icons.DensityMediumRounded />,
    DensityMediumSharp: <icons.DensityMediumSharp />,
    DensityMediumTwoTone: <icons.DensityMediumTwoTone />,
    DensitySmall: <icons.DensitySmall />,
    DensitySmallOutlined: <icons.DensitySmallOutlined />,
    DensitySmallRounded: <icons.DensitySmallRounded />,
    DensitySmallSharp: <icons.DensitySmallSharp />,
    DensitySmallTwoTone: <icons.DensitySmallTwoTone />,
    DepartureBoard: <icons.DepartureBoard />,
    DepartureBoardOutlined: <icons.DepartureBoardOutlined />,
    DepartureBoardRounded: <icons.DepartureBoardRounded />,
    DepartureBoardSharp: <icons.DepartureBoardSharp />,
    DepartureBoardTwoTone: <icons.DepartureBoardTwoTone />,
    Description: <icons.Description />,
    DescriptionOutlined: <icons.DescriptionOutlined />,
    DescriptionRounded: <icons.DescriptionRounded />,
    DescriptionSharp: <icons.DescriptionSharp />,
    DescriptionTwoTone: <icons.DescriptionTwoTone />,
    Deselect: <icons.Deselect />,
    DeselectOutlined: <icons.DeselectOutlined />,
    DeselectRounded: <icons.DeselectRounded />,
    DeselectSharp: <icons.DeselectSharp />,
    DeselectTwoTone: <icons.DeselectTwoTone />,
    DesignServices: <icons.DesignServices />,
    DesignServicesOutlined: <icons.DesignServicesOutlined />,
    DesignServicesRounded: <icons.DesignServicesRounded />,
    DesignServicesSharp: <icons.DesignServicesSharp />,
    DesignServicesTwoTone: <icons.DesignServicesTwoTone />,
    Desk: <icons.Desk />,
    DeskOutlined: <icons.DeskOutlined />,
    DeskRounded: <icons.DeskRounded />,
    DeskSharp: <icons.DeskSharp />,
    DeskTwoTone: <icons.DeskTwoTone />,
    DesktopAccessDisabled: <icons.DesktopAccessDisabled />,
    DesktopAccessDisabledOutlined: <icons.DesktopAccessDisabledOutlined />,
    DesktopAccessDisabledRounded: <icons.DesktopAccessDisabledRounded />,
    DesktopAccessDisabledSharp: <icons.DesktopAccessDisabledSharp />,
    DesktopAccessDisabledTwoTone: <icons.DesktopAccessDisabledTwoTone />,
    DesktopMac: <icons.DesktopMac />,
    DesktopMacOutlined: <icons.DesktopMacOutlined />,
    DesktopMacRounded: <icons.DesktopMacRounded />,
    DesktopMacSharp: <icons.DesktopMacSharp />,
    DesktopMacTwoTone: <icons.DesktopMacTwoTone />,
    DesktopWindows: <icons.DesktopWindows />,
    DesktopWindowsOutlined: <icons.DesktopWindowsOutlined />,
    DesktopWindowsRounded: <icons.DesktopWindowsRounded />,
    DesktopWindowsSharp: <icons.DesktopWindowsSharp />,
    DesktopWindowsTwoTone: <icons.DesktopWindowsTwoTone />,
    Details: <icons.Details />,
    DetailsOutlined: <icons.DetailsOutlined />,
    DetailsRounded: <icons.DetailsRounded />,
    DetailsSharp: <icons.DetailsSharp />,
    DetailsTwoTone: <icons.DetailsTwoTone />,
    DeveloperBoard: <icons.DeveloperBoard />,
    DeveloperBoardOff: <icons.DeveloperBoardOff />,
    DeveloperBoardOffOutlined: <icons.DeveloperBoardOffOutlined />,
    DeveloperBoardOffRounded: <icons.DeveloperBoardOffRounded />,
    DeveloperBoardOffSharp: <icons.DeveloperBoardOffSharp />,
    DeveloperBoardOffTwoTone: <icons.DeveloperBoardOffTwoTone />,
    DeveloperBoardOutlined: <icons.DeveloperBoardOutlined />,
    DeveloperBoardRounded: <icons.DeveloperBoardRounded />,
    DeveloperBoardSharp: <icons.DeveloperBoardSharp />,
    DeveloperBoardTwoTone: <icons.DeveloperBoardTwoTone />,
    DeveloperMode: <icons.DeveloperMode />,
    DeveloperModeOutlined: <icons.DeveloperModeOutlined />,
    DeveloperModeRounded: <icons.DeveloperModeRounded />,
    DeveloperModeSharp: <icons.DeveloperModeSharp />,
    DeveloperModeTwoTone: <icons.DeveloperModeTwoTone />,
    DeviceHub: <icons.DeviceHub />,
    DeviceHubOutlined: <icons.DeviceHubOutlined />,
    DeviceHubRounded: <icons.DeviceHubRounded />,
    DeviceHubSharp: <icons.DeviceHubSharp />,
    DeviceHubTwoTone: <icons.DeviceHubTwoTone />,
    DeviceThermostat: <icons.DeviceThermostat />,
    DeviceThermostatOutlined: <icons.DeviceThermostatOutlined />,
    DeviceThermostatRounded: <icons.DeviceThermostatRounded />,
    DeviceThermostatSharp: <icons.DeviceThermostatSharp />,
    DeviceThermostatTwoTone: <icons.DeviceThermostatTwoTone />,
    DeviceUnknown: <icons.DeviceUnknown />,
    DeviceUnknownOutlined: <icons.DeviceUnknownOutlined />,
    DeviceUnknownRounded: <icons.DeviceUnknownRounded />,
    DeviceUnknownSharp: <icons.DeviceUnknownSharp />,
    DeviceUnknownTwoTone: <icons.DeviceUnknownTwoTone />,
    Devices: <icons.Devices />,
    DevicesFold: <icons.DevicesFold />,
    DevicesFoldOutlined: <icons.DevicesFoldOutlined />,
    DevicesFoldRounded: <icons.DevicesFoldRounded />,
    DevicesFoldSharp: <icons.DevicesFoldSharp />,
    DevicesFoldTwoTone: <icons.DevicesFoldTwoTone />,
    DevicesOther: <icons.DevicesOther />,
    DevicesOtherOutlined: <icons.DevicesOtherOutlined />,
    DevicesOtherRounded: <icons.DevicesOtherRounded />,
    DevicesOtherSharp: <icons.DevicesOtherSharp />,
    DevicesOtherTwoTone: <icons.DevicesOtherTwoTone />,
    DevicesOutlined: <icons.DevicesOutlined />,
    DevicesRounded: <icons.DevicesRounded />,
    DevicesSharp: <icons.DevicesSharp />,
    DevicesTwoTone: <icons.DevicesTwoTone />,
    DialerSip: <icons.DialerSip />,
    DialerSipOutlined: <icons.DialerSipOutlined />,
    DialerSipRounded: <icons.DialerSipRounded />,
    DialerSipSharp: <icons.DialerSipSharp />,
    DialerSipTwoTone: <icons.DialerSipTwoTone />,
    Dialpad: <icons.Dialpad />,
    DialpadOutlined: <icons.DialpadOutlined />,
    DialpadRounded: <icons.DialpadRounded />,
    DialpadSharp: <icons.DialpadSharp />,
    DialpadTwoTone: <icons.DialpadTwoTone />,
    Diamond: <icons.Diamond />,
    DiamondOutlined: <icons.DiamondOutlined />,
    DiamondRounded: <icons.DiamondRounded />,
    DiamondSharp: <icons.DiamondSharp />,
    DiamondTwoTone: <icons.DiamondTwoTone />,
    Difference: <icons.Difference />,
    DifferenceOutlined: <icons.DifferenceOutlined />,
    DifferenceRounded: <icons.DifferenceRounded />,
    DifferenceSharp: <icons.DifferenceSharp />,
    DifferenceTwoTone: <icons.DifferenceTwoTone />,
    Dining: <icons.Dining />,
    DiningOutlined: <icons.DiningOutlined />,
    DiningRounded: <icons.DiningRounded />,
    DiningSharp: <icons.DiningSharp />,
    DiningTwoTone: <icons.DiningTwoTone />,
    DinnerDining: <icons.DinnerDining />,
    DinnerDiningOutlined: <icons.DinnerDiningOutlined />,
    DinnerDiningRounded: <icons.DinnerDiningRounded />,
    DinnerDiningSharp: <icons.DinnerDiningSharp />,
    DinnerDiningTwoTone: <icons.DinnerDiningTwoTone />,
    Directions: <icons.Directions />,
    DirectionsBike: <icons.DirectionsBike />,
    DirectionsBikeOutlined: <icons.DirectionsBikeOutlined />,
    DirectionsBikeRounded: <icons.DirectionsBikeRounded />,
    DirectionsBikeSharp: <icons.DirectionsBikeSharp />,
    DirectionsBikeTwoTone: <icons.DirectionsBikeTwoTone />,
    DirectionsBoat: <icons.DirectionsBoat />,
    DirectionsBoatFilled: <icons.DirectionsBoatFilled />,
    DirectionsBoatFilledOutlined: <icons.DirectionsBoatFilledOutlined />,
    DirectionsBoatFilledRounded: <icons.DirectionsBoatFilledRounded />,
    DirectionsBoatFilledSharp: <icons.DirectionsBoatFilledSharp />,
    DirectionsBoatFilledTwoTone: <icons.DirectionsBoatFilledTwoTone />,
    DirectionsBoatOutlined: <icons.DirectionsBoatOutlined />,
    DirectionsBoatRounded: <icons.DirectionsBoatRounded />,
    DirectionsBoatSharp: <icons.DirectionsBoatSharp />,
    DirectionsBoatTwoTone: <icons.DirectionsBoatTwoTone />,
    DirectionsBus: <icons.DirectionsBus />,
    DirectionsBusFilled: <icons.DirectionsBusFilled />,
    DirectionsBusFilledOutlined: <icons.DirectionsBusFilledOutlined />,
    DirectionsBusFilledRounded: <icons.DirectionsBusFilledRounded />,
    DirectionsBusFilledSharp: <icons.DirectionsBusFilledSharp />,
    DirectionsBusFilledTwoTone: <icons.DirectionsBusFilledTwoTone />,
    DirectionsBusOutlined: <icons.DirectionsBusOutlined />,
    DirectionsBusRounded: <icons.DirectionsBusRounded />,
    DirectionsBusSharp: <icons.DirectionsBusSharp />,
    DirectionsBusTwoTone: <icons.DirectionsBusTwoTone />,
    DirectionsCar: <icons.DirectionsCar />,
    DirectionsCarFilled: <icons.DirectionsCarFilled />,
    DirectionsCarFilledOutlined: <icons.DirectionsCarFilledOutlined />,
    DirectionsCarFilledRounded: <icons.DirectionsCarFilledRounded />,
    DirectionsCarFilledSharp: <icons.DirectionsCarFilledSharp />,
    DirectionsCarFilledTwoTone: <icons.DirectionsCarFilledTwoTone />,
    DirectionsCarOutlined: <icons.DirectionsCarOutlined />,
    DirectionsCarRounded: <icons.DirectionsCarRounded />,
    DirectionsCarSharp: <icons.DirectionsCarSharp />,
    DirectionsCarTwoTone: <icons.DirectionsCarTwoTone />,
    DirectionsOff: <icons.DirectionsOff />,
    DirectionsOffOutlined: <icons.DirectionsOffOutlined />,
    DirectionsOffRounded: <icons.DirectionsOffRounded />,
    DirectionsOffSharp: <icons.DirectionsOffSharp />,
    DirectionsOffTwoTone: <icons.DirectionsOffTwoTone />,
    DirectionsOutlined: <icons.DirectionsOutlined />,
    DirectionsRailway: <icons.DirectionsRailway />,
    DirectionsRailwayFilled: <icons.DirectionsRailwayFilled />,
    DirectionsRailwayFilledOutlined: <icons.DirectionsRailwayFilledOutlined />,
    DirectionsRailwayFilledRounded: <icons.DirectionsRailwayFilledRounded />,
    DirectionsRailwayFilledSharp: <icons.DirectionsRailwayFilledSharp />,
    DirectionsRailwayFilledTwoTone: <icons.DirectionsRailwayFilledTwoTone />,
    DirectionsRailwayOutlined: <icons.DirectionsRailwayOutlined />,
    DirectionsRailwayRounded: <icons.DirectionsRailwayRounded />,
    DirectionsRailwaySharp: <icons.DirectionsRailwaySharp />,
    DirectionsRailwayTwoTone: <icons.DirectionsRailwayTwoTone />,
    DirectionsRounded: <icons.DirectionsRounded />,
    DirectionsRun: <icons.DirectionsRun />,
    DirectionsRunOutlined: <icons.DirectionsRunOutlined />,
    DirectionsRunRounded: <icons.DirectionsRunRounded />,
    DirectionsRunSharp: <icons.DirectionsRunSharp />,
    DirectionsRunTwoTone: <icons.DirectionsRunTwoTone />,
    DirectionsSharp: <icons.DirectionsSharp />,
    DirectionsSubway: <icons.DirectionsSubway />,
    DirectionsSubwayFilled: <icons.DirectionsSubwayFilled />,
    DirectionsSubwayFilledOutlined: <icons.DirectionsSubwayFilledOutlined />,
    DirectionsSubwayFilledRounded: <icons.DirectionsSubwayFilledRounded />,
    DirectionsSubwayFilledSharp: <icons.DirectionsSubwayFilledSharp />,
    DirectionsSubwayFilledTwoTone: <icons.DirectionsSubwayFilledTwoTone />,
    DirectionsSubwayOutlined: <icons.DirectionsSubwayOutlined />,
    DirectionsSubwayRounded: <icons.DirectionsSubwayRounded />,
    DirectionsSubwaySharp: <icons.DirectionsSubwaySharp />,
    DirectionsSubwayTwoTone: <icons.DirectionsSubwayTwoTone />,
    DirectionsTransit: <icons.DirectionsTransit />,
    DirectionsTransitFilled: <icons.DirectionsTransitFilled />,
    DirectionsTransitFilledOutlined: <icons.DirectionsTransitFilledOutlined />,
    DirectionsTransitFilledRounded: <icons.DirectionsTransitFilledRounded />,
    DirectionsTransitFilledSharp: <icons.DirectionsTransitFilledSharp />,
    DirectionsTransitFilledTwoTone: <icons.DirectionsTransitFilledTwoTone />,
    DirectionsTransitOutlined: <icons.DirectionsTransitOutlined />,
    DirectionsTransitRounded: <icons.DirectionsTransitRounded />,
    DirectionsTransitSharp: <icons.DirectionsTransitSharp />,
    DirectionsTransitTwoTone: <icons.DirectionsTransitTwoTone />,
    DirectionsTwoTone: <icons.DirectionsTwoTone />,
    DirectionsWalk: <icons.DirectionsWalk />,
    DirectionsWalkOutlined: <icons.DirectionsWalkOutlined />,
    DirectionsWalkRounded: <icons.DirectionsWalkRounded />,
    DirectionsWalkSharp: <icons.DirectionsWalkSharp />,
    DirectionsWalkTwoTone: <icons.DirectionsWalkTwoTone />,
    DirtyLens: <icons.DirtyLens />,
    DirtyLensOutlined: <icons.DirtyLensOutlined />,
    DirtyLensRounded: <icons.DirtyLensRounded />,
    DirtyLensSharp: <icons.DirtyLensSharp />,
    DirtyLensTwoTone: <icons.DirtyLensTwoTone />,
    DisabledByDefault: <icons.DisabledByDefault />,
    DisabledByDefaultOutlined: <icons.DisabledByDefaultOutlined />,
    DisabledByDefaultRounded: <icons.DisabledByDefaultRounded />,
    DisabledByDefaultSharp: <icons.DisabledByDefaultSharp />,
    DisabledByDefaultTwoTone: <icons.DisabledByDefaultTwoTone />,
    DiscFull: <icons.DiscFull />,
    DiscFullOutlined: <icons.DiscFullOutlined />,
    DiscFullRounded: <icons.DiscFullRounded />,
    DiscFullSharp: <icons.DiscFullSharp />,
    DiscFullTwoTone: <icons.DiscFullTwoTone />,
    Discount: <icons.Discount />,
    DiscountOutlined: <icons.DiscountOutlined />,
    DiscountRounded: <icons.DiscountRounded />,
    DiscountSharp: <icons.DiscountSharp />,
    DiscountTwoTone: <icons.DiscountTwoTone />,
    DisplaySettings: <icons.DisplaySettings />,
    DisplaySettingsOutlined: <icons.DisplaySettingsOutlined />,
    DisplaySettingsRounded: <icons.DisplaySettingsRounded />,
    DisplaySettingsSharp: <icons.DisplaySettingsSharp />,
    DisplaySettingsTwoTone: <icons.DisplaySettingsTwoTone />,
    Diversity1: <icons.Diversity1 />,
    Diversity1Outlined: <icons.Diversity1Outlined />,
    Diversity1Rounded: <icons.Diversity1Rounded />,
    Diversity1Sharp: <icons.Diversity1Sharp />,
    Diversity1TwoTone: <icons.Diversity1TwoTone />,
    Diversity2: <icons.Diversity2 />,
    Diversity2Outlined: <icons.Diversity2Outlined />,
    Diversity2Rounded: <icons.Diversity2Rounded />,
    Diversity2Sharp: <icons.Diversity2Sharp />,
    Diversity2TwoTone: <icons.Diversity2TwoTone />,
    Diversity3: <icons.Diversity3 />,
    Diversity3Outlined: <icons.Diversity3Outlined />,
    Diversity3Rounded: <icons.Diversity3Rounded />,
    Diversity3Sharp: <icons.Diversity3Sharp />,
    Diversity3TwoTone: <icons.Diversity3TwoTone />,
    Dns: <icons.Dns />,
    DnsOutlined: <icons.DnsOutlined />,
    DnsRounded: <icons.DnsRounded />,
    DnsSharp: <icons.DnsSharp />,
    DnsTwoTone: <icons.DnsTwoTone />,
    DoDisturb: <icons.DoDisturb />,
    DoDisturbAlt: <icons.DoDisturbAlt />,
    DoDisturbAltOutlined: <icons.DoDisturbAltOutlined />,
    DoDisturbAltRounded: <icons.DoDisturbAltRounded />,
    DoDisturbAltSharp: <icons.DoDisturbAltSharp />,
    DoDisturbAltTwoTone: <icons.DoDisturbAltTwoTone />,
    DoDisturbOff: <icons.DoDisturbOff />,
    DoDisturbOffOutlined: <icons.DoDisturbOffOutlined />,
    DoDisturbOffRounded: <icons.DoDisturbOffRounded />,
    DoDisturbOffSharp: <icons.DoDisturbOffSharp />,
    DoDisturbOffTwoTone: <icons.DoDisturbOffTwoTone />,
    DoDisturbOn: <icons.DoDisturbOn />,
    DoDisturbOnOutlined: <icons.DoDisturbOnOutlined />,
    DoDisturbOnRounded: <icons.DoDisturbOnRounded />,
    DoDisturbOnSharp: <icons.DoDisturbOnSharp />,
    DoDisturbOnTwoTone: <icons.DoDisturbOnTwoTone />,
    DoDisturbOutlined: <icons.DoDisturbOutlined />,
    DoDisturbRounded: <icons.DoDisturbRounded />,
    DoDisturbSharp: <icons.DoDisturbSharp />,
    DoDisturbTwoTone: <icons.DoDisturbTwoTone />,
    DoNotDisturb: <icons.DoNotDisturb />,
    DoNotDisturbAlt: <icons.DoNotDisturbAlt />,
    DoNotDisturbAltOutlined: <icons.DoNotDisturbAltOutlined />,
    DoNotDisturbAltRounded: <icons.DoNotDisturbAltRounded />,
    DoNotDisturbAltSharp: <icons.DoNotDisturbAltSharp />,
    DoNotDisturbAltTwoTone: <icons.DoNotDisturbAltTwoTone />,
    DoNotDisturbOff: <icons.DoNotDisturbOff />,
    DoNotDisturbOffOutlined: <icons.DoNotDisturbOffOutlined />,
    DoNotDisturbOffRounded: <icons.DoNotDisturbOffRounded />,
    DoNotDisturbOffSharp: <icons.DoNotDisturbOffSharp />,
    DoNotDisturbOffTwoTone: <icons.DoNotDisturbOffTwoTone />,
    DoNotDisturbOn: <icons.DoNotDisturbOn />,
    DoNotDisturbOnOutlined: <icons.DoNotDisturbOnOutlined />,
    DoNotDisturbOnRounded: <icons.DoNotDisturbOnRounded />,
    DoNotDisturbOnSharp: <icons.DoNotDisturbOnSharp />,
    DoNotDisturbOnTotalSilence: <icons.DoNotDisturbOnTotalSilence />,
    DoNotDisturbOnTotalSilenceOutlined: (
        <icons.DoNotDisturbOnTotalSilenceOutlined />
    ),
    DoNotDisturbOnTotalSilenceRounded: (
        <icons.DoNotDisturbOnTotalSilenceRounded />
    ),
    DoNotDisturbOnTotalSilenceSharp: <icons.DoNotDisturbOnTotalSilenceSharp />,
    DoNotDisturbOnTotalSilenceTwoTone: (
        <icons.DoNotDisturbOnTotalSilenceTwoTone />
    ),
    DoNotDisturbOnTwoTone: <icons.DoNotDisturbOnTwoTone />,
    DoNotDisturbOutlined: <icons.DoNotDisturbOutlined />,
    DoNotDisturbRounded: <icons.DoNotDisturbRounded />,
    DoNotDisturbSharp: <icons.DoNotDisturbSharp />,
    DoNotDisturbTwoTone: <icons.DoNotDisturbTwoTone />,
    DoNotStep: <icons.DoNotStep />,
    DoNotStepOutlined: <icons.DoNotStepOutlined />,
    DoNotStepRounded: <icons.DoNotStepRounded />,
    DoNotStepSharp: <icons.DoNotStepSharp />,
    DoNotStepTwoTone: <icons.DoNotStepTwoTone />,
    DoNotTouch: <icons.DoNotTouch />,
    DoNotTouchOutlined: <icons.DoNotTouchOutlined />,
    DoNotTouchRounded: <icons.DoNotTouchRounded />,
    DoNotTouchSharp: <icons.DoNotTouchSharp />,
    DoNotTouchTwoTone: <icons.DoNotTouchTwoTone />,
    Dock: <icons.Dock />,
    DockOutlined: <icons.DockOutlined />,
    DockRounded: <icons.DockRounded />,
    DockSharp: <icons.DockSharp />,
    DockTwoTone: <icons.DockTwoTone />,
    DocumentScanner: <icons.DocumentScanner />,
    DocumentScannerOutlined: <icons.DocumentScannerOutlined />,
    DocumentScannerRounded: <icons.DocumentScannerRounded />,
    DocumentScannerSharp: <icons.DocumentScannerSharp />,
    DocumentScannerTwoTone: <icons.DocumentScannerTwoTone />,
    Domain: <icons.Domain />,
    DomainAdd: <icons.DomainAdd />,
    DomainAddOutlined: <icons.DomainAddOutlined />,
    DomainAddRounded: <icons.DomainAddRounded />,
    DomainAddSharp: <icons.DomainAddSharp />,
    DomainAddTwoTone: <icons.DomainAddTwoTone />,
    DomainDisabled: <icons.DomainDisabled />,
    DomainDisabledOutlined: <icons.DomainDisabledOutlined />,
    DomainDisabledRounded: <icons.DomainDisabledRounded />,
    DomainDisabledSharp: <icons.DomainDisabledSharp />,
    DomainDisabledTwoTone: <icons.DomainDisabledTwoTone />,
    DomainOutlined: <icons.DomainOutlined />,
    DomainRounded: <icons.DomainRounded />,
    DomainSharp: <icons.DomainSharp />,
    DomainTwoTone: <icons.DomainTwoTone />,
    DomainVerification: <icons.DomainVerification />,
    DomainVerificationOutlined: <icons.DomainVerificationOutlined />,
    DomainVerificationRounded: <icons.DomainVerificationRounded />,
    DomainVerificationSharp: <icons.DomainVerificationSharp />,
    DomainVerificationTwoTone: <icons.DomainVerificationTwoTone />,
    Done: <icons.Done />,
    DoneAll: <icons.DoneAll />,
    DoneAllOutlined: <icons.DoneAllOutlined />,
    DoneAllRounded: <icons.DoneAllRounded />,
    DoneAllSharp: <icons.DoneAllSharp />,
    DoneAllTwoTone: <icons.DoneAllTwoTone />,
    DoneOutline: <icons.DoneOutline />,
    DoneOutlineOutlined: <icons.DoneOutlineOutlined />,
    DoneOutlineRounded: <icons.DoneOutlineRounded />,
    DoneOutlineSharp: <icons.DoneOutlineSharp />,
    DoneOutlineTwoTone: <icons.DoneOutlineTwoTone />,
    DoneOutlined: <icons.DoneOutlined />,
    DoneRounded: <icons.DoneRounded />,
    DoneSharp: <icons.DoneSharp />,
    DoneTwoTone: <icons.DoneTwoTone />,
    DonutLarge: <icons.DonutLarge />,
    DonutLargeOutlined: <icons.DonutLargeOutlined />,
    DonutLargeRounded: <icons.DonutLargeRounded />,
    DonutLargeSharp: <icons.DonutLargeSharp />,
    DonutLargeTwoTone: <icons.DonutLargeTwoTone />,
    DonutSmall: <icons.DonutSmall />,
    DonutSmallOutlined: <icons.DonutSmallOutlined />,
    DonutSmallRounded: <icons.DonutSmallRounded />,
    DonutSmallSharp: <icons.DonutSmallSharp />,
    DonutSmallTwoTone: <icons.DonutSmallTwoTone />,
    DoorBack: <icons.DoorBack />,
    DoorBackOutlined: <icons.DoorBackOutlined />,
    DoorBackRounded: <icons.DoorBackRounded />,
    DoorBackSharp: <icons.DoorBackSharp />,
    DoorBackTwoTone: <icons.DoorBackTwoTone />,
    DoorFront: <icons.DoorFront />,
    DoorFrontOutlined: <icons.DoorFrontOutlined />,
    DoorFrontRounded: <icons.DoorFrontRounded />,
    DoorFrontSharp: <icons.DoorFrontSharp />,
    DoorFrontTwoTone: <icons.DoorFrontTwoTone />,
    DoorSliding: <icons.DoorSliding />,
    DoorSlidingOutlined: <icons.DoorSlidingOutlined />,
    DoorSlidingRounded: <icons.DoorSlidingRounded />,
    DoorSlidingSharp: <icons.DoorSlidingSharp />,
    DoorSlidingTwoTone: <icons.DoorSlidingTwoTone />,
    Doorbell: <icons.Doorbell />,
    DoorbellOutlined: <icons.DoorbellOutlined />,
    DoorbellRounded: <icons.DoorbellRounded />,
    DoorbellSharp: <icons.DoorbellSharp />,
    DoorbellTwoTone: <icons.DoorbellTwoTone />,
    DoubleArrow: <icons.DoubleArrow />,
    DoubleArrowOutlined: <icons.DoubleArrowOutlined />,
    DoubleArrowRounded: <icons.DoubleArrowRounded />,
    DoubleArrowSharp: <icons.DoubleArrowSharp />,
    DoubleArrowTwoTone: <icons.DoubleArrowTwoTone />,
    DownhillSkiing: <icons.DownhillSkiing />,
    DownhillSkiingOutlined: <icons.DownhillSkiingOutlined />,
    DownhillSkiingRounded: <icons.DownhillSkiingRounded />,
    DownhillSkiingSharp: <icons.DownhillSkiingSharp />,
    DownhillSkiingTwoTone: <icons.DownhillSkiingTwoTone />,
    Download: <icons.Download />,
    DownloadDone: <icons.DownloadDone />,
    DownloadDoneOutlined: <icons.DownloadDoneOutlined />,
    DownloadDoneRounded: <icons.DownloadDoneRounded />,
    DownloadDoneSharp: <icons.DownloadDoneSharp />,
    DownloadDoneTwoTone: <icons.DownloadDoneTwoTone />,
    DownloadForOffline: <icons.DownloadForOffline />,
    DownloadForOfflineOutlined: <icons.DownloadForOfflineOutlined />,
    DownloadForOfflineRounded: <icons.DownloadForOfflineRounded />,
    DownloadForOfflineSharp: <icons.DownloadForOfflineSharp />,
    DownloadForOfflineTwoTone: <icons.DownloadForOfflineTwoTone />,
    DownloadOutlined: <icons.DownloadOutlined />,
    DownloadRounded: <icons.DownloadRounded />,
    DownloadSharp: <icons.DownloadSharp />,
    DownloadTwoTone: <icons.DownloadTwoTone />,
    Downloading: <icons.Downloading />,
    DownloadingOutlined: <icons.DownloadingOutlined />,
    DownloadingRounded: <icons.DownloadingRounded />,
    DownloadingSharp: <icons.DownloadingSharp />,
    DownloadingTwoTone: <icons.DownloadingTwoTone />,
    Drafts: <icons.Drafts />,
    DraftsOutlined: <icons.DraftsOutlined />,
    DraftsRounded: <icons.DraftsRounded />,
    DraftsSharp: <icons.DraftsSharp />,
    DraftsTwoTone: <icons.DraftsTwoTone />,
    DragHandle: <icons.DragHandle />,
    DragHandleOutlined: <icons.DragHandleOutlined />,
    DragHandleRounded: <icons.DragHandleRounded />,
    DragHandleSharp: <icons.DragHandleSharp />,
    DragHandleTwoTone: <icons.DragHandleTwoTone />,
    DragIndicator: <icons.DragIndicator />,
    DragIndicatorOutlined: <icons.DragIndicatorOutlined />,
    DragIndicatorRounded: <icons.DragIndicatorRounded />,
    DragIndicatorSharp: <icons.DragIndicatorSharp />,
    DragIndicatorTwoTone: <icons.DragIndicatorTwoTone />,
    Draw: <icons.Draw />,
    DrawOutlined: <icons.DrawOutlined />,
    DrawRounded: <icons.DrawRounded />,
    DrawSharp: <icons.DrawSharp />,
    DrawTwoTone: <icons.DrawTwoTone />,
    DriveEta: <icons.DriveEta />,
    DriveEtaOutlined: <icons.DriveEtaOutlined />,
    DriveEtaRounded: <icons.DriveEtaRounded />,
    DriveEtaSharp: <icons.DriveEtaSharp />,
    DriveEtaTwoTone: <icons.DriveEtaTwoTone />,
    DriveFileMove: <icons.DriveFileMove />,
    DriveFileMoveOutlined: <icons.DriveFileMoveOutlined />,
    DriveFileMoveRounded: <icons.DriveFileMoveRounded />,
    DriveFileMoveSharp: <icons.DriveFileMoveSharp />,
    DriveFileMoveTwoTone: <icons.DriveFileMoveTwoTone />,
    DriveFileRenameOutline: <icons.DriveFileRenameOutline />,
    DriveFileRenameOutlineOutlined: <icons.DriveFileRenameOutlineOutlined />,
    DriveFileRenameOutlineRounded: <icons.DriveFileRenameOutlineRounded />,
    DriveFileRenameOutlineSharp: <icons.DriveFileRenameOutlineSharp />,
    DriveFileRenameOutlineTwoTone: <icons.DriveFileRenameOutlineTwoTone />,
    DriveFolderUpload: <icons.DriveFolderUpload />,
    DriveFolderUploadOutlined: <icons.DriveFolderUploadOutlined />,
    DriveFolderUploadRounded: <icons.DriveFolderUploadRounded />,
    DriveFolderUploadSharp: <icons.DriveFolderUploadSharp />,
    DriveFolderUploadTwoTone: <icons.DriveFolderUploadTwoTone />,
    Dry: <icons.Dry />,
    DryCleaning: <icons.DryCleaning />,
    DryCleaningOutlined: <icons.DryCleaningOutlined />,
    DryCleaningRounded: <icons.DryCleaningRounded />,
    DryCleaningSharp: <icons.DryCleaningSharp />,
    DryCleaningTwoTone: <icons.DryCleaningTwoTone />,
    DryOutlined: <icons.DryOutlined />,
    DryRounded: <icons.DryRounded />,
    DrySharp: <icons.DrySharp />,
    DryTwoTone: <icons.DryTwoTone />,
    Duo: <icons.Duo />,
    DuoOutlined: <icons.DuoOutlined />,
    DuoRounded: <icons.DuoRounded />,
    DuoSharp: <icons.DuoSharp />,
    DuoTwoTone: <icons.DuoTwoTone />,
    Dvr: <icons.Dvr />,
    DvrOutlined: <icons.DvrOutlined />,
    DvrRounded: <icons.DvrRounded />,
    DvrSharp: <icons.DvrSharp />,
    DvrTwoTone: <icons.DvrTwoTone />,
    DynamicFeed: <icons.DynamicFeed />,
    DynamicFeedOutlined: <icons.DynamicFeedOutlined />,
    DynamicFeedRounded: <icons.DynamicFeedRounded />,
    DynamicFeedSharp: <icons.DynamicFeedSharp />,
    DynamicFeedTwoTone: <icons.DynamicFeedTwoTone />,
    DynamicForm: <icons.DynamicForm />,
    DynamicFormOutlined: <icons.DynamicFormOutlined />,
    DynamicFormRounded: <icons.DynamicFormRounded />,
    DynamicFormSharp: <icons.DynamicFormSharp />,
    DynamicFormTwoTone: <icons.DynamicFormTwoTone />,
    EMobiledata: <icons.EMobiledata />,
    EMobiledataOutlined: <icons.EMobiledataOutlined />,
    EMobiledataRounded: <icons.EMobiledataRounded />,
    EMobiledataSharp: <icons.EMobiledataSharp />,
    EMobiledataTwoTone: <icons.EMobiledataTwoTone />,
    Earbuds: <icons.Earbuds />,
    EarbudsBattery: <icons.EarbudsBattery />,
    EarbudsBatteryOutlined: <icons.EarbudsBatteryOutlined />,
    EarbudsBatteryRounded: <icons.EarbudsBatteryRounded />,
    EarbudsBatterySharp: <icons.EarbudsBatterySharp />,
    EarbudsBatteryTwoTone: <icons.EarbudsBatteryTwoTone />,
    EarbudsOutlined: <icons.EarbudsOutlined />,
    EarbudsRounded: <icons.EarbudsRounded />,
    EarbudsSharp: <icons.EarbudsSharp />,
    EarbudsTwoTone: <icons.EarbudsTwoTone />,
    East: <icons.East />,
    EastOutlined: <icons.EastOutlined />,
    EastRounded: <icons.EastRounded />,
    EastSharp: <icons.EastSharp />,
    EastTwoTone: <icons.EastTwoTone />,
    EdgesensorHigh: <icons.EdgesensorHigh />,
    EdgesensorHighOutlined: <icons.EdgesensorHighOutlined />,
    EdgesensorHighRounded: <icons.EdgesensorHighRounded />,
    EdgesensorHighSharp: <icons.EdgesensorHighSharp />,
    EdgesensorHighTwoTone: <icons.EdgesensorHighTwoTone />,
    EdgesensorLow: <icons.EdgesensorLow />,
    EdgesensorLowOutlined: <icons.EdgesensorLowOutlined />,
    EdgesensorLowRounded: <icons.EdgesensorLowRounded />,
    EdgesensorLowSharp: <icons.EdgesensorLowSharp />,
    EdgesensorLowTwoTone: <icons.EdgesensorLowTwoTone />,
    Edit: <icons.Edit />,
    EditAttributes: <icons.EditAttributes />,
    EditAttributesOutlined: <icons.EditAttributesOutlined />,
    EditAttributesRounded: <icons.EditAttributesRounded />,
    EditAttributesSharp: <icons.EditAttributesSharp />,
    EditAttributesTwoTone: <icons.EditAttributesTwoTone />,
    EditCalendar: <icons.EditCalendar />,
    EditCalendarOutlined: <icons.EditCalendarOutlined />,
    EditCalendarRounded: <icons.EditCalendarRounded />,
    EditCalendarSharp: <icons.EditCalendarSharp />,
    EditCalendarTwoTone: <icons.EditCalendarTwoTone />,
    EditLocation: <icons.EditLocation />,
    EditLocationAlt: <icons.EditLocationAlt />,
    EditLocationAltOutlined: <icons.EditLocationAltOutlined />,
    EditLocationAltRounded: <icons.EditLocationAltRounded />,
    EditLocationAltSharp: <icons.EditLocationAltSharp />,
    EditLocationAltTwoTone: <icons.EditLocationAltTwoTone />,
    EditLocationOutlined: <icons.EditLocationOutlined />,
    EditLocationRounded: <icons.EditLocationRounded />,
    EditLocationSharp: <icons.EditLocationSharp />,
    EditLocationTwoTone: <icons.EditLocationTwoTone />,
    EditNote: <icons.EditNote />,
    EditNoteOutlined: <icons.EditNoteOutlined />,
    EditNoteRounded: <icons.EditNoteRounded />,
    EditNoteSharp: <icons.EditNoteSharp />,
    EditNoteTwoTone: <icons.EditNoteTwoTone />,
    EditNotifications: <icons.EditNotifications />,
    EditNotificationsOutlined: <icons.EditNotificationsOutlined />,
    EditNotificationsRounded: <icons.EditNotificationsRounded />,
    EditNotificationsSharp: <icons.EditNotificationsSharp />,
    EditNotificationsTwoTone: <icons.EditNotificationsTwoTone />,
    EditOff: <icons.EditOff />,
    EditOffOutlined: <icons.EditOffOutlined />,
    EditOffRounded: <icons.EditOffRounded />,
    EditOffSharp: <icons.EditOffSharp />,
    EditOffTwoTone: <icons.EditOffTwoTone />,
    EditOutlined: <icons.EditOutlined />,
    EditRoad: <icons.EditRoad />,
    EditRoadOutlined: <icons.EditRoadOutlined />,
    EditRoadRounded: <icons.EditRoadRounded />,
    EditRoadSharp: <icons.EditRoadSharp />,
    EditRoadTwoTone: <icons.EditRoadTwoTone />,
    EditRounded: <icons.EditRounded />,
    EditSharp: <icons.EditSharp />,
    EditTwoTone: <icons.EditTwoTone />,
    Egg: <icons.Egg />,
    EggAlt: <icons.EggAlt />,
    EggAltOutlined: <icons.EggAltOutlined />,
    EggAltRounded: <icons.EggAltRounded />,
    EggAltSharp: <icons.EggAltSharp />,
    EggAltTwoTone: <icons.EggAltTwoTone />,
    EggOutlined: <icons.EggOutlined />,
    EggRounded: <icons.EggRounded />,
    EggSharp: <icons.EggSharp />,
    EggTwoTone: <icons.EggTwoTone />,
    EightK: <icons.EightK />,
    EightKOutlined: <icons.EightKOutlined />,
    EightKPlus: <icons.EightKPlus />,
    EightKPlusOutlined: <icons.EightKPlusOutlined />,
    EightKPlusRounded: <icons.EightKPlusRounded />,
    EightKPlusSharp: <icons.EightKPlusSharp />,
    EightKPlusTwoTone: <icons.EightKPlusTwoTone />,
    EightKRounded: <icons.EightKRounded />,
    EightKSharp: <icons.EightKSharp />,
    EightKTwoTone: <icons.EightKTwoTone />,
    EightMp: <icons.EightMp />,
    EightMpOutlined: <icons.EightMpOutlined />,
    EightMpRounded: <icons.EightMpRounded />,
    EightMpSharp: <icons.EightMpSharp />,
    EightMpTwoTone: <icons.EightMpTwoTone />,
    EighteenMp: <icons.EighteenMp />,
    EighteenMpOutlined: <icons.EighteenMpOutlined />,
    EighteenMpRounded: <icons.EighteenMpRounded />,
    EighteenMpSharp: <icons.EighteenMpSharp />,
    EighteenMpTwoTone: <icons.EighteenMpTwoTone />,
    EighteenUpRating: <icons.EighteenUpRating />,
    EighteenUpRatingOutlined: <icons.EighteenUpRatingOutlined />,
    EighteenUpRatingRounded: <icons.EighteenUpRatingRounded />,
    EighteenUpRatingSharp: <icons.EighteenUpRatingSharp />,
    EighteenUpRatingTwoTone: <icons.EighteenUpRatingTwoTone />,
    EightteenMp: <icons.EightteenMp />,
    EightteenMpOutlined: <icons.EightteenMpOutlined />,
    EightteenMpRounded: <icons.EightteenMpRounded />,
    EightteenMpSharp: <icons.EightteenMpSharp />,
    EightteenMpTwoTone: <icons.EightteenMpTwoTone />,
    Eject: <icons.Eject />,
    EjectOutlined: <icons.EjectOutlined />,
    EjectRounded: <icons.EjectRounded />,
    EjectSharp: <icons.EjectSharp />,
    EjectTwoTone: <icons.EjectTwoTone />,
    Elderly: <icons.Elderly />,
    ElderlyOutlined: <icons.ElderlyOutlined />,
    ElderlyRounded: <icons.ElderlyRounded />,
    ElderlySharp: <icons.ElderlySharp />,
    ElderlyTwoTone: <icons.ElderlyTwoTone />,
    ElderlyWoman: <icons.ElderlyWoman />,
    ElderlyWomanOutlined: <icons.ElderlyWomanOutlined />,
    ElderlyWomanRounded: <icons.ElderlyWomanRounded />,
    ElderlyWomanSharp: <icons.ElderlyWomanSharp />,
    ElderlyWomanTwoTone: <icons.ElderlyWomanTwoTone />,
    ElectricBike: <icons.ElectricBike />,
    ElectricBikeOutlined: <icons.ElectricBikeOutlined />,
    ElectricBikeRounded: <icons.ElectricBikeRounded />,
    ElectricBikeSharp: <icons.ElectricBikeSharp />,
    ElectricBikeTwoTone: <icons.ElectricBikeTwoTone />,
    ElectricBolt: <icons.ElectricBolt />,
    ElectricBoltOutlined: <icons.ElectricBoltOutlined />,
    ElectricBoltRounded: <icons.ElectricBoltRounded />,
    ElectricBoltSharp: <icons.ElectricBoltSharp />,
    ElectricBoltTwoTone: <icons.ElectricBoltTwoTone />,
    ElectricCar: <icons.ElectricCar />,
    ElectricCarOutlined: <icons.ElectricCarOutlined />,
    ElectricCarRounded: <icons.ElectricCarRounded />,
    ElectricCarSharp: <icons.ElectricCarSharp />,
    ElectricCarTwoTone: <icons.ElectricCarTwoTone />,
    ElectricMeter: <icons.ElectricMeter />,
    ElectricMeterOutlined: <icons.ElectricMeterOutlined />,
    ElectricMeterRounded: <icons.ElectricMeterRounded />,
    ElectricMeterSharp: <icons.ElectricMeterSharp />,
    ElectricMeterTwoTone: <icons.ElectricMeterTwoTone />,
    ElectricMoped: <icons.ElectricMoped />,
    ElectricMopedOutlined: <icons.ElectricMopedOutlined />,
    ElectricMopedRounded: <icons.ElectricMopedRounded />,
    ElectricMopedSharp: <icons.ElectricMopedSharp />,
    ElectricMopedTwoTone: <icons.ElectricMopedTwoTone />,
    ElectricRickshaw: <icons.ElectricRickshaw />,
    ElectricRickshawOutlined: <icons.ElectricRickshawOutlined />,
    ElectricRickshawRounded: <icons.ElectricRickshawRounded />,
    ElectricRickshawSharp: <icons.ElectricRickshawSharp />,
    ElectricRickshawTwoTone: <icons.ElectricRickshawTwoTone />,
    ElectricScooter: <icons.ElectricScooter />,
    ElectricScooterOutlined: <icons.ElectricScooterOutlined />,
    ElectricScooterRounded: <icons.ElectricScooterRounded />,
    ElectricScooterSharp: <icons.ElectricScooterSharp />,
    ElectricScooterTwoTone: <icons.ElectricScooterTwoTone />,
    ElectricalServices: <icons.ElectricalServices />,
    ElectricalServicesOutlined: <icons.ElectricalServicesOutlined />,
    ElectricalServicesRounded: <icons.ElectricalServicesRounded />,
    ElectricalServicesSharp: <icons.ElectricalServicesSharp />,
    ElectricalServicesTwoTone: <icons.ElectricalServicesTwoTone />,
    Elevator: <icons.Elevator />,
    ElevatorOutlined: <icons.ElevatorOutlined />,
    ElevatorRounded: <icons.ElevatorRounded />,
    ElevatorSharp: <icons.ElevatorSharp />,
    ElevatorTwoTone: <icons.ElevatorTwoTone />,
    ElevenMp: <icons.ElevenMp />,
    ElevenMpOutlined: <icons.ElevenMpOutlined />,
    ElevenMpRounded: <icons.ElevenMpRounded />,
    ElevenMpSharp: <icons.ElevenMpSharp />,
    ElevenMpTwoTone: <icons.ElevenMpTwoTone />,
    Email: <icons.Email />,
    EmailOutlined: <icons.EmailOutlined />,
    EmailRounded: <icons.EmailRounded />,
    EmailSharp: <icons.EmailSharp />,
    EmailTwoTone: <icons.EmailTwoTone />,
    EmergencyRecording: <icons.EmergencyRecording />,
    EmergencyRecordingOutlined: <icons.EmergencyRecordingOutlined />,
    EmergencyRecordingRounded: <icons.EmergencyRecordingRounded />,
    EmergencyRecordingSharp: <icons.EmergencyRecordingSharp />,
    EmergencyRecordingTwoTone: <icons.EmergencyRecordingTwoTone />,
    EmergencyShare: <icons.EmergencyShare />,
    EmergencyShareOutlined: <icons.EmergencyShareOutlined />,
    EmergencyShareRounded: <icons.EmergencyShareRounded />,
    EmergencyShareSharp: <icons.EmergencyShareSharp />,
    EmergencyShareTwoTone: <icons.EmergencyShareTwoTone />,
    EmojiEmotions: <icons.EmojiEmotions />,
    EmojiEmotionsOutlined: <icons.EmojiEmotionsOutlined />,
    EmojiEmotionsRounded: <icons.EmojiEmotionsRounded />,
    EmojiEmotionsSharp: <icons.EmojiEmotionsSharp />,
    EmojiEmotionsTwoTone: <icons.EmojiEmotionsTwoTone />,
    EmojiEvents: <icons.EmojiEvents />,
    EmojiEventsOutlined: <icons.EmojiEventsOutlined />,
    EmojiEventsRounded: <icons.EmojiEventsRounded />,
    EmojiEventsSharp: <icons.EmojiEventsSharp />,
    EmojiEventsTwoTone: <icons.EmojiEventsTwoTone />,
    EmojiFlags: <icons.EmojiFlags />,
    EmojiFlagsOutlined: <icons.EmojiFlagsOutlined />,
    EmojiFlagsRounded: <icons.EmojiFlagsRounded />,
    EmojiFlagsSharp: <icons.EmojiFlagsSharp />,
    EmojiFlagsTwoTone: <icons.EmojiFlagsTwoTone />,
    EmojiFoodBeverage: <icons.EmojiFoodBeverage />,
    EmojiFoodBeverageOutlined: <icons.EmojiFoodBeverageOutlined />,
    EmojiFoodBeverageRounded: <icons.EmojiFoodBeverageRounded />,
    EmojiFoodBeverageSharp: <icons.EmojiFoodBeverageSharp />,
    EmojiFoodBeverageTwoTone: <icons.EmojiFoodBeverageTwoTone />,
    EmojiNature: <icons.EmojiNature />,
    EmojiNatureOutlined: <icons.EmojiNatureOutlined />,
    EmojiNatureRounded: <icons.EmojiNatureRounded />,
    EmojiNatureSharp: <icons.EmojiNatureSharp />,
    EmojiNatureTwoTone: <icons.EmojiNatureTwoTone />,
    EmojiObjects: <icons.EmojiObjects />,
    EmojiObjectsOutlined: <icons.EmojiObjectsOutlined />,
    EmojiObjectsRounded: <icons.EmojiObjectsRounded />,
    EmojiObjectsSharp: <icons.EmojiObjectsSharp />,
    EmojiObjectsTwoTone: <icons.EmojiObjectsTwoTone />,
    EmojiPeople: <icons.EmojiPeople />,
    EmojiPeopleOutlined: <icons.EmojiPeopleOutlined />,
    EmojiPeopleRounded: <icons.EmojiPeopleRounded />,
    EmojiPeopleSharp: <icons.EmojiPeopleSharp />,
    EmojiPeopleTwoTone: <icons.EmojiPeopleTwoTone />,
    EmojiSymbols: <icons.EmojiSymbols />,
    EmojiSymbolsOutlined: <icons.EmojiSymbolsOutlined />,
    EmojiSymbolsRounded: <icons.EmojiSymbolsRounded />,
    EmojiSymbolsSharp: <icons.EmojiSymbolsSharp />,
    EmojiSymbolsTwoTone: <icons.EmojiSymbolsTwoTone />,
    EmojiTransportation: <icons.EmojiTransportation />,
    EmojiTransportationOutlined: <icons.EmojiTransportationOutlined />,
    EmojiTransportationRounded: <icons.EmojiTransportationRounded />,
    EmojiTransportationSharp: <icons.EmojiTransportationSharp />,
    EmojiTransportationTwoTone: <icons.EmojiTransportationTwoTone />,
    EnergySavingsLeaf: <icons.EnergySavingsLeaf />,
    EnergySavingsLeafOutlined: <icons.EnergySavingsLeafOutlined />,
    EnergySavingsLeafRounded: <icons.EnergySavingsLeafRounded />,
    EnergySavingsLeafSharp: <icons.EnergySavingsLeafSharp />,
    EnergySavingsLeafTwoTone: <icons.EnergySavingsLeafTwoTone />,
    Engineering: <icons.Engineering />,
    EngineeringOutlined: <icons.EngineeringOutlined />,
    EngineeringRounded: <icons.EngineeringRounded />,
    EngineeringSharp: <icons.EngineeringSharp />,
    EngineeringTwoTone: <icons.EngineeringTwoTone />,
    EnhancedEncryption: <icons.EnhancedEncryption />,
    EnhancedEncryptionOutlined: <icons.EnhancedEncryptionOutlined />,
    EnhancedEncryptionRounded: <icons.EnhancedEncryptionRounded />,
    EnhancedEncryptionSharp: <icons.EnhancedEncryptionSharp />,
    EnhancedEncryptionTwoTone: <icons.EnhancedEncryptionTwoTone />,
    Equalizer: <icons.Equalizer />,
    EqualizerOutlined: <icons.EqualizerOutlined />,
    EqualizerRounded: <icons.EqualizerRounded />,
    EqualizerSharp: <icons.EqualizerSharp />,
    EqualizerTwoTone: <icons.EqualizerTwoTone />,
    Error: <icons.Error />,
    ErrorOutline: <icons.ErrorOutline />,
    ErrorOutlineOutlined: <icons.ErrorOutlineOutlined />,
    ErrorOutlineRounded: <icons.ErrorOutlineRounded />,
    ErrorOutlineSharp: <icons.ErrorOutlineSharp />,
    ErrorOutlineTwoTone: <icons.ErrorOutlineTwoTone />,
    ErrorOutlined: <icons.ErrorOutlined />,
    ErrorRounded: <icons.ErrorRounded />,
    ErrorSharp: <icons.ErrorSharp />,
    ErrorTwoTone: <icons.ErrorTwoTone />,
    Escalator: <icons.Escalator />,
    EscalatorOutlined: <icons.EscalatorOutlined />,
    EscalatorRounded: <icons.EscalatorRounded />,
    EscalatorSharp: <icons.EscalatorSharp />,
    EscalatorTwoTone: <icons.EscalatorTwoTone />,
    EscalatorWarning: <icons.EscalatorWarning />,
    EscalatorWarningOutlined: <icons.EscalatorWarningOutlined />,
    EscalatorWarningRounded: <icons.EscalatorWarningRounded />,
    EscalatorWarningSharp: <icons.EscalatorWarningSharp />,
    EscalatorWarningTwoTone: <icons.EscalatorWarningTwoTone />,
    Euro: <icons.Euro />,
    EuroOutlined: <icons.EuroOutlined />,
    EuroRounded: <icons.EuroRounded />,
    EuroSharp: <icons.EuroSharp />,
    EuroSymbol: <icons.EuroSymbol />,
    EuroSymbolOutlined: <icons.EuroSymbolOutlined />,
    EuroSymbolRounded: <icons.EuroSymbolRounded />,
    EuroSymbolSharp: <icons.EuroSymbolSharp />,
    EuroSymbolTwoTone: <icons.EuroSymbolTwoTone />,
    EuroTwoTone: <icons.EuroTwoTone />,
    EvStation: <icons.EvStation />,
    EvStationOutlined: <icons.EvStationOutlined />,
    EvStationRounded: <icons.EvStationRounded />,
    EvStationSharp: <icons.EvStationSharp />,
    EvStationTwoTone: <icons.EvStationTwoTone />,
    Event: <icons.Event />,
    EventAvailable: <icons.EventAvailable />,
    EventAvailableOutlined: <icons.EventAvailableOutlined />,
    EventAvailableRounded: <icons.EventAvailableRounded />,
    EventAvailableSharp: <icons.EventAvailableSharp />,
    EventAvailableTwoTone: <icons.EventAvailableTwoTone />,
    EventBusy: <icons.EventBusy />,
    EventBusyOutlined: <icons.EventBusyOutlined />,
    EventBusyRounded: <icons.EventBusyRounded />,
    EventBusySharp: <icons.EventBusySharp />,
    EventBusyTwoTone: <icons.EventBusyTwoTone />,
    EventNote: <icons.EventNote />,
    EventNoteOutlined: <icons.EventNoteOutlined />,
    EventNoteRounded: <icons.EventNoteRounded />,
    EventNoteSharp: <icons.EventNoteSharp />,
    EventNoteTwoTone: <icons.EventNoteTwoTone />,
    EventOutlined: <icons.EventOutlined />,
    EventRepeat: <icons.EventRepeat />,
    EventRepeatOutlined: <icons.EventRepeatOutlined />,
    EventRepeatRounded: <icons.EventRepeatRounded />,
    EventRepeatSharp: <icons.EventRepeatSharp />,
    EventRepeatTwoTone: <icons.EventRepeatTwoTone />,
    EventRounded: <icons.EventRounded />,
    EventSeat: <icons.EventSeat />,
    EventSeatOutlined: <icons.EventSeatOutlined />,
    EventSeatRounded: <icons.EventSeatRounded />,
    EventSeatSharp: <icons.EventSeatSharp />,
    EventSeatTwoTone: <icons.EventSeatTwoTone />,
    EventSharp: <icons.EventSharp />,
    EventTwoTone: <icons.EventTwoTone />,
    ExitToApp: <icons.ExitToApp />,
    ExitToAppOutlined: <icons.ExitToAppOutlined />,
    ExitToAppRounded: <icons.ExitToAppRounded />,
    ExitToAppSharp: <icons.ExitToAppSharp />,
    ExitToAppTwoTone: <icons.ExitToAppTwoTone />,
    Expand: <icons.Expand />,
    ExpandCircleDown: <icons.ExpandCircleDown />,
    ExpandCircleDownOutlined: <icons.ExpandCircleDownOutlined />,
    ExpandCircleDownRounded: <icons.ExpandCircleDownRounded />,
    ExpandCircleDownSharp: <icons.ExpandCircleDownSharp />,
    ExpandCircleDownTwoTone: <icons.ExpandCircleDownTwoTone />,
    ExpandLess: <icons.ExpandLess />,
    ExpandLessOutlined: <icons.ExpandLessOutlined />,
    ExpandLessRounded: <icons.ExpandLessRounded />,
    ExpandLessSharp: <icons.ExpandLessSharp />,
    ExpandLessTwoTone: <icons.ExpandLessTwoTone />,
    ExpandMore: <icons.ExpandMore />,
    ExpandMoreOutlined: <icons.ExpandMoreOutlined />,
    ExpandMoreRounded: <icons.ExpandMoreRounded />,
    ExpandMoreSharp: <icons.ExpandMoreSharp />,
    ExpandMoreTwoTone: <icons.ExpandMoreTwoTone />,
    ExpandOutlined: <icons.ExpandOutlined />,
    ExpandRounded: <icons.ExpandRounded />,
    ExpandSharp: <icons.ExpandSharp />,
    ExpandTwoTone: <icons.ExpandTwoTone />,
    Explicit: <icons.Explicit />,
    ExplicitOutlined: <icons.ExplicitOutlined />,
    ExplicitRounded: <icons.ExplicitRounded />,
    ExplicitSharp: <icons.ExplicitSharp />,
    ExplicitTwoTone: <icons.ExplicitTwoTone />,
    Explore: <icons.Explore />,
    ExploreOff: <icons.ExploreOff />,
    ExploreOffOutlined: <icons.ExploreOffOutlined />,
    ExploreOffRounded: <icons.ExploreOffRounded />,
    ExploreOffSharp: <icons.ExploreOffSharp />,
    ExploreOffTwoTone: <icons.ExploreOffTwoTone />,
    ExploreOutlined: <icons.ExploreOutlined />,
    ExploreRounded: <icons.ExploreRounded />,
    ExploreSharp: <icons.ExploreSharp />,
    ExploreTwoTone: <icons.ExploreTwoTone />,
    Exposure: <icons.Exposure />,
    ExposureOutlined: <icons.ExposureOutlined />,
    ExposureRounded: <icons.ExposureRounded />,
    ExposureSharp: <icons.ExposureSharp />,
    ExposureTwoTone: <icons.ExposureTwoTone />,
    Extension: <icons.Extension />,
    ExtensionOff: <icons.ExtensionOff />,
    ExtensionOffOutlined: <icons.ExtensionOffOutlined />,
    ExtensionOffRounded: <icons.ExtensionOffRounded />,
    ExtensionOffSharp: <icons.ExtensionOffSharp />,
    ExtensionOffTwoTone: <icons.ExtensionOffTwoTone />,
    ExtensionOutlined: <icons.ExtensionOutlined />,
    ExtensionRounded: <icons.ExtensionRounded />,
    ExtensionSharp: <icons.ExtensionSharp />,
    ExtensionTwoTone: <icons.ExtensionTwoTone />,
    Face: <icons.Face />,
    Face2: <icons.Face2 />,
    Face2Outlined: <icons.Face2Outlined />,
    Face2Rounded: <icons.Face2Rounded />,
    Face2Sharp: <icons.Face2Sharp />,
    Face2TwoTone: <icons.Face2TwoTone />,
    Face3: <icons.Face3 />,
    Face3Outlined: <icons.Face3Outlined />,
    Face3Rounded: <icons.Face3Rounded />,
    Face3Sharp: <icons.Face3Sharp />,
    Face3TwoTone: <icons.Face3TwoTone />,
    Face4: <icons.Face4 />,
    Face4Outlined: <icons.Face4Outlined />,
    Face4Rounded: <icons.Face4Rounded />,
    Face4Sharp: <icons.Face4Sharp />,
    Face4TwoTone: <icons.Face4TwoTone />,
    Face5: <icons.Face5 />,
    Face5Outlined: <icons.Face5Outlined />,
    Face5Rounded: <icons.Face5Rounded />,
    Face5Sharp: <icons.Face5Sharp />,
    Face5TwoTone: <icons.Face5TwoTone />,
    Face6: <icons.Face6 />,
    Face6Outlined: <icons.Face6Outlined />,
    Face6Rounded: <icons.Face6Rounded />,
    Face6Sharp: <icons.Face6Sharp />,
    Face6TwoTone: <icons.Face6TwoTone />,
    FaceOutlined: <icons.FaceOutlined />,
    FaceRetouchingNatural: <icons.FaceRetouchingNatural />,
    FaceRetouchingNaturalOutlined: <icons.FaceRetouchingNaturalOutlined />,
    FaceRetouchingNaturalRounded: <icons.FaceRetouchingNaturalRounded />,
    FaceRetouchingNaturalSharp: <icons.FaceRetouchingNaturalSharp />,
    FaceRetouchingNaturalTwoTone: <icons.FaceRetouchingNaturalTwoTone />,
    FaceRetouchingOff: <icons.FaceRetouchingOff />,
    FaceRetouchingOffOutlined: <icons.FaceRetouchingOffOutlined />,
    FaceRetouchingOffRounded: <icons.FaceRetouchingOffRounded />,
    FaceRetouchingOffSharp: <icons.FaceRetouchingOffSharp />,
    FaceRetouchingOffTwoTone: <icons.FaceRetouchingOffTwoTone />,
    FaceRounded: <icons.FaceRounded />,
    FaceSharp: <icons.FaceSharp />,
    FaceTwoTone: <icons.FaceTwoTone />,
    Facebook: <icons.Facebook />,
    FacebookOutlined: <icons.FacebookOutlined />,
    FacebookRounded: <icons.FacebookRounded />,
    FacebookSharp: <icons.FacebookSharp />,
    FacebookTwoTone: <icons.FacebookTwoTone />,
    FactCheck: <icons.FactCheck />,
    FactCheckOutlined: <icons.FactCheckOutlined />,
    FactCheckRounded: <icons.FactCheckRounded />,
    FactCheckSharp: <icons.FactCheckSharp />,
    FactCheckTwoTone: <icons.FactCheckTwoTone />,
    Factory: <icons.Factory />,
    FactoryOutlined: <icons.FactoryOutlined />,
    FactoryRounded: <icons.FactoryRounded />,
    FactorySharp: <icons.FactorySharp />,
    FactoryTwoTone: <icons.FactoryTwoTone />,
    FamilyRestroom: <icons.FamilyRestroom />,
    FamilyRestroomOutlined: <icons.FamilyRestroomOutlined />,
    FamilyRestroomRounded: <icons.FamilyRestroomRounded />,
    FamilyRestroomSharp: <icons.FamilyRestroomSharp />,
    FamilyRestroomTwoTone: <icons.FamilyRestroomTwoTone />,
    FastForward: <icons.FastForward />,
    FastForwardOutlined: <icons.FastForwardOutlined />,
    FastForwardRounded: <icons.FastForwardRounded />,
    FastForwardSharp: <icons.FastForwardSharp />,
    FastForwardTwoTone: <icons.FastForwardTwoTone />,
    FastRewind: <icons.FastRewind />,
    FastRewindOutlined: <icons.FastRewindOutlined />,
    FastRewindRounded: <icons.FastRewindRounded />,
    FastRewindSharp: <icons.FastRewindSharp />,
    FastRewindTwoTone: <icons.FastRewindTwoTone />,
    Fastfood: <icons.Fastfood />,
    FastfoodOutlined: <icons.FastfoodOutlined />,
    FastfoodRounded: <icons.FastfoodRounded />,
    FastfoodSharp: <icons.FastfoodSharp />,
    FastfoodTwoTone: <icons.FastfoodTwoTone />,
    Favorite: <icons.Favorite />,
    FavoriteBorder: <icons.FavoriteBorder />,
    FavoriteBorderOutlined: <icons.FavoriteBorderOutlined />,
    FavoriteBorderRounded: <icons.FavoriteBorderRounded />,
    FavoriteBorderSharp: <icons.FavoriteBorderSharp />,
    FavoriteBorderTwoTone: <icons.FavoriteBorderTwoTone />,
    FavoriteOutlined: <icons.FavoriteOutlined />,
    FavoriteRounded: <icons.FavoriteRounded />,
    FavoriteSharp: <icons.FavoriteSharp />,
    FavoriteTwoTone: <icons.FavoriteTwoTone />,
    Fax: <icons.Fax />,
    FaxOutlined: <icons.FaxOutlined />,
    FaxRounded: <icons.FaxRounded />,
    FaxSharp: <icons.FaxSharp />,
    FaxTwoTone: <icons.FaxTwoTone />,
    FeaturedPlayList: <icons.FeaturedPlayList />,
    FeaturedPlayListOutlined: <icons.FeaturedPlayListOutlined />,
    FeaturedPlayListRounded: <icons.FeaturedPlayListRounded />,
    FeaturedPlayListSharp: <icons.FeaturedPlayListSharp />,
    FeaturedPlayListTwoTone: <icons.FeaturedPlayListTwoTone />,
    FeaturedVideo: <icons.FeaturedVideo />,
    FeaturedVideoOutlined: <icons.FeaturedVideoOutlined />,
    FeaturedVideoRounded: <icons.FeaturedVideoRounded />,
    FeaturedVideoSharp: <icons.FeaturedVideoSharp />,
    FeaturedVideoTwoTone: <icons.FeaturedVideoTwoTone />,
    Feed: <icons.Feed />,
    FeedOutlined: <icons.FeedOutlined />,
    FeedRounded: <icons.FeedRounded />,
    FeedSharp: <icons.FeedSharp />,
    FeedTwoTone: <icons.FeedTwoTone />,
    Feedback: <icons.Feedback />,
    FeedbackOutlined: <icons.FeedbackOutlined />,
    FeedbackRounded: <icons.FeedbackRounded />,
    FeedbackSharp: <icons.FeedbackSharp />,
    FeedbackTwoTone: <icons.FeedbackTwoTone />,
    Female: <icons.Female />,
    FemaleOutlined: <icons.FemaleOutlined />,
    FemaleRounded: <icons.FemaleRounded />,
    FemaleSharp: <icons.FemaleSharp />,
    FemaleTwoTone: <icons.FemaleTwoTone />,
    Fence: <icons.Fence />,
    FenceOutlined: <icons.FenceOutlined />,
    FenceRounded: <icons.FenceRounded />,
    FenceSharp: <icons.FenceSharp />,
    FenceTwoTone: <icons.FenceTwoTone />,
    Festival: <icons.Festival />,
    FestivalOutlined: <icons.FestivalOutlined />,
    FestivalRounded: <icons.FestivalRounded />,
    FestivalSharp: <icons.FestivalSharp />,
    FestivalTwoTone: <icons.FestivalTwoTone />,
    FiberDvr: <icons.FiberDvr />,
    FiberDvrOutlined: <icons.FiberDvrOutlined />,
    FiberDvrRounded: <icons.FiberDvrRounded />,
    FiberDvrSharp: <icons.FiberDvrSharp />,
    FiberDvrTwoTone: <icons.FiberDvrTwoTone />,
    FiberManualRecord: <icons.FiberManualRecord />,
    FiberManualRecordOutlined: <icons.FiberManualRecordOutlined />,
    FiberManualRecordRounded: <icons.FiberManualRecordRounded />,
    FiberManualRecordSharp: <icons.FiberManualRecordSharp />,
    FiberManualRecordTwoTone: <icons.FiberManualRecordTwoTone />,
    FiberNew: <icons.FiberNew />,
    FiberNewOutlined: <icons.FiberNewOutlined />,
    FiberNewRounded: <icons.FiberNewRounded />,
    FiberNewSharp: <icons.FiberNewSharp />,
    FiberNewTwoTone: <icons.FiberNewTwoTone />,
    FiberPin: <icons.FiberPin />,
    FiberPinOutlined: <icons.FiberPinOutlined />,
    FiberPinRounded: <icons.FiberPinRounded />,
    FiberPinSharp: <icons.FiberPinSharp />,
    FiberPinTwoTone: <icons.FiberPinTwoTone />,
    FiberSmartRecord: <icons.FiberSmartRecord />,
    FiberSmartRecordOutlined: <icons.FiberSmartRecordOutlined />,
    FiberSmartRecordRounded: <icons.FiberSmartRecordRounded />,
    FiberSmartRecordSharp: <icons.FiberSmartRecordSharp />,
    FiberSmartRecordTwoTone: <icons.FiberSmartRecordTwoTone />,
    FifteenMp: <icons.FifteenMp />,
    FifteenMpOutlined: <icons.FifteenMpOutlined />,
    FifteenMpRounded: <icons.FifteenMpRounded />,
    FifteenMpSharp: <icons.FifteenMpSharp />,
    FifteenMpTwoTone: <icons.FifteenMpTwoTone />,
    FileCopy: <icons.FileCopy />,
    FileCopyOutlined: <icons.FileCopyOutlined />,
    FileCopyRounded: <icons.FileCopyRounded />,
    FileCopySharp: <icons.FileCopySharp />,
    FileCopyTwoTone: <icons.FileCopyTwoTone />,
    FileDownload: <icons.FileDownload />,
    FileDownloadDone: <icons.FileDownloadDone />,
    FileDownloadDoneOutlined: <icons.FileDownloadDoneOutlined />,
    FileDownloadDoneRounded: <icons.FileDownloadDoneRounded />,
    FileDownloadDoneSharp: <icons.FileDownloadDoneSharp />,
    FileDownloadDoneTwoTone: <icons.FileDownloadDoneTwoTone />,
    FileDownloadOff: <icons.FileDownloadOff />,
    FileDownloadOffOutlined: <icons.FileDownloadOffOutlined />,
    FileDownloadOffRounded: <icons.FileDownloadOffRounded />,
    FileDownloadOffSharp: <icons.FileDownloadOffSharp />,
    FileDownloadOffTwoTone: <icons.FileDownloadOffTwoTone />,
    FileDownloadOutlined: <icons.FileDownloadOutlined />,
    FileDownloadRounded: <icons.FileDownloadRounded />,
    FileDownloadSharp: <icons.FileDownloadSharp />,
    FileDownloadTwoTone: <icons.FileDownloadTwoTone />,
    FileOpen: <icons.FileOpen />,
    FileOpenOutlined: <icons.FileOpenOutlined />,
    FileOpenRounded: <icons.FileOpenRounded />,
    FileOpenSharp: <icons.FileOpenSharp />,
    FileOpenTwoTone: <icons.FileOpenTwoTone />,
    FilePresent: <icons.FilePresent />,
    FilePresentOutlined: <icons.FilePresentOutlined />,
    FilePresentRounded: <icons.FilePresentRounded />,
    FilePresentSharp: <icons.FilePresentSharp />,
    FilePresentTwoTone: <icons.FilePresentTwoTone />,
    FileUpload: <icons.FileUpload />,
    FileUploadOutlined: <icons.FileUploadOutlined />,
    FileUploadRounded: <icons.FileUploadRounded />,
    FileUploadSharp: <icons.FileUploadSharp />,
    FileUploadTwoTone: <icons.FileUploadTwoTone />,
    Filter: <icons.Filter />,
    Filter1: <icons.Filter1 />,
    Filter1Outlined: <icons.Filter1Outlined />,
    Filter1Rounded: <icons.Filter1Rounded />,
    Filter1Sharp: <icons.Filter1Sharp />,
    Filter1TwoTone: <icons.Filter1TwoTone />,
    Filter2: <icons.Filter2 />,
    Filter2Outlined: <icons.Filter2Outlined />,
    Filter2Rounded: <icons.Filter2Rounded />,
    Filter2Sharp: <icons.Filter2Sharp />,
    Filter2TwoTone: <icons.Filter2TwoTone />,
    Filter3: <icons.Filter3 />,
    Filter3Outlined: <icons.Filter3Outlined />,
    Filter3Rounded: <icons.Filter3Rounded />,
    Filter3Sharp: <icons.Filter3Sharp />,
    Filter3TwoTone: <icons.Filter3TwoTone />,
    Filter4: <icons.Filter4 />,
    Filter4Outlined: <icons.Filter4Outlined />,
    Filter4Rounded: <icons.Filter4Rounded />,
    Filter4Sharp: <icons.Filter4Sharp />,
    Filter4TwoTone: <icons.Filter4TwoTone />,
    Filter5: <icons.Filter5 />,
    Filter5Outlined: <icons.Filter5Outlined />,
    Filter5Rounded: <icons.Filter5Rounded />,
    Filter5Sharp: <icons.Filter5Sharp />,
    Filter5TwoTone: <icons.Filter5TwoTone />,
    Filter6: <icons.Filter6 />,
    Filter6Outlined: <icons.Filter6Outlined />,
    Filter6Rounded: <icons.Filter6Rounded />,
    Filter6Sharp: <icons.Filter6Sharp />,
    Filter6TwoTone: <icons.Filter6TwoTone />,
    Filter7: <icons.Filter7 />,
    Filter7Outlined: <icons.Filter7Outlined />,
    Filter7Rounded: <icons.Filter7Rounded />,
    Filter7Sharp: <icons.Filter7Sharp />,
    Filter7TwoTone: <icons.Filter7TwoTone />,
    Filter8: <icons.Filter8 />,
    Filter8Outlined: <icons.Filter8Outlined />,
    Filter8Rounded: <icons.Filter8Rounded />,
    Filter8Sharp: <icons.Filter8Sharp />,
    Filter8TwoTone: <icons.Filter8TwoTone />,
    Filter9: <icons.Filter9 />,
    Filter9Outlined: <icons.Filter9Outlined />,
    Filter9Plus: <icons.Filter9Plus />,
    Filter9PlusOutlined: <icons.Filter9PlusOutlined />,
    Filter9PlusRounded: <icons.Filter9PlusRounded />,
    Filter9PlusSharp: <icons.Filter9PlusSharp />,
    Filter9PlusTwoTone: <icons.Filter9PlusTwoTone />,
    Filter9Rounded: <icons.Filter9Rounded />,
    Filter9Sharp: <icons.Filter9Sharp />,
    Filter9TwoTone: <icons.Filter9TwoTone />,
    FilterAlt: <icons.FilterAlt />,
    FilterAltOff: <icons.FilterAltOff />,
    FilterAltOffOutlined: <icons.FilterAltOffOutlined />,
    FilterAltOffRounded: <icons.FilterAltOffRounded />,
    FilterAltOffSharp: <icons.FilterAltOffSharp />,
    FilterAltOffTwoTone: <icons.FilterAltOffTwoTone />,
    FilterAltOutlined: <icons.FilterAltOutlined />,
    FilterAltRounded: <icons.FilterAltRounded />,
    FilterAltSharp: <icons.FilterAltSharp />,
    FilterAltTwoTone: <icons.FilterAltTwoTone />,
    FilterBAndW: <icons.FilterBAndW />,
    FilterBAndWOutlined: <icons.FilterBAndWOutlined />,
    FilterBAndWRounded: <icons.FilterBAndWRounded />,
    FilterBAndWSharp: <icons.FilterBAndWSharp />,
    FilterBAndWTwoTone: <icons.FilterBAndWTwoTone />,
    FilterCenterFocus: <icons.FilterCenterFocus />,
    FilterCenterFocusOutlined: <icons.FilterCenterFocusOutlined />,
    FilterCenterFocusRounded: <icons.FilterCenterFocusRounded />,
    FilterCenterFocusSharp: <icons.FilterCenterFocusSharp />,
    FilterCenterFocusTwoTone: <icons.FilterCenterFocusTwoTone />,
    FilterDrama: <icons.FilterDrama />,
    FilterDramaOutlined: <icons.FilterDramaOutlined />,
    FilterDramaRounded: <icons.FilterDramaRounded />,
    FilterDramaSharp: <icons.FilterDramaSharp />,
    FilterDramaTwoTone: <icons.FilterDramaTwoTone />,
    FilterFrames: <icons.FilterFrames />,
    FilterFramesOutlined: <icons.FilterFramesOutlined />,
    FilterFramesRounded: <icons.FilterFramesRounded />,
    FilterFramesSharp: <icons.FilterFramesSharp />,
    FilterFramesTwoTone: <icons.FilterFramesTwoTone />,
    FilterHdr: <icons.FilterHdr />,
    FilterHdrOutlined: <icons.FilterHdrOutlined />,
    FilterHdrRounded: <icons.FilterHdrRounded />,
    FilterHdrSharp: <icons.FilterHdrSharp />,
    FilterHdrTwoTone: <icons.FilterHdrTwoTone />,
    FilterList: <icons.FilterList />,
    FilterListOff: <icons.FilterListOff />,
    FilterListOffOutlined: <icons.FilterListOffOutlined />,
    FilterListOffRounded: <icons.FilterListOffRounded />,
    FilterListOffSharp: <icons.FilterListOffSharp />,
    FilterListOffTwoTone: <icons.FilterListOffTwoTone />,
    FilterListOutlined: <icons.FilterListOutlined />,
    FilterListRounded: <icons.FilterListRounded />,
    FilterListSharp: <icons.FilterListSharp />,
    FilterListTwoTone: <icons.FilterListTwoTone />,
    FilterNone: <icons.FilterNone />,
    FilterNoneOutlined: <icons.FilterNoneOutlined />,
    FilterNoneRounded: <icons.FilterNoneRounded />,
    FilterNoneSharp: <icons.FilterNoneSharp />,
    FilterNoneTwoTone: <icons.FilterNoneTwoTone />,
    FilterOutlined: <icons.FilterOutlined />,
    FilterRounded: <icons.FilterRounded />,
    FilterSharp: <icons.FilterSharp />,
    FilterTiltShift: <icons.FilterTiltShift />,
    FilterTiltShiftOutlined: <icons.FilterTiltShiftOutlined />,
    FilterTiltShiftRounded: <icons.FilterTiltShiftRounded />,
    FilterTiltShiftSharp: <icons.FilterTiltShiftSharp />,
    FilterTiltShiftTwoTone: <icons.FilterTiltShiftTwoTone />,
    FilterTwoTone: <icons.FilterTwoTone />,
    FilterVintage: <icons.FilterVintage />,
    FilterVintageOutlined: <icons.FilterVintageOutlined />,
    FilterVintageRounded: <icons.FilterVintageRounded />,
    FilterVintageSharp: <icons.FilterVintageSharp />,
    FilterVintageTwoTone: <icons.FilterVintageTwoTone />,
    FindInPage: <icons.FindInPage />,
    FindInPageOutlined: <icons.FindInPageOutlined />,
    FindInPageRounded: <icons.FindInPageRounded />,
    FindInPageSharp: <icons.FindInPageSharp />,
    FindInPageTwoTone: <icons.FindInPageTwoTone />,
    FindReplace: <icons.FindReplace />,
    FindReplaceOutlined: <icons.FindReplaceOutlined />,
    FindReplaceRounded: <icons.FindReplaceRounded />,
    FindReplaceSharp: <icons.FindReplaceSharp />,
    FindReplaceTwoTone: <icons.FindReplaceTwoTone />,
    Fingerprint: <icons.Fingerprint />,
    FingerprintOutlined: <icons.FingerprintOutlined />,
    FingerprintRounded: <icons.FingerprintRounded />,
    FingerprintSharp: <icons.FingerprintSharp />,
    FingerprintTwoTone: <icons.FingerprintTwoTone />,
    FireExtinguisher: <icons.FireExtinguisher />,
    FireExtinguisherOutlined: <icons.FireExtinguisherOutlined />,
    FireExtinguisherRounded: <icons.FireExtinguisherRounded />,
    FireExtinguisherSharp: <icons.FireExtinguisherSharp />,
    FireExtinguisherTwoTone: <icons.FireExtinguisherTwoTone />,
    FireHydrantAlt: <icons.FireHydrantAlt />,
    FireHydrantAltOutlined: <icons.FireHydrantAltOutlined />,
    FireHydrantAltRounded: <icons.FireHydrantAltRounded />,
    FireHydrantAltSharp: <icons.FireHydrantAltSharp />,
    FireHydrantAltTwoTone: <icons.FireHydrantAltTwoTone />,
    FireTruck: <icons.FireTruck />,
    FireTruckOutlined: <icons.FireTruckOutlined />,
    FireTruckRounded: <icons.FireTruckRounded />,
    FireTruckSharp: <icons.FireTruckSharp />,
    FireTruckTwoTone: <icons.FireTruckTwoTone />,
    Fireplace: <icons.Fireplace />,
    FireplaceOutlined: <icons.FireplaceOutlined />,
    FireplaceRounded: <icons.FireplaceRounded />,
    FireplaceSharp: <icons.FireplaceSharp />,
    FireplaceTwoTone: <icons.FireplaceTwoTone />,
    FirstPage: <icons.FirstPage />,
    FirstPageOutlined: <icons.FirstPageOutlined />,
    FirstPageRounded: <icons.FirstPageRounded />,
    FirstPageSharp: <icons.FirstPageSharp />,
    FirstPageTwoTone: <icons.FirstPageTwoTone />,
    FitScreen: <icons.FitScreen />,
    FitScreenOutlined: <icons.FitScreenOutlined />,
    FitScreenRounded: <icons.FitScreenRounded />,
    FitScreenSharp: <icons.FitScreenSharp />,
    FitScreenTwoTone: <icons.FitScreenTwoTone />,
    Fitbit: <icons.Fitbit />,
    FitbitOutlined: <icons.FitbitOutlined />,
    FitbitRounded: <icons.FitbitRounded />,
    FitbitSharp: <icons.FitbitSharp />,
    FitbitTwoTone: <icons.FitbitTwoTone />,
    FitnessCenter: <icons.FitnessCenter />,
    FitnessCenterOutlined: <icons.FitnessCenterOutlined />,
    FitnessCenterRounded: <icons.FitnessCenterRounded />,
    FitnessCenterSharp: <icons.FitnessCenterSharp />,
    FitnessCenterTwoTone: <icons.FitnessCenterTwoTone />,
    FiveG: <icons.FiveG />,
    FiveGOutlined: <icons.FiveGOutlined />,
    FiveGRounded: <icons.FiveGRounded />,
    FiveGSharp: <icons.FiveGSharp />,
    FiveGTwoTone: <icons.FiveGTwoTone />,
    FiveK: <icons.FiveK />,
    FiveKOutlined: <icons.FiveKOutlined />,
    FiveKPlus: <icons.FiveKPlus />,
    FiveKPlusOutlined: <icons.FiveKPlusOutlined />,
    FiveKPlusRounded: <icons.FiveKPlusRounded />,
    FiveKPlusSharp: <icons.FiveKPlusSharp />,
    FiveKPlusTwoTone: <icons.FiveKPlusTwoTone />,
    FiveKRounded: <icons.FiveKRounded />,
    FiveKSharp: <icons.FiveKSharp />,
    FiveKTwoTone: <icons.FiveKTwoTone />,
    FiveMp: <icons.FiveMp />,
    FiveMpOutlined: <icons.FiveMpOutlined />,
    FiveMpRounded: <icons.FiveMpRounded />,
    FiveMpSharp: <icons.FiveMpSharp />,
    FiveMpTwoTone: <icons.FiveMpTwoTone />,
    FivteenMp: <icons.FivteenMp />,
    FivteenMpOutlined: <icons.FivteenMpOutlined />,
    FivteenMpRounded: <icons.FivteenMpRounded />,
    FivteenMpSharp: <icons.FivteenMpSharp />,
    FivteenMpTwoTone: <icons.FivteenMpTwoTone />,
    Flag: <icons.Flag />,
    FlagCircle: <icons.FlagCircle />,
    FlagCircleOutlined: <icons.FlagCircleOutlined />,
    FlagCircleRounded: <icons.FlagCircleRounded />,
    FlagCircleSharp: <icons.FlagCircleSharp />,
    FlagCircleTwoTone: <icons.FlagCircleTwoTone />,
    FlagOutlined: <icons.FlagOutlined />,
    FlagRounded: <icons.FlagRounded />,
    FlagSharp: <icons.FlagSharp />,
    FlagTwoTone: <icons.FlagTwoTone />,
    Flaky: <icons.Flaky />,
    FlakyOutlined: <icons.FlakyOutlined />,
    FlakyRounded: <icons.FlakyRounded />,
    FlakySharp: <icons.FlakySharp />,
    FlakyTwoTone: <icons.FlakyTwoTone />,
    Flare: <icons.Flare />,
    FlareOutlined: <icons.FlareOutlined />,
    FlareRounded: <icons.FlareRounded />,
    FlareSharp: <icons.FlareSharp />,
    FlareTwoTone: <icons.FlareTwoTone />,
    FlashAuto: <icons.FlashAuto />,
    FlashAutoOutlined: <icons.FlashAutoOutlined />,
    FlashAutoRounded: <icons.FlashAutoRounded />,
    FlashAutoSharp: <icons.FlashAutoSharp />,
    FlashAutoTwoTone: <icons.FlashAutoTwoTone />,
    FlashOff: <icons.FlashOff />,
    FlashOffOutlined: <icons.FlashOffOutlined />,
    FlashOffRounded: <icons.FlashOffRounded />,
    FlashOffSharp: <icons.FlashOffSharp />,
    FlashOffTwoTone: <icons.FlashOffTwoTone />,
    FlashOn: <icons.FlashOn />,
    FlashOnOutlined: <icons.FlashOnOutlined />,
    FlashOnRounded: <icons.FlashOnRounded />,
    FlashOnSharp: <icons.FlashOnSharp />,
    FlashOnTwoTone: <icons.FlashOnTwoTone />,
    FlashlightOff: <icons.FlashlightOff />,
    FlashlightOffOutlined: <icons.FlashlightOffOutlined />,
    FlashlightOffRounded: <icons.FlashlightOffRounded />,
    FlashlightOffSharp: <icons.FlashlightOffSharp />,
    FlashlightOffTwoTone: <icons.FlashlightOffTwoTone />,
    FlashlightOn: <icons.FlashlightOn />,
    FlashlightOnOutlined: <icons.FlashlightOnOutlined />,
    FlashlightOnRounded: <icons.FlashlightOnRounded />,
    FlashlightOnSharp: <icons.FlashlightOnSharp />,
    FlashlightOnTwoTone: <icons.FlashlightOnTwoTone />,
    Flatware: <icons.Flatware />,
    FlatwareOutlined: <icons.FlatwareOutlined />,
    FlatwareRounded: <icons.FlatwareRounded />,
    FlatwareSharp: <icons.FlatwareSharp />,
    FlatwareTwoTone: <icons.FlatwareTwoTone />,
    Flight: <icons.Flight />,
    FlightClass: <icons.FlightClass />,
    FlightClassOutlined: <icons.FlightClassOutlined />,
    FlightClassRounded: <icons.FlightClassRounded />,
    FlightClassSharp: <icons.FlightClassSharp />,
    FlightClassTwoTone: <icons.FlightClassTwoTone />,
    FlightLand: <icons.FlightLand />,
    FlightLandOutlined: <icons.FlightLandOutlined />,
    FlightLandRounded: <icons.FlightLandRounded />,
    FlightLandSharp: <icons.FlightLandSharp />,
    FlightLandTwoTone: <icons.FlightLandTwoTone />,
    FlightOutlined: <icons.FlightOutlined />,
    FlightRounded: <icons.FlightRounded />,
    FlightSharp: <icons.FlightSharp />,
    FlightTakeoff: <icons.FlightTakeoff />,
    FlightTakeoffOutlined: <icons.FlightTakeoffOutlined />,
    FlightTakeoffRounded: <icons.FlightTakeoffRounded />,
    FlightTakeoffSharp: <icons.FlightTakeoffSharp />,
    FlightTakeoffTwoTone: <icons.FlightTakeoffTwoTone />,
    FlightTwoTone: <icons.FlightTwoTone />,
    Flip: <icons.Flip />,
    FlipCameraAndroid: <icons.FlipCameraAndroid />,
    FlipCameraAndroidOutlined: <icons.FlipCameraAndroidOutlined />,
    FlipCameraAndroidRounded: <icons.FlipCameraAndroidRounded />,
    FlipCameraAndroidSharp: <icons.FlipCameraAndroidSharp />,
    FlipCameraAndroidTwoTone: <icons.FlipCameraAndroidTwoTone />,
    FlipCameraIos: <icons.FlipCameraIos />,
    FlipCameraIosOutlined: <icons.FlipCameraIosOutlined />,
    FlipCameraIosRounded: <icons.FlipCameraIosRounded />,
    FlipCameraIosSharp: <icons.FlipCameraIosSharp />,
    FlipCameraIosTwoTone: <icons.FlipCameraIosTwoTone />,
    FlipOutlined: <icons.FlipOutlined />,
    FlipRounded: <icons.FlipRounded />,
    FlipSharp: <icons.FlipSharp />,
    FlipToBack: <icons.FlipToBack />,
    FlipToBackOutlined: <icons.FlipToBackOutlined />,
    FlipToBackRounded: <icons.FlipToBackRounded />,
    FlipToBackSharp: <icons.FlipToBackSharp />,
    FlipToBackTwoTone: <icons.FlipToBackTwoTone />,
    FlipToFront: <icons.FlipToFront />,
    FlipToFrontOutlined: <icons.FlipToFrontOutlined />,
    FlipToFrontRounded: <icons.FlipToFrontRounded />,
    FlipToFrontSharp: <icons.FlipToFrontSharp />,
    FlipToFrontTwoTone: <icons.FlipToFrontTwoTone />,
    FlipTwoTone: <icons.FlipTwoTone />,
    Flood: <icons.Flood />,
    FloodOutlined: <icons.FloodOutlined />,
    FloodRounded: <icons.FloodRounded />,
    FloodSharp: <icons.FloodSharp />,
    FloodTwoTone: <icons.FloodTwoTone />,
    Fluorescent: <icons.Fluorescent />,
    FluorescentOutlined: <icons.FluorescentOutlined />,
    FluorescentRounded: <icons.FluorescentRounded />,
    FluorescentSharp: <icons.FluorescentSharp />,
    FluorescentTwoTone: <icons.FluorescentTwoTone />,
    FlutterDash: <icons.FlutterDash />,
    FlutterDashOutlined: <icons.FlutterDashOutlined />,
    FlutterDashRounded: <icons.FlutterDashRounded />,
    FlutterDashSharp: <icons.FlutterDashSharp />,
    FlutterDashTwoTone: <icons.FlutterDashTwoTone />,
    FmdBad: <icons.FmdBad />,
    FmdBadOutlined: <icons.FmdBadOutlined />,
    FmdBadRounded: <icons.FmdBadRounded />,
    FmdBadSharp: <icons.FmdBadSharp />,
    FmdBadTwoTone: <icons.FmdBadTwoTone />,
    FmdGood: <icons.FmdGood />,
    FmdGoodOutlined: <icons.FmdGoodOutlined />,
    FmdGoodRounded: <icons.FmdGoodRounded />,
    FmdGoodSharp: <icons.FmdGoodSharp />,
    FmdGoodTwoTone: <icons.FmdGoodTwoTone />,
    Folder: <icons.Folder />,
    FolderCopy: <icons.FolderCopy />,
    FolderCopyOutlined: <icons.FolderCopyOutlined />,
    FolderCopyRounded: <icons.FolderCopyRounded />,
    FolderCopySharp: <icons.FolderCopySharp />,
    FolderCopyTwoTone: <icons.FolderCopyTwoTone />,
    FolderDelete: <icons.FolderDelete />,
    FolderDeleteOutlined: <icons.FolderDeleteOutlined />,
    FolderDeleteRounded: <icons.FolderDeleteRounded />,
    FolderDeleteSharp: <icons.FolderDeleteSharp />,
    FolderDeleteTwoTone: <icons.FolderDeleteTwoTone />,
    FolderOff: <icons.FolderOff />,
    FolderOffOutlined: <icons.FolderOffOutlined />,
    FolderOffRounded: <icons.FolderOffRounded />,
    FolderOffSharp: <icons.FolderOffSharp />,
    FolderOffTwoTone: <icons.FolderOffTwoTone />,
    FolderOpen: <icons.FolderOpen />,
    FolderOpenOutlined: <icons.FolderOpenOutlined />,
    FolderOpenRounded: <icons.FolderOpenRounded />,
    FolderOpenSharp: <icons.FolderOpenSharp />,
    FolderOpenTwoTone: <icons.FolderOpenTwoTone />,
    FolderOutlined: <icons.FolderOutlined />,
    FolderRounded: <icons.FolderRounded />,
    FolderShared: <icons.FolderShared />,
    FolderSharedOutlined: <icons.FolderSharedOutlined />,
    FolderSharedRounded: <icons.FolderSharedRounded />,
    FolderSharedSharp: <icons.FolderSharedSharp />,
    FolderSharedTwoTone: <icons.FolderSharedTwoTone />,
    FolderSharp: <icons.FolderSharp />,
    FolderSpecial: <icons.FolderSpecial />,
    FolderSpecialOutlined: <icons.FolderSpecialOutlined />,
    FolderSpecialRounded: <icons.FolderSpecialRounded />,
    FolderSpecialSharp: <icons.FolderSpecialSharp />,
    FolderSpecialTwoTone: <icons.FolderSpecialTwoTone />,
    FolderTwoTone: <icons.FolderTwoTone />,
    FolderZip: <icons.FolderZip />,
    FolderZipOutlined: <icons.FolderZipOutlined />,
    FolderZipRounded: <icons.FolderZipRounded />,
    FolderZipSharp: <icons.FolderZipSharp />,
    FolderZipTwoTone: <icons.FolderZipTwoTone />,
    FollowTheSigns: <icons.FollowTheSigns />,
    FollowTheSignsOutlined: <icons.FollowTheSignsOutlined />,
    FollowTheSignsRounded: <icons.FollowTheSignsRounded />,
    FollowTheSignsSharp: <icons.FollowTheSignsSharp />,
    FollowTheSignsTwoTone: <icons.FollowTheSignsTwoTone />,
    FontDownload: <icons.FontDownload />,
    FontDownloadOff: <icons.FontDownloadOff />,
    FontDownloadOffOutlined: <icons.FontDownloadOffOutlined />,
    FontDownloadOffRounded: <icons.FontDownloadOffRounded />,
    FontDownloadOffSharp: <icons.FontDownloadOffSharp />,
    FontDownloadOffTwoTone: <icons.FontDownloadOffTwoTone />,
    FontDownloadOutlined: <icons.FontDownloadOutlined />,
    FontDownloadRounded: <icons.FontDownloadRounded />,
    FontDownloadSharp: <icons.FontDownloadSharp />,
    FontDownloadTwoTone: <icons.FontDownloadTwoTone />,
    FoodBank: <icons.FoodBank />,
    FoodBankOutlined: <icons.FoodBankOutlined />,
    FoodBankRounded: <icons.FoodBankRounded />,
    FoodBankSharp: <icons.FoodBankSharp />,
    FoodBankTwoTone: <icons.FoodBankTwoTone />,
    Forest: <icons.Forest />,
    ForestOutlined: <icons.ForestOutlined />,
    ForestRounded: <icons.ForestRounded />,
    ForestSharp: <icons.ForestSharp />,
    ForestTwoTone: <icons.ForestTwoTone />,
    ForkLeft: <icons.ForkLeft />,
    ForkLeftOutlined: <icons.ForkLeftOutlined />,
    ForkLeftRounded: <icons.ForkLeftRounded />,
    ForkLeftSharp: <icons.ForkLeftSharp />,
    ForkLeftTwoTone: <icons.ForkLeftTwoTone />,
    ForkRight: <icons.ForkRight />,
    ForkRightOutlined: <icons.ForkRightOutlined />,
    ForkRightRounded: <icons.ForkRightRounded />,
    ForkRightSharp: <icons.ForkRightSharp />,
    ForkRightTwoTone: <icons.ForkRightTwoTone />,
    FormatAlignCenter: <icons.FormatAlignCenter />,
    FormatAlignCenterOutlined: <icons.FormatAlignCenterOutlined />,
    FormatAlignCenterRounded: <icons.FormatAlignCenterRounded />,
    FormatAlignCenterSharp: <icons.FormatAlignCenterSharp />,
    FormatAlignCenterTwoTone: <icons.FormatAlignCenterTwoTone />,
    FormatAlignJustify: <icons.FormatAlignJustify />,
    FormatAlignJustifyOutlined: <icons.FormatAlignJustifyOutlined />,
    FormatAlignJustifyRounded: <icons.FormatAlignJustifyRounded />,
    FormatAlignJustifySharp: <icons.FormatAlignJustifySharp />,
    FormatAlignJustifyTwoTone: <icons.FormatAlignJustifyTwoTone />,
    FormatAlignLeft: <icons.FormatAlignLeft />,
    FormatAlignLeftOutlined: <icons.FormatAlignLeftOutlined />,
    FormatAlignLeftRounded: <icons.FormatAlignLeftRounded />,
    FormatAlignLeftSharp: <icons.FormatAlignLeftSharp />,
    FormatAlignLeftTwoTone: <icons.FormatAlignLeftTwoTone />,
    FormatAlignRight: <icons.FormatAlignRight />,
    FormatAlignRightOutlined: <icons.FormatAlignRightOutlined />,
    FormatAlignRightRounded: <icons.FormatAlignRightRounded />,
    FormatAlignRightSharp: <icons.FormatAlignRightSharp />,
    FormatAlignRightTwoTone: <icons.FormatAlignRightTwoTone />,
    FormatBold: <icons.FormatBold />,
    FormatBoldOutlined: <icons.FormatBoldOutlined />,
    FormatBoldRounded: <icons.FormatBoldRounded />,
    FormatBoldSharp: <icons.FormatBoldSharp />,
    FormatBoldTwoTone: <icons.FormatBoldTwoTone />,
    FormatClear: <icons.FormatClear />,
    FormatClearOutlined: <icons.FormatClearOutlined />,
    FormatClearRounded: <icons.FormatClearRounded />,
    FormatClearSharp: <icons.FormatClearSharp />,
    FormatClearTwoTone: <icons.FormatClearTwoTone />,
    FormatColorFill: <icons.FormatColorFill />,
    FormatColorFillOutlined: <icons.FormatColorFillOutlined />,
    FormatColorFillRounded: <icons.FormatColorFillRounded />,
    FormatColorFillSharp: <icons.FormatColorFillSharp />,
    FormatColorFillTwoTone: <icons.FormatColorFillTwoTone />,
    FormatColorReset: <icons.FormatColorReset />,
    FormatColorResetOutlined: <icons.FormatColorResetOutlined />,
    FormatColorResetRounded: <icons.FormatColorResetRounded />,
    FormatColorResetSharp: <icons.FormatColorResetSharp />,
    FormatColorResetTwoTone: <icons.FormatColorResetTwoTone />,
    FormatColorText: <icons.FormatColorText />,
    FormatColorTextOutlined: <icons.FormatColorTextOutlined />,
    FormatColorTextRounded: <icons.FormatColorTextRounded />,
    FormatColorTextSharp: <icons.FormatColorTextSharp />,
    FormatColorTextTwoTone: <icons.FormatColorTextTwoTone />,
    FormatIndentDecrease: <icons.FormatIndentDecrease />,
    FormatIndentDecreaseOutlined: <icons.FormatIndentDecreaseOutlined />,
    FormatIndentDecreaseRounded: <icons.FormatIndentDecreaseRounded />,
    FormatIndentDecreaseSharp: <icons.FormatIndentDecreaseSharp />,
    FormatIndentDecreaseTwoTone: <icons.FormatIndentDecreaseTwoTone />,
    FormatIndentIncrease: <icons.FormatIndentIncrease />,
    FormatIndentIncreaseOutlined: <icons.FormatIndentIncreaseOutlined />,
    FormatIndentIncreaseRounded: <icons.FormatIndentIncreaseRounded />,
    FormatIndentIncreaseSharp: <icons.FormatIndentIncreaseSharp />,
    FormatIndentIncreaseTwoTone: <icons.FormatIndentIncreaseTwoTone />,
    FormatItalic: <icons.FormatItalic />,
    FormatItalicOutlined: <icons.FormatItalicOutlined />,
    FormatItalicRounded: <icons.FormatItalicRounded />,
    FormatItalicSharp: <icons.FormatItalicSharp />,
    FormatItalicTwoTone: <icons.FormatItalicTwoTone />,
    FormatLineSpacing: <icons.FormatLineSpacing />,
    FormatLineSpacingOutlined: <icons.FormatLineSpacingOutlined />,
    FormatLineSpacingRounded: <icons.FormatLineSpacingRounded />,
    FormatLineSpacingSharp: <icons.FormatLineSpacingSharp />,
    FormatLineSpacingTwoTone: <icons.FormatLineSpacingTwoTone />,
    FormatListBulleted: <icons.FormatListBulleted />,
    FormatListBulletedOutlined: <icons.FormatListBulletedOutlined />,
    FormatListBulletedRounded: <icons.FormatListBulletedRounded />,
    FormatListBulletedSharp: <icons.FormatListBulletedSharp />,
    FormatListBulletedTwoTone: <icons.FormatListBulletedTwoTone />,
    FormatListNumbered: <icons.FormatListNumbered />,
    FormatListNumberedOutlined: <icons.FormatListNumberedOutlined />,
    FormatListNumberedRounded: <icons.FormatListNumberedRounded />,
    FormatListNumberedRtl: <icons.FormatListNumberedRtl />,
    FormatListNumberedRtlOutlined: <icons.FormatListNumberedRtlOutlined />,
    FormatListNumberedRtlRounded: <icons.FormatListNumberedRtlRounded />,
    FormatListNumberedRtlSharp: <icons.FormatListNumberedRtlSharp />,
    FormatListNumberedRtlTwoTone: <icons.FormatListNumberedRtlTwoTone />,
    FormatListNumberedSharp: <icons.FormatListNumberedSharp />,
    FormatListNumberedTwoTone: <icons.FormatListNumberedTwoTone />,
    FormatOverline: <icons.FormatOverline />,
    FormatOverlineOutlined: <icons.FormatOverlineOutlined />,
    FormatOverlineRounded: <icons.FormatOverlineRounded />,
    FormatOverlineSharp: <icons.FormatOverlineSharp />,
    FormatOverlineTwoTone: <icons.FormatOverlineTwoTone />,
    FormatPaint: <icons.FormatPaint />,
    FormatPaintOutlined: <icons.FormatPaintOutlined />,
    FormatPaintRounded: <icons.FormatPaintRounded />,
    FormatPaintSharp: <icons.FormatPaintSharp />,
    FormatPaintTwoTone: <icons.FormatPaintTwoTone />,
    FormatQuote: <icons.FormatQuote />,
    FormatQuoteOutlined: <icons.FormatQuoteOutlined />,
    FormatQuoteRounded: <icons.FormatQuoteRounded />,
    FormatQuoteSharp: <icons.FormatQuoteSharp />,
    FormatQuoteTwoTone: <icons.FormatQuoteTwoTone />,
    FormatShapes: <icons.FormatShapes />,
    FormatShapesOutlined: <icons.FormatShapesOutlined />,
    FormatShapesRounded: <icons.FormatShapesRounded />,
    FormatShapesSharp: <icons.FormatShapesSharp />,
    FormatShapesTwoTone: <icons.FormatShapesTwoTone />,
    FormatSize: <icons.FormatSize />,
    FormatSizeOutlined: <icons.FormatSizeOutlined />,
    FormatSizeRounded: <icons.FormatSizeRounded />,
    FormatSizeSharp: <icons.FormatSizeSharp />,
    FormatSizeTwoTone: <icons.FormatSizeTwoTone />,
    FormatStrikethrough: <icons.FormatStrikethrough />,
    FormatStrikethroughOutlined: <icons.FormatStrikethroughOutlined />,
    FormatStrikethroughRounded: <icons.FormatStrikethroughRounded />,
    FormatStrikethroughSharp: <icons.FormatStrikethroughSharp />,
    FormatStrikethroughTwoTone: <icons.FormatStrikethroughTwoTone />,
    FormatTextdirectionLToR: <icons.FormatTextdirectionLToR />,
    FormatTextdirectionLToROutlined: <icons.FormatTextdirectionLToROutlined />,
    FormatTextdirectionLToRRounded: <icons.FormatTextdirectionLToRRounded />,
    FormatTextdirectionLToRSharp: <icons.FormatTextdirectionLToRSharp />,
    FormatTextdirectionLToRTwoTone: <icons.FormatTextdirectionLToRTwoTone />,
    FormatTextdirectionRToL: <icons.FormatTextdirectionRToL />,
    FormatTextdirectionRToLOutlined: <icons.FormatTextdirectionRToLOutlined />,
    FormatTextdirectionRToLRounded: <icons.FormatTextdirectionRToLRounded />,
    FormatTextdirectionRToLSharp: <icons.FormatTextdirectionRToLSharp />,
    FormatTextdirectionRToLTwoTone: <icons.FormatTextdirectionRToLTwoTone />,
    FormatUnderlined: <icons.FormatUnderlined />,
    FormatUnderlinedOutlined: <icons.FormatUnderlinedOutlined />,
    FormatUnderlinedRounded: <icons.FormatUnderlinedRounded />,
    FormatUnderlinedSharp: <icons.FormatUnderlinedSharp />,
    FormatUnderlinedTwoTone: <icons.FormatUnderlinedTwoTone />,
    Fort: <icons.Fort />,
    FortOutlined: <icons.FortOutlined />,
    FortRounded: <icons.FortRounded />,
    FortSharp: <icons.FortSharp />,
    FortTwoTone: <icons.FortTwoTone />,
    Forum: <icons.Forum />,
    ForumOutlined: <icons.ForumOutlined />,
    ForumRounded: <icons.ForumRounded />,
    ForumSharp: <icons.ForumSharp />,
    ForumTwoTone: <icons.ForumTwoTone />,
    Forward: <icons.Forward />,
    Forward10: <icons.Forward10 />,
    Forward10Outlined: <icons.Forward10Outlined />,
    Forward10Rounded: <icons.Forward10Rounded />,
    Forward10Sharp: <icons.Forward10Sharp />,
    Forward10TwoTone: <icons.Forward10TwoTone />,
    Forward30: <icons.Forward30 />,
    Forward30Outlined: <icons.Forward30Outlined />,
    Forward30Rounded: <icons.Forward30Rounded />,
    Forward30Sharp: <icons.Forward30Sharp />,
    Forward30TwoTone: <icons.Forward30TwoTone />,
    Forward5: <icons.Forward5 />,
    Forward5Outlined: <icons.Forward5Outlined />,
    Forward5Rounded: <icons.Forward5Rounded />,
    Forward5Sharp: <icons.Forward5Sharp />,
    Forward5TwoTone: <icons.Forward5TwoTone />,
    ForwardOutlined: <icons.ForwardOutlined />,
    ForwardRounded: <icons.ForwardRounded />,
    ForwardSharp: <icons.ForwardSharp />,
    ForwardToInbox: <icons.ForwardToInbox />,
    ForwardToInboxOutlined: <icons.ForwardToInboxOutlined />,
    ForwardToInboxRounded: <icons.ForwardToInboxRounded />,
    ForwardToInboxSharp: <icons.ForwardToInboxSharp />,
    ForwardToInboxTwoTone: <icons.ForwardToInboxTwoTone />,
    ForwardTwoTone: <icons.ForwardTwoTone />,
    Foundation: <icons.Foundation />,
    FoundationOutlined: <icons.FoundationOutlined />,
    FoundationRounded: <icons.FoundationRounded />,
    FoundationSharp: <icons.FoundationSharp />,
    FoundationTwoTone: <icons.FoundationTwoTone />,
    FourGMobiledata: <icons.FourGMobiledata />,
    FourGMobiledataOutlined: <icons.FourGMobiledataOutlined />,
    FourGMobiledataRounded: <icons.FourGMobiledataRounded />,
    FourGMobiledataSharp: <icons.FourGMobiledataSharp />,
    FourGMobiledataTwoTone: <icons.FourGMobiledataTwoTone />,
    FourGPlusMobiledata: <icons.FourGPlusMobiledata />,
    FourGPlusMobiledataOutlined: <icons.FourGPlusMobiledataOutlined />,
    FourGPlusMobiledataRounded: <icons.FourGPlusMobiledataRounded />,
    FourGPlusMobiledataSharp: <icons.FourGPlusMobiledataSharp />,
    FourGPlusMobiledataTwoTone: <icons.FourGPlusMobiledataTwoTone />,
    FourK: <icons.FourK />,
    FourKOutlined: <icons.FourKOutlined />,
    FourKPlus: <icons.FourKPlus />,
    FourKPlusOutlined: <icons.FourKPlusOutlined />,
    FourKPlusRounded: <icons.FourKPlusRounded />,
    FourKPlusSharp: <icons.FourKPlusSharp />,
    FourKPlusTwoTone: <icons.FourKPlusTwoTone />,
    FourKRounded: <icons.FourKRounded />,
    FourKSharp: <icons.FourKSharp />,
    FourKTwoTone: <icons.FourKTwoTone />,
    FourMp: <icons.FourMp />,
    FourMpOutlined: <icons.FourMpOutlined />,
    FourMpRounded: <icons.FourMpRounded />,
    FourMpSharp: <icons.FourMpSharp />,
    FourMpTwoTone: <icons.FourMpTwoTone />,
    FourteenMp: <icons.FourteenMp />,
    FourteenMpOutlined: <icons.FourteenMpOutlined />,
    FourteenMpRounded: <icons.FourteenMpRounded />,
    FourteenMpSharp: <icons.FourteenMpSharp />,
    FourteenMpTwoTone: <icons.FourteenMpTwoTone />,
    FreeBreakfast: <icons.FreeBreakfast />,
    FreeBreakfastOutlined: <icons.FreeBreakfastOutlined />,
    FreeBreakfastRounded: <icons.FreeBreakfastRounded />,
    FreeBreakfastSharp: <icons.FreeBreakfastSharp />,
    FreeBreakfastTwoTone: <icons.FreeBreakfastTwoTone />,
    Fullscreen: <icons.Fullscreen />,
    FullscreenExit: <icons.FullscreenExit />,
    FullscreenExitOutlined: <icons.FullscreenExitOutlined />,
    FullscreenExitRounded: <icons.FullscreenExitRounded />,
    FullscreenExitSharp: <icons.FullscreenExitSharp />,
    FullscreenExitTwoTone: <icons.FullscreenExitTwoTone />,
    FullscreenOutlined: <icons.FullscreenOutlined />,
    FullscreenRounded: <icons.FullscreenRounded />,
    FullscreenSharp: <icons.FullscreenSharp />,
    FullscreenTwoTone: <icons.FullscreenTwoTone />,
    Functions: <icons.Functions />,
    FunctionsOutlined: <icons.FunctionsOutlined />,
    FunctionsRounded: <icons.FunctionsRounded />,
    FunctionsSharp: <icons.FunctionsSharp />,
    FunctionsTwoTone: <icons.FunctionsTwoTone />,
    GMobiledata: <icons.GMobiledata />,
    GMobiledataOutlined: <icons.GMobiledataOutlined />,
    GMobiledataRounded: <icons.GMobiledataRounded />,
    GMobiledataSharp: <icons.GMobiledataSharp />,
    GMobiledataTwoTone: <icons.GMobiledataTwoTone />,
    GTranslate: <icons.GTranslate />,
    GTranslateOutlined: <icons.GTranslateOutlined />,
    GTranslateRounded: <icons.GTranslateRounded />,
    GTranslateSharp: <icons.GTranslateSharp />,
    GTranslateTwoTone: <icons.GTranslateTwoTone />,
    Gamepad: <icons.Gamepad />,
    GamepadOutlined: <icons.GamepadOutlined />,
    GamepadRounded: <icons.GamepadRounded />,
    GamepadSharp: <icons.GamepadSharp />,
    GamepadTwoTone: <icons.GamepadTwoTone />,
    Games: <icons.Games />,
    GamesOutlined: <icons.GamesOutlined />,
    GamesRounded: <icons.GamesRounded />,
    GamesSharp: <icons.GamesSharp />,
    GamesTwoTone: <icons.GamesTwoTone />,
    Garage: <icons.Garage />,
    GarageOutlined: <icons.GarageOutlined />,
    GarageRounded: <icons.GarageRounded />,
    GarageSharp: <icons.GarageSharp />,
    GarageTwoTone: <icons.GarageTwoTone />,
    GasMeter: <icons.GasMeter />,
    GasMeterOutlined: <icons.GasMeterOutlined />,
    GasMeterRounded: <icons.GasMeterRounded />,
    GasMeterSharp: <icons.GasMeterSharp />,
    GasMeterTwoTone: <icons.GasMeterTwoTone />,
    Gavel: <icons.Gavel />,
    GavelOutlined: <icons.GavelOutlined />,
    GavelRounded: <icons.GavelRounded />,
    GavelSharp: <icons.GavelSharp />,
    GavelTwoTone: <icons.GavelTwoTone />,
    Gesture: <icons.Gesture />,
    GestureOutlined: <icons.GestureOutlined />,
    GestureRounded: <icons.GestureRounded />,
    GestureSharp: <icons.GestureSharp />,
    GestureTwoTone: <icons.GestureTwoTone />,
    GetApp: <icons.GetApp />,
    GetAppOutlined: <icons.GetAppOutlined />,
    GetAppRounded: <icons.GetAppRounded />,
    GetAppSharp: <icons.GetAppSharp />,
    GetAppTwoTone: <icons.GetAppTwoTone />,
    Gif: <icons.Gif />,
    GifBox: <icons.GifBox />,
    GifBoxOutlined: <icons.GifBoxOutlined />,
    GifBoxRounded: <icons.GifBoxRounded />,
    GifBoxSharp: <icons.GifBoxSharp />,
    GifBoxTwoTone: <icons.GifBoxTwoTone />,
    GifOutlined: <icons.GifOutlined />,
    GifRounded: <icons.GifRounded />,
    GifSharp: <icons.GifSharp />,
    GifTwoTone: <icons.GifTwoTone />,
    Girl: <icons.Girl />,
    GirlOutlined: <icons.GirlOutlined />,
    GirlRounded: <icons.GirlRounded />,
    GirlSharp: <icons.GirlSharp />,
    GirlTwoTone: <icons.GirlTwoTone />,
    GitHub: <icons.GitHub />,
    Gite: <icons.Gite />,
    GiteOutlined: <icons.GiteOutlined />,
    GiteRounded: <icons.GiteRounded />,
    GiteSharp: <icons.GiteSharp />,
    GiteTwoTone: <icons.GiteTwoTone />,
    GolfCourse: <icons.GolfCourse />,
    GolfCourseOutlined: <icons.GolfCourseOutlined />,
    GolfCourseRounded: <icons.GolfCourseRounded />,
    GolfCourseSharp: <icons.GolfCourseSharp />,
    GolfCourseTwoTone: <icons.GolfCourseTwoTone />,
    Google: <icons.Google />,
    GppBad: <icons.GppBad />,
    GppBadOutlined: <icons.GppBadOutlined />,
    GppBadRounded: <icons.GppBadRounded />,
    GppBadSharp: <icons.GppBadSharp />,
    GppBadTwoTone: <icons.GppBadTwoTone />,
    GppGood: <icons.GppGood />,
    GppGoodOutlined: <icons.GppGoodOutlined />,
    GppGoodRounded: <icons.GppGoodRounded />,
    GppGoodSharp: <icons.GppGoodSharp />,
    GppGoodTwoTone: <icons.GppGoodTwoTone />,
    GppMaybe: <icons.GppMaybe />,
    GppMaybeOutlined: <icons.GppMaybeOutlined />,
    GppMaybeRounded: <icons.GppMaybeRounded />,
    GppMaybeSharp: <icons.GppMaybeSharp />,
    GppMaybeTwoTone: <icons.GppMaybeTwoTone />,
    GpsFixed: <icons.GpsFixed />,
    GpsFixedOutlined: <icons.GpsFixedOutlined />,
    GpsFixedRounded: <icons.GpsFixedRounded />,
    GpsFixedSharp: <icons.GpsFixedSharp />,
    GpsFixedTwoTone: <icons.GpsFixedTwoTone />,
    GpsNotFixed: <icons.GpsNotFixed />,
    GpsNotFixedOutlined: <icons.GpsNotFixedOutlined />,
    GpsNotFixedRounded: <icons.GpsNotFixedRounded />,
    GpsNotFixedSharp: <icons.GpsNotFixedSharp />,
    GpsNotFixedTwoTone: <icons.GpsNotFixedTwoTone />,
    GpsOff: <icons.GpsOff />,
    GpsOffOutlined: <icons.GpsOffOutlined />,
    GpsOffRounded: <icons.GpsOffRounded />,
    GpsOffSharp: <icons.GpsOffSharp />,
    GpsOffTwoTone: <icons.GpsOffTwoTone />,
    Grade: <icons.Grade />,
    GradeOutlined: <icons.GradeOutlined />,
    GradeRounded: <icons.GradeRounded />,
    GradeSharp: <icons.GradeSharp />,
    GradeTwoTone: <icons.GradeTwoTone />,
    Gradient: <icons.Gradient />,
    GradientOutlined: <icons.GradientOutlined />,
    GradientRounded: <icons.GradientRounded />,
    GradientSharp: <icons.GradientSharp />,
    GradientTwoTone: <icons.GradientTwoTone />,
    Grading: <icons.Grading />,
    GradingOutlined: <icons.GradingOutlined />,
    GradingRounded: <icons.GradingRounded />,
    GradingSharp: <icons.GradingSharp />,
    GradingTwoTone: <icons.GradingTwoTone />,
    Grain: <icons.Grain />,
    GrainOutlined: <icons.GrainOutlined />,
    GrainRounded: <icons.GrainRounded />,
    GrainSharp: <icons.GrainSharp />,
    GrainTwoTone: <icons.GrainTwoTone />,
    GraphicEq: <icons.GraphicEq />,
    GraphicEqOutlined: <icons.GraphicEqOutlined />,
    GraphicEqRounded: <icons.GraphicEqRounded />,
    GraphicEqSharp: <icons.GraphicEqSharp />,
    GraphicEqTwoTone: <icons.GraphicEqTwoTone />,
    Grass: <icons.Grass />,
    GrassOutlined: <icons.GrassOutlined />,
    GrassRounded: <icons.GrassRounded />,
    GrassSharp: <icons.GrassSharp />,
    GrassTwoTone: <icons.GrassTwoTone />,
    Grid3x3: <icons.Grid3x3 />,
    Grid3x3Outlined: <icons.Grid3x3Outlined />,
    Grid3x3Rounded: <icons.Grid3x3Rounded />,
    Grid3x3Sharp: <icons.Grid3x3Sharp />,
    Grid3x3TwoTone: <icons.Grid3x3TwoTone />,
    Grid4x4: <icons.Grid4x4 />,
    Grid4x4Outlined: <icons.Grid4x4Outlined />,
    Grid4x4Rounded: <icons.Grid4x4Rounded />,
    Grid4x4Sharp: <icons.Grid4x4Sharp />,
    Grid4x4TwoTone: <icons.Grid4x4TwoTone />,
    GridGoldenratio: <icons.GridGoldenratio />,
    GridGoldenratioOutlined: <icons.GridGoldenratioOutlined />,
    GridGoldenratioRounded: <icons.GridGoldenratioRounded />,
    GridGoldenratioSharp: <icons.GridGoldenratioSharp />,
    GridGoldenratioTwoTone: <icons.GridGoldenratioTwoTone />,
    GridOff: <icons.GridOff />,
    GridOffOutlined: <icons.GridOffOutlined />,
    GridOffRounded: <icons.GridOffRounded />,
    GridOffSharp: <icons.GridOffSharp />,
    GridOffTwoTone: <icons.GridOffTwoTone />,
    GridOn: <icons.GridOn />,
    GridOnOutlined: <icons.GridOnOutlined />,
    GridOnRounded: <icons.GridOnRounded />,
    GridOnSharp: <icons.GridOnSharp />,
    GridOnTwoTone: <icons.GridOnTwoTone />,
    GridView: <icons.GridView />,
    GridViewOutlined: <icons.GridViewOutlined />,
    GridViewRounded: <icons.GridViewRounded />,
    GridViewSharp: <icons.GridViewSharp />,
    GridViewTwoTone: <icons.GridViewTwoTone />,
    Group: <icons.Group />,
    GroupAdd: <icons.GroupAdd />,
    GroupAddOutlined: <icons.GroupAddOutlined />,
    GroupAddRounded: <icons.GroupAddRounded />,
    GroupAddSharp: <icons.GroupAddSharp />,
    GroupAddTwoTone: <icons.GroupAddTwoTone />,
    GroupOutlined: <icons.GroupOutlined />,
    GroupRemove: <icons.GroupRemove />,
    GroupRemoveOutlined: <icons.GroupRemoveOutlined />,
    GroupRemoveRounded: <icons.GroupRemoveRounded />,
    GroupRemoveSharp: <icons.GroupRemoveSharp />,
    GroupRemoveTwoTone: <icons.GroupRemoveTwoTone />,
    GroupRounded: <icons.GroupRounded />,
    GroupSharp: <icons.GroupSharp />,
    GroupTwoTone: <icons.GroupTwoTone />,
    GroupWork: <icons.GroupWork />,
    GroupWorkOutlined: <icons.GroupWorkOutlined />,
    GroupWorkRounded: <icons.GroupWorkRounded />,
    GroupWorkSharp: <icons.GroupWorkSharp />,
    GroupWorkTwoTone: <icons.GroupWorkTwoTone />,
    Groups: <icons.Groups />,
    Groups2: <icons.Groups2 />,
    Groups2Outlined: <icons.Groups2Outlined />,
    Groups2Rounded: <icons.Groups2Rounded />,
    Groups2Sharp: <icons.Groups2Sharp />,
    Groups2TwoTone: <icons.Groups2TwoTone />,
    Groups3: <icons.Groups3 />,
    Groups3Outlined: <icons.Groups3Outlined />,
    Groups3Rounded: <icons.Groups3Rounded />,
    Groups3Sharp: <icons.Groups3Sharp />,
    Groups3TwoTone: <icons.Groups3TwoTone />,
    GroupsOutlined: <icons.GroupsOutlined />,
    GroupsRounded: <icons.GroupsRounded />,
    GroupsSharp: <icons.GroupsSharp />,
    GroupsTwoTone: <icons.GroupsTwoTone />,
    HMobiledata: <icons.HMobiledata />,
    HMobiledataOutlined: <icons.HMobiledataOutlined />,
    HMobiledataRounded: <icons.HMobiledataRounded />,
    HMobiledataSharp: <icons.HMobiledataSharp />,
    HMobiledataTwoTone: <icons.HMobiledataTwoTone />,
    HPlusMobiledata: <icons.HPlusMobiledata />,
    HPlusMobiledataOutlined: <icons.HPlusMobiledataOutlined />,
    HPlusMobiledataRounded: <icons.HPlusMobiledataRounded />,
    HPlusMobiledataSharp: <icons.HPlusMobiledataSharp />,
    HPlusMobiledataTwoTone: <icons.HPlusMobiledataTwoTone />,
    Hail: <icons.Hail />,
    HailOutlined: <icons.HailOutlined />,
    HailRounded: <icons.HailRounded />,
    HailSharp: <icons.HailSharp />,
    HailTwoTone: <icons.HailTwoTone />,
    Handshake: <icons.Handshake />,
    HandshakeOutlined: <icons.HandshakeOutlined />,
    HandshakeRounded: <icons.HandshakeRounded />,
    HandshakeSharp: <icons.HandshakeSharp />,
    HandshakeTwoTone: <icons.HandshakeTwoTone />,
    Handyman: <icons.Handyman />,
    HandymanOutlined: <icons.HandymanOutlined />,
    HandymanRounded: <icons.HandymanRounded />,
    HandymanSharp: <icons.HandymanSharp />,
    HandymanTwoTone: <icons.HandymanTwoTone />,
    Hardware: <icons.Hardware />,
    HardwareOutlined: <icons.HardwareOutlined />,
    HardwareRounded: <icons.HardwareRounded />,
    HardwareSharp: <icons.HardwareSharp />,
    HardwareTwoTone: <icons.HardwareTwoTone />,
    Hd: <icons.Hd />,
    HdOutlined: <icons.HdOutlined />,
    HdRounded: <icons.HdRounded />,
    HdSharp: <icons.HdSharp />,
    HdTwoTone: <icons.HdTwoTone />,
    HdrAuto: <icons.HdrAuto />,
    HdrAutoOutlined: <icons.HdrAutoOutlined />,
    HdrAutoRounded: <icons.HdrAutoRounded />,
    HdrAutoSelect: <icons.HdrAutoSelect />,
    HdrAutoSelectOutlined: <icons.HdrAutoSelectOutlined />,
    HdrAutoSelectRounded: <icons.HdrAutoSelectRounded />,
    HdrAutoSelectSharp: <icons.HdrAutoSelectSharp />,
    HdrAutoSelectTwoTone: <icons.HdrAutoSelectTwoTone />,
    HdrAutoSharp: <icons.HdrAutoSharp />,
    HdrAutoTwoTone: <icons.HdrAutoTwoTone />,
    HdrEnhancedSelect: <icons.HdrEnhancedSelect />,
    HdrEnhancedSelectOutlined: <icons.HdrEnhancedSelectOutlined />,
    HdrEnhancedSelectRounded: <icons.HdrEnhancedSelectRounded />,
    HdrEnhancedSelectSharp: <icons.HdrEnhancedSelectSharp />,
    HdrEnhancedSelectTwoTone: <icons.HdrEnhancedSelectTwoTone />,
    HdrOff: <icons.HdrOff />,
    HdrOffOutlined: <icons.HdrOffOutlined />,
    HdrOffRounded: <icons.HdrOffRounded />,
    HdrOffSelect: <icons.HdrOffSelect />,
    HdrOffSelectOutlined: <icons.HdrOffSelectOutlined />,
    HdrOffSelectRounded: <icons.HdrOffSelectRounded />,
    HdrOffSelectSharp: <icons.HdrOffSelectSharp />,
    HdrOffSelectTwoTone: <icons.HdrOffSelectTwoTone />,
    HdrOffSharp: <icons.HdrOffSharp />,
    HdrOffTwoTone: <icons.HdrOffTwoTone />,
    HdrOn: <icons.HdrOn />,
    HdrOnOutlined: <icons.HdrOnOutlined />,
    HdrOnRounded: <icons.HdrOnRounded />,
    HdrOnSelect: <icons.HdrOnSelect />,
    HdrOnSelectOutlined: <icons.HdrOnSelectOutlined />,
    HdrOnSelectRounded: <icons.HdrOnSelectRounded />,
    HdrOnSelectSharp: <icons.HdrOnSelectSharp />,
    HdrOnSelectTwoTone: <icons.HdrOnSelectTwoTone />,
    HdrOnSharp: <icons.HdrOnSharp />,
    HdrOnTwoTone: <icons.HdrOnTwoTone />,
    HdrPlus: <icons.HdrPlus />,
    HdrPlusOutlined: <icons.HdrPlusOutlined />,
    HdrPlusRounded: <icons.HdrPlusRounded />,
    HdrPlusSharp: <icons.HdrPlusSharp />,
    HdrPlusTwoTone: <icons.HdrPlusTwoTone />,
    HdrStrong: <icons.HdrStrong />,
    HdrStrongOutlined: <icons.HdrStrongOutlined />,
    HdrStrongRounded: <icons.HdrStrongRounded />,
    HdrStrongSharp: <icons.HdrStrongSharp />,
    HdrStrongTwoTone: <icons.HdrStrongTwoTone />,
    HdrWeak: <icons.HdrWeak />,
    HdrWeakOutlined: <icons.HdrWeakOutlined />,
    HdrWeakRounded: <icons.HdrWeakRounded />,
    HdrWeakSharp: <icons.HdrWeakSharp />,
    HdrWeakTwoTone: <icons.HdrWeakTwoTone />,
    Headphones: <icons.Headphones />,
    HeadphonesBattery: <icons.HeadphonesBattery />,
    HeadphonesBatteryOutlined: <icons.HeadphonesBatteryOutlined />,
    HeadphonesBatteryRounded: <icons.HeadphonesBatteryRounded />,
    HeadphonesBatterySharp: <icons.HeadphonesBatterySharp />,
    HeadphonesBatteryTwoTone: <icons.HeadphonesBatteryTwoTone />,
    HeadphonesOutlined: <icons.HeadphonesOutlined />,
    HeadphonesRounded: <icons.HeadphonesRounded />,
    HeadphonesSharp: <icons.HeadphonesSharp />,
    HeadphonesTwoTone: <icons.HeadphonesTwoTone />,
    Headset: <icons.Headset />,
    HeadsetMic: <icons.HeadsetMic />,
    HeadsetMicOutlined: <icons.HeadsetMicOutlined />,
    HeadsetMicRounded: <icons.HeadsetMicRounded />,
    HeadsetMicSharp: <icons.HeadsetMicSharp />,
    HeadsetMicTwoTone: <icons.HeadsetMicTwoTone />,
    HeadsetOff: <icons.HeadsetOff />,
    HeadsetOffOutlined: <icons.HeadsetOffOutlined />,
    HeadsetOffRounded: <icons.HeadsetOffRounded />,
    HeadsetOffSharp: <icons.HeadsetOffSharp />,
    HeadsetOffTwoTone: <icons.HeadsetOffTwoTone />,
    HeadsetOutlined: <icons.HeadsetOutlined />,
    HeadsetRounded: <icons.HeadsetRounded />,
    HeadsetSharp: <icons.HeadsetSharp />,
    HeadsetTwoTone: <icons.HeadsetTwoTone />,
    Healing: <icons.Healing />,
    HealingOutlined: <icons.HealingOutlined />,
    HealingRounded: <icons.HealingRounded />,
    HealingSharp: <icons.HealingSharp />,
    HealingTwoTone: <icons.HealingTwoTone />,
    HealthAndSafety: <icons.HealthAndSafety />,
    HealthAndSafetyOutlined: <icons.HealthAndSafetyOutlined />,
    HealthAndSafetyRounded: <icons.HealthAndSafetyRounded />,
    HealthAndSafetySharp: <icons.HealthAndSafetySharp />,
    HealthAndSafetyTwoTone: <icons.HealthAndSafetyTwoTone />,
    Hearing: <icons.Hearing />,
    HearingDisabled: <icons.HearingDisabled />,
    HearingDisabledOutlined: <icons.HearingDisabledOutlined />,
    HearingDisabledRounded: <icons.HearingDisabledRounded />,
    HearingDisabledSharp: <icons.HearingDisabledSharp />,
    HearingDisabledTwoTone: <icons.HearingDisabledTwoTone />,
    HearingOutlined: <icons.HearingOutlined />,
    HearingRounded: <icons.HearingRounded />,
    HearingSharp: <icons.HearingSharp />,
    HearingTwoTone: <icons.HearingTwoTone />,
    HeartBroken: <icons.HeartBroken />,
    HeartBrokenOutlined: <icons.HeartBrokenOutlined />,
    HeartBrokenRounded: <icons.HeartBrokenRounded />,
    HeartBrokenSharp: <icons.HeartBrokenSharp />,
    HeartBrokenTwoTone: <icons.HeartBrokenTwoTone />,
    HeatPump: <icons.HeatPump />,
    HeatPumpOutlined: <icons.HeatPumpOutlined />,
    HeatPumpRounded: <icons.HeatPumpRounded />,
    HeatPumpSharp: <icons.HeatPumpSharp />,
    HeatPumpTwoTone: <icons.HeatPumpTwoTone />,
    Height: <icons.Height />,
    HeightOutlined: <icons.HeightOutlined />,
    HeightRounded: <icons.HeightRounded />,
    HeightSharp: <icons.HeightSharp />,
    HeightTwoTone: <icons.HeightTwoTone />,
    Help: <icons.Help />,
    HelpCenter: <icons.HelpCenter />,
    HelpCenterOutlined: <icons.HelpCenterOutlined />,
    HelpCenterRounded: <icons.HelpCenterRounded />,
    HelpCenterSharp: <icons.HelpCenterSharp />,
    HelpCenterTwoTone: <icons.HelpCenterTwoTone />,
    HelpOutline: <icons.HelpOutline />,
    HelpOutlineOutlined: <icons.HelpOutlineOutlined />,
    HelpOutlineRounded: <icons.HelpOutlineRounded />,
    HelpOutlineSharp: <icons.HelpOutlineSharp />,
    HelpOutlineTwoTone: <icons.HelpOutlineTwoTone />,
    HelpOutlined: <icons.HelpOutlined />,
    HelpRounded: <icons.HelpRounded />,
    HelpSharp: <icons.HelpSharp />,
    HelpTwoTone: <icons.HelpTwoTone />,
    Hevc: <icons.Hevc />,
    HevcOutlined: <icons.HevcOutlined />,
    HevcRounded: <icons.HevcRounded />,
    HevcSharp: <icons.HevcSharp />,
    HevcTwoTone: <icons.HevcTwoTone />,
    Hexagon: <icons.Hexagon />,
    HexagonOutlined: <icons.HexagonOutlined />,
    HexagonRounded: <icons.HexagonRounded />,
    HexagonSharp: <icons.HexagonSharp />,
    HexagonTwoTone: <icons.HexagonTwoTone />,
    HideImage: <icons.HideImage />,
    HideImageOutlined: <icons.HideImageOutlined />,
    HideImageRounded: <icons.HideImageRounded />,
    HideImageSharp: <icons.HideImageSharp />,
    HideImageTwoTone: <icons.HideImageTwoTone />,
    HideSource: <icons.HideSource />,
    HideSourceOutlined: <icons.HideSourceOutlined />,
    HideSourceRounded: <icons.HideSourceRounded />,
    HideSourceSharp: <icons.HideSourceSharp />,
    HideSourceTwoTone: <icons.HideSourceTwoTone />,
    HighQuality: <icons.HighQuality />,
    HighQualityOutlined: <icons.HighQualityOutlined />,
    HighQualityRounded: <icons.HighQualityRounded />,
    HighQualitySharp: <icons.HighQualitySharp />,
    HighQualityTwoTone: <icons.HighQualityTwoTone />,
    Highlight: <icons.Highlight />,
    HighlightAlt: <icons.HighlightAlt />,
    HighlightAltOutlined: <icons.HighlightAltOutlined />,
    HighlightAltRounded: <icons.HighlightAltRounded />,
    HighlightAltSharp: <icons.HighlightAltSharp />,
    HighlightAltTwoTone: <icons.HighlightAltTwoTone />,
    HighlightOff: <icons.HighlightOff />,
    HighlightOffOutlined: <icons.HighlightOffOutlined />,
    HighlightOffRounded: <icons.HighlightOffRounded />,
    HighlightOffSharp: <icons.HighlightOffSharp />,
    HighlightOffTwoTone: <icons.HighlightOffTwoTone />,
    HighlightOutlined: <icons.HighlightOutlined />,
    HighlightRounded: <icons.HighlightRounded />,
    HighlightSharp: <icons.HighlightSharp />,
    HighlightTwoTone: <icons.HighlightTwoTone />,
    Hiking: <icons.Hiking />,
    HikingOutlined: <icons.HikingOutlined />,
    HikingRounded: <icons.HikingRounded />,
    HikingSharp: <icons.HikingSharp />,
    HikingTwoTone: <icons.HikingTwoTone />,
    History: <icons.History />,
    HistoryEdu: <icons.HistoryEdu />,
    HistoryEduOutlined: <icons.HistoryEduOutlined />,
    HistoryEduRounded: <icons.HistoryEduRounded />,
    HistoryEduSharp: <icons.HistoryEduSharp />,
    HistoryEduTwoTone: <icons.HistoryEduTwoTone />,
    HistoryOutlined: <icons.HistoryOutlined />,
    HistoryRounded: <icons.HistoryRounded />,
    HistorySharp: <icons.HistorySharp />,
    HistoryToggleOff: <icons.HistoryToggleOff />,
    HistoryToggleOffOutlined: <icons.HistoryToggleOffOutlined />,
    HistoryToggleOffRounded: <icons.HistoryToggleOffRounded />,
    HistoryToggleOffSharp: <icons.HistoryToggleOffSharp />,
    HistoryToggleOffTwoTone: <icons.HistoryToggleOffTwoTone />,
    HistoryTwoTone: <icons.HistoryTwoTone />,
    Hive: <icons.Hive />,
    HiveOutlined: <icons.HiveOutlined />,
    HiveRounded: <icons.HiveRounded />,
    HiveSharp: <icons.HiveSharp />,
    HiveTwoTone: <icons.HiveTwoTone />,
    Hls: <icons.Hls />,
    HlsOff: <icons.HlsOff />,
    HlsOffOutlined: <icons.HlsOffOutlined />,
    HlsOffRounded: <icons.HlsOffRounded />,
    HlsOffSharp: <icons.HlsOffSharp />,
    HlsOffTwoTone: <icons.HlsOffTwoTone />,
    HlsOutlined: <icons.HlsOutlined />,
    HlsRounded: <icons.HlsRounded />,
    HlsSharp: <icons.HlsSharp />,
    HlsTwoTone: <icons.HlsTwoTone />,
    HolidayVillage: <icons.HolidayVillage />,
    HolidayVillageOutlined: <icons.HolidayVillageOutlined />,
    HolidayVillageRounded: <icons.HolidayVillageRounded />,
    HolidayVillageSharp: <icons.HolidayVillageSharp />,
    HolidayVillageTwoTone: <icons.HolidayVillageTwoTone />,
    Home: <icons.Home />,
    HomeMax: <icons.HomeMax />,
    HomeMaxOutlined: <icons.HomeMaxOutlined />,
    HomeMaxRounded: <icons.HomeMaxRounded />,
    HomeMaxSharp: <icons.HomeMaxSharp />,
    HomeMaxTwoTone: <icons.HomeMaxTwoTone />,
    HomeMini: <icons.HomeMini />,
    HomeMiniOutlined: <icons.HomeMiniOutlined />,
    HomeMiniRounded: <icons.HomeMiniRounded />,
    HomeMiniSharp: <icons.HomeMiniSharp />,
    HomeMiniTwoTone: <icons.HomeMiniTwoTone />,
    HomeOutlined: <icons.HomeOutlined />,
    HomeRepairService: <icons.HomeRepairService />,
    HomeRepairServiceOutlined: <icons.HomeRepairServiceOutlined />,
    HomeRepairServiceRounded: <icons.HomeRepairServiceRounded />,
    HomeRepairServiceSharp: <icons.HomeRepairServiceSharp />,
    HomeRepairServiceTwoTone: <icons.HomeRepairServiceTwoTone />,
    HomeRounded: <icons.HomeRounded />,
    HomeSharp: <icons.HomeSharp />,
    HomeTwoTone: <icons.HomeTwoTone />,
    HomeWork: <icons.HomeWork />,
    HomeWorkOutlined: <icons.HomeWorkOutlined />,
    HomeWorkRounded: <icons.HomeWorkRounded />,
    HomeWorkSharp: <icons.HomeWorkSharp />,
    HomeWorkTwoTone: <icons.HomeWorkTwoTone />,
    HorizontalRule: <icons.HorizontalRule />,
    HorizontalRuleOutlined: <icons.HorizontalRuleOutlined />,
    HorizontalRuleRounded: <icons.HorizontalRuleRounded />,
    HorizontalRuleSharp: <icons.HorizontalRuleSharp />,
    HorizontalRuleTwoTone: <icons.HorizontalRuleTwoTone />,
    HorizontalSplit: <icons.HorizontalSplit />,
    HorizontalSplitOutlined: <icons.HorizontalSplitOutlined />,
    HorizontalSplitRounded: <icons.HorizontalSplitRounded />,
    HorizontalSplitSharp: <icons.HorizontalSplitSharp />,
    HorizontalSplitTwoTone: <icons.HorizontalSplitTwoTone />,
    HotTub: <icons.HotTub />,
    HotTubOutlined: <icons.HotTubOutlined />,
    HotTubRounded: <icons.HotTubRounded />,
    HotTubSharp: <icons.HotTubSharp />,
    HotTubTwoTone: <icons.HotTubTwoTone />,
    Hotel: <icons.Hotel />,
    HotelOutlined: <icons.HotelOutlined />,
    HotelRounded: <icons.HotelRounded />,
    HotelSharp: <icons.HotelSharp />,
    HotelTwoTone: <icons.HotelTwoTone />,
    HourglassBottom: <icons.HourglassBottom />,
    HourglassBottomOutlined: <icons.HourglassBottomOutlined />,
    HourglassBottomRounded: <icons.HourglassBottomRounded />,
    HourglassBottomSharp: <icons.HourglassBottomSharp />,
    HourglassBottomTwoTone: <icons.HourglassBottomTwoTone />,
    HourglassDisabled: <icons.HourglassDisabled />,
    HourglassDisabledOutlined: <icons.HourglassDisabledOutlined />,
    HourglassDisabledRounded: <icons.HourglassDisabledRounded />,
    HourglassDisabledSharp: <icons.HourglassDisabledSharp />,
    HourglassDisabledTwoTone: <icons.HourglassDisabledTwoTone />,
    HourglassEmpty: <icons.HourglassEmpty />,
    HourglassEmptyOutlined: <icons.HourglassEmptyOutlined />,
    HourglassEmptyRounded: <icons.HourglassEmptyRounded />,
    HourglassEmptySharp: <icons.HourglassEmptySharp />,
    HourglassEmptyTwoTone: <icons.HourglassEmptyTwoTone />,
    HourglassFull: <icons.HourglassFull />,
    HourglassFullOutlined: <icons.HourglassFullOutlined />,
    HourglassFullRounded: <icons.HourglassFullRounded />,
    HourglassFullSharp: <icons.HourglassFullSharp />,
    HourglassFullTwoTone: <icons.HourglassFullTwoTone />,
    HourglassTop: <icons.HourglassTop />,
    HourglassTopOutlined: <icons.HourglassTopOutlined />,
    HourglassTopRounded: <icons.HourglassTopRounded />,
    HourglassTopSharp: <icons.HourglassTopSharp />,
    HourglassTopTwoTone: <icons.HourglassTopTwoTone />,
    House: <icons.House />,
    HouseOutlined: <icons.HouseOutlined />,
    HouseRounded: <icons.HouseRounded />,
    HouseSharp: <icons.HouseSharp />,
    HouseSiding: <icons.HouseSiding />,
    HouseSidingOutlined: <icons.HouseSidingOutlined />,
    HouseSidingRounded: <icons.HouseSidingRounded />,
    HouseSidingSharp: <icons.HouseSidingSharp />,
    HouseSidingTwoTone: <icons.HouseSidingTwoTone />,
    HouseTwoTone: <icons.HouseTwoTone />,
    Houseboat: <icons.Houseboat />,
    HouseboatOutlined: <icons.HouseboatOutlined />,
    HouseboatRounded: <icons.HouseboatRounded />,
    HouseboatSharp: <icons.HouseboatSharp />,
    HouseboatTwoTone: <icons.HouseboatTwoTone />,
    HowToReg: <icons.HowToReg />,
    HowToRegOutlined: <icons.HowToRegOutlined />,
    HowToRegRounded: <icons.HowToRegRounded />,
    HowToRegSharp: <icons.HowToRegSharp />,
    HowToRegTwoTone: <icons.HowToRegTwoTone />,
    HowToVote: <icons.HowToVote />,
    HowToVoteOutlined: <icons.HowToVoteOutlined />,
    HowToVoteRounded: <icons.HowToVoteRounded />,
    HowToVoteSharp: <icons.HowToVoteSharp />,
    HowToVoteTwoTone: <icons.HowToVoteTwoTone />,
    Html: <icons.Html />,
    HtmlOutlined: <icons.HtmlOutlined />,
    HtmlRounded: <icons.HtmlRounded />,
    HtmlSharp: <icons.HtmlSharp />,
    HtmlTwoTone: <icons.HtmlTwoTone />,
    Http: <icons.Http />,
    HttpOutlined: <icons.HttpOutlined />,
    HttpRounded: <icons.HttpRounded />,
    HttpSharp: <icons.HttpSharp />,
    HttpTwoTone: <icons.HttpTwoTone />,
    Https: <icons.Https />,
    HttpsOutlined: <icons.HttpsOutlined />,
    HttpsRounded: <icons.HttpsRounded />,
    HttpsSharp: <icons.HttpsSharp />,
    HttpsTwoTone: <icons.HttpsTwoTone />,
    Hub: <icons.Hub />,
    HubOutlined: <icons.HubOutlined />,
    HubRounded: <icons.HubRounded />,
    HubSharp: <icons.HubSharp />,
    HubTwoTone: <icons.HubTwoTone />,
    Hvac: <icons.Hvac />,
    HvacOutlined: <icons.HvacOutlined />,
    HvacRounded: <icons.HvacRounded />,
    HvacSharp: <icons.HvacSharp />,
    HvacTwoTone: <icons.HvacTwoTone />,
    IceSkating: <icons.IceSkating />,
    IceSkatingOutlined: <icons.IceSkatingOutlined />,
    IceSkatingRounded: <icons.IceSkatingRounded />,
    IceSkatingSharp: <icons.IceSkatingSharp />,
    IceSkatingTwoTone: <icons.IceSkatingTwoTone />,
    Icecream: <icons.Icecream />,
    IcecreamOutlined: <icons.IcecreamOutlined />,
    IcecreamRounded: <icons.IcecreamRounded />,
    IcecreamSharp: <icons.IcecreamSharp />,
    IcecreamTwoTone: <icons.IcecreamTwoTone />,
    Image: <icons.Image />,
    ImageAspectRatio: <icons.ImageAspectRatio />,
    ImageAspectRatioOutlined: <icons.ImageAspectRatioOutlined />,
    ImageAspectRatioRounded: <icons.ImageAspectRatioRounded />,
    ImageAspectRatioSharp: <icons.ImageAspectRatioSharp />,
    ImageAspectRatioTwoTone: <icons.ImageAspectRatioTwoTone />,
    ImageNotSupported: <icons.ImageNotSupported />,
    ImageNotSupportedOutlined: <icons.ImageNotSupportedOutlined />,
    ImageNotSupportedRounded: <icons.ImageNotSupportedRounded />,
    ImageNotSupportedSharp: <icons.ImageNotSupportedSharp />,
    ImageNotSupportedTwoTone: <icons.ImageNotSupportedTwoTone />,
    ImageOutlined: <icons.ImageOutlined />,
    ImageRounded: <icons.ImageRounded />,
    ImageSearch: <icons.ImageSearch />,
    ImageSearchOutlined: <icons.ImageSearchOutlined />,
    ImageSearchRounded: <icons.ImageSearchRounded />,
    ImageSearchSharp: <icons.ImageSearchSharp />,
    ImageSearchTwoTone: <icons.ImageSearchTwoTone />,
    ImageSharp: <icons.ImageSharp />,
    ImageTwoTone: <icons.ImageTwoTone />,
    ImagesearchRoller: <icons.ImagesearchRoller />,
    ImagesearchRollerOutlined: <icons.ImagesearchRollerOutlined />,
    ImagesearchRollerRounded: <icons.ImagesearchRollerRounded />,
    ImagesearchRollerSharp: <icons.ImagesearchRollerSharp />,
    ImagesearchRollerTwoTone: <icons.ImagesearchRollerTwoTone />,
    ImportContacts: <icons.ImportContacts />,
    ImportContactsOutlined: <icons.ImportContactsOutlined />,
    ImportContactsRounded: <icons.ImportContactsRounded />,
    ImportContactsSharp: <icons.ImportContactsSharp />,
    ImportContactsTwoTone: <icons.ImportContactsTwoTone />,
    ImportExport: <icons.ImportExport />,
    ImportExportOutlined: <icons.ImportExportOutlined />,
    ImportExportRounded: <icons.ImportExportRounded />,
    ImportExportSharp: <icons.ImportExportSharp />,
    ImportExportTwoTone: <icons.ImportExportTwoTone />,
    ImportantDevices: <icons.ImportantDevices />,
    ImportantDevicesOutlined: <icons.ImportantDevicesOutlined />,
    ImportantDevicesRounded: <icons.ImportantDevicesRounded />,
    ImportantDevicesSharp: <icons.ImportantDevicesSharp />,
    ImportantDevicesTwoTone: <icons.ImportantDevicesTwoTone />,
    Inbox: <icons.Inbox />,
    InboxOutlined: <icons.InboxOutlined />,
    InboxRounded: <icons.InboxRounded />,
    InboxSharp: <icons.InboxSharp />,
    InboxTwoTone: <icons.InboxTwoTone />,
    IndeterminateCheckBox: <icons.IndeterminateCheckBox />,
    IndeterminateCheckBoxOutlined: <icons.IndeterminateCheckBoxOutlined />,
    IndeterminateCheckBoxRounded: <icons.IndeterminateCheckBoxRounded />,
    IndeterminateCheckBoxSharp: <icons.IndeterminateCheckBoxSharp />,
    IndeterminateCheckBoxTwoTone: <icons.IndeterminateCheckBoxTwoTone />,
    Info: <icons.Info />,
    InfoOutlined: <icons.InfoOutlined />,
    InfoRounded: <icons.InfoRounded />,
    InfoSharp: <icons.InfoSharp />,
    InfoTwoTone: <icons.InfoTwoTone />,
    Input: <icons.Input />,
    InputOutlined: <icons.InputOutlined />,
    InputRounded: <icons.InputRounded />,
    InputSharp: <icons.InputSharp />,
    InputTwoTone: <icons.InputTwoTone />,
    InsertChart: <icons.InsertChart />,
    InsertChartOutlined: <icons.InsertChartOutlined />,
    InsertChartOutlinedOutlined: <icons.InsertChartOutlinedOutlined />,
    InsertChartOutlinedRounded: <icons.InsertChartOutlinedRounded />,
    InsertChartOutlinedSharp: <icons.InsertChartOutlinedSharp />,
    InsertChartOutlinedTwoTone: <icons.InsertChartOutlinedTwoTone />,
    InsertChartRounded: <icons.InsertChartRounded />,
    InsertChartSharp: <icons.InsertChartSharp />,
    InsertChartTwoTone: <icons.InsertChartTwoTone />,
    InsertComment: <icons.InsertComment />,
    InsertCommentOutlined: <icons.InsertCommentOutlined />,
    InsertCommentRounded: <icons.InsertCommentRounded />,
    InsertCommentSharp: <icons.InsertCommentSharp />,
    InsertCommentTwoTone: <icons.InsertCommentTwoTone />,
    InsertDriveFile: <icons.InsertDriveFile />,
    InsertDriveFileOutlined: <icons.InsertDriveFileOutlined />,
    InsertDriveFileRounded: <icons.InsertDriveFileRounded />,
    InsertDriveFileSharp: <icons.InsertDriveFileSharp />,
    InsertDriveFileTwoTone: <icons.InsertDriveFileTwoTone />,
    InsertEmoticon: <icons.InsertEmoticon />,
    InsertEmoticonOutlined: <icons.InsertEmoticonOutlined />,
    InsertEmoticonRounded: <icons.InsertEmoticonRounded />,
    InsertEmoticonSharp: <icons.InsertEmoticonSharp />,
    InsertEmoticonTwoTone: <icons.InsertEmoticonTwoTone />,
    InsertInvitation: <icons.InsertInvitation />,
    InsertInvitationOutlined: <icons.InsertInvitationOutlined />,
    InsertInvitationRounded: <icons.InsertInvitationRounded />,
    InsertInvitationSharp: <icons.InsertInvitationSharp />,
    InsertInvitationTwoTone: <icons.InsertInvitationTwoTone />,
    InsertLink: <icons.InsertLink />,
    InsertLinkOutlined: <icons.InsertLinkOutlined />,
    InsertLinkRounded: <icons.InsertLinkRounded />,
    InsertLinkSharp: <icons.InsertLinkSharp />,
    InsertLinkTwoTone: <icons.InsertLinkTwoTone />,
    InsertPageBreak: <icons.InsertPageBreak />,
    InsertPageBreakOutlined: <icons.InsertPageBreakOutlined />,
    InsertPageBreakRounded: <icons.InsertPageBreakRounded />,
    InsertPageBreakSharp: <icons.InsertPageBreakSharp />,
    InsertPageBreakTwoTone: <icons.InsertPageBreakTwoTone />,
    InsertPhoto: <icons.InsertPhoto />,
    InsertPhotoOutlined: <icons.InsertPhotoOutlined />,
    InsertPhotoRounded: <icons.InsertPhotoRounded />,
    InsertPhotoSharp: <icons.InsertPhotoSharp />,
    InsertPhotoTwoTone: <icons.InsertPhotoTwoTone />,
    Insights: <icons.Insights />,
    InsightsOutlined: <icons.InsightsOutlined />,
    InsightsRounded: <icons.InsightsRounded />,
    InsightsSharp: <icons.InsightsSharp />,
    InsightsTwoTone: <icons.InsightsTwoTone />,
    Instagram: <icons.Instagram />,
    InstallDesktop: <icons.InstallDesktop />,
    InstallDesktopOutlined: <icons.InstallDesktopOutlined />,
    InstallDesktopRounded: <icons.InstallDesktopRounded />,
    InstallDesktopSharp: <icons.InstallDesktopSharp />,
    InstallDesktopTwoTone: <icons.InstallDesktopTwoTone />,
    InstallMobile: <icons.InstallMobile />,
    InstallMobileOutlined: <icons.InstallMobileOutlined />,
    InstallMobileRounded: <icons.InstallMobileRounded />,
    InstallMobileSharp: <icons.InstallMobileSharp />,
    InstallMobileTwoTone: <icons.InstallMobileTwoTone />,
    IntegrationInstructions: <icons.IntegrationInstructions />,
    IntegrationInstructionsOutlined: <icons.IntegrationInstructionsOutlined />,
    IntegrationInstructionsRounded: <icons.IntegrationInstructionsRounded />,
    IntegrationInstructionsSharp: <icons.IntegrationInstructionsSharp />,
    IntegrationInstructionsTwoTone: <icons.IntegrationInstructionsTwoTone />,
    Interests: <icons.Interests />,
    InterestsOutlined: <icons.InterestsOutlined />,
    InterestsRounded: <icons.InterestsRounded />,
    InterestsSharp: <icons.InterestsSharp />,
    InterestsTwoTone: <icons.InterestsTwoTone />,
    InterpreterMode: <icons.InterpreterMode />,
    InterpreterModeOutlined: <icons.InterpreterModeOutlined />,
    InterpreterModeRounded: <icons.InterpreterModeRounded />,
    InterpreterModeSharp: <icons.InterpreterModeSharp />,
    InterpreterModeTwoTone: <icons.InterpreterModeTwoTone />,
    Inventory: <icons.Inventory />,
    Inventory2: <icons.Inventory2 />,
    Inventory2Outlined: <icons.Inventory2Outlined />,
    Inventory2Rounded: <icons.Inventory2Rounded />,
    Inventory2Sharp: <icons.Inventory2Sharp />,
    Inventory2TwoTone: <icons.Inventory2TwoTone />,
    InventoryOutlined: <icons.InventoryOutlined />,
    InventoryRounded: <icons.InventoryRounded />,
    InventorySharp: <icons.InventorySharp />,
    InventoryTwoTone: <icons.InventoryTwoTone />,
    InvertColors: <icons.InvertColors />,
    InvertColorsOff: <icons.InvertColorsOff />,
    InvertColorsOffOutlined: <icons.InvertColorsOffOutlined />,
    InvertColorsOffRounded: <icons.InvertColorsOffRounded />,
    InvertColorsOffSharp: <icons.InvertColorsOffSharp />,
    InvertColorsOffTwoTone: <icons.InvertColorsOffTwoTone />,
    InvertColorsOutlined: <icons.InvertColorsOutlined />,
    InvertColorsRounded: <icons.InvertColorsRounded />,
    InvertColorsSharp: <icons.InvertColorsSharp />,
    InvertColorsTwoTone: <icons.InvertColorsTwoTone />,
    IosShare: <icons.IosShare />,
    IosShareOutlined: <icons.IosShareOutlined />,
    IosShareRounded: <icons.IosShareRounded />,
    IosShareSharp: <icons.IosShareSharp />,
    IosShareTwoTone: <icons.IosShareTwoTone />,
    Iron: <icons.Iron />,
    IronOutlined: <icons.IronOutlined />,
    IronRounded: <icons.IronRounded />,
    IronSharp: <icons.IronSharp />,
    IronTwoTone: <icons.IronTwoTone />,
    Iso: <icons.Iso />,
    IsoOutlined: <icons.IsoOutlined />,
    IsoRounded: <icons.IsoRounded />,
    IsoSharp: <icons.IsoSharp />,
    IsoTwoTone: <icons.IsoTwoTone />,
    Javascript: <icons.Javascript />,
    JavascriptOutlined: <icons.JavascriptOutlined />,
    JavascriptRounded: <icons.JavascriptRounded />,
    JavascriptSharp: <icons.JavascriptSharp />,
    JavascriptTwoTone: <icons.JavascriptTwoTone />,
    JoinFull: <icons.JoinFull />,
    JoinFullOutlined: <icons.JoinFullOutlined />,
    JoinFullRounded: <icons.JoinFullRounded />,
    JoinFullSharp: <icons.JoinFullSharp />,
    JoinFullTwoTone: <icons.JoinFullTwoTone />,
    JoinInner: <icons.JoinInner />,
    JoinInnerOutlined: <icons.JoinInnerOutlined />,
    JoinInnerRounded: <icons.JoinInnerRounded />,
    JoinInnerSharp: <icons.JoinInnerSharp />,
    JoinInnerTwoTone: <icons.JoinInnerTwoTone />,
    JoinLeft: <icons.JoinLeft />,
    JoinLeftOutlined: <icons.JoinLeftOutlined />,
    JoinLeftRounded: <icons.JoinLeftRounded />,
    JoinLeftSharp: <icons.JoinLeftSharp />,
    JoinLeftTwoTone: <icons.JoinLeftTwoTone />,
    JoinRight: <icons.JoinRight />,
    JoinRightOutlined: <icons.JoinRightOutlined />,
    JoinRightRounded: <icons.JoinRightRounded />,
    JoinRightSharp: <icons.JoinRightSharp />,
    JoinRightTwoTone: <icons.JoinRightTwoTone />,
    Kayaking: <icons.Kayaking />,
    KayakingOutlined: <icons.KayakingOutlined />,
    KayakingRounded: <icons.KayakingRounded />,
    KayakingSharp: <icons.KayakingSharp />,
    KayakingTwoTone: <icons.KayakingTwoTone />,
    KebabDining: <icons.KebabDining />,
    KebabDiningOutlined: <icons.KebabDiningOutlined />,
    KebabDiningRounded: <icons.KebabDiningRounded />,
    KebabDiningSharp: <icons.KebabDiningSharp />,
    KebabDiningTwoTone: <icons.KebabDiningTwoTone />,
    Key: <icons.Key />,
    KeyOff: <icons.KeyOff />,
    KeyOffOutlined: <icons.KeyOffOutlined />,
    KeyOffRounded: <icons.KeyOffRounded />,
    KeyOffSharp: <icons.KeyOffSharp />,
    KeyOffTwoTone: <icons.KeyOffTwoTone />,
    KeyOutlined: <icons.KeyOutlined />,
    KeyRounded: <icons.KeyRounded />,
    KeySharp: <icons.KeySharp />,
    KeyTwoTone: <icons.KeyTwoTone />,
    Keyboard: <icons.Keyboard />,
    KeyboardAlt: <icons.KeyboardAlt />,
    KeyboardAltOutlined: <icons.KeyboardAltOutlined />,
    KeyboardAltRounded: <icons.KeyboardAltRounded />,
    KeyboardAltSharp: <icons.KeyboardAltSharp />,
    KeyboardAltTwoTone: <icons.KeyboardAltTwoTone />,
    KeyboardArrowDown: <icons.KeyboardArrowDown />,
    KeyboardArrowDownOutlined: <icons.KeyboardArrowDownOutlined />,
    KeyboardArrowDownRounded: <icons.KeyboardArrowDownRounded />,
    KeyboardArrowDownSharp: <icons.KeyboardArrowDownSharp />,
    KeyboardArrowDownTwoTone: <icons.KeyboardArrowDownTwoTone />,
    KeyboardArrowLeft: <icons.KeyboardArrowLeft />,
    KeyboardArrowLeftOutlined: <icons.KeyboardArrowLeftOutlined />,
    KeyboardArrowLeftRounded: <icons.KeyboardArrowLeftRounded />,
    KeyboardArrowLeftSharp: <icons.KeyboardArrowLeftSharp />,
    KeyboardArrowLeftTwoTone: <icons.KeyboardArrowLeftTwoTone />,
    KeyboardArrowRight: <icons.KeyboardArrowRight />,
    KeyboardArrowRightOutlined: <icons.KeyboardArrowRightOutlined />,
    KeyboardArrowRightRounded: <icons.KeyboardArrowRightRounded />,
    KeyboardArrowRightSharp: <icons.KeyboardArrowRightSharp />,
    KeyboardArrowRightTwoTone: <icons.KeyboardArrowRightTwoTone />,
    KeyboardArrowUp: <icons.KeyboardArrowUp />,
    KeyboardArrowUpOutlined: <icons.KeyboardArrowUpOutlined />,
    KeyboardArrowUpRounded: <icons.KeyboardArrowUpRounded />,
    KeyboardArrowUpSharp: <icons.KeyboardArrowUpSharp />,
    KeyboardArrowUpTwoTone: <icons.KeyboardArrowUpTwoTone />,
    KeyboardBackspace: <icons.KeyboardBackspace />,
    KeyboardBackspaceOutlined: <icons.KeyboardBackspaceOutlined />,
    KeyboardBackspaceRounded: <icons.KeyboardBackspaceRounded />,
    KeyboardBackspaceSharp: <icons.KeyboardBackspaceSharp />,
    KeyboardBackspaceTwoTone: <icons.KeyboardBackspaceTwoTone />,
    KeyboardCapslock: <icons.KeyboardCapslock />,
    KeyboardCapslockOutlined: <icons.KeyboardCapslockOutlined />,
    KeyboardCapslockRounded: <icons.KeyboardCapslockRounded />,
    KeyboardCapslockSharp: <icons.KeyboardCapslockSharp />,
    KeyboardCapslockTwoTone: <icons.KeyboardCapslockTwoTone />,
    KeyboardCommandKey: <icons.KeyboardCommandKey />,
    KeyboardCommandKeyOutlined: <icons.KeyboardCommandKeyOutlined />,
    KeyboardCommandKeyRounded: <icons.KeyboardCommandKeyRounded />,
    KeyboardCommandKeySharp: <icons.KeyboardCommandKeySharp />,
    KeyboardCommandKeyTwoTone: <icons.KeyboardCommandKeyTwoTone />,
    KeyboardControlKey: <icons.KeyboardControlKey />,
    KeyboardControlKeyOutlined: <icons.KeyboardControlKeyOutlined />,
    KeyboardControlKeyRounded: <icons.KeyboardControlKeyRounded />,
    KeyboardControlKeySharp: <icons.KeyboardControlKeySharp />,
    KeyboardControlKeyTwoTone: <icons.KeyboardControlKeyTwoTone />,
    KeyboardDoubleArrowDown: <icons.KeyboardDoubleArrowDown />,
    KeyboardDoubleArrowDownOutlined: <icons.KeyboardDoubleArrowDownOutlined />,
    KeyboardDoubleArrowDownRounded: <icons.KeyboardDoubleArrowDownRounded />,
    KeyboardDoubleArrowDownSharp: <icons.KeyboardDoubleArrowDownSharp />,
    KeyboardDoubleArrowDownTwoTone: <icons.KeyboardDoubleArrowDownTwoTone />,
    KeyboardDoubleArrowLeft: <icons.KeyboardDoubleArrowLeft />,
    KeyboardDoubleArrowLeftOutlined: <icons.KeyboardDoubleArrowLeftOutlined />,
    KeyboardDoubleArrowLeftRounded: <icons.KeyboardDoubleArrowLeftRounded />,
    KeyboardDoubleArrowLeftSharp: <icons.KeyboardDoubleArrowLeftSharp />,
    KeyboardDoubleArrowLeftTwoTone: <icons.KeyboardDoubleArrowLeftTwoTone />,
    KeyboardDoubleArrowRight: <icons.KeyboardDoubleArrowRight />,
    KeyboardDoubleArrowRightOutlined: (
        <icons.KeyboardDoubleArrowRightOutlined />
    ),
    KeyboardDoubleArrowRightRounded: <icons.KeyboardDoubleArrowRightRounded />,
    KeyboardDoubleArrowRightSharp: <icons.KeyboardDoubleArrowRightSharp />,
    KeyboardDoubleArrowRightTwoTone: <icons.KeyboardDoubleArrowRightTwoTone />,
    KeyboardDoubleArrowUp: <icons.KeyboardDoubleArrowUp />,
    KeyboardDoubleArrowUpOutlined: <icons.KeyboardDoubleArrowUpOutlined />,
    KeyboardDoubleArrowUpRounded: <icons.KeyboardDoubleArrowUpRounded />,
    KeyboardDoubleArrowUpSharp: <icons.KeyboardDoubleArrowUpSharp />,
    KeyboardDoubleArrowUpTwoTone: <icons.KeyboardDoubleArrowUpTwoTone />,
    KeyboardHide: <icons.KeyboardHide />,
    KeyboardHideOutlined: <icons.KeyboardHideOutlined />,
    KeyboardHideRounded: <icons.KeyboardHideRounded />,
    KeyboardHideSharp: <icons.KeyboardHideSharp />,
    KeyboardHideTwoTone: <icons.KeyboardHideTwoTone />,
    KeyboardOptionKey: <icons.KeyboardOptionKey />,
    KeyboardOptionKeyOutlined: <icons.KeyboardOptionKeyOutlined />,
    KeyboardOptionKeyRounded: <icons.KeyboardOptionKeyRounded />,
    KeyboardOptionKeySharp: <icons.KeyboardOptionKeySharp />,
    KeyboardOptionKeyTwoTone: <icons.KeyboardOptionKeyTwoTone />,
    KeyboardOutlined: <icons.KeyboardOutlined />,
    KeyboardReturn: <icons.KeyboardReturn />,
    KeyboardReturnOutlined: <icons.KeyboardReturnOutlined />,
    KeyboardReturnRounded: <icons.KeyboardReturnRounded />,
    KeyboardReturnSharp: <icons.KeyboardReturnSharp />,
    KeyboardReturnTwoTone: <icons.KeyboardReturnTwoTone />,
    KeyboardRounded: <icons.KeyboardRounded />,
    KeyboardSharp: <icons.KeyboardSharp />,
    KeyboardTab: <icons.KeyboardTab />,
    KeyboardTabOutlined: <icons.KeyboardTabOutlined />,
    KeyboardTabRounded: <icons.KeyboardTabRounded />,
    KeyboardTabSharp: <icons.KeyboardTabSharp />,
    KeyboardTabTwoTone: <icons.KeyboardTabTwoTone />,
    KeyboardTwoTone: <icons.KeyboardTwoTone />,
    KeyboardVoice: <icons.KeyboardVoice />,
    KeyboardVoiceOutlined: <icons.KeyboardVoiceOutlined />,
    KeyboardVoiceRounded: <icons.KeyboardVoiceRounded />,
    KeyboardVoiceSharp: <icons.KeyboardVoiceSharp />,
    KeyboardVoiceTwoTone: <icons.KeyboardVoiceTwoTone />,
    KingBed: <icons.KingBed />,
    KingBedOutlined: <icons.KingBedOutlined />,
    KingBedRounded: <icons.KingBedRounded />,
    KingBedSharp: <icons.KingBedSharp />,
    KingBedTwoTone: <icons.KingBedTwoTone />,
    Kitchen: <icons.Kitchen />,
    KitchenOutlined: <icons.KitchenOutlined />,
    KitchenRounded: <icons.KitchenRounded />,
    KitchenSharp: <icons.KitchenSharp />,
    KitchenTwoTone: <icons.KitchenTwoTone />,
    Kitesurfing: <icons.Kitesurfing />,
    KitesurfingOutlined: <icons.KitesurfingOutlined />,
    KitesurfingRounded: <icons.KitesurfingRounded />,
    KitesurfingSharp: <icons.KitesurfingSharp />,
    KitesurfingTwoTone: <icons.KitesurfingTwoTone />,
    Label: <icons.Label />,
    LabelImportant: <icons.LabelImportant />,
    LabelImportantOutlined: <icons.LabelImportantOutlined />,
    LabelImportantRounded: <icons.LabelImportantRounded />,
    LabelImportantSharp: <icons.LabelImportantSharp />,
    LabelImportantTwoTone: <icons.LabelImportantTwoTone />,
    LabelOff: <icons.LabelOff />,
    LabelOffOutlined: <icons.LabelOffOutlined />,
    LabelOffRounded: <icons.LabelOffRounded />,
    LabelOffSharp: <icons.LabelOffSharp />,
    LabelOffTwoTone: <icons.LabelOffTwoTone />,
    LabelOutlined: <icons.LabelOutlined />,
    LabelRounded: <icons.LabelRounded />,
    LabelSharp: <icons.LabelSharp />,
    LabelTwoTone: <icons.LabelTwoTone />,
    Lan: <icons.Lan />,
    LanOutlined: <icons.LanOutlined />,
    LanRounded: <icons.LanRounded />,
    LanSharp: <icons.LanSharp />,
    LanTwoTone: <icons.LanTwoTone />,
    Landscape: <icons.Landscape />,
    LandscapeOutlined: <icons.LandscapeOutlined />,
    LandscapeRounded: <icons.LandscapeRounded />,
    LandscapeSharp: <icons.LandscapeSharp />,
    LandscapeTwoTone: <icons.LandscapeTwoTone />,
    Landslide: <icons.Landslide />,
    LandslideOutlined: <icons.LandslideOutlined />,
    LandslideRounded: <icons.LandslideRounded />,
    LandslideSharp: <icons.LandslideSharp />,
    LandslideTwoTone: <icons.LandslideTwoTone />,
    Language: <icons.Language />,
    LanguageOutlined: <icons.LanguageOutlined />,
    LanguageRounded: <icons.LanguageRounded />,
    LanguageSharp: <icons.LanguageSharp />,
    LanguageTwoTone: <icons.LanguageTwoTone />,
    Laptop: <icons.Laptop />,
    LaptopChromebook: <icons.LaptopChromebook />,
    LaptopChromebookOutlined: <icons.LaptopChromebookOutlined />,
    LaptopChromebookRounded: <icons.LaptopChromebookRounded />,
    LaptopChromebookSharp: <icons.LaptopChromebookSharp />,
    LaptopChromebookTwoTone: <icons.LaptopChromebookTwoTone />,
    LaptopMac: <icons.LaptopMac />,
    LaptopMacOutlined: <icons.LaptopMacOutlined />,
    LaptopMacRounded: <icons.LaptopMacRounded />,
    LaptopMacSharp: <icons.LaptopMacSharp />,
    LaptopMacTwoTone: <icons.LaptopMacTwoTone />,
    LaptopOutlined: <icons.LaptopOutlined />,
    LaptopRounded: <icons.LaptopRounded />,
    LaptopSharp: <icons.LaptopSharp />,
    LaptopTwoTone: <icons.LaptopTwoTone />,
    LaptopWindows: <icons.LaptopWindows />,
    LaptopWindowsOutlined: <icons.LaptopWindowsOutlined />,
    LaptopWindowsRounded: <icons.LaptopWindowsRounded />,
    LaptopWindowsSharp: <icons.LaptopWindowsSharp />,
    LaptopWindowsTwoTone: <icons.LaptopWindowsTwoTone />,
    LastPage: <icons.LastPage />,
    LastPageOutlined: <icons.LastPageOutlined />,
    LastPageRounded: <icons.LastPageRounded />,
    LastPageSharp: <icons.LastPageSharp />,
    LastPageTwoTone: <icons.LastPageTwoTone />,
    Launch: <icons.Launch />,
    LaunchOutlined: <icons.LaunchOutlined />,
    LaunchRounded: <icons.LaunchRounded />,
    LaunchSharp: <icons.LaunchSharp />,
    LaunchTwoTone: <icons.LaunchTwoTone />,
    Layers: <icons.Layers />,
    LayersClear: <icons.LayersClear />,
    LayersClearOutlined: <icons.LayersClearOutlined />,
    LayersClearRounded: <icons.LayersClearRounded />,
    LayersClearSharp: <icons.LayersClearSharp />,
    LayersClearTwoTone: <icons.LayersClearTwoTone />,
    LayersOutlined: <icons.LayersOutlined />,
    LayersRounded: <icons.LayersRounded />,
    LayersSharp: <icons.LayersSharp />,
    LayersTwoTone: <icons.LayersTwoTone />,
    Leaderboard: <icons.Leaderboard />,
    LeaderboardOutlined: <icons.LeaderboardOutlined />,
    LeaderboardRounded: <icons.LeaderboardRounded />,
    LeaderboardSharp: <icons.LeaderboardSharp />,
    LeaderboardTwoTone: <icons.LeaderboardTwoTone />,
    LeakAdd: <icons.LeakAdd />,
    LeakAddOutlined: <icons.LeakAddOutlined />,
    LeakAddRounded: <icons.LeakAddRounded />,
    LeakAddSharp: <icons.LeakAddSharp />,
    LeakAddTwoTone: <icons.LeakAddTwoTone />,
    LeakRemove: <icons.LeakRemove />,
    LeakRemoveOutlined: <icons.LeakRemoveOutlined />,
    LeakRemoveRounded: <icons.LeakRemoveRounded />,
    LeakRemoveSharp: <icons.LeakRemoveSharp />,
    LeakRemoveTwoTone: <icons.LeakRemoveTwoTone />,
    LegendToggle: <icons.LegendToggle />,
    LegendToggleOutlined: <icons.LegendToggleOutlined />,
    LegendToggleRounded: <icons.LegendToggleRounded />,
    LegendToggleSharp: <icons.LegendToggleSharp />,
    LegendToggleTwoTone: <icons.LegendToggleTwoTone />,
    Lens: <icons.Lens />,
    LensBlur: <icons.LensBlur />,
    LensBlurOutlined: <icons.LensBlurOutlined />,
    LensBlurRounded: <icons.LensBlurRounded />,
    LensBlurSharp: <icons.LensBlurSharp />,
    LensBlurTwoTone: <icons.LensBlurTwoTone />,
    LensOutlined: <icons.LensOutlined />,
    LensRounded: <icons.LensRounded />,
    LensSharp: <icons.LensSharp />,
    LensTwoTone: <icons.LensTwoTone />,
    LibraryAdd: <icons.LibraryAdd />,
    LibraryAddCheck: <icons.LibraryAddCheck />,
    LibraryAddCheckOutlined: <icons.LibraryAddCheckOutlined />,
    LibraryAddCheckRounded: <icons.LibraryAddCheckRounded />,
    LibraryAddCheckSharp: <icons.LibraryAddCheckSharp />,
    LibraryAddCheckTwoTone: <icons.LibraryAddCheckTwoTone />,
    LibraryAddOutlined: <icons.LibraryAddOutlined />,
    LibraryAddRounded: <icons.LibraryAddRounded />,
    LibraryAddSharp: <icons.LibraryAddSharp />,
    LibraryAddTwoTone: <icons.LibraryAddTwoTone />,
    LibraryBooks: <icons.LibraryBooks />,
    LibraryBooksOutlined: <icons.LibraryBooksOutlined />,
    LibraryBooksRounded: <icons.LibraryBooksRounded />,
    LibraryBooksSharp: <icons.LibraryBooksSharp />,
    LibraryBooksTwoTone: <icons.LibraryBooksTwoTone />,
    LibraryMusic: <icons.LibraryMusic />,
    LibraryMusicOutlined: <icons.LibraryMusicOutlined />,
    LibraryMusicRounded: <icons.LibraryMusicRounded />,
    LibraryMusicSharp: <icons.LibraryMusicSharp />,
    LibraryMusicTwoTone: <icons.LibraryMusicTwoTone />,
    Light: <icons.Light />,
    LightMode: <icons.LightMode />,
    LightModeOutlined: <icons.LightModeOutlined />,
    LightModeRounded: <icons.LightModeRounded />,
    LightModeSharp: <icons.LightModeSharp />,
    LightModeTwoTone: <icons.LightModeTwoTone />,
    LightOutlined: <icons.LightOutlined />,
    LightRounded: <icons.LightRounded />,
    LightSharp: <icons.LightSharp />,
    LightTwoTone: <icons.LightTwoTone />,
    Lightbulb: <icons.Lightbulb />,
    LightbulbCircle: <icons.LightbulbCircle />,
    LightbulbCircleOutlined: <icons.LightbulbCircleOutlined />,
    LightbulbCircleRounded: <icons.LightbulbCircleRounded />,
    LightbulbCircleSharp: <icons.LightbulbCircleSharp />,
    LightbulbCircleTwoTone: <icons.LightbulbCircleTwoTone />,
    LightbulbOutlined: <icons.LightbulbOutlined />,
    LightbulbRounded: <icons.LightbulbRounded />,
    LightbulbSharp: <icons.LightbulbSharp />,
    LightbulbTwoTone: <icons.LightbulbTwoTone />,
    LineAxis: <icons.LineAxis />,
    LineAxisOutlined: <icons.LineAxisOutlined />,
    LineAxisRounded: <icons.LineAxisRounded />,
    LineAxisSharp: <icons.LineAxisSharp />,
    LineAxisTwoTone: <icons.LineAxisTwoTone />,
    LineStyle: <icons.LineStyle />,
    LineStyleOutlined: <icons.LineStyleOutlined />,
    LineStyleRounded: <icons.LineStyleRounded />,
    LineStyleSharp: <icons.LineStyleSharp />,
    LineStyleTwoTone: <icons.LineStyleTwoTone />,
    LineWeight: <icons.LineWeight />,
    LineWeightOutlined: <icons.LineWeightOutlined />,
    LineWeightRounded: <icons.LineWeightRounded />,
    LineWeightSharp: <icons.LineWeightSharp />,
    LineWeightTwoTone: <icons.LineWeightTwoTone />,
    LinearScale: <icons.LinearScale />,
    LinearScaleOutlined: <icons.LinearScaleOutlined />,
    LinearScaleRounded: <icons.LinearScaleRounded />,
    LinearScaleSharp: <icons.LinearScaleSharp />,
    LinearScaleTwoTone: <icons.LinearScaleTwoTone />,
    Link: <icons.Link />,
    LinkOff: <icons.LinkOff />,
    LinkOffOutlined: <icons.LinkOffOutlined />,
    LinkOffRounded: <icons.LinkOffRounded />,
    LinkOffSharp: <icons.LinkOffSharp />,
    LinkOffTwoTone: <icons.LinkOffTwoTone />,
    LinkOutlined: <icons.LinkOutlined />,
    LinkRounded: <icons.LinkRounded />,
    LinkSharp: <icons.LinkSharp />,
    LinkTwoTone: <icons.LinkTwoTone />,
    LinkedCamera: <icons.LinkedCamera />,
    LinkedCameraOutlined: <icons.LinkedCameraOutlined />,
    LinkedCameraRounded: <icons.LinkedCameraRounded />,
    LinkedCameraSharp: <icons.LinkedCameraSharp />,
    LinkedCameraTwoTone: <icons.LinkedCameraTwoTone />,
    LinkedIn: <icons.LinkedIn />,
    Liquor: <icons.Liquor />,
    LiquorOutlined: <icons.LiquorOutlined />,
    LiquorRounded: <icons.LiquorRounded />,
    LiquorSharp: <icons.LiquorSharp />,
    LiquorTwoTone: <icons.LiquorTwoTone />,
    List: <icons.List />,
    ListAlt: <icons.ListAlt />,
    ListAltOutlined: <icons.ListAltOutlined />,
    ListAltRounded: <icons.ListAltRounded />,
    ListAltSharp: <icons.ListAltSharp />,
    ListAltTwoTone: <icons.ListAltTwoTone />,
    ListOutlined: <icons.ListOutlined />,
    ListRounded: <icons.ListRounded />,
    ListSharp: <icons.ListSharp />,
    ListTwoTone: <icons.ListTwoTone />,
    LiveHelp: <icons.LiveHelp />,
    LiveHelpOutlined: <icons.LiveHelpOutlined />,
    LiveHelpRounded: <icons.LiveHelpRounded />,
    LiveHelpSharp: <icons.LiveHelpSharp />,
    LiveHelpTwoTone: <icons.LiveHelpTwoTone />,
    LiveTv: <icons.LiveTv />,
    LiveTvOutlined: <icons.LiveTvOutlined />,
    LiveTvRounded: <icons.LiveTvRounded />,
    LiveTvSharp: <icons.LiveTvSharp />,
    LiveTvTwoTone: <icons.LiveTvTwoTone />,
    Living: <icons.Living />,
    LivingOutlined: <icons.LivingOutlined />,
    LivingRounded: <icons.LivingRounded />,
    LivingSharp: <icons.LivingSharp />,
    LivingTwoTone: <icons.LivingTwoTone />,
    LocalActivity: <icons.LocalActivity />,
    LocalActivityOutlined: <icons.LocalActivityOutlined />,
    LocalActivityRounded: <icons.LocalActivityRounded />,
    LocalActivitySharp: <icons.LocalActivitySharp />,
    LocalActivityTwoTone: <icons.LocalActivityTwoTone />,
    LocalAirport: <icons.LocalAirport />,
    LocalAirportOutlined: <icons.LocalAirportOutlined />,
    LocalAirportRounded: <icons.LocalAirportRounded />,
    LocalAirportSharp: <icons.LocalAirportSharp />,
    LocalAirportTwoTone: <icons.LocalAirportTwoTone />,
    LocalAtm: <icons.LocalAtm />,
    LocalAtmOutlined: <icons.LocalAtmOutlined />,
    LocalAtmRounded: <icons.LocalAtmRounded />,
    LocalAtmSharp: <icons.LocalAtmSharp />,
    LocalAtmTwoTone: <icons.LocalAtmTwoTone />,
    LocalBar: <icons.LocalBar />,
    LocalBarOutlined: <icons.LocalBarOutlined />,
    LocalBarRounded: <icons.LocalBarRounded />,
    LocalBarSharp: <icons.LocalBarSharp />,
    LocalBarTwoTone: <icons.LocalBarTwoTone />,
    LocalCafe: <icons.LocalCafe />,
    LocalCafeOutlined: <icons.LocalCafeOutlined />,
    LocalCafeRounded: <icons.LocalCafeRounded />,
    LocalCafeSharp: <icons.LocalCafeSharp />,
    LocalCafeTwoTone: <icons.LocalCafeTwoTone />,
    LocalCarWash: <icons.LocalCarWash />,
    LocalCarWashOutlined: <icons.LocalCarWashOutlined />,
    LocalCarWashRounded: <icons.LocalCarWashRounded />,
    LocalCarWashSharp: <icons.LocalCarWashSharp />,
    LocalCarWashTwoTone: <icons.LocalCarWashTwoTone />,
    LocalConvenienceStore: <icons.LocalConvenienceStore />,
    LocalConvenienceStoreOutlined: <icons.LocalConvenienceStoreOutlined />,
    LocalConvenienceStoreRounded: <icons.LocalConvenienceStoreRounded />,
    LocalConvenienceStoreSharp: <icons.LocalConvenienceStoreSharp />,
    LocalConvenienceStoreTwoTone: <icons.LocalConvenienceStoreTwoTone />,
    LocalDining: <icons.LocalDining />,
    LocalDiningOutlined: <icons.LocalDiningOutlined />,
    LocalDiningRounded: <icons.LocalDiningRounded />,
    LocalDiningSharp: <icons.LocalDiningSharp />,
    LocalDiningTwoTone: <icons.LocalDiningTwoTone />,
    LocalDrink: <icons.LocalDrink />,
    LocalDrinkOutlined: <icons.LocalDrinkOutlined />,
    LocalDrinkRounded: <icons.LocalDrinkRounded />,
    LocalDrinkSharp: <icons.LocalDrinkSharp />,
    LocalDrinkTwoTone: <icons.LocalDrinkTwoTone />,
    LocalFireDepartment: <icons.LocalFireDepartment />,
    LocalFireDepartmentOutlined: <icons.LocalFireDepartmentOutlined />,
    LocalFireDepartmentRounded: <icons.LocalFireDepartmentRounded />,
    LocalFireDepartmentSharp: <icons.LocalFireDepartmentSharp />,
    LocalFireDepartmentTwoTone: <icons.LocalFireDepartmentTwoTone />,
    LocalFlorist: <icons.LocalFlorist />,
    LocalFloristOutlined: <icons.LocalFloristOutlined />,
    LocalFloristRounded: <icons.LocalFloristRounded />,
    LocalFloristSharp: <icons.LocalFloristSharp />,
    LocalFloristTwoTone: <icons.LocalFloristTwoTone />,
    LocalGasStation: <icons.LocalGasStation />,
    LocalGasStationOutlined: <icons.LocalGasStationOutlined />,
    LocalGasStationRounded: <icons.LocalGasStationRounded />,
    LocalGasStationSharp: <icons.LocalGasStationSharp />,
    LocalGasStationTwoTone: <icons.LocalGasStationTwoTone />,
    LocalGroceryStore: <icons.LocalGroceryStore />,
    LocalGroceryStoreOutlined: <icons.LocalGroceryStoreOutlined />,
    LocalGroceryStoreRounded: <icons.LocalGroceryStoreRounded />,
    LocalGroceryStoreSharp: <icons.LocalGroceryStoreSharp />,
    LocalGroceryStoreTwoTone: <icons.LocalGroceryStoreTwoTone />,
    LocalHospital: <icons.LocalHospital />,
    LocalHospitalOutlined: <icons.LocalHospitalOutlined />,
    LocalHospitalRounded: <icons.LocalHospitalRounded />,
    LocalHospitalSharp: <icons.LocalHospitalSharp />,
    LocalHospitalTwoTone: <icons.LocalHospitalTwoTone />,
    LocalHotel: <icons.LocalHotel />,
    LocalHotelOutlined: <icons.LocalHotelOutlined />,
    LocalHotelRounded: <icons.LocalHotelRounded />,
    LocalHotelSharp: <icons.LocalHotelSharp />,
    LocalHotelTwoTone: <icons.LocalHotelTwoTone />,
    LocalLaundryService: <icons.LocalLaundryService />,
    LocalLaundryServiceOutlined: <icons.LocalLaundryServiceOutlined />,
    LocalLaundryServiceRounded: <icons.LocalLaundryServiceRounded />,
    LocalLaundryServiceSharp: <icons.LocalLaundryServiceSharp />,
    LocalLaundryServiceTwoTone: <icons.LocalLaundryServiceTwoTone />,
    LocalLibrary: <icons.LocalLibrary />,
    LocalLibraryOutlined: <icons.LocalLibraryOutlined />,
    LocalLibraryRounded: <icons.LocalLibraryRounded />,
    LocalLibrarySharp: <icons.LocalLibrarySharp />,
    LocalLibraryTwoTone: <icons.LocalLibraryTwoTone />,
    LocalMall: <icons.LocalMall />,
    LocalMallOutlined: <icons.LocalMallOutlined />,
    LocalMallRounded: <icons.LocalMallRounded />,
    LocalMallSharp: <icons.LocalMallSharp />,
    LocalMallTwoTone: <icons.LocalMallTwoTone />,
    LocalMovies: <icons.LocalMovies />,
    LocalMoviesOutlined: <icons.LocalMoviesOutlined />,
    LocalMoviesRounded: <icons.LocalMoviesRounded />,
    LocalMoviesSharp: <icons.LocalMoviesSharp />,
    LocalMoviesTwoTone: <icons.LocalMoviesTwoTone />,
    LocalOffer: <icons.LocalOffer />,
    LocalOfferOutlined: <icons.LocalOfferOutlined />,
    LocalOfferRounded: <icons.LocalOfferRounded />,
    LocalOfferSharp: <icons.LocalOfferSharp />,
    LocalOfferTwoTone: <icons.LocalOfferTwoTone />,
    LocalParking: <icons.LocalParking />,
    LocalParkingOutlined: <icons.LocalParkingOutlined />,
    LocalParkingRounded: <icons.LocalParkingRounded />,
    LocalParkingSharp: <icons.LocalParkingSharp />,
    LocalParkingTwoTone: <icons.LocalParkingTwoTone />,
    LocalPharmacy: <icons.LocalPharmacy />,
    LocalPharmacyOutlined: <icons.LocalPharmacyOutlined />,
    LocalPharmacyRounded: <icons.LocalPharmacyRounded />,
    LocalPharmacySharp: <icons.LocalPharmacySharp />,
    LocalPharmacyTwoTone: <icons.LocalPharmacyTwoTone />,
    LocalPhone: <icons.LocalPhone />,
    LocalPhoneOutlined: <icons.LocalPhoneOutlined />,
    LocalPhoneRounded: <icons.LocalPhoneRounded />,
    LocalPhoneSharp: <icons.LocalPhoneSharp />,
    LocalPhoneTwoTone: <icons.LocalPhoneTwoTone />,
    LocalPizza: <icons.LocalPizza />,
    LocalPizzaOutlined: <icons.LocalPizzaOutlined />,
    LocalPizzaRounded: <icons.LocalPizzaRounded />,
    LocalPizzaSharp: <icons.LocalPizzaSharp />,
    LocalPizzaTwoTone: <icons.LocalPizzaTwoTone />,
    LocalPlay: <icons.LocalPlay />,
    LocalPlayOutlined: <icons.LocalPlayOutlined />,
    LocalPlayRounded: <icons.LocalPlayRounded />,
    LocalPlaySharp: <icons.LocalPlaySharp />,
    LocalPlayTwoTone: <icons.LocalPlayTwoTone />,
    LocalPolice: <icons.LocalPolice />,
    LocalPoliceOutlined: <icons.LocalPoliceOutlined />,
    LocalPoliceRounded: <icons.LocalPoliceRounded />,
    LocalPoliceSharp: <icons.LocalPoliceSharp />,
    LocalPoliceTwoTone: <icons.LocalPoliceTwoTone />,
    LocalPostOffice: <icons.LocalPostOffice />,
    LocalPostOfficeOutlined: <icons.LocalPostOfficeOutlined />,
    LocalPostOfficeRounded: <icons.LocalPostOfficeRounded />,
    LocalPostOfficeSharp: <icons.LocalPostOfficeSharp />,
    LocalPostOfficeTwoTone: <icons.LocalPostOfficeTwoTone />,
    LocalPrintshop: <icons.LocalPrintshop />,
    LocalPrintshopOutlined: <icons.LocalPrintshopOutlined />,
    LocalPrintshopRounded: <icons.LocalPrintshopRounded />,
    LocalPrintshopSharp: <icons.LocalPrintshopSharp />,
    LocalPrintshopTwoTone: <icons.LocalPrintshopTwoTone />,
    LocalSee: <icons.LocalSee />,
    LocalSeeOutlined: <icons.LocalSeeOutlined />,
    LocalSeeRounded: <icons.LocalSeeRounded />,
    LocalSeeSharp: <icons.LocalSeeSharp />,
    LocalSeeTwoTone: <icons.LocalSeeTwoTone />,
    LocalShipping: <icons.LocalShipping />,
    LocalShippingOutlined: <icons.LocalShippingOutlined />,
    LocalShippingRounded: <icons.LocalShippingRounded />,
    LocalShippingSharp: <icons.LocalShippingSharp />,
    LocalShippingTwoTone: <icons.LocalShippingTwoTone />,
    LocalTaxi: <icons.LocalTaxi />,
    LocalTaxiOutlined: <icons.LocalTaxiOutlined />,
    LocalTaxiRounded: <icons.LocalTaxiRounded />,
    LocalTaxiSharp: <icons.LocalTaxiSharp />,
    LocalTaxiTwoTone: <icons.LocalTaxiTwoTone />,
    LocationCity: <icons.LocationCity />,
    LocationCityOutlined: <icons.LocationCityOutlined />,
    LocationCityRounded: <icons.LocationCityRounded />,
    LocationCitySharp: <icons.LocationCitySharp />,
    LocationCityTwoTone: <icons.LocationCityTwoTone />,
    LocationDisabled: <icons.LocationDisabled />,
    LocationDisabledOutlined: <icons.LocationDisabledOutlined />,
    LocationDisabledRounded: <icons.LocationDisabledRounded />,
    LocationDisabledSharp: <icons.LocationDisabledSharp />,
    LocationDisabledTwoTone: <icons.LocationDisabledTwoTone />,
    LocationOff: <icons.LocationOff />,
    LocationOffOutlined: <icons.LocationOffOutlined />,
    LocationOffRounded: <icons.LocationOffRounded />,
    LocationOffSharp: <icons.LocationOffSharp />,
    LocationOffTwoTone: <icons.LocationOffTwoTone />,
    LocationOn: <icons.LocationOn />,
    LocationOnOutlined: <icons.LocationOnOutlined />,
    LocationOnRounded: <icons.LocationOnRounded />,
    LocationOnSharp: <icons.LocationOnSharp />,
    LocationOnTwoTone: <icons.LocationOnTwoTone />,
    LocationSearching: <icons.LocationSearching />,
    LocationSearchingOutlined: <icons.LocationSearchingOutlined />,
    LocationSearchingRounded: <icons.LocationSearchingRounded />,
    LocationSearchingSharp: <icons.LocationSearchingSharp />,
    LocationSearchingTwoTone: <icons.LocationSearchingTwoTone />,
    Lock: <icons.Lock />,
    LockClock: <icons.LockClock />,
    LockClockOutlined: <icons.LockClockOutlined />,
    LockClockRounded: <icons.LockClockRounded />,
    LockClockSharp: <icons.LockClockSharp />,
    LockClockTwoTone: <icons.LockClockTwoTone />,
    LockOpen: <icons.LockOpen />,
    LockOpenOutlined: <icons.LockOpenOutlined />,
    LockOpenRounded: <icons.LockOpenRounded />,
    LockOpenSharp: <icons.LockOpenSharp />,
    LockOpenTwoTone: <icons.LockOpenTwoTone />,
    LockOutlined: <icons.LockOutlined />,
    LockPerson: <icons.LockPerson />,
    LockPersonOutlined: <icons.LockPersonOutlined />,
    LockPersonRounded: <icons.LockPersonRounded />,
    LockPersonSharp: <icons.LockPersonSharp />,
    LockPersonTwoTone: <icons.LockPersonTwoTone />,
    LockReset: <icons.LockReset />,
    LockResetOutlined: <icons.LockResetOutlined />,
    LockResetRounded: <icons.LockResetRounded />,
    LockResetSharp: <icons.LockResetSharp />,
    LockResetTwoTone: <icons.LockResetTwoTone />,
    LockRounded: <icons.LockRounded />,
    LockSharp: <icons.LockSharp />,
    LockTwoTone: <icons.LockTwoTone />,
    Login: <icons.Login />,
    LoginOutlined: <icons.LoginOutlined />,
    LoginRounded: <icons.LoginRounded />,
    LoginSharp: <icons.LoginSharp />,
    LoginTwoTone: <icons.LoginTwoTone />,
    LogoDev: <icons.LogoDev />,
    LogoDevOutlined: <icons.LogoDevOutlined />,
    LogoDevRounded: <icons.LogoDevRounded />,
    LogoDevSharp: <icons.LogoDevSharp />,
    LogoDevTwoTone: <icons.LogoDevTwoTone />,
    Logout: <icons.Logout />,
    LogoutOutlined: <icons.LogoutOutlined />,
    LogoutRounded: <icons.LogoutRounded />,
    LogoutSharp: <icons.LogoutSharp />,
    LogoutTwoTone: <icons.LogoutTwoTone />,
    Looks: <icons.Looks />,
    Looks3: <icons.Looks3 />,
    Looks3Outlined: <icons.Looks3Outlined />,
    Looks3Rounded: <icons.Looks3Rounded />,
    Looks3Sharp: <icons.Looks3Sharp />,
    Looks3TwoTone: <icons.Looks3TwoTone />,
    Looks4: <icons.Looks4 />,
    Looks4Outlined: <icons.Looks4Outlined />,
    Looks4Rounded: <icons.Looks4Rounded />,
    Looks4Sharp: <icons.Looks4Sharp />,
    Looks4TwoTone: <icons.Looks4TwoTone />,
    Looks5: <icons.Looks5 />,
    Looks5Outlined: <icons.Looks5Outlined />,
    Looks5Rounded: <icons.Looks5Rounded />,
    Looks5Sharp: <icons.Looks5Sharp />,
    Looks5TwoTone: <icons.Looks5TwoTone />,
    Looks6: <icons.Looks6 />,
    Looks6Outlined: <icons.Looks6Outlined />,
    Looks6Rounded: <icons.Looks6Rounded />,
    Looks6Sharp: <icons.Looks6Sharp />,
    Looks6TwoTone: <icons.Looks6TwoTone />,
    LooksOne: <icons.LooksOne />,
    LooksOneOutlined: <icons.LooksOneOutlined />,
    LooksOneRounded: <icons.LooksOneRounded />,
    LooksOneSharp: <icons.LooksOneSharp />,
    LooksOneTwoTone: <icons.LooksOneTwoTone />,
    LooksOutlined: <icons.LooksOutlined />,
    LooksRounded: <icons.LooksRounded />,
    LooksSharp: <icons.LooksSharp />,
    LooksTwo: <icons.LooksTwo />,
    LooksTwoOutlined: <icons.LooksTwoOutlined />,
    LooksTwoRounded: <icons.LooksTwoRounded />,
    LooksTwoSharp: <icons.LooksTwoSharp />,
    LooksTwoTone: <icons.LooksTwoTone />,
    LooksTwoTwoTone: <icons.LooksTwoTwoTone />,
    Loop: <icons.Loop />,
    LoopOutlined: <icons.LoopOutlined />,
    LoopRounded: <icons.LoopRounded />,
    LoopSharp: <icons.LoopSharp />,
    LoopTwoTone: <icons.LoopTwoTone />,
    Loupe: <icons.Loupe />,
    LoupeOutlined: <icons.LoupeOutlined />,
    LoupeRounded: <icons.LoupeRounded />,
    LoupeSharp: <icons.LoupeSharp />,
    LoupeTwoTone: <icons.LoupeTwoTone />,
    LowPriority: <icons.LowPriority />,
    LowPriorityOutlined: <icons.LowPriorityOutlined />,
    LowPriorityRounded: <icons.LowPriorityRounded />,
    LowPrioritySharp: <icons.LowPrioritySharp />,
    LowPriorityTwoTone: <icons.LowPriorityTwoTone />,
    Loyalty: <icons.Loyalty />,
    LoyaltyOutlined: <icons.LoyaltyOutlined />,
    LoyaltyRounded: <icons.LoyaltyRounded />,
    LoyaltySharp: <icons.LoyaltySharp />,
    LoyaltyTwoTone: <icons.LoyaltyTwoTone />,
    LteMobiledata: <icons.LteMobiledata />,
    LteMobiledataOutlined: <icons.LteMobiledataOutlined />,
    LteMobiledataRounded: <icons.LteMobiledataRounded />,
    LteMobiledataSharp: <icons.LteMobiledataSharp />,
    LteMobiledataTwoTone: <icons.LteMobiledataTwoTone />,
    LtePlusMobiledata: <icons.LtePlusMobiledata />,
    LtePlusMobiledataOutlined: <icons.LtePlusMobiledataOutlined />,
    LtePlusMobiledataRounded: <icons.LtePlusMobiledataRounded />,
    LtePlusMobiledataSharp: <icons.LtePlusMobiledataSharp />,
    LtePlusMobiledataTwoTone: <icons.LtePlusMobiledataTwoTone />,
    Luggage: <icons.Luggage />,
    LuggageOutlined: <icons.LuggageOutlined />,
    LuggageRounded: <icons.LuggageRounded />,
    LuggageSharp: <icons.LuggageSharp />,
    LuggageTwoTone: <icons.LuggageTwoTone />,
    LunchDining: <icons.LunchDining />,
    LunchDiningOutlined: <icons.LunchDiningOutlined />,
    LunchDiningRounded: <icons.LunchDiningRounded />,
    LunchDiningSharp: <icons.LunchDiningSharp />,
    LunchDiningTwoTone: <icons.LunchDiningTwoTone />,
    Lyrics: <icons.Lyrics />,
    LyricsOutlined: <icons.LyricsOutlined />,
    LyricsRounded: <icons.LyricsRounded />,
    LyricsSharp: <icons.LyricsSharp />,
    LyricsTwoTone: <icons.LyricsTwoTone />,
    MacroOff: <icons.MacroOff />,
    MacroOffOutlined: <icons.MacroOffOutlined />,
    MacroOffRounded: <icons.MacroOffRounded />,
    MacroOffSharp: <icons.MacroOffSharp />,
    MacroOffTwoTone: <icons.MacroOffTwoTone />,
    Mail: <icons.Mail />,
    MailLock: <icons.MailLock />,
    MailLockOutlined: <icons.MailLockOutlined />,
    MailLockRounded: <icons.MailLockRounded />,
    MailLockSharp: <icons.MailLockSharp />,
    MailLockTwoTone: <icons.MailLockTwoTone />,
    MailOutline: <icons.MailOutline />,
    MailOutlineOutlined: <icons.MailOutlineOutlined />,
    MailOutlineRounded: <icons.MailOutlineRounded />,
    MailOutlineSharp: <icons.MailOutlineSharp />,
    MailOutlineTwoTone: <icons.MailOutlineTwoTone />,
    MailOutlined: <icons.MailOutlined />,
    MailRounded: <icons.MailRounded />,
    MailSharp: <icons.MailSharp />,
    MailTwoTone: <icons.MailTwoTone />,
    Male: <icons.Male />,
    MaleOutlined: <icons.MaleOutlined />,
    MaleRounded: <icons.MaleRounded />,
    MaleSharp: <icons.MaleSharp />,
    MaleTwoTone: <icons.MaleTwoTone />,
    Man: <icons.Man />,
    Man2: <icons.Man2 />,
    Man2Outlined: <icons.Man2Outlined />,
    Man2Rounded: <icons.Man2Rounded />,
    Man2Sharp: <icons.Man2Sharp />,
    Man2TwoTone: <icons.Man2TwoTone />,
    Man3: <icons.Man3 />,
    Man3Outlined: <icons.Man3Outlined />,
    Man3Rounded: <icons.Man3Rounded />,
    Man3Sharp: <icons.Man3Sharp />,
    Man3TwoTone: <icons.Man3TwoTone />,
    Man4: <icons.Man4 />,
    Man4Outlined: <icons.Man4Outlined />,
    Man4Rounded: <icons.Man4Rounded />,
    Man4Sharp: <icons.Man4Sharp />,
    Man4TwoTone: <icons.Man4TwoTone />,
    ManOutlined: <icons.ManOutlined />,
    ManRounded: <icons.ManRounded />,
    ManSharp: <icons.ManSharp />,
    ManTwoTone: <icons.ManTwoTone />,
    ManageAccounts: <icons.ManageAccounts />,
    ManageAccountsOutlined: <icons.ManageAccountsOutlined />,
    ManageAccountsRounded: <icons.ManageAccountsRounded />,
    ManageAccountsSharp: <icons.ManageAccountsSharp />,
    ManageAccountsTwoTone: <icons.ManageAccountsTwoTone />,
    ManageHistory: <icons.ManageHistory />,
    ManageHistoryOutlined: <icons.ManageHistoryOutlined />,
    ManageHistoryRounded: <icons.ManageHistoryRounded />,
    ManageHistorySharp: <icons.ManageHistorySharp />,
    ManageHistoryTwoTone: <icons.ManageHistoryTwoTone />,
    ManageSearch: <icons.ManageSearch />,
    ManageSearchOutlined: <icons.ManageSearchOutlined />,
    ManageSearchRounded: <icons.ManageSearchRounded />,
    ManageSearchSharp: <icons.ManageSearchSharp />,
    ManageSearchTwoTone: <icons.ManageSearchTwoTone />,
    Map: <icons.Map />,
    MapOutlined: <icons.MapOutlined />,
    MapRounded: <icons.MapRounded />,
    MapSharp: <icons.MapSharp />,
    MapTwoTone: <icons.MapTwoTone />,
    MapsHomeWork: <icons.MapsHomeWork />,
    MapsHomeWorkOutlined: <icons.MapsHomeWorkOutlined />,
    MapsHomeWorkRounded: <icons.MapsHomeWorkRounded />,
    MapsHomeWorkSharp: <icons.MapsHomeWorkSharp />,
    MapsHomeWorkTwoTone: <icons.MapsHomeWorkTwoTone />,
    MapsUgc: <icons.MapsUgc />,
    MapsUgcOutlined: <icons.MapsUgcOutlined />,
    MapsUgcRounded: <icons.MapsUgcRounded />,
    MapsUgcSharp: <icons.MapsUgcSharp />,
    MapsUgcTwoTone: <icons.MapsUgcTwoTone />,
    Margin: <icons.Margin />,
    MarginOutlined: <icons.MarginOutlined />,
    MarginRounded: <icons.MarginRounded />,
    MarginSharp: <icons.MarginSharp />,
    MarginTwoTone: <icons.MarginTwoTone />,
    MarkAsUnread: <icons.MarkAsUnread />,
    MarkAsUnreadOutlined: <icons.MarkAsUnreadOutlined />,
    MarkAsUnreadRounded: <icons.MarkAsUnreadRounded />,
    MarkAsUnreadSharp: <icons.MarkAsUnreadSharp />,
    MarkAsUnreadTwoTone: <icons.MarkAsUnreadTwoTone />,
    MarkChatRead: <icons.MarkChatRead />,
    MarkChatReadOutlined: <icons.MarkChatReadOutlined />,
    MarkChatReadRounded: <icons.MarkChatReadRounded />,
    MarkChatReadSharp: <icons.MarkChatReadSharp />,
    MarkChatReadTwoTone: <icons.MarkChatReadTwoTone />,
    MarkChatUnread: <icons.MarkChatUnread />,
    MarkChatUnreadOutlined: <icons.MarkChatUnreadOutlined />,
    MarkChatUnreadRounded: <icons.MarkChatUnreadRounded />,
    MarkChatUnreadSharp: <icons.MarkChatUnreadSharp />,
    MarkChatUnreadTwoTone: <icons.MarkChatUnreadTwoTone />,
    MarkEmailRead: <icons.MarkEmailRead />,
    MarkEmailReadOutlined: <icons.MarkEmailReadOutlined />,
    MarkEmailReadRounded: <icons.MarkEmailReadRounded />,
    MarkEmailReadSharp: <icons.MarkEmailReadSharp />,
    MarkEmailReadTwoTone: <icons.MarkEmailReadTwoTone />,
    MarkEmailUnread: <icons.MarkEmailUnread />,
    MarkEmailUnreadOutlined: <icons.MarkEmailUnreadOutlined />,
    MarkEmailUnreadRounded: <icons.MarkEmailUnreadRounded />,
    MarkEmailUnreadSharp: <icons.MarkEmailUnreadSharp />,
    MarkEmailUnreadTwoTone: <icons.MarkEmailUnreadTwoTone />,
    MarkUnreadChatAlt: <icons.MarkUnreadChatAlt />,
    MarkUnreadChatAltOutlined: <icons.MarkUnreadChatAltOutlined />,
    MarkUnreadChatAltRounded: <icons.MarkUnreadChatAltRounded />,
    MarkUnreadChatAltSharp: <icons.MarkUnreadChatAltSharp />,
    MarkUnreadChatAltTwoTone: <icons.MarkUnreadChatAltTwoTone />,
    Markunread: <icons.Markunread />,
    MarkunreadMailbox: <icons.MarkunreadMailbox />,
    MarkunreadMailboxOutlined: <icons.MarkunreadMailboxOutlined />,
    MarkunreadMailboxRounded: <icons.MarkunreadMailboxRounded />,
    MarkunreadMailboxSharp: <icons.MarkunreadMailboxSharp />,
    MarkunreadMailboxTwoTone: <icons.MarkunreadMailboxTwoTone />,
    MarkunreadOutlined: <icons.MarkunreadOutlined />,
    MarkunreadRounded: <icons.MarkunreadRounded />,
    MarkunreadSharp: <icons.MarkunreadSharp />,
    MarkunreadTwoTone: <icons.MarkunreadTwoTone />,
    Masks: <icons.Masks />,
    MasksOutlined: <icons.MasksOutlined />,
    MasksRounded: <icons.MasksRounded />,
    MasksSharp: <icons.MasksSharp />,
    MasksTwoTone: <icons.MasksTwoTone />,
    Maximize: <icons.Maximize />,
    MaximizeOutlined: <icons.MaximizeOutlined />,
    MaximizeRounded: <icons.MaximizeRounded />,
    MaximizeSharp: <icons.MaximizeSharp />,
    MaximizeTwoTone: <icons.MaximizeTwoTone />,
    MediaBluetoothOff: <icons.MediaBluetoothOff />,
    MediaBluetoothOffOutlined: <icons.MediaBluetoothOffOutlined />,
    MediaBluetoothOffRounded: <icons.MediaBluetoothOffRounded />,
    MediaBluetoothOffSharp: <icons.MediaBluetoothOffSharp />,
    MediaBluetoothOffTwoTone: <icons.MediaBluetoothOffTwoTone />,
    MediaBluetoothOn: <icons.MediaBluetoothOn />,
    MediaBluetoothOnOutlined: <icons.MediaBluetoothOnOutlined />,
    MediaBluetoothOnRounded: <icons.MediaBluetoothOnRounded />,
    MediaBluetoothOnSharp: <icons.MediaBluetoothOnSharp />,
    MediaBluetoothOnTwoTone: <icons.MediaBluetoothOnTwoTone />,
    Mediation: <icons.Mediation />,
    MediationOutlined: <icons.MediationOutlined />,
    MediationRounded: <icons.MediationRounded />,
    MediationSharp: <icons.MediationSharp />,
    MediationTwoTone: <icons.MediationTwoTone />,
    MedicalInformation: <icons.MedicalInformation />,
    MedicalInformationOutlined: <icons.MedicalInformationOutlined />,
    MedicalInformationRounded: <icons.MedicalInformationRounded />,
    MedicalInformationSharp: <icons.MedicalInformationSharp />,
    MedicalInformationTwoTone: <icons.MedicalInformationTwoTone />,
    MedicalServices: <icons.MedicalServices />,
    MedicalServicesOutlined: <icons.MedicalServicesOutlined />,
    MedicalServicesRounded: <icons.MedicalServicesRounded />,
    MedicalServicesSharp: <icons.MedicalServicesSharp />,
    MedicalServicesTwoTone: <icons.MedicalServicesTwoTone />,
    Medication: <icons.Medication />,
    MedicationLiquid: <icons.MedicationLiquid />,
    MedicationLiquidOutlined: <icons.MedicationLiquidOutlined />,
    MedicationLiquidRounded: <icons.MedicationLiquidRounded />,
    MedicationLiquidSharp: <icons.MedicationLiquidSharp />,
    MedicationLiquidTwoTone: <icons.MedicationLiquidTwoTone />,
    MedicationOutlined: <icons.MedicationOutlined />,
    MedicationRounded: <icons.MedicationRounded />,
    MedicationSharp: <icons.MedicationSharp />,
    MedicationTwoTone: <icons.MedicationTwoTone />,
    MeetingRoom: <icons.MeetingRoom />,
    MeetingRoomOutlined: <icons.MeetingRoomOutlined />,
    MeetingRoomRounded: <icons.MeetingRoomRounded />,
    MeetingRoomSharp: <icons.MeetingRoomSharp />,
    MeetingRoomTwoTone: <icons.MeetingRoomTwoTone />,
    Memory: <icons.Memory />,
    MemoryOutlined: <icons.MemoryOutlined />,
    MemoryRounded: <icons.MemoryRounded />,
    MemorySharp: <icons.MemorySharp />,
    MemoryTwoTone: <icons.MemoryTwoTone />,
    Menu: <icons.Menu />,
    MenuBook: <icons.MenuBook />,
    MenuBookOutlined: <icons.MenuBookOutlined />,
    MenuBookRounded: <icons.MenuBookRounded />,
    MenuBookSharp: <icons.MenuBookSharp />,
    MenuBookTwoTone: <icons.MenuBookTwoTone />,
    MenuOpen: <icons.MenuOpen />,
    MenuOpenOutlined: <icons.MenuOpenOutlined />,
    MenuOpenRounded: <icons.MenuOpenRounded />,
    MenuOpenSharp: <icons.MenuOpenSharp />,
    MenuOpenTwoTone: <icons.MenuOpenTwoTone />,
    MenuOutlined: <icons.MenuOutlined />,
    MenuRounded: <icons.MenuRounded />,
    MenuSharp: <icons.MenuSharp />,
    MenuTwoTone: <icons.MenuTwoTone />,
    Merge: <icons.Merge />,
    MergeOutlined: <icons.MergeOutlined />,
    MergeRounded: <icons.MergeRounded />,
    MergeSharp: <icons.MergeSharp />,
    MergeTwoTone: <icons.MergeTwoTone />,
    MergeType: <icons.MergeType />,
    MergeTypeOutlined: <icons.MergeTypeOutlined />,
    MergeTypeRounded: <icons.MergeTypeRounded />,
    MergeTypeSharp: <icons.MergeTypeSharp />,
    MergeTypeTwoTone: <icons.MergeTypeTwoTone />,
    Message: <icons.Message />,
    MessageOutlined: <icons.MessageOutlined />,
    MessageRounded: <icons.MessageRounded />,
    MessageSharp: <icons.MessageSharp />,
    MessageTwoTone: <icons.MessageTwoTone />,
    Mic: <icons.Mic />,
    MicExternalOff: <icons.MicExternalOff />,
    MicExternalOffOutlined: <icons.MicExternalOffOutlined />,
    MicExternalOffRounded: <icons.MicExternalOffRounded />,
    MicExternalOffSharp: <icons.MicExternalOffSharp />,
    MicExternalOffTwoTone: <icons.MicExternalOffTwoTone />,
    MicExternalOn: <icons.MicExternalOn />,
    MicExternalOnOutlined: <icons.MicExternalOnOutlined />,
    MicExternalOnRounded: <icons.MicExternalOnRounded />,
    MicExternalOnSharp: <icons.MicExternalOnSharp />,
    MicExternalOnTwoTone: <icons.MicExternalOnTwoTone />,
    MicNone: <icons.MicNone />,
    MicNoneOutlined: <icons.MicNoneOutlined />,
    MicNoneRounded: <icons.MicNoneRounded />,
    MicNoneSharp: <icons.MicNoneSharp />,
    MicNoneTwoTone: <icons.MicNoneTwoTone />,
    MicOff: <icons.MicOff />,
    MicOffOutlined: <icons.MicOffOutlined />,
    MicOffRounded: <icons.MicOffRounded />,
    MicOffSharp: <icons.MicOffSharp />,
    MicOffTwoTone: <icons.MicOffTwoTone />,
    MicOutlined: <icons.MicOutlined />,
    MicRounded: <icons.MicRounded />,
    MicSharp: <icons.MicSharp />,
    MicTwoTone: <icons.MicTwoTone />,
    Microsoft: <icons.Microsoft />,
    Microwave: <icons.Microwave />,
    MicrowaveOutlined: <icons.MicrowaveOutlined />,
    MicrowaveRounded: <icons.MicrowaveRounded />,
    MicrowaveSharp: <icons.MicrowaveSharp />,
    MicrowaveTwoTone: <icons.MicrowaveTwoTone />,
    MilitaryTech: <icons.MilitaryTech />,
    MilitaryTechOutlined: <icons.MilitaryTechOutlined />,
    MilitaryTechRounded: <icons.MilitaryTechRounded />,
    MilitaryTechSharp: <icons.MilitaryTechSharp />,
    MilitaryTechTwoTone: <icons.MilitaryTechTwoTone />,
    Minimize: <icons.Minimize />,
    MinimizeOutlined: <icons.MinimizeOutlined />,
    MinimizeRounded: <icons.MinimizeRounded />,
    MinimizeSharp: <icons.MinimizeSharp />,
    MinimizeTwoTone: <icons.MinimizeTwoTone />,
    MinorCrash: <icons.MinorCrash />,
    MinorCrashOutlined: <icons.MinorCrashOutlined />,
    MinorCrashRounded: <icons.MinorCrashRounded />,
    MinorCrashSharp: <icons.MinorCrashSharp />,
    MinorCrashTwoTone: <icons.MinorCrashTwoTone />,
    MiscellaneousServices: <icons.MiscellaneousServices />,
    MiscellaneousServicesOutlined: <icons.MiscellaneousServicesOutlined />,
    MiscellaneousServicesRounded: <icons.MiscellaneousServicesRounded />,
    MiscellaneousServicesSharp: <icons.MiscellaneousServicesSharp />,
    MiscellaneousServicesTwoTone: <icons.MiscellaneousServicesTwoTone />,
    MissedVideoCall: <icons.MissedVideoCall />,
    MissedVideoCallOutlined: <icons.MissedVideoCallOutlined />,
    MissedVideoCallRounded: <icons.MissedVideoCallRounded />,
    MissedVideoCallSharp: <icons.MissedVideoCallSharp />,
    MissedVideoCallTwoTone: <icons.MissedVideoCallTwoTone />,
    Mms: <icons.Mms />,
    MmsOutlined: <icons.MmsOutlined />,
    MmsRounded: <icons.MmsRounded />,
    MmsSharp: <icons.MmsSharp />,
    MmsTwoTone: <icons.MmsTwoTone />,
    MobileFriendly: <icons.MobileFriendly />,
    MobileFriendlyOutlined: <icons.MobileFriendlyOutlined />,
    MobileFriendlyRounded: <icons.MobileFriendlyRounded />,
    MobileFriendlySharp: <icons.MobileFriendlySharp />,
    MobileFriendlyTwoTone: <icons.MobileFriendlyTwoTone />,
    MobileOff: <icons.MobileOff />,
    MobileOffOutlined: <icons.MobileOffOutlined />,
    MobileOffRounded: <icons.MobileOffRounded />,
    MobileOffSharp: <icons.MobileOffSharp />,
    MobileOffTwoTone: <icons.MobileOffTwoTone />,
    MobileScreenShare: <icons.MobileScreenShare />,
    MobileScreenShareOutlined: <icons.MobileScreenShareOutlined />,
    MobileScreenShareRounded: <icons.MobileScreenShareRounded />,
    MobileScreenShareSharp: <icons.MobileScreenShareSharp />,
    MobileScreenShareTwoTone: <icons.MobileScreenShareTwoTone />,
    MobiledataOff: <icons.MobiledataOff />,
    MobiledataOffOutlined: <icons.MobiledataOffOutlined />,
    MobiledataOffRounded: <icons.MobiledataOffRounded />,
    MobiledataOffSharp: <icons.MobiledataOffSharp />,
    MobiledataOffTwoTone: <icons.MobiledataOffTwoTone />,
    Mode: <icons.Mode />,
    ModeComment: <icons.ModeComment />,
    ModeCommentOutlined: <icons.ModeCommentOutlined />,
    ModeCommentRounded: <icons.ModeCommentRounded />,
    ModeCommentSharp: <icons.ModeCommentSharp />,
    ModeCommentTwoTone: <icons.ModeCommentTwoTone />,
    ModeEdit: <icons.ModeEdit />,
    ModeEditOutline: <icons.ModeEditOutline />,
    ModeEditOutlineOutlined: <icons.ModeEditOutlineOutlined />,
    ModeEditOutlineRounded: <icons.ModeEditOutlineRounded />,
    ModeEditOutlineSharp: <icons.ModeEditOutlineSharp />,
    ModeEditOutlineTwoTone: <icons.ModeEditOutlineTwoTone />,
    ModeEditOutlined: <icons.ModeEditOutlined />,
    ModeEditRounded: <icons.ModeEditRounded />,
    ModeEditSharp: <icons.ModeEditSharp />,
    ModeEditTwoTone: <icons.ModeEditTwoTone />,
    ModeFanOff: <icons.ModeFanOff />,
    ModeFanOffOutlined: <icons.ModeFanOffOutlined />,
    ModeFanOffRounded: <icons.ModeFanOffRounded />,
    ModeFanOffSharp: <icons.ModeFanOffSharp />,
    ModeFanOffTwoTone: <icons.ModeFanOffTwoTone />,
    ModeNight: <icons.ModeNight />,
    ModeNightOutlined: <icons.ModeNightOutlined />,
    ModeNightRounded: <icons.ModeNightRounded />,
    ModeNightSharp: <icons.ModeNightSharp />,
    ModeNightTwoTone: <icons.ModeNightTwoTone />,
    ModeOfTravel: <icons.ModeOfTravel />,
    ModeOfTravelOutlined: <icons.ModeOfTravelOutlined />,
    ModeOfTravelRounded: <icons.ModeOfTravelRounded />,
    ModeOfTravelSharp: <icons.ModeOfTravelSharp />,
    ModeOfTravelTwoTone: <icons.ModeOfTravelTwoTone />,
    ModeOutlined: <icons.ModeOutlined />,
    ModeRounded: <icons.ModeRounded />,
    ModeSharp: <icons.ModeSharp />,
    ModeStandby: <icons.ModeStandby />,
    ModeStandbyOutlined: <icons.ModeStandbyOutlined />,
    ModeStandbyRounded: <icons.ModeStandbyRounded />,
    ModeStandbySharp: <icons.ModeStandbySharp />,
    ModeStandbyTwoTone: <icons.ModeStandbyTwoTone />,
    ModeTwoTone: <icons.ModeTwoTone />,
    ModelTraining: <icons.ModelTraining />,
    ModelTrainingOutlined: <icons.ModelTrainingOutlined />,
    ModelTrainingRounded: <icons.ModelTrainingRounded />,
    ModelTrainingSharp: <icons.ModelTrainingSharp />,
    ModelTrainingTwoTone: <icons.ModelTrainingTwoTone />,
    MonetizationOn: <icons.MonetizationOn />,
    MonetizationOnOutlined: <icons.MonetizationOnOutlined />,
    MonetizationOnRounded: <icons.MonetizationOnRounded />,
    MonetizationOnSharp: <icons.MonetizationOnSharp />,
    MonetizationOnTwoTone: <icons.MonetizationOnTwoTone />,
    Money: <icons.Money />,
    MoneyOff: <icons.MoneyOff />,
    MoneyOffCsred: <icons.MoneyOffCsred />,
    MoneyOffCsredOutlined: <icons.MoneyOffCsredOutlined />,
    MoneyOffCsredRounded: <icons.MoneyOffCsredRounded />,
    MoneyOffCsredSharp: <icons.MoneyOffCsredSharp />,
    MoneyOffCsredTwoTone: <icons.MoneyOffCsredTwoTone />,
    MoneyOffOutlined: <icons.MoneyOffOutlined />,
    MoneyOffRounded: <icons.MoneyOffRounded />,
    MoneyOffSharp: <icons.MoneyOffSharp />,
    MoneyOffTwoTone: <icons.MoneyOffTwoTone />,
    MoneyOutlined: <icons.MoneyOutlined />,
    MoneyRounded: <icons.MoneyRounded />,
    MoneySharp: <icons.MoneySharp />,
    MoneyTwoTone: <icons.MoneyTwoTone />,
    Monitor: <icons.Monitor />,
    MonitorHeart: <icons.MonitorHeart />,
    MonitorHeartOutlined: <icons.MonitorHeartOutlined />,
    MonitorHeartRounded: <icons.MonitorHeartRounded />,
    MonitorHeartSharp: <icons.MonitorHeartSharp />,
    MonitorHeartTwoTone: <icons.MonitorHeartTwoTone />,
    MonitorOutlined: <icons.MonitorOutlined />,
    MonitorRounded: <icons.MonitorRounded />,
    MonitorSharp: <icons.MonitorSharp />,
    MonitorTwoTone: <icons.MonitorTwoTone />,
    MonitorWeight: <icons.MonitorWeight />,
    MonitorWeightOutlined: <icons.MonitorWeightOutlined />,
    MonitorWeightRounded: <icons.MonitorWeightRounded />,
    MonitorWeightSharp: <icons.MonitorWeightSharp />,
    MonitorWeightTwoTone: <icons.MonitorWeightTwoTone />,
    MonochromePhotos: <icons.MonochromePhotos />,
    MonochromePhotosOutlined: <icons.MonochromePhotosOutlined />,
    MonochromePhotosRounded: <icons.MonochromePhotosRounded />,
    MonochromePhotosSharp: <icons.MonochromePhotosSharp />,
    MonochromePhotosTwoTone: <icons.MonochromePhotosTwoTone />,
    Mood: <icons.Mood />,
    MoodBad: <icons.MoodBad />,
    MoodBadOutlined: <icons.MoodBadOutlined />,
    MoodBadRounded: <icons.MoodBadRounded />,
    MoodBadSharp: <icons.MoodBadSharp />,
    MoodBadTwoTone: <icons.MoodBadTwoTone />,
    MoodOutlined: <icons.MoodOutlined />,
    MoodRounded: <icons.MoodRounded />,
    MoodSharp: <icons.MoodSharp />,
    MoodTwoTone: <icons.MoodTwoTone />,
    Moped: <icons.Moped />,
    MopedOutlined: <icons.MopedOutlined />,
    MopedRounded: <icons.MopedRounded />,
    MopedSharp: <icons.MopedSharp />,
    MopedTwoTone: <icons.MopedTwoTone />,
    More: <icons.More />,
    MoreHoriz: <icons.MoreHoriz />,
    MoreHorizOutlined: <icons.MoreHorizOutlined />,
    MoreHorizRounded: <icons.MoreHorizRounded />,
    MoreHorizSharp: <icons.MoreHorizSharp />,
    MoreHorizTwoTone: <icons.MoreHorizTwoTone />,
    MoreOutlined: <icons.MoreOutlined />,
    MoreRounded: <icons.MoreRounded />,
    MoreSharp: <icons.MoreSharp />,
    MoreTime: <icons.MoreTime />,
    MoreTimeOutlined: <icons.MoreTimeOutlined />,
    MoreTimeRounded: <icons.MoreTimeRounded />,
    MoreTimeSharp: <icons.MoreTimeSharp />,
    MoreTimeTwoTone: <icons.MoreTimeTwoTone />,
    MoreTwoTone: <icons.MoreTwoTone />,
    MoreVert: <icons.MoreVert />,
    MoreVertOutlined: <icons.MoreVertOutlined />,
    MoreVertRounded: <icons.MoreVertRounded />,
    MoreVertSharp: <icons.MoreVertSharp />,
    MoreVertTwoTone: <icons.MoreVertTwoTone />,
    Mosque: <icons.Mosque />,
    MosqueOutlined: <icons.MosqueOutlined />,
    MosqueRounded: <icons.MosqueRounded />,
    MosqueSharp: <icons.MosqueSharp />,
    MosqueTwoTone: <icons.MosqueTwoTone />,
    MotionPhotosAuto: <icons.MotionPhotosAuto />,
    MotionPhotosAutoOutlined: <icons.MotionPhotosAutoOutlined />,
    MotionPhotosAutoRounded: <icons.MotionPhotosAutoRounded />,
    MotionPhotosAutoSharp: <icons.MotionPhotosAutoSharp />,
    MotionPhotosAutoTwoTone: <icons.MotionPhotosAutoTwoTone />,
    MotionPhotosOff: <icons.MotionPhotosOff />,
    MotionPhotosOffOutlined: <icons.MotionPhotosOffOutlined />,
    MotionPhotosOffRounded: <icons.MotionPhotosOffRounded />,
    MotionPhotosOffSharp: <icons.MotionPhotosOffSharp />,
    MotionPhotosOffTwoTone: <icons.MotionPhotosOffTwoTone />,
    Mouse: <icons.Mouse />,
    MouseOutlined: <icons.MouseOutlined />,
    MouseRounded: <icons.MouseRounded />,
    MouseSharp: <icons.MouseSharp />,
    MouseTwoTone: <icons.MouseTwoTone />,
    MoveDown: <icons.MoveDown />,
    MoveDownOutlined: <icons.MoveDownOutlined />,
    MoveDownRounded: <icons.MoveDownRounded />,
    MoveDownSharp: <icons.MoveDownSharp />,
    MoveDownTwoTone: <icons.MoveDownTwoTone />,
    MoveToInbox: <icons.MoveToInbox />,
    MoveToInboxOutlined: <icons.MoveToInboxOutlined />,
    MoveToInboxRounded: <icons.MoveToInboxRounded />,
    MoveToInboxSharp: <icons.MoveToInboxSharp />,
    MoveToInboxTwoTone: <icons.MoveToInboxTwoTone />,
    MoveUp: <icons.MoveUp />,
    MoveUpOutlined: <icons.MoveUpOutlined />,
    MoveUpRounded: <icons.MoveUpRounded />,
    MoveUpSharp: <icons.MoveUpSharp />,
    MoveUpTwoTone: <icons.MoveUpTwoTone />,
    Movie: <icons.Movie />,
    MovieCreation: <icons.MovieCreation />,
    MovieCreationOutlined: <icons.MovieCreationOutlined />,
    MovieCreationRounded: <icons.MovieCreationRounded />,
    MovieCreationSharp: <icons.MovieCreationSharp />,
    MovieCreationTwoTone: <icons.MovieCreationTwoTone />,
    MovieFilter: <icons.MovieFilter />,
    MovieFilterOutlined: <icons.MovieFilterOutlined />,
    MovieFilterRounded: <icons.MovieFilterRounded />,
    MovieFilterSharp: <icons.MovieFilterSharp />,
    MovieFilterTwoTone: <icons.MovieFilterTwoTone />,
    MovieOutlined: <icons.MovieOutlined />,
    MovieRounded: <icons.MovieRounded />,
    MovieSharp: <icons.MovieSharp />,
    MovieTwoTone: <icons.MovieTwoTone />,
    Moving: <icons.Moving />,
    MovingOutlined: <icons.MovingOutlined />,
    MovingRounded: <icons.MovingRounded />,
    MovingSharp: <icons.MovingSharp />,
    MovingTwoTone: <icons.MovingTwoTone />,
    Mp: <icons.Mp />,
    MpOutlined: <icons.MpOutlined />,
    MpRounded: <icons.MpRounded />,
    MpSharp: <icons.MpSharp />,
    MpTwoTone: <icons.MpTwoTone />,
    MultilineChart: <icons.MultilineChart />,
    MultilineChartOutlined: <icons.MultilineChartOutlined />,
    MultilineChartRounded: <icons.MultilineChartRounded />,
    MultilineChartSharp: <icons.MultilineChartSharp />,
    MultilineChartTwoTone: <icons.MultilineChartTwoTone />,
    MultipleStop: <icons.MultipleStop />,
    MultipleStopOutlined: <icons.MultipleStopOutlined />,
    MultipleStopRounded: <icons.MultipleStopRounded />,
    MultipleStopSharp: <icons.MultipleStopSharp />,
    MultipleStopTwoTone: <icons.MultipleStopTwoTone />,
    Museum: <icons.Museum />,
    MuseumOutlined: <icons.MuseumOutlined />,
    MuseumRounded: <icons.MuseumRounded />,
    MuseumSharp: <icons.MuseumSharp />,
    MuseumTwoTone: <icons.MuseumTwoTone />,
    MusicNote: <icons.MusicNote />,
    MusicNoteOutlined: <icons.MusicNoteOutlined />,
    MusicNoteRounded: <icons.MusicNoteRounded />,
    MusicNoteSharp: <icons.MusicNoteSharp />,
    MusicNoteTwoTone: <icons.MusicNoteTwoTone />,
    MusicOff: <icons.MusicOff />,
    MusicOffOutlined: <icons.MusicOffOutlined />,
    MusicOffRounded: <icons.MusicOffRounded />,
    MusicOffSharp: <icons.MusicOffSharp />,
    MusicOffTwoTone: <icons.MusicOffTwoTone />,
    MusicVideo: <icons.MusicVideo />,
    MusicVideoOutlined: <icons.MusicVideoOutlined />,
    MusicVideoRounded: <icons.MusicVideoRounded />,
    MusicVideoSharp: <icons.MusicVideoSharp />,
    MusicVideoTwoTone: <icons.MusicVideoTwoTone />,
    MyLocation: <icons.MyLocation />,
    MyLocationOutlined: <icons.MyLocationOutlined />,
    MyLocationRounded: <icons.MyLocationRounded />,
    MyLocationSharp: <icons.MyLocationSharp />,
    MyLocationTwoTone: <icons.MyLocationTwoTone />,
    Nat: <icons.Nat />,
    NatOutlined: <icons.NatOutlined />,
    NatRounded: <icons.NatRounded />,
    NatSharp: <icons.NatSharp />,
    NatTwoTone: <icons.NatTwoTone />,
    Nature: <icons.Nature />,
    NatureOutlined: <icons.NatureOutlined />,
    NaturePeople: <icons.NaturePeople />,
    NaturePeopleOutlined: <icons.NaturePeopleOutlined />,
    NaturePeopleRounded: <icons.NaturePeopleRounded />,
    NaturePeopleSharp: <icons.NaturePeopleSharp />,
    NaturePeopleTwoTone: <icons.NaturePeopleTwoTone />,
    NatureRounded: <icons.NatureRounded />,
    NatureSharp: <icons.NatureSharp />,
    NatureTwoTone: <icons.NatureTwoTone />,
    NavigateBefore: <icons.NavigateBefore />,
    NavigateBeforeOutlined: <icons.NavigateBeforeOutlined />,
    NavigateBeforeRounded: <icons.NavigateBeforeRounded />,
    NavigateBeforeSharp: <icons.NavigateBeforeSharp />,
    NavigateBeforeTwoTone: <icons.NavigateBeforeTwoTone />,
    NavigateNext: <icons.NavigateNext />,
    NavigateNextOutlined: <icons.NavigateNextOutlined />,
    NavigateNextRounded: <icons.NavigateNextRounded />,
    NavigateNextSharp: <icons.NavigateNextSharp />,
    NavigateNextTwoTone: <icons.NavigateNextTwoTone />,
    Navigation: <icons.Navigation />,
    NavigationOutlined: <icons.NavigationOutlined />,
    NavigationRounded: <icons.NavigationRounded />,
    NavigationSharp: <icons.NavigationSharp />,
    NavigationTwoTone: <icons.NavigationTwoTone />,
    NearMe: <icons.NearMe />,
    NearMeDisabled: <icons.NearMeDisabled />,
    NearMeDisabledOutlined: <icons.NearMeDisabledOutlined />,
    NearMeDisabledRounded: <icons.NearMeDisabledRounded />,
    NearMeDisabledSharp: <icons.NearMeDisabledSharp />,
    NearMeDisabledTwoTone: <icons.NearMeDisabledTwoTone />,
    NearMeOutlined: <icons.NearMeOutlined />,
    NearMeRounded: <icons.NearMeRounded />,
    NearMeSharp: <icons.NearMeSharp />,
    NearMeTwoTone: <icons.NearMeTwoTone />,
    NearbyError: <icons.NearbyError />,
    NearbyErrorOutlined: <icons.NearbyErrorOutlined />,
    NearbyErrorRounded: <icons.NearbyErrorRounded />,
    NearbyErrorSharp: <icons.NearbyErrorSharp />,
    NearbyErrorTwoTone: <icons.NearbyErrorTwoTone />,
    NearbyOff: <icons.NearbyOff />,
    NearbyOffOutlined: <icons.NearbyOffOutlined />,
    NearbyOffRounded: <icons.NearbyOffRounded />,
    NearbyOffSharp: <icons.NearbyOffSharp />,
    NearbyOffTwoTone: <icons.NearbyOffTwoTone />,
    NestCamWiredStand: <icons.NestCamWiredStand />,
    NestCamWiredStandOutlined: <icons.NestCamWiredStandOutlined />,
    NestCamWiredStandRounded: <icons.NestCamWiredStandRounded />,
    NestCamWiredStandSharp: <icons.NestCamWiredStandSharp />,
    NestCamWiredStandTwoTone: <icons.NestCamWiredStandTwoTone />,
    NetworkCell: <icons.NetworkCell />,
    NetworkCellOutlined: <icons.NetworkCellOutlined />,
    NetworkCellRounded: <icons.NetworkCellRounded />,
    NetworkCellSharp: <icons.NetworkCellSharp />,
    NetworkCellTwoTone: <icons.NetworkCellTwoTone />,
    NetworkCheck: <icons.NetworkCheck />,
    NetworkCheckOutlined: <icons.NetworkCheckOutlined />,
    NetworkCheckRounded: <icons.NetworkCheckRounded />,
    NetworkCheckSharp: <icons.NetworkCheckSharp />,
    NetworkCheckTwoTone: <icons.NetworkCheckTwoTone />,
    NetworkLocked: <icons.NetworkLocked />,
    NetworkLockedOutlined: <icons.NetworkLockedOutlined />,
    NetworkLockedRounded: <icons.NetworkLockedRounded />,
    NetworkLockedSharp: <icons.NetworkLockedSharp />,
    NetworkLockedTwoTone: <icons.NetworkLockedTwoTone />,
    NetworkPing: <icons.NetworkPing />,
    NetworkPingOutlined: <icons.NetworkPingOutlined />,
    NetworkPingRounded: <icons.NetworkPingRounded />,
    NetworkPingSharp: <icons.NetworkPingSharp />,
    NetworkPingTwoTone: <icons.NetworkPingTwoTone />,
    NetworkWifi: <icons.NetworkWifi />,
    NetworkWifi1Bar: <icons.NetworkWifi1Bar />,
    NetworkWifi1BarOutlined: <icons.NetworkWifi1BarOutlined />,
    NetworkWifi1BarRounded: <icons.NetworkWifi1BarRounded />,
    NetworkWifi1BarSharp: <icons.NetworkWifi1BarSharp />,
    NetworkWifi1BarTwoTone: <icons.NetworkWifi1BarTwoTone />,
    NetworkWifi2Bar: <icons.NetworkWifi2Bar />,
    NetworkWifi2BarOutlined: <icons.NetworkWifi2BarOutlined />,
    NetworkWifi2BarRounded: <icons.NetworkWifi2BarRounded />,
    NetworkWifi2BarSharp: <icons.NetworkWifi2BarSharp />,
    NetworkWifi2BarTwoTone: <icons.NetworkWifi2BarTwoTone />,
    NetworkWifi3Bar: <icons.NetworkWifi3Bar />,
    NetworkWifi3BarOutlined: <icons.NetworkWifi3BarOutlined />,
    NetworkWifi3BarRounded: <icons.NetworkWifi3BarRounded />,
    NetworkWifi3BarSharp: <icons.NetworkWifi3BarSharp />,
    NetworkWifi3BarTwoTone: <icons.NetworkWifi3BarTwoTone />,
    NetworkWifiOutlined: <icons.NetworkWifiOutlined />,
    NetworkWifiRounded: <icons.NetworkWifiRounded />,
    NetworkWifiSharp: <icons.NetworkWifiSharp />,
    NetworkWifiTwoTone: <icons.NetworkWifiTwoTone />,
    NewReleases: <icons.NewReleases />,
    NewReleasesOutlined: <icons.NewReleasesOutlined />,
    NewReleasesRounded: <icons.NewReleasesRounded />,
    NewReleasesSharp: <icons.NewReleasesSharp />,
    NewReleasesTwoTone: <icons.NewReleasesTwoTone />,
    Newspaper: <icons.Newspaper />,
    NewspaperOutlined: <icons.NewspaperOutlined />,
    NewspaperRounded: <icons.NewspaperRounded />,
    NewspaperSharp: <icons.NewspaperSharp />,
    NewspaperTwoTone: <icons.NewspaperTwoTone />,
    NextPlan: <icons.NextPlan />,
    NextPlanOutlined: <icons.NextPlanOutlined />,
    NextPlanRounded: <icons.NextPlanRounded />,
    NextPlanSharp: <icons.NextPlanSharp />,
    NextPlanTwoTone: <icons.NextPlanTwoTone />,
    NextWeek: <icons.NextWeek />,
    NextWeekOutlined: <icons.NextWeekOutlined />,
    NextWeekRounded: <icons.NextWeekRounded />,
    NextWeekSharp: <icons.NextWeekSharp />,
    NextWeekTwoTone: <icons.NextWeekTwoTone />,
    Nfc: <icons.Nfc />,
    NfcOutlined: <icons.NfcOutlined />,
    NfcRounded: <icons.NfcRounded />,
    NfcSharp: <icons.NfcSharp />,
    NfcTwoTone: <icons.NfcTwoTone />,
    NightShelter: <icons.NightShelter />,
    NightShelterOutlined: <icons.NightShelterOutlined />,
    NightShelterRounded: <icons.NightShelterRounded />,
    NightShelterSharp: <icons.NightShelterSharp />,
    NightShelterTwoTone: <icons.NightShelterTwoTone />,
    Nightlife: <icons.Nightlife />,
    NightlifeOutlined: <icons.NightlifeOutlined />,
    NightlifeRounded: <icons.NightlifeRounded />,
    NightlifeSharp: <icons.NightlifeSharp />,
    NightlifeTwoTone: <icons.NightlifeTwoTone />,
    Nightlight: <icons.Nightlight />,
    NightlightOutlined: <icons.NightlightOutlined />,
    NightlightRound: <icons.NightlightRound />,
    NightlightRoundOutlined: <icons.NightlightRoundOutlined />,
    NightlightRoundRounded: <icons.NightlightRoundRounded />,
    NightlightRoundSharp: <icons.NightlightRoundSharp />,
    NightlightRoundTwoTone: <icons.NightlightRoundTwoTone />,
    NightlightRounded: <icons.NightlightRounded />,
    NightlightSharp: <icons.NightlightSharp />,
    NightlightTwoTone: <icons.NightlightTwoTone />,
    NightsStay: <icons.NightsStay />,
    NightsStayOutlined: <icons.NightsStayOutlined />,
    NightsStayRounded: <icons.NightsStayRounded />,
    NightsStaySharp: <icons.NightsStaySharp />,
    NightsStayTwoTone: <icons.NightsStayTwoTone />,
    NineK: <icons.NineK />,
    NineKOutlined: <icons.NineKOutlined />,
    NineKPlus: <icons.NineKPlus />,
    NineKPlusOutlined: <icons.NineKPlusOutlined />,
    NineKPlusRounded: <icons.NineKPlusRounded />,
    NineKPlusSharp: <icons.NineKPlusSharp />,
    NineKPlusTwoTone: <icons.NineKPlusTwoTone />,
    NineKRounded: <icons.NineKRounded />,
    NineKSharp: <icons.NineKSharp />,
    NineKTwoTone: <icons.NineKTwoTone />,
    NineMp: <icons.NineMp />,
    NineMpOutlined: <icons.NineMpOutlined />,
    NineMpRounded: <icons.NineMpRounded />,
    NineMpSharp: <icons.NineMpSharp />,
    NineMpTwoTone: <icons.NineMpTwoTone />,
    NineteenMp: <icons.NineteenMp />,
    NineteenMpOutlined: <icons.NineteenMpOutlined />,
    NineteenMpRounded: <icons.NineteenMpRounded />,
    NineteenMpSharp: <icons.NineteenMpSharp />,
    NineteenMpTwoTone: <icons.NineteenMpTwoTone />,
    NoAccounts: <icons.NoAccounts />,
    NoAccountsOutlined: <icons.NoAccountsOutlined />,
    NoAccountsRounded: <icons.NoAccountsRounded />,
    NoAccountsSharp: <icons.NoAccountsSharp />,
    NoAccountsTwoTone: <icons.NoAccountsTwoTone />,
    NoAdultContent: <icons.NoAdultContent />,
    NoAdultContentOutlined: <icons.NoAdultContentOutlined />,
    NoAdultContentRounded: <icons.NoAdultContentRounded />,
    NoAdultContentSharp: <icons.NoAdultContentSharp />,
    NoAdultContentTwoTone: <icons.NoAdultContentTwoTone />,
    NoBackpack: <icons.NoBackpack />,
    NoBackpackOutlined: <icons.NoBackpackOutlined />,
    NoBackpackRounded: <icons.NoBackpackRounded />,
    NoBackpackSharp: <icons.NoBackpackSharp />,
    NoBackpackTwoTone: <icons.NoBackpackTwoTone />,
    NoCell: <icons.NoCell />,
    NoCellOutlined: <icons.NoCellOutlined />,
    NoCellRounded: <icons.NoCellRounded />,
    NoCellSharp: <icons.NoCellSharp />,
    NoCellTwoTone: <icons.NoCellTwoTone />,
    NoCrash: <icons.NoCrash />,
    NoCrashOutlined: <icons.NoCrashOutlined />,
    NoCrashRounded: <icons.NoCrashRounded />,
    NoCrashSharp: <icons.NoCrashSharp />,
    NoCrashTwoTone: <icons.NoCrashTwoTone />,
    NoDrinks: <icons.NoDrinks />,
    NoDrinksOutlined: <icons.NoDrinksOutlined />,
    NoDrinksRounded: <icons.NoDrinksRounded />,
    NoDrinksSharp: <icons.NoDrinksSharp />,
    NoDrinksTwoTone: <icons.NoDrinksTwoTone />,
    NoEncryption: <icons.NoEncryption />,
    NoEncryptionGmailerrorred: <icons.NoEncryptionGmailerrorred />,
    NoEncryptionGmailerrorredOutlined: (
        <icons.NoEncryptionGmailerrorredOutlined />
    ),
    NoEncryptionGmailerrorredRounded: (
        <icons.NoEncryptionGmailerrorredRounded />
    ),
    NoEncryptionGmailerrorredSharp: <icons.NoEncryptionGmailerrorredSharp />,
    NoEncryptionGmailerrorredTwoTone: (
        <icons.NoEncryptionGmailerrorredTwoTone />
    ),
    NoEncryptionOutlined: <icons.NoEncryptionOutlined />,
    NoEncryptionRounded: <icons.NoEncryptionRounded />,
    NoEncryptionSharp: <icons.NoEncryptionSharp />,
    NoEncryptionTwoTone: <icons.NoEncryptionTwoTone />,
    NoFlash: <icons.NoFlash />,
    NoFlashOutlined: <icons.NoFlashOutlined />,
    NoFlashRounded: <icons.NoFlashRounded />,
    NoFlashSharp: <icons.NoFlashSharp />,
    NoFlashTwoTone: <icons.NoFlashTwoTone />,
    NoFood: <icons.NoFood />,
    NoFoodOutlined: <icons.NoFoodOutlined />,
    NoFoodRounded: <icons.NoFoodRounded />,
    NoFoodSharp: <icons.NoFoodSharp />,
    NoFoodTwoTone: <icons.NoFoodTwoTone />,
    NoLuggage: <icons.NoLuggage />,
    NoLuggageOutlined: <icons.NoLuggageOutlined />,
    NoLuggageRounded: <icons.NoLuggageRounded />,
    NoLuggageSharp: <icons.NoLuggageSharp />,
    NoLuggageTwoTone: <icons.NoLuggageTwoTone />,
    NoMeals: <icons.NoMeals />,
    NoMealsOutlined: <icons.NoMealsOutlined />,
    NoMealsRounded: <icons.NoMealsRounded />,
    NoMealsSharp: <icons.NoMealsSharp />,
    NoMealsTwoTone: <icons.NoMealsTwoTone />,
    NoMeetingRoom: <icons.NoMeetingRoom />,
    NoMeetingRoomOutlined: <icons.NoMeetingRoomOutlined />,
    NoMeetingRoomRounded: <icons.NoMeetingRoomRounded />,
    NoMeetingRoomSharp: <icons.NoMeetingRoomSharp />,
    NoMeetingRoomTwoTone: <icons.NoMeetingRoomTwoTone />,
    NoPhotography: <icons.NoPhotography />,
    NoPhotographyOutlined: <icons.NoPhotographyOutlined />,
    NoPhotographyRounded: <icons.NoPhotographyRounded />,
    NoPhotographySharp: <icons.NoPhotographySharp />,
    NoPhotographyTwoTone: <icons.NoPhotographyTwoTone />,
    NoSim: <icons.NoSim />,
    NoSimOutlined: <icons.NoSimOutlined />,
    NoSimRounded: <icons.NoSimRounded />,
    NoSimSharp: <icons.NoSimSharp />,
    NoSimTwoTone: <icons.NoSimTwoTone />,
    NoStroller: <icons.NoStroller />,
    NoStrollerOutlined: <icons.NoStrollerOutlined />,
    NoStrollerRounded: <icons.NoStrollerRounded />,
    NoStrollerSharp: <icons.NoStrollerSharp />,
    NoStrollerTwoTone: <icons.NoStrollerTwoTone />,
    NoTransfer: <icons.NoTransfer />,
    NoTransferOutlined: <icons.NoTransferOutlined />,
    NoTransferRounded: <icons.NoTransferRounded />,
    NoTransferSharp: <icons.NoTransferSharp />,
    NoTransferTwoTone: <icons.NoTransferTwoTone />,
    NoiseAware: <icons.NoiseAware />,
    NoiseAwareOutlined: <icons.NoiseAwareOutlined />,
    NoiseAwareRounded: <icons.NoiseAwareRounded />,
    NoiseAwareSharp: <icons.NoiseAwareSharp />,
    NoiseAwareTwoTone: <icons.NoiseAwareTwoTone />,
    NoiseControlOff: <icons.NoiseControlOff />,
    NoiseControlOffOutlined: <icons.NoiseControlOffOutlined />,
    NoiseControlOffRounded: <icons.NoiseControlOffRounded />,
    NoiseControlOffSharp: <icons.NoiseControlOffSharp />,
    NoiseControlOffTwoTone: <icons.NoiseControlOffTwoTone />,
    NordicWalking: <icons.NordicWalking />,
    NordicWalkingOutlined: <icons.NordicWalkingOutlined />,
    NordicWalkingRounded: <icons.NordicWalkingRounded />,
    NordicWalkingSharp: <icons.NordicWalkingSharp />,
    NordicWalkingTwoTone: <icons.NordicWalkingTwoTone />,
    North: <icons.North />,
    NorthEast: <icons.NorthEast />,
    NorthEastOutlined: <icons.NorthEastOutlined />,
    NorthEastRounded: <icons.NorthEastRounded />,
    NorthEastSharp: <icons.NorthEastSharp />,
    NorthEastTwoTone: <icons.NorthEastTwoTone />,
    NorthOutlined: <icons.NorthOutlined />,
    NorthRounded: <icons.NorthRounded />,
    NorthSharp: <icons.NorthSharp />,
    NorthTwoTone: <icons.NorthTwoTone />,
    NorthWest: <icons.NorthWest />,
    NorthWestOutlined: <icons.NorthWestOutlined />,
    NorthWestRounded: <icons.NorthWestRounded />,
    NorthWestSharp: <icons.NorthWestSharp />,
    NorthWestTwoTone: <icons.NorthWestTwoTone />,
    NotAccessible: <icons.NotAccessible />,
    NotAccessibleOutlined: <icons.NotAccessibleOutlined />,
    NotAccessibleRounded: <icons.NotAccessibleRounded />,
    NotAccessibleSharp: <icons.NotAccessibleSharp />,
    NotAccessibleTwoTone: <icons.NotAccessibleTwoTone />,
    NotInterested: <icons.NotInterested />,
    NotInterestedOutlined: <icons.NotInterestedOutlined />,
    NotInterestedRounded: <icons.NotInterestedRounded />,
    NotInterestedSharp: <icons.NotInterestedSharp />,
    NotInterestedTwoTone: <icons.NotInterestedTwoTone />,
    NotListedLocation: <icons.NotListedLocation />,
    NotListedLocationOutlined: <icons.NotListedLocationOutlined />,
    NotListedLocationRounded: <icons.NotListedLocationRounded />,
    NotListedLocationSharp: <icons.NotListedLocationSharp />,
    NotListedLocationTwoTone: <icons.NotListedLocationTwoTone />,
    NotStarted: <icons.NotStarted />,
    NotStartedOutlined: <icons.NotStartedOutlined />,
    NotStartedRounded: <icons.NotStartedRounded />,
    NotStartedSharp: <icons.NotStartedSharp />,
    NotStartedTwoTone: <icons.NotStartedTwoTone />,
    Note: <icons.Note />,
    NoteAdd: <icons.NoteAdd />,
    NoteAddOutlined: <icons.NoteAddOutlined />,
    NoteAddRounded: <icons.NoteAddRounded />,
    NoteAddSharp: <icons.NoteAddSharp />,
    NoteAddTwoTone: <icons.NoteAddTwoTone />,
    NoteAlt: <icons.NoteAlt />,
    NoteAltOutlined: <icons.NoteAltOutlined />,
    NoteAltRounded: <icons.NoteAltRounded />,
    NoteAltSharp: <icons.NoteAltSharp />,
    NoteAltTwoTone: <icons.NoteAltTwoTone />,
    NoteOutlined: <icons.NoteOutlined />,
    NoteRounded: <icons.NoteRounded />,
    NoteSharp: <icons.NoteSharp />,
    NoteTwoTone: <icons.NoteTwoTone />,
    Notes: <icons.Notes />,
    NotesOutlined: <icons.NotesOutlined />,
    NotesRounded: <icons.NotesRounded />,
    NotesSharp: <icons.NotesSharp />,
    NotesTwoTone: <icons.NotesTwoTone />,
    NotificationAdd: <icons.NotificationAdd />,
    NotificationAddOutlined: <icons.NotificationAddOutlined />,
    NotificationAddRounded: <icons.NotificationAddRounded />,
    NotificationAddSharp: <icons.NotificationAddSharp />,
    NotificationAddTwoTone: <icons.NotificationAddTwoTone />,
    NotificationImportant: <icons.NotificationImportant />,
    NotificationImportantOutlined: <icons.NotificationImportantOutlined />,
    NotificationImportantRounded: <icons.NotificationImportantRounded />,
    NotificationImportantSharp: <icons.NotificationImportantSharp />,
    NotificationImportantTwoTone: <icons.NotificationImportantTwoTone />,
    Notifications: <icons.Notifications />,
    NotificationsActive: <icons.NotificationsActive />,
    NotificationsActiveOutlined: <icons.NotificationsActiveOutlined />,
    NotificationsActiveRounded: <icons.NotificationsActiveRounded />,
    NotificationsActiveSharp: <icons.NotificationsActiveSharp />,
    NotificationsActiveTwoTone: <icons.NotificationsActiveTwoTone />,
    NotificationsNone: <icons.NotificationsNone />,
    NotificationsNoneOutlined: <icons.NotificationsNoneOutlined />,
    NotificationsNoneRounded: <icons.NotificationsNoneRounded />,
    NotificationsNoneSharp: <icons.NotificationsNoneSharp />,
    NotificationsNoneTwoTone: <icons.NotificationsNoneTwoTone />,
    NotificationsOff: <icons.NotificationsOff />,
    NotificationsOffOutlined: <icons.NotificationsOffOutlined />,
    NotificationsOffRounded: <icons.NotificationsOffRounded />,
    NotificationsOffSharp: <icons.NotificationsOffSharp />,
    NotificationsOffTwoTone: <icons.NotificationsOffTwoTone />,
    NotificationsOutlined: <icons.NotificationsOutlined />,
    NotificationsPaused: <icons.NotificationsPaused />,
    NotificationsPausedOutlined: <icons.NotificationsPausedOutlined />,
    NotificationsPausedRounded: <icons.NotificationsPausedRounded />,
    NotificationsPausedSharp: <icons.NotificationsPausedSharp />,
    NotificationsPausedTwoTone: <icons.NotificationsPausedTwoTone />,
    NotificationsRounded: <icons.NotificationsRounded />,
    NotificationsSharp: <icons.NotificationsSharp />,
    NotificationsTwoTone: <icons.NotificationsTwoTone />,
    Numbers: <icons.Numbers />,
    NumbersOutlined: <icons.NumbersOutlined />,
    NumbersRounded: <icons.NumbersRounded />,
    NumbersSharp: <icons.NumbersSharp />,
    NumbersTwoTone: <icons.NumbersTwoTone />,
    OfflineBolt: <icons.OfflineBolt />,
    OfflineBoltOutlined: <icons.OfflineBoltOutlined />,
    OfflineBoltRounded: <icons.OfflineBoltRounded />,
    OfflineBoltSharp: <icons.OfflineBoltSharp />,
    OfflineBoltTwoTone: <icons.OfflineBoltTwoTone />,
    OfflinePin: <icons.OfflinePin />,
    OfflinePinOutlined: <icons.OfflinePinOutlined />,
    OfflinePinRounded: <icons.OfflinePinRounded />,
    OfflinePinSharp: <icons.OfflinePinSharp />,
    OfflinePinTwoTone: <icons.OfflinePinTwoTone />,
    OfflineShare: <icons.OfflineShare />,
    OfflineShareOutlined: <icons.OfflineShareOutlined />,
    OfflineShareRounded: <icons.OfflineShareRounded />,
    OfflineShareSharp: <icons.OfflineShareSharp />,
    OfflineShareTwoTone: <icons.OfflineShareTwoTone />,
    OilBarrel: <icons.OilBarrel />,
    OilBarrelOutlined: <icons.OilBarrelOutlined />,
    OilBarrelRounded: <icons.OilBarrelRounded />,
    OilBarrelSharp: <icons.OilBarrelSharp />,
    OilBarrelTwoTone: <icons.OilBarrelTwoTone />,
    OnDeviceTraining: <icons.OnDeviceTraining />,
    OnDeviceTrainingOutlined: <icons.OnDeviceTrainingOutlined />,
    OnDeviceTrainingRounded: <icons.OnDeviceTrainingRounded />,
    OnDeviceTrainingSharp: <icons.OnDeviceTrainingSharp />,
    OnDeviceTrainingTwoTone: <icons.OnDeviceTrainingTwoTone />,
    OndemandVideo: <icons.OndemandVideo />,
    OndemandVideoOutlined: <icons.OndemandVideoOutlined />,
    OndemandVideoRounded: <icons.OndemandVideoRounded />,
    OndemandVideoSharp: <icons.OndemandVideoSharp />,
    OndemandVideoTwoTone: <icons.OndemandVideoTwoTone />,
    OneK: <icons.OneK />,
    OneKOutlined: <icons.OneKOutlined />,
    OneKPlus: <icons.OneKPlus />,
    OneKPlusOutlined: <icons.OneKPlusOutlined />,
    OneKPlusRounded: <icons.OneKPlusRounded />,
    OneKPlusSharp: <icons.OneKPlusSharp />,
    OneKPlusTwoTone: <icons.OneKPlusTwoTone />,
    OneKRounded: <icons.OneKRounded />,
    OneKSharp: <icons.OneKSharp />,
    OneKTwoTone: <icons.OneKTwoTone />,
    OneKk: <icons.OneKk />,
    OneKkOutlined: <icons.OneKkOutlined />,
    OneKkRounded: <icons.OneKkRounded />,
    OneKkSharp: <icons.OneKkSharp />,
    OneKkTwoTone: <icons.OneKkTwoTone />,
    OnlinePrediction: <icons.OnlinePrediction />,
    OnlinePredictionOutlined: <icons.OnlinePredictionOutlined />,
    OnlinePredictionRounded: <icons.OnlinePredictionRounded />,
    OnlinePredictionSharp: <icons.OnlinePredictionSharp />,
    OnlinePredictionTwoTone: <icons.OnlinePredictionTwoTone />,
    Opacity: <icons.Opacity />,
    OpacityOutlined: <icons.OpacityOutlined />,
    OpacityRounded: <icons.OpacityRounded />,
    OpacitySharp: <icons.OpacitySharp />,
    OpacityTwoTone: <icons.OpacityTwoTone />,
    OpenInBrowser: <icons.OpenInBrowser />,
    OpenInBrowserOutlined: <icons.OpenInBrowserOutlined />,
    OpenInBrowserRounded: <icons.OpenInBrowserRounded />,
    OpenInBrowserSharp: <icons.OpenInBrowserSharp />,
    OpenInBrowserTwoTone: <icons.OpenInBrowserTwoTone />,
    OpenInFull: <icons.OpenInFull />,
    OpenInFullOutlined: <icons.OpenInFullOutlined />,
    OpenInFullRounded: <icons.OpenInFullRounded />,
    OpenInFullSharp: <icons.OpenInFullSharp />,
    OpenInFullTwoTone: <icons.OpenInFullTwoTone />,
    OpenInNew: <icons.OpenInNew />,
    OpenInNewOff: <icons.OpenInNewOff />,
    OpenInNewOffOutlined: <icons.OpenInNewOffOutlined />,
    OpenInNewOffRounded: <icons.OpenInNewOffRounded />,
    OpenInNewOffSharp: <icons.OpenInNewOffSharp />,
    OpenInNewOffTwoTone: <icons.OpenInNewOffTwoTone />,
    OpenInNewOutlined: <icons.OpenInNewOutlined />,
    OpenInNewRounded: <icons.OpenInNewRounded />,
    OpenInNewSharp: <icons.OpenInNewSharp />,
    OpenInNewTwoTone: <icons.OpenInNewTwoTone />,
    OpenWith: <icons.OpenWith />,
    OpenWithOutlined: <icons.OpenWithOutlined />,
    OpenWithRounded: <icons.OpenWithRounded />,
    OpenWithSharp: <icons.OpenWithSharp />,
    OpenWithTwoTone: <icons.OpenWithTwoTone />,
    OtherHouses: <icons.OtherHouses />,
    OtherHousesOutlined: <icons.OtherHousesOutlined />,
    OtherHousesRounded: <icons.OtherHousesRounded />,
    OtherHousesSharp: <icons.OtherHousesSharp />,
    OtherHousesTwoTone: <icons.OtherHousesTwoTone />,
    Outbound: <icons.Outbound />,
    OutboundOutlined: <icons.OutboundOutlined />,
    OutboundRounded: <icons.OutboundRounded />,
    OutboundSharp: <icons.OutboundSharp />,
    OutboundTwoTone: <icons.OutboundTwoTone />,
    Outbox: <icons.Outbox />,
    OutboxOutlined: <icons.OutboxOutlined />,
    OutboxRounded: <icons.OutboxRounded />,
    OutboxSharp: <icons.OutboxSharp />,
    OutboxTwoTone: <icons.OutboxTwoTone />,
    OutdoorGrill: <icons.OutdoorGrill />,
    OutdoorGrillOutlined: <icons.OutdoorGrillOutlined />,
    OutdoorGrillRounded: <icons.OutdoorGrillRounded />,
    OutdoorGrillSharp: <icons.OutdoorGrillSharp />,
    OutdoorGrillTwoTone: <icons.OutdoorGrillTwoTone />,
    Outlet: <icons.Outlet />,
    OutletOutlined: <icons.OutletOutlined />,
    OutletRounded: <icons.OutletRounded />,
    OutletSharp: <icons.OutletSharp />,
    OutletTwoTone: <icons.OutletTwoTone />,
    OutlinedFlag: <icons.OutlinedFlag />,
    OutlinedFlagOutlined: <icons.OutlinedFlagOutlined />,
    OutlinedFlagRounded: <icons.OutlinedFlagRounded />,
    OutlinedFlagSharp: <icons.OutlinedFlagSharp />,
    OutlinedFlagTwoTone: <icons.OutlinedFlagTwoTone />,
    Output: <icons.Output />,
    OutputOutlined: <icons.OutputOutlined />,
    OutputRounded: <icons.OutputRounded />,
    OutputSharp: <icons.OutputSharp />,
    OutputTwoTone: <icons.OutputTwoTone />,
    Padding: <icons.Padding />,
    PaddingOutlined: <icons.PaddingOutlined />,
    PaddingRounded: <icons.PaddingRounded />,
    PaddingSharp: <icons.PaddingSharp />,
    PaddingTwoTone: <icons.PaddingTwoTone />,
    Pages: <icons.Pages />,
    PagesOutlined: <icons.PagesOutlined />,
    PagesRounded: <icons.PagesRounded />,
    PagesSharp: <icons.PagesSharp />,
    PagesTwoTone: <icons.PagesTwoTone />,
    Pageview: <icons.Pageview />,
    PageviewOutlined: <icons.PageviewOutlined />,
    PageviewRounded: <icons.PageviewRounded />,
    PageviewSharp: <icons.PageviewSharp />,
    PageviewTwoTone: <icons.PageviewTwoTone />,
    Paid: <icons.Paid />,
    PaidOutlined: <icons.PaidOutlined />,
    PaidRounded: <icons.PaidRounded />,
    PaidSharp: <icons.PaidSharp />,
    PaidTwoTone: <icons.PaidTwoTone />,
    Palette: <icons.Palette />,
    PaletteOutlined: <icons.PaletteOutlined />,
    PaletteRounded: <icons.PaletteRounded />,
    PaletteSharp: <icons.PaletteSharp />,
    PaletteTwoTone: <icons.PaletteTwoTone />,
    PanTool: <icons.PanTool />,
    PanToolAlt: <icons.PanToolAlt />,
    PanToolAltOutlined: <icons.PanToolAltOutlined />,
    PanToolAltRounded: <icons.PanToolAltRounded />,
    PanToolAltSharp: <icons.PanToolAltSharp />,
    PanToolAltTwoTone: <icons.PanToolAltTwoTone />,
    PanToolOutlined: <icons.PanToolOutlined />,
    PanToolRounded: <icons.PanToolRounded />,
    PanToolSharp: <icons.PanToolSharp />,
    PanToolTwoTone: <icons.PanToolTwoTone />,
    Panorama: <icons.Panorama />,
    PanoramaFishEye: <icons.PanoramaFishEye />,
    PanoramaFishEyeOutlined: <icons.PanoramaFishEyeOutlined />,
    PanoramaFishEyeRounded: <icons.PanoramaFishEyeRounded />,
    PanoramaFishEyeSharp: <icons.PanoramaFishEyeSharp />,
    PanoramaFishEyeTwoTone: <icons.PanoramaFishEyeTwoTone />,
    PanoramaHorizontal: <icons.PanoramaHorizontal />,
    PanoramaHorizontalOutlined: <icons.PanoramaHorizontalOutlined />,
    PanoramaHorizontalRounded: <icons.PanoramaHorizontalRounded />,
    PanoramaHorizontalSelect: <icons.PanoramaHorizontalSelect />,
    PanoramaHorizontalSelectOutlined: (
        <icons.PanoramaHorizontalSelectOutlined />
    ),
    PanoramaHorizontalSelectRounded: <icons.PanoramaHorizontalSelectRounded />,
    PanoramaHorizontalSelectSharp: <icons.PanoramaHorizontalSelectSharp />,
    PanoramaHorizontalSelectTwoTone: <icons.PanoramaHorizontalSelectTwoTone />,
    PanoramaHorizontalSharp: <icons.PanoramaHorizontalSharp />,
    PanoramaHorizontalTwoTone: <icons.PanoramaHorizontalTwoTone />,
    PanoramaOutlined: <icons.PanoramaOutlined />,
    PanoramaPhotosphere: <icons.PanoramaPhotosphere />,
    PanoramaPhotosphereOutlined: <icons.PanoramaPhotosphereOutlined />,
    PanoramaPhotosphereRounded: <icons.PanoramaPhotosphereRounded />,
    PanoramaPhotosphereSelect: <icons.PanoramaPhotosphereSelect />,
    PanoramaPhotosphereSelectOutlined: (
        <icons.PanoramaPhotosphereSelectOutlined />
    ),
    PanoramaPhotosphereSelectRounded: (
        <icons.PanoramaPhotosphereSelectRounded />
    ),
    PanoramaPhotosphereSelectSharp: <icons.PanoramaPhotosphereSelectSharp />,
    PanoramaPhotosphereSelectTwoTone: (
        <icons.PanoramaPhotosphereSelectTwoTone />
    ),
    PanoramaPhotosphereSharp: <icons.PanoramaPhotosphereSharp />,
    PanoramaPhotosphereTwoTone: <icons.PanoramaPhotosphereTwoTone />,
    PanoramaRounded: <icons.PanoramaRounded />,
    PanoramaSharp: <icons.PanoramaSharp />,
    PanoramaTwoTone: <icons.PanoramaTwoTone />,
    PanoramaVertical: <icons.PanoramaVertical />,
    PanoramaVerticalOutlined: <icons.PanoramaVerticalOutlined />,
    PanoramaVerticalRounded: <icons.PanoramaVerticalRounded />,
    PanoramaVerticalSelect: <icons.PanoramaVerticalSelect />,
    PanoramaVerticalSelectOutlined: <icons.PanoramaVerticalSelectOutlined />,
    PanoramaVerticalSelectRounded: <icons.PanoramaVerticalSelectRounded />,
    PanoramaVerticalSelectSharp: <icons.PanoramaVerticalSelectSharp />,
    PanoramaVerticalSelectTwoTone: <icons.PanoramaVerticalSelectTwoTone />,
    PanoramaVerticalSharp: <icons.PanoramaVerticalSharp />,
    PanoramaVerticalTwoTone: <icons.PanoramaVerticalTwoTone />,
    PanoramaWideAngle: <icons.PanoramaWideAngle />,
    PanoramaWideAngleOutlined: <icons.PanoramaWideAngleOutlined />,
    PanoramaWideAngleRounded: <icons.PanoramaWideAngleRounded />,
    PanoramaWideAngleSelect: <icons.PanoramaWideAngleSelect />,
    PanoramaWideAngleSelectOutlined: <icons.PanoramaWideAngleSelectOutlined />,
    PanoramaWideAngleSelectRounded: <icons.PanoramaWideAngleSelectRounded />,
    PanoramaWideAngleSelectSharp: <icons.PanoramaWideAngleSelectSharp />,
    PanoramaWideAngleSelectTwoTone: <icons.PanoramaWideAngleSelectTwoTone />,
    PanoramaWideAngleSharp: <icons.PanoramaWideAngleSharp />,
    PanoramaWideAngleTwoTone: <icons.PanoramaWideAngleTwoTone />,
    Paragliding: <icons.Paragliding />,
    ParaglidingOutlined: <icons.ParaglidingOutlined />,
    ParaglidingRounded: <icons.ParaglidingRounded />,
    ParaglidingSharp: <icons.ParaglidingSharp />,
    ParaglidingTwoTone: <icons.ParaglidingTwoTone />,
    Park: <icons.Park />,
    ParkOutlined: <icons.ParkOutlined />,
    ParkRounded: <icons.ParkRounded />,
    ParkSharp: <icons.ParkSharp />,
    ParkTwoTone: <icons.ParkTwoTone />,
    PartyMode: <icons.PartyMode />,
    PartyModeOutlined: <icons.PartyModeOutlined />,
    PartyModeRounded: <icons.PartyModeRounded />,
    PartyModeSharp: <icons.PartyModeSharp />,
    PartyModeTwoTone: <icons.PartyModeTwoTone />,
    Password: <icons.Password />,
    PasswordOutlined: <icons.PasswordOutlined />,
    PasswordRounded: <icons.PasswordRounded />,
    PasswordSharp: <icons.PasswordSharp />,
    PasswordTwoTone: <icons.PasswordTwoTone />,
    Pattern: <icons.Pattern />,
    PatternOutlined: <icons.PatternOutlined />,
    PatternRounded: <icons.PatternRounded />,
    PatternSharp: <icons.PatternSharp />,
    PatternTwoTone: <icons.PatternTwoTone />,
    Pause: <icons.Pause />,
    PauseCircle: <icons.PauseCircle />,
    PauseCircleFilled: <icons.PauseCircleFilled />,
    PauseCircleFilledOutlined: <icons.PauseCircleFilledOutlined />,
    PauseCircleFilledRounded: <icons.PauseCircleFilledRounded />,
    PauseCircleFilledSharp: <icons.PauseCircleFilledSharp />,
    PauseCircleFilledTwoTone: <icons.PauseCircleFilledTwoTone />,
    PauseCircleOutline: <icons.PauseCircleOutline />,
    PauseCircleOutlineOutlined: <icons.PauseCircleOutlineOutlined />,
    PauseCircleOutlineRounded: <icons.PauseCircleOutlineRounded />,
    PauseCircleOutlineSharp: <icons.PauseCircleOutlineSharp />,
    PauseCircleOutlineTwoTone: <icons.PauseCircleOutlineTwoTone />,
    PauseCircleOutlined: <icons.PauseCircleOutlined />,
    PauseCircleRounded: <icons.PauseCircleRounded />,
    PauseCircleSharp: <icons.PauseCircleSharp />,
    PauseCircleTwoTone: <icons.PauseCircleTwoTone />,
    PauseOutlined: <icons.PauseOutlined />,
    PausePresentation: <icons.PausePresentation />,
    PausePresentationOutlined: <icons.PausePresentationOutlined />,
    PausePresentationRounded: <icons.PausePresentationRounded />,
    PausePresentationSharp: <icons.PausePresentationSharp />,
    PausePresentationTwoTone: <icons.PausePresentationTwoTone />,
    PauseRounded: <icons.PauseRounded />,
    PauseSharp: <icons.PauseSharp />,
    PauseTwoTone: <icons.PauseTwoTone />,
    Payment: <icons.Payment />,
    PaymentOutlined: <icons.PaymentOutlined />,
    PaymentRounded: <icons.PaymentRounded />,
    PaymentSharp: <icons.PaymentSharp />,
    PaymentTwoTone: <icons.PaymentTwoTone />,
    Payments: <icons.Payments />,
    PaymentsOutlined: <icons.PaymentsOutlined />,
    PaymentsRounded: <icons.PaymentsRounded />,
    PaymentsSharp: <icons.PaymentsSharp />,
    PaymentsTwoTone: <icons.PaymentsTwoTone />,
    PedalBike: <icons.PedalBike />,
    PedalBikeOutlined: <icons.PedalBikeOutlined />,
    PedalBikeRounded: <icons.PedalBikeRounded />,
    PedalBikeSharp: <icons.PedalBikeSharp />,
    PedalBikeTwoTone: <icons.PedalBikeTwoTone />,
    Pending: <icons.Pending />,
    PendingActions: <icons.PendingActions />,
    PendingActionsOutlined: <icons.PendingActionsOutlined />,
    PendingActionsRounded: <icons.PendingActionsRounded />,
    PendingActionsSharp: <icons.PendingActionsSharp />,
    PendingActionsTwoTone: <icons.PendingActionsTwoTone />,
    PendingOutlined: <icons.PendingOutlined />,
    PendingRounded: <icons.PendingRounded />,
    PendingSharp: <icons.PendingSharp />,
    PendingTwoTone: <icons.PendingTwoTone />,
    Pentagon: <icons.Pentagon />,
    PentagonOutlined: <icons.PentagonOutlined />,
    PentagonRounded: <icons.PentagonRounded />,
    PentagonSharp: <icons.PentagonSharp />,
    PentagonTwoTone: <icons.PentagonTwoTone />,
    People: <icons.People />,
    PeopleAlt: <icons.PeopleAlt />,
    PeopleAltOutlined: <icons.PeopleAltOutlined />,
    PeopleAltRounded: <icons.PeopleAltRounded />,
    PeopleAltSharp: <icons.PeopleAltSharp />,
    PeopleAltTwoTone: <icons.PeopleAltTwoTone />,
    PeopleOutline: <icons.PeopleOutline />,
    PeopleOutlineOutlined: <icons.PeopleOutlineOutlined />,
    PeopleOutlineRounded: <icons.PeopleOutlineRounded />,
    PeopleOutlineSharp: <icons.PeopleOutlineSharp />,
    PeopleOutlineTwoTone: <icons.PeopleOutlineTwoTone />,
    PeopleOutlined: <icons.PeopleOutlined />,
    PeopleRounded: <icons.PeopleRounded />,
    PeopleSharp: <icons.PeopleSharp />,
    PeopleTwoTone: <icons.PeopleTwoTone />,
    Percent: <icons.Percent />,
    PercentOutlined: <icons.PercentOutlined />,
    PercentRounded: <icons.PercentRounded />,
    PercentSharp: <icons.PercentSharp />,
    PercentTwoTone: <icons.PercentTwoTone />,
    PermCameraMic: <icons.PermCameraMic />,
    PermCameraMicOutlined: <icons.PermCameraMicOutlined />,
    PermCameraMicRounded: <icons.PermCameraMicRounded />,
    PermCameraMicSharp: <icons.PermCameraMicSharp />,
    PermCameraMicTwoTone: <icons.PermCameraMicTwoTone />,
    PermContactCalendar: <icons.PermContactCalendar />,
    PermContactCalendarOutlined: <icons.PermContactCalendarOutlined />,
    PermContactCalendarRounded: <icons.PermContactCalendarRounded />,
    PermContactCalendarSharp: <icons.PermContactCalendarSharp />,
    PermContactCalendarTwoTone: <icons.PermContactCalendarTwoTone />,
    PermDataSetting: <icons.PermDataSetting />,
    PermDataSettingOutlined: <icons.PermDataSettingOutlined />,
    PermDataSettingRounded: <icons.PermDataSettingRounded />,
    PermDataSettingSharp: <icons.PermDataSettingSharp />,
    PermDataSettingTwoTone: <icons.PermDataSettingTwoTone />,
    PermDeviceInformation: <icons.PermDeviceInformation />,
    PermDeviceInformationOutlined: <icons.PermDeviceInformationOutlined />,
    PermDeviceInformationRounded: <icons.PermDeviceInformationRounded />,
    PermDeviceInformationSharp: <icons.PermDeviceInformationSharp />,
    PermDeviceInformationTwoTone: <icons.PermDeviceInformationTwoTone />,
    PermIdentity: <icons.PermIdentity />,
    PermIdentityOutlined: <icons.PermIdentityOutlined />,
    PermIdentityRounded: <icons.PermIdentityRounded />,
    PermIdentitySharp: <icons.PermIdentitySharp />,
    PermIdentityTwoTone: <icons.PermIdentityTwoTone />,
    PermMedia: <icons.PermMedia />,
    PermMediaOutlined: <icons.PermMediaOutlined />,
    PermMediaRounded: <icons.PermMediaRounded />,
    PermMediaSharp: <icons.PermMediaSharp />,
    PermMediaTwoTone: <icons.PermMediaTwoTone />,
    PermPhoneMsg: <icons.PermPhoneMsg />,
    PermPhoneMsgOutlined: <icons.PermPhoneMsgOutlined />,
    PermPhoneMsgRounded: <icons.PermPhoneMsgRounded />,
    PermPhoneMsgSharp: <icons.PermPhoneMsgSharp />,
    PermPhoneMsgTwoTone: <icons.PermPhoneMsgTwoTone />,
    PermScanWifi: <icons.PermScanWifi />,
    PermScanWifiOutlined: <icons.PermScanWifiOutlined />,
    PermScanWifiRounded: <icons.PermScanWifiRounded />,
    PermScanWifiSharp: <icons.PermScanWifiSharp />,
    PermScanWifiTwoTone: <icons.PermScanWifiTwoTone />,
    Person: <icons.Person />,
    Person2: <icons.Person2 />,
    Person2Outlined: <icons.Person2Outlined />,
    Person2Rounded: <icons.Person2Rounded />,
    Person2Sharp: <icons.Person2Sharp />,
    Person2TwoTone: <icons.Person2TwoTone />,
    Person3: <icons.Person3 />,
    Person3Outlined: <icons.Person3Outlined />,
    Person3Rounded: <icons.Person3Rounded />,
    Person3Sharp: <icons.Person3Sharp />,
    Person3TwoTone: <icons.Person3TwoTone />,
    Person4: <icons.Person4 />,
    Person4Outlined: <icons.Person4Outlined />,
    Person4Rounded: <icons.Person4Rounded />,
    Person4Sharp: <icons.Person4Sharp />,
    Person4TwoTone: <icons.Person4TwoTone />,
    PersonAdd: <icons.PersonAdd />,
    PersonAddAlt: <icons.PersonAddAlt />,
    PersonAddAlt1: <icons.PersonAddAlt1 />,
    PersonAddAlt1Outlined: <icons.PersonAddAlt1Outlined />,
    PersonAddAlt1Rounded: <icons.PersonAddAlt1Rounded />,
    PersonAddAlt1Sharp: <icons.PersonAddAlt1Sharp />,
    PersonAddAlt1TwoTone: <icons.PersonAddAlt1TwoTone />,
    PersonAddAltOutlined: <icons.PersonAddAltOutlined />,
    PersonAddAltRounded: <icons.PersonAddAltRounded />,
    PersonAddAltSharp: <icons.PersonAddAltSharp />,
    PersonAddAltTwoTone: <icons.PersonAddAltTwoTone />,
    PersonAddDisabled: <icons.PersonAddDisabled />,
    PersonAddDisabledOutlined: <icons.PersonAddDisabledOutlined />,
    PersonAddDisabledRounded: <icons.PersonAddDisabledRounded />,
    PersonAddDisabledSharp: <icons.PersonAddDisabledSharp />,
    PersonAddDisabledTwoTone: <icons.PersonAddDisabledTwoTone />,
    PersonAddOutlined: <icons.PersonAddOutlined />,
    PersonAddRounded: <icons.PersonAddRounded />,
    PersonAddSharp: <icons.PersonAddSharp />,
    PersonAddTwoTone: <icons.PersonAddTwoTone />,
    PersonOff: <icons.PersonOff />,
    PersonOffOutlined: <icons.PersonOffOutlined />,
    PersonOffRounded: <icons.PersonOffRounded />,
    PersonOffSharp: <icons.PersonOffSharp />,
    PersonOffTwoTone: <icons.PersonOffTwoTone />,
    PersonOutline: <icons.PersonOutline />,
    PersonOutlineOutlined: <icons.PersonOutlineOutlined />,
    PersonOutlineRounded: <icons.PersonOutlineRounded />,
    PersonOutlineSharp: <icons.PersonOutlineSharp />,
    PersonOutlineTwoTone: <icons.PersonOutlineTwoTone />,
    PersonOutlined: <icons.PersonOutlined />,
    PersonPin: <icons.PersonPin />,
    PersonPinCircle: <icons.PersonPinCircle />,
    PersonPinCircleOutlined: <icons.PersonPinCircleOutlined />,
    PersonPinCircleRounded: <icons.PersonPinCircleRounded />,
    PersonPinCircleSharp: <icons.PersonPinCircleSharp />,
    PersonPinCircleTwoTone: <icons.PersonPinCircleTwoTone />,
    PersonPinOutlined: <icons.PersonPinOutlined />,
    PersonPinRounded: <icons.PersonPinRounded />,
    PersonPinSharp: <icons.PersonPinSharp />,
    PersonPinTwoTone: <icons.PersonPinTwoTone />,
    PersonRemove: <icons.PersonRemove />,
    PersonRemoveAlt1: <icons.PersonRemoveAlt1 />,
    PersonRemoveAlt1Outlined: <icons.PersonRemoveAlt1Outlined />,
    PersonRemoveAlt1Rounded: <icons.PersonRemoveAlt1Rounded />,
    PersonRemoveAlt1Sharp: <icons.PersonRemoveAlt1Sharp />,
    PersonRemoveAlt1TwoTone: <icons.PersonRemoveAlt1TwoTone />,
    PersonRemoveOutlined: <icons.PersonRemoveOutlined />,
    PersonRemoveRounded: <icons.PersonRemoveRounded />,
    PersonRemoveSharp: <icons.PersonRemoveSharp />,
    PersonRemoveTwoTone: <icons.PersonRemoveTwoTone />,
    PersonRounded: <icons.PersonRounded />,
    PersonSearch: <icons.PersonSearch />,
    PersonSearchOutlined: <icons.PersonSearchOutlined />,
    PersonSearchRounded: <icons.PersonSearchRounded />,
    PersonSearchSharp: <icons.PersonSearchSharp />,
    PersonSearchTwoTone: <icons.PersonSearchTwoTone />,
    PersonSharp: <icons.PersonSharp />,
    PersonTwoTone: <icons.PersonTwoTone />,
    PersonalVideo: <icons.PersonalVideo />,
    PersonalVideoOutlined: <icons.PersonalVideoOutlined />,
    PersonalVideoRounded: <icons.PersonalVideoRounded />,
    PersonalVideoSharp: <icons.PersonalVideoSharp />,
    PersonalVideoTwoTone: <icons.PersonalVideoTwoTone />,
    PestControl: <icons.PestControl />,
    PestControlOutlined: <icons.PestControlOutlined />,
    PestControlRodent: <icons.PestControlRodent />,
    PestControlRodentOutlined: <icons.PestControlRodentOutlined />,
    PestControlRodentRounded: <icons.PestControlRodentRounded />,
    PestControlRodentSharp: <icons.PestControlRodentSharp />,
    PestControlRodentTwoTone: <icons.PestControlRodentTwoTone />,
    PestControlRounded: <icons.PestControlRounded />,
    PestControlSharp: <icons.PestControlSharp />,
    PestControlTwoTone: <icons.PestControlTwoTone />,
    Pets: <icons.Pets />,
    PetsOutlined: <icons.PetsOutlined />,
    PetsRounded: <icons.PetsRounded />,
    PetsSharp: <icons.PetsSharp />,
    PetsTwoTone: <icons.PetsTwoTone />,
    Phishing: <icons.Phishing />,
    PhishingOutlined: <icons.PhishingOutlined />,
    PhishingRounded: <icons.PhishingRounded />,
    PhishingSharp: <icons.PhishingSharp />,
    PhishingTwoTone: <icons.PhishingTwoTone />,
    Phone: <icons.Phone />,
    PhoneAndroid: <icons.PhoneAndroid />,
    PhoneAndroidOutlined: <icons.PhoneAndroidOutlined />,
    PhoneAndroidRounded: <icons.PhoneAndroidRounded />,
    PhoneAndroidSharp: <icons.PhoneAndroidSharp />,
    PhoneAndroidTwoTone: <icons.PhoneAndroidTwoTone />,
    PhoneBluetoothSpeaker: <icons.PhoneBluetoothSpeaker />,
    PhoneBluetoothSpeakerOutlined: <icons.PhoneBluetoothSpeakerOutlined />,
    PhoneBluetoothSpeakerRounded: <icons.PhoneBluetoothSpeakerRounded />,
    PhoneBluetoothSpeakerSharp: <icons.PhoneBluetoothSpeakerSharp />,
    PhoneBluetoothSpeakerTwoTone: <icons.PhoneBluetoothSpeakerTwoTone />,
    PhoneCallback: <icons.PhoneCallback />,
    PhoneCallbackOutlined: <icons.PhoneCallbackOutlined />,
    PhoneCallbackRounded: <icons.PhoneCallbackRounded />,
    PhoneCallbackSharp: <icons.PhoneCallbackSharp />,
    PhoneCallbackTwoTone: <icons.PhoneCallbackTwoTone />,
    PhoneDisabled: <icons.PhoneDisabled />,
    PhoneDisabledOutlined: <icons.PhoneDisabledOutlined />,
    PhoneDisabledRounded: <icons.PhoneDisabledRounded />,
    PhoneDisabledSharp: <icons.PhoneDisabledSharp />,
    PhoneDisabledTwoTone: <icons.PhoneDisabledTwoTone />,
    PhoneEnabled: <icons.PhoneEnabled />,
    PhoneEnabledOutlined: <icons.PhoneEnabledOutlined />,
    PhoneEnabledRounded: <icons.PhoneEnabledRounded />,
    PhoneEnabledSharp: <icons.PhoneEnabledSharp />,
    PhoneEnabledTwoTone: <icons.PhoneEnabledTwoTone />,
    PhoneForwarded: <icons.PhoneForwarded />,
    PhoneForwardedOutlined: <icons.PhoneForwardedOutlined />,
    PhoneForwardedRounded: <icons.PhoneForwardedRounded />,
    PhoneForwardedSharp: <icons.PhoneForwardedSharp />,
    PhoneForwardedTwoTone: <icons.PhoneForwardedTwoTone />,
    PhoneInTalk: <icons.PhoneInTalk />,
    PhoneInTalkOutlined: <icons.PhoneInTalkOutlined />,
    PhoneInTalkRounded: <icons.PhoneInTalkRounded />,
    PhoneInTalkSharp: <icons.PhoneInTalkSharp />,
    PhoneInTalkTwoTone: <icons.PhoneInTalkTwoTone />,
    PhoneIphone: <icons.PhoneIphone />,
    PhoneIphoneOutlined: <icons.PhoneIphoneOutlined />,
    PhoneIphoneRounded: <icons.PhoneIphoneRounded />,
    PhoneIphoneSharp: <icons.PhoneIphoneSharp />,
    PhoneIphoneTwoTone: <icons.PhoneIphoneTwoTone />,
    PhoneLocked: <icons.PhoneLocked />,
    PhoneLockedOutlined: <icons.PhoneLockedOutlined />,
    PhoneLockedRounded: <icons.PhoneLockedRounded />,
    PhoneLockedSharp: <icons.PhoneLockedSharp />,
    PhoneLockedTwoTone: <icons.PhoneLockedTwoTone />,
    PhoneMissed: <icons.PhoneMissed />,
    PhoneMissedOutlined: <icons.PhoneMissedOutlined />,
    PhoneMissedRounded: <icons.PhoneMissedRounded />,
    PhoneMissedSharp: <icons.PhoneMissedSharp />,
    PhoneMissedTwoTone: <icons.PhoneMissedTwoTone />,
    PhoneOutlined: <icons.PhoneOutlined />,
    PhonePaused: <icons.PhonePaused />,
    PhonePausedOutlined: <icons.PhonePausedOutlined />,
    PhonePausedRounded: <icons.PhonePausedRounded />,
    PhonePausedSharp: <icons.PhonePausedSharp />,
    PhonePausedTwoTone: <icons.PhonePausedTwoTone />,
    PhoneRounded: <icons.PhoneRounded />,
    PhoneSharp: <icons.PhoneSharp />,
    PhoneTwoTone: <icons.PhoneTwoTone />,
    Phonelink: <icons.Phonelink />,
    PhonelinkErase: <icons.PhonelinkErase />,
    PhonelinkEraseOutlined: <icons.PhonelinkEraseOutlined />,
    PhonelinkEraseRounded: <icons.PhonelinkEraseRounded />,
    PhonelinkEraseSharp: <icons.PhonelinkEraseSharp />,
    PhonelinkEraseTwoTone: <icons.PhonelinkEraseTwoTone />,
    PhonelinkLock: <icons.PhonelinkLock />,
    PhonelinkLockOutlined: <icons.PhonelinkLockOutlined />,
    PhonelinkLockRounded: <icons.PhonelinkLockRounded />,
    PhonelinkLockSharp: <icons.PhonelinkLockSharp />,
    PhonelinkLockTwoTone: <icons.PhonelinkLockTwoTone />,
    PhonelinkOff: <icons.PhonelinkOff />,
    PhonelinkOffOutlined: <icons.PhonelinkOffOutlined />,
    PhonelinkOffRounded: <icons.PhonelinkOffRounded />,
    PhonelinkOffSharp: <icons.PhonelinkOffSharp />,
    PhonelinkOffTwoTone: <icons.PhonelinkOffTwoTone />,
    PhonelinkOutlined: <icons.PhonelinkOutlined />,
    PhonelinkRing: <icons.PhonelinkRing />,
    PhonelinkRingOutlined: <icons.PhonelinkRingOutlined />,
    PhonelinkRingRounded: <icons.PhonelinkRingRounded />,
    PhonelinkRingSharp: <icons.PhonelinkRingSharp />,
    PhonelinkRingTwoTone: <icons.PhonelinkRingTwoTone />,
    PhonelinkRounded: <icons.PhonelinkRounded />,
    PhonelinkSetup: <icons.PhonelinkSetup />,
    PhonelinkSetupOutlined: <icons.PhonelinkSetupOutlined />,
    PhonelinkSetupRounded: <icons.PhonelinkSetupRounded />,
    PhonelinkSetupSharp: <icons.PhonelinkSetupSharp />,
    PhonelinkSetupTwoTone: <icons.PhonelinkSetupTwoTone />,
    PhonelinkSharp: <icons.PhonelinkSharp />,
    PhonelinkTwoTone: <icons.PhonelinkTwoTone />,
    Photo: <icons.Photo />,
    PhotoAlbum: <icons.PhotoAlbum />,
    PhotoAlbumOutlined: <icons.PhotoAlbumOutlined />,
    PhotoAlbumRounded: <icons.PhotoAlbumRounded />,
    PhotoAlbumSharp: <icons.PhotoAlbumSharp />,
    PhotoAlbumTwoTone: <icons.PhotoAlbumTwoTone />,
    PhotoCamera: <icons.PhotoCamera />,
    PhotoCameraBack: <icons.PhotoCameraBack />,
    PhotoCameraBackOutlined: <icons.PhotoCameraBackOutlined />,
    PhotoCameraBackRounded: <icons.PhotoCameraBackRounded />,
    PhotoCameraBackSharp: <icons.PhotoCameraBackSharp />,
    PhotoCameraBackTwoTone: <icons.PhotoCameraBackTwoTone />,
    PhotoCameraFront: <icons.PhotoCameraFront />,
    PhotoCameraFrontOutlined: <icons.PhotoCameraFrontOutlined />,
    PhotoCameraFrontRounded: <icons.PhotoCameraFrontRounded />,
    PhotoCameraFrontSharp: <icons.PhotoCameraFrontSharp />,
    PhotoCameraFrontTwoTone: <icons.PhotoCameraFrontTwoTone />,
    PhotoCameraOutlined: <icons.PhotoCameraOutlined />,
    PhotoCameraRounded: <icons.PhotoCameraRounded />,
    PhotoCameraSharp: <icons.PhotoCameraSharp />,
    PhotoCameraTwoTone: <icons.PhotoCameraTwoTone />,
    PhotoFilter: <icons.PhotoFilter />,
    PhotoFilterOutlined: <icons.PhotoFilterOutlined />,
    PhotoFilterRounded: <icons.PhotoFilterRounded />,
    PhotoFilterSharp: <icons.PhotoFilterSharp />,
    PhotoFilterTwoTone: <icons.PhotoFilterTwoTone />,
    PhotoLibrary: <icons.PhotoLibrary />,
    PhotoLibraryOutlined: <icons.PhotoLibraryOutlined />,
    PhotoLibraryRounded: <icons.PhotoLibraryRounded />,
    PhotoLibrarySharp: <icons.PhotoLibrarySharp />,
    PhotoLibraryTwoTone: <icons.PhotoLibraryTwoTone />,
    PhotoOutlined: <icons.PhotoOutlined />,
    PhotoRounded: <icons.PhotoRounded />,
    PhotoSharp: <icons.PhotoSharp />,
    PhotoSizeSelectActual: <icons.PhotoSizeSelectActual />,
    PhotoSizeSelectActualOutlined: <icons.PhotoSizeSelectActualOutlined />,
    PhotoSizeSelectActualRounded: <icons.PhotoSizeSelectActualRounded />,
    PhotoSizeSelectActualSharp: <icons.PhotoSizeSelectActualSharp />,
    PhotoSizeSelectActualTwoTone: <icons.PhotoSizeSelectActualTwoTone />,
    PhotoSizeSelectLarge: <icons.PhotoSizeSelectLarge />,
    PhotoSizeSelectLargeOutlined: <icons.PhotoSizeSelectLargeOutlined />,
    PhotoSizeSelectLargeRounded: <icons.PhotoSizeSelectLargeRounded />,
    PhotoSizeSelectLargeSharp: <icons.PhotoSizeSelectLargeSharp />,
    PhotoSizeSelectLargeTwoTone: <icons.PhotoSizeSelectLargeTwoTone />,
    PhotoSizeSelectSmall: <icons.PhotoSizeSelectSmall />,
    PhotoSizeSelectSmallOutlined: <icons.PhotoSizeSelectSmallOutlined />,
    PhotoSizeSelectSmallRounded: <icons.PhotoSizeSelectSmallRounded />,
    PhotoSizeSelectSmallSharp: <icons.PhotoSizeSelectSmallSharp />,
    PhotoSizeSelectSmallTwoTone: <icons.PhotoSizeSelectSmallTwoTone />,
    PhotoTwoTone: <icons.PhotoTwoTone />,
    Php: <icons.Php />,
    PhpOutlined: <icons.PhpOutlined />,
    PhpRounded: <icons.PhpRounded />,
    PhpSharp: <icons.PhpSharp />,
    PhpTwoTone: <icons.PhpTwoTone />,
    Piano: <icons.Piano />,
    PianoOff: <icons.PianoOff />,
    PianoOffOutlined: <icons.PianoOffOutlined />,
    PianoOffRounded: <icons.PianoOffRounded />,
    PianoOffSharp: <icons.PianoOffSharp />,
    PianoOffTwoTone: <icons.PianoOffTwoTone />,
    PianoOutlined: <icons.PianoOutlined />,
    PianoRounded: <icons.PianoRounded />,
    PianoSharp: <icons.PianoSharp />,
    PianoTwoTone: <icons.PianoTwoTone />,
    PictureAsPdf: <icons.PictureAsPdf />,
    PictureAsPdfOutlined: <icons.PictureAsPdfOutlined />,
    PictureAsPdfRounded: <icons.PictureAsPdfRounded />,
    PictureAsPdfSharp: <icons.PictureAsPdfSharp />,
    PictureAsPdfTwoTone: <icons.PictureAsPdfTwoTone />,
    PictureInPicture: <icons.PictureInPicture />,
    PictureInPictureAlt: <icons.PictureInPictureAlt />,
    PictureInPictureAltOutlined: <icons.PictureInPictureAltOutlined />,
    PictureInPictureAltRounded: <icons.PictureInPictureAltRounded />,
    PictureInPictureAltSharp: <icons.PictureInPictureAltSharp />,
    PictureInPictureAltTwoTone: <icons.PictureInPictureAltTwoTone />,
    PictureInPictureOutlined: <icons.PictureInPictureOutlined />,
    PictureInPictureRounded: <icons.PictureInPictureRounded />,
    PictureInPictureSharp: <icons.PictureInPictureSharp />,
    PictureInPictureTwoTone: <icons.PictureInPictureTwoTone />,
    PieChart: <icons.PieChart />,
    PieChartOutline: <icons.PieChartOutline />,
    PieChartOutlineOutlined: <icons.PieChartOutlineOutlined />,
    PieChartOutlineRounded: <icons.PieChartOutlineRounded />,
    PieChartOutlineSharp: <icons.PieChartOutlineSharp />,
    PieChartOutlineTwoTone: <icons.PieChartOutlineTwoTone />,
    PieChartOutlined: <icons.PieChartOutlined />,
    PieChartRounded: <icons.PieChartRounded />,
    PieChartSharp: <icons.PieChartSharp />,
    PieChartTwoTone: <icons.PieChartTwoTone />,
    Pin: <icons.Pin />,
    PinDrop: <icons.PinDrop />,
    PinDropOutlined: <icons.PinDropOutlined />,
    PinDropRounded: <icons.PinDropRounded />,
    PinDropSharp: <icons.PinDropSharp />,
    PinDropTwoTone: <icons.PinDropTwoTone />,
    PinOutlined: <icons.PinOutlined />,
    PinRounded: <icons.PinRounded />,
    PinSharp: <icons.PinSharp />,
    PinTwoTone: <icons.PinTwoTone />,
    Pinch: <icons.Pinch />,
    PinchOutlined: <icons.PinchOutlined />,
    PinchRounded: <icons.PinchRounded />,
    PinchSharp: <icons.PinchSharp />,
    PinchTwoTone: <icons.PinchTwoTone />,
    Pinterest: <icons.Pinterest />,
    PivotTableChart: <icons.PivotTableChart />,
    PivotTableChartOutlined: <icons.PivotTableChartOutlined />,
    PivotTableChartRounded: <icons.PivotTableChartRounded />,
    PivotTableChartSharp: <icons.PivotTableChartSharp />,
    PivotTableChartTwoTone: <icons.PivotTableChartTwoTone />,
    Pix: <icons.Pix />,
    PixOutlined: <icons.PixOutlined />,
    PixRounded: <icons.PixRounded />,
    PixSharp: <icons.PixSharp />,
    PixTwoTone: <icons.PixTwoTone />,
    Place: <icons.Place />,
    PlaceOutlined: <icons.PlaceOutlined />,
    PlaceRounded: <icons.PlaceRounded />,
    PlaceSharp: <icons.PlaceSharp />,
    PlaceTwoTone: <icons.PlaceTwoTone />,
    Plagiarism: <icons.Plagiarism />,
    PlagiarismOutlined: <icons.PlagiarismOutlined />,
    PlagiarismRounded: <icons.PlagiarismRounded />,
    PlagiarismSharp: <icons.PlagiarismSharp />,
    PlagiarismTwoTone: <icons.PlagiarismTwoTone />,
    PlayArrow: <icons.PlayArrow />,
    PlayArrowOutlined: <icons.PlayArrowOutlined />,
    PlayArrowRounded: <icons.PlayArrowRounded />,
    PlayArrowSharp: <icons.PlayArrowSharp />,
    PlayArrowTwoTone: <icons.PlayArrowTwoTone />,
    PlayCircle: <icons.PlayCircle />,
    PlayCircleFilled: <icons.PlayCircleFilled />,
    PlayCircleFilledOutlined: <icons.PlayCircleFilledOutlined />,
    PlayCircleFilledRounded: <icons.PlayCircleFilledRounded />,
    PlayCircleFilledSharp: <icons.PlayCircleFilledSharp />,
    PlayCircleFilledTwoTone: <icons.PlayCircleFilledTwoTone />,
    PlayCircleFilledWhite: <icons.PlayCircleFilledWhite />,
    PlayCircleFilledWhiteOutlined: <icons.PlayCircleFilledWhiteOutlined />,
    PlayCircleFilledWhiteRounded: <icons.PlayCircleFilledWhiteRounded />,
    PlayCircleFilledWhiteSharp: <icons.PlayCircleFilledWhiteSharp />,
    PlayCircleFilledWhiteTwoTone: <icons.PlayCircleFilledWhiteTwoTone />,
    PlayCircleOutline: <icons.PlayCircleOutline />,
    PlayCircleOutlineOutlined: <icons.PlayCircleOutlineOutlined />,
    PlayCircleOutlineRounded: <icons.PlayCircleOutlineRounded />,
    PlayCircleOutlineSharp: <icons.PlayCircleOutlineSharp />,
    PlayCircleOutlineTwoTone: <icons.PlayCircleOutlineTwoTone />,
    PlayCircleOutlined: <icons.PlayCircleOutlined />,
    PlayCircleRounded: <icons.PlayCircleRounded />,
    PlayCircleSharp: <icons.PlayCircleSharp />,
    PlayCircleTwoTone: <icons.PlayCircleTwoTone />,
    PlayDisabled: <icons.PlayDisabled />,
    PlayDisabledOutlined: <icons.PlayDisabledOutlined />,
    PlayDisabledRounded: <icons.PlayDisabledRounded />,
    PlayDisabledSharp: <icons.PlayDisabledSharp />,
    PlayDisabledTwoTone: <icons.PlayDisabledTwoTone />,
    PlayForWork: <icons.PlayForWork />,
    PlayForWorkOutlined: <icons.PlayForWorkOutlined />,
    PlayForWorkRounded: <icons.PlayForWorkRounded />,
    PlayForWorkSharp: <icons.PlayForWorkSharp />,
    PlayForWorkTwoTone: <icons.PlayForWorkTwoTone />,
    PlayLesson: <icons.PlayLesson />,
    PlayLessonOutlined: <icons.PlayLessonOutlined />,
    PlayLessonRounded: <icons.PlayLessonRounded />,
    PlayLessonSharp: <icons.PlayLessonSharp />,
    PlayLessonTwoTone: <icons.PlayLessonTwoTone />,
    PlaylistAdd: <icons.PlaylistAdd />,
    PlaylistAddCheck: <icons.PlaylistAddCheck />,
    PlaylistAddCheckCircle: <icons.PlaylistAddCheckCircle />,
    PlaylistAddCheckCircleOutlined: <icons.PlaylistAddCheckCircleOutlined />,
    PlaylistAddCheckCircleRounded: <icons.PlaylistAddCheckCircleRounded />,
    PlaylistAddCheckCircleSharp: <icons.PlaylistAddCheckCircleSharp />,
    PlaylistAddCheckCircleTwoTone: <icons.PlaylistAddCheckCircleTwoTone />,
    PlaylistAddCheckOutlined: <icons.PlaylistAddCheckOutlined />,
    PlaylistAddCheckRounded: <icons.PlaylistAddCheckRounded />,
    PlaylistAddCheckSharp: <icons.PlaylistAddCheckSharp />,
    PlaylistAddCheckTwoTone: <icons.PlaylistAddCheckTwoTone />,
    PlaylistAddCircle: <icons.PlaylistAddCircle />,
    PlaylistAddCircleOutlined: <icons.PlaylistAddCircleOutlined />,
    PlaylistAddCircleRounded: <icons.PlaylistAddCircleRounded />,
    PlaylistAddCircleSharp: <icons.PlaylistAddCircleSharp />,
    PlaylistAddCircleTwoTone: <icons.PlaylistAddCircleTwoTone />,
    PlaylistAddOutlined: <icons.PlaylistAddOutlined />,
    PlaylistAddRounded: <icons.PlaylistAddRounded />,
    PlaylistAddSharp: <icons.PlaylistAddSharp />,
    PlaylistAddTwoTone: <icons.PlaylistAddTwoTone />,
    PlaylistPlay: <icons.PlaylistPlay />,
    PlaylistPlayOutlined: <icons.PlaylistPlayOutlined />,
    PlaylistPlayRounded: <icons.PlaylistPlayRounded />,
    PlaylistPlaySharp: <icons.PlaylistPlaySharp />,
    PlaylistPlayTwoTone: <icons.PlaylistPlayTwoTone />,
    PlaylistRemove: <icons.PlaylistRemove />,
    PlaylistRemoveOutlined: <icons.PlaylistRemoveOutlined />,
    PlaylistRemoveRounded: <icons.PlaylistRemoveRounded />,
    PlaylistRemoveSharp: <icons.PlaylistRemoveSharp />,
    PlaylistRemoveTwoTone: <icons.PlaylistRemoveTwoTone />,
    Plumbing: <icons.Plumbing />,
    PlumbingOutlined: <icons.PlumbingOutlined />,
    PlumbingRounded: <icons.PlumbingRounded />,
    PlumbingSharp: <icons.PlumbingSharp />,
    PlumbingTwoTone: <icons.PlumbingTwoTone />,
    PlusOne: <icons.PlusOne />,
    PlusOneOutlined: <icons.PlusOneOutlined />,
    PlusOneRounded: <icons.PlusOneRounded />,
    PlusOneSharp: <icons.PlusOneSharp />,
    PlusOneTwoTone: <icons.PlusOneTwoTone />,
    Podcasts: <icons.Podcasts />,
    PodcastsOutlined: <icons.PodcastsOutlined />,
    PodcastsRounded: <icons.PodcastsRounded />,
    PodcastsSharp: <icons.PodcastsSharp />,
    PodcastsTwoTone: <icons.PodcastsTwoTone />,
    PointOfSale: <icons.PointOfSale />,
    PointOfSaleOutlined: <icons.PointOfSaleOutlined />,
    PointOfSaleRounded: <icons.PointOfSaleRounded />,
    PointOfSaleSharp: <icons.PointOfSaleSharp />,
    PointOfSaleTwoTone: <icons.PointOfSaleTwoTone />,
    Policy: <icons.Policy />,
    PolicyOutlined: <icons.PolicyOutlined />,
    PolicyRounded: <icons.PolicyRounded />,
    PolicySharp: <icons.PolicySharp />,
    PolicyTwoTone: <icons.PolicyTwoTone />,
    Poll: <icons.Poll />,
    PollOutlined: <icons.PollOutlined />,
    PollRounded: <icons.PollRounded />,
    PollSharp: <icons.PollSharp />,
    PollTwoTone: <icons.PollTwoTone />,
    Polyline: <icons.Polyline />,
    PolylineOutlined: <icons.PolylineOutlined />,
    PolylineRounded: <icons.PolylineRounded />,
    PolylineSharp: <icons.PolylineSharp />,
    PolylineTwoTone: <icons.PolylineTwoTone />,
    Pool: <icons.Pool />,
    PoolOutlined: <icons.PoolOutlined />,
    PoolRounded: <icons.PoolRounded />,
    PoolSharp: <icons.PoolSharp />,
    PoolTwoTone: <icons.PoolTwoTone />,
    PortableWifiOff: <icons.PortableWifiOff />,
    PortableWifiOffOutlined: <icons.PortableWifiOffOutlined />,
    PortableWifiOffRounded: <icons.PortableWifiOffRounded />,
    PortableWifiOffSharp: <icons.PortableWifiOffSharp />,
    PortableWifiOffTwoTone: <icons.PortableWifiOffTwoTone />,
    Portrait: <icons.Portrait />,
    PortraitOutlined: <icons.PortraitOutlined />,
    PortraitRounded: <icons.PortraitRounded />,
    PortraitSharp: <icons.PortraitSharp />,
    PortraitTwoTone: <icons.PortraitTwoTone />,
    PostAdd: <icons.PostAdd />,
    PostAddOutlined: <icons.PostAddOutlined />,
    PostAddRounded: <icons.PostAddRounded />,
    PostAddSharp: <icons.PostAddSharp />,
    PostAddTwoTone: <icons.PostAddTwoTone />,
    Power: <icons.Power />,
    PowerInput: <icons.PowerInput />,
    PowerInputOutlined: <icons.PowerInputOutlined />,
    PowerInputRounded: <icons.PowerInputRounded />,
    PowerInputSharp: <icons.PowerInputSharp />,
    PowerInputTwoTone: <icons.PowerInputTwoTone />,
    PowerOff: <icons.PowerOff />,
    PowerOffOutlined: <icons.PowerOffOutlined />,
    PowerOffRounded: <icons.PowerOffRounded />,
    PowerOffSharp: <icons.PowerOffSharp />,
    PowerOffTwoTone: <icons.PowerOffTwoTone />,
    PowerOutlined: <icons.PowerOutlined />,
    PowerRounded: <icons.PowerRounded />,
    PowerSettingsNew: <icons.PowerSettingsNew />,
    PowerSettingsNewOutlined: <icons.PowerSettingsNewOutlined />,
    PowerSettingsNewRounded: <icons.PowerSettingsNewRounded />,
    PowerSettingsNewSharp: <icons.PowerSettingsNewSharp />,
    PowerSettingsNewTwoTone: <icons.PowerSettingsNewTwoTone />,
    PowerSharp: <icons.PowerSharp />,
    PowerTwoTone: <icons.PowerTwoTone />,
    PrecisionManufacturing: <icons.PrecisionManufacturing />,
    PrecisionManufacturingOutlined: <icons.PrecisionManufacturingOutlined />,
    PrecisionManufacturingRounded: <icons.PrecisionManufacturingRounded />,
    PrecisionManufacturingSharp: <icons.PrecisionManufacturingSharp />,
    PrecisionManufacturingTwoTone: <icons.PrecisionManufacturingTwoTone />,
    PregnantWoman: <icons.PregnantWoman />,
    PregnantWomanOutlined: <icons.PregnantWomanOutlined />,
    PregnantWomanRounded: <icons.PregnantWomanRounded />,
    PregnantWomanSharp: <icons.PregnantWomanSharp />,
    PregnantWomanTwoTone: <icons.PregnantWomanTwoTone />,
    PresentToAll: <icons.PresentToAll />,
    PresentToAllOutlined: <icons.PresentToAllOutlined />,
    PresentToAllRounded: <icons.PresentToAllRounded />,
    PresentToAllSharp: <icons.PresentToAllSharp />,
    PresentToAllTwoTone: <icons.PresentToAllTwoTone />,
    Preview: <icons.Preview />,
    PreviewOutlined: <icons.PreviewOutlined />,
    PreviewRounded: <icons.PreviewRounded />,
    PreviewSharp: <icons.PreviewSharp />,
    PreviewTwoTone: <icons.PreviewTwoTone />,
    PriceChange: <icons.PriceChange />,
    PriceChangeOutlined: <icons.PriceChangeOutlined />,
    PriceChangeRounded: <icons.PriceChangeRounded />,
    PriceChangeSharp: <icons.PriceChangeSharp />,
    PriceChangeTwoTone: <icons.PriceChangeTwoTone />,
    PriceCheck: <icons.PriceCheck />,
    PriceCheckOutlined: <icons.PriceCheckOutlined />,
    PriceCheckRounded: <icons.PriceCheckRounded />,
    PriceCheckSharp: <icons.PriceCheckSharp />,
    PriceCheckTwoTone: <icons.PriceCheckTwoTone />,
    Print: <icons.Print />,
    PrintDisabled: <icons.PrintDisabled />,
    PrintDisabledOutlined: <icons.PrintDisabledOutlined />,
    PrintDisabledRounded: <icons.PrintDisabledRounded />,
    PrintDisabledSharp: <icons.PrintDisabledSharp />,
    PrintDisabledTwoTone: <icons.PrintDisabledTwoTone />,
    PrintOutlined: <icons.PrintOutlined />,
    PrintRounded: <icons.PrintRounded />,
    PrintSharp: <icons.PrintSharp />,
    PrintTwoTone: <icons.PrintTwoTone />,
    PriorityHigh: <icons.PriorityHigh />,
    PriorityHighOutlined: <icons.PriorityHighOutlined />,
    PriorityHighRounded: <icons.PriorityHighRounded />,
    PriorityHighSharp: <icons.PriorityHighSharp />,
    PriorityHighTwoTone: <icons.PriorityHighTwoTone />,
    PrivacyTip: <icons.PrivacyTip />,
    PrivacyTipOutlined: <icons.PrivacyTipOutlined />,
    PrivacyTipRounded: <icons.PrivacyTipRounded />,
    PrivacyTipSharp: <icons.PrivacyTipSharp />,
    PrivacyTipTwoTone: <icons.PrivacyTipTwoTone />,
    ProductionQuantityLimits: <icons.ProductionQuantityLimits />,
    ProductionQuantityLimitsOutlined: (
        <icons.ProductionQuantityLimitsOutlined />
    ),
    ProductionQuantityLimitsRounded: <icons.ProductionQuantityLimitsRounded />,
    ProductionQuantityLimitsSharp: <icons.ProductionQuantityLimitsSharp />,
    ProductionQuantityLimitsTwoTone: <icons.ProductionQuantityLimitsTwoTone />,
    Propane: <icons.Propane />,
    PropaneOutlined: <icons.PropaneOutlined />,
    PropaneRounded: <icons.PropaneRounded />,
    PropaneSharp: <icons.PropaneSharp />,
    PropaneTank: <icons.PropaneTank />,
    PropaneTankOutlined: <icons.PropaneTankOutlined />,
    PropaneTankRounded: <icons.PropaneTankRounded />,
    PropaneTankSharp: <icons.PropaneTankSharp />,
    PropaneTankTwoTone: <icons.PropaneTankTwoTone />,
    PropaneTwoTone: <icons.PropaneTwoTone />,
    Psychology: <icons.Psychology />,
    PsychologyAlt: <icons.PsychologyAlt />,
    PsychologyAltOutlined: <icons.PsychologyAltOutlined />,
    PsychologyAltRounded: <icons.PsychologyAltRounded />,
    PsychologyAltSharp: <icons.PsychologyAltSharp />,
    PsychologyAltTwoTone: <icons.PsychologyAltTwoTone />,
    PsychologyOutlined: <icons.PsychologyOutlined />,
    PsychologyRounded: <icons.PsychologyRounded />,
    PsychologySharp: <icons.PsychologySharp />,
    PsychologyTwoTone: <icons.PsychologyTwoTone />,
    Public: <icons.Public />,
    PublicOff: <icons.PublicOff />,
    PublicOffOutlined: <icons.PublicOffOutlined />,
    PublicOffRounded: <icons.PublicOffRounded />,
    PublicOffSharp: <icons.PublicOffSharp />,
    PublicOffTwoTone: <icons.PublicOffTwoTone />,
    PublicOutlined: <icons.PublicOutlined />,
    PublicRounded: <icons.PublicRounded />,
    PublicSharp: <icons.PublicSharp />,
    PublicTwoTone: <icons.PublicTwoTone />,
    Publish: <icons.Publish />,
    PublishOutlined: <icons.PublishOutlined />,
    PublishRounded: <icons.PublishRounded />,
    PublishSharp: <icons.PublishSharp />,
    PublishTwoTone: <icons.PublishTwoTone />,
    PublishedWithChanges: <icons.PublishedWithChanges />,
    PublishedWithChangesOutlined: <icons.PublishedWithChangesOutlined />,
    PublishedWithChangesRounded: <icons.PublishedWithChangesRounded />,
    PublishedWithChangesSharp: <icons.PublishedWithChangesSharp />,
    PublishedWithChangesTwoTone: <icons.PublishedWithChangesTwoTone />,
    PunchClock: <icons.PunchClock />,
    PunchClockOutlined: <icons.PunchClockOutlined />,
    PunchClockRounded: <icons.PunchClockRounded />,
    PunchClockSharp: <icons.PunchClockSharp />,
    PunchClockTwoTone: <icons.PunchClockTwoTone />,
    PushPin: <icons.PushPin />,
    PushPinOutlined: <icons.PushPinOutlined />,
    PushPinRounded: <icons.PushPinRounded />,
    PushPinSharp: <icons.PushPinSharp />,
    PushPinTwoTone: <icons.PushPinTwoTone />,
    QrCode: <icons.QrCode />,
    QrCode2: <icons.QrCode2 />,
    QrCode2Outlined: <icons.QrCode2Outlined />,
    QrCode2Rounded: <icons.QrCode2Rounded />,
    QrCode2Sharp: <icons.QrCode2Sharp />,
    QrCode2TwoTone: <icons.QrCode2TwoTone />,
    QrCodeOutlined: <icons.QrCodeOutlined />,
    QrCodeRounded: <icons.QrCodeRounded />,
    QrCodeScanner: <icons.QrCodeScanner />,
    QrCodeScannerOutlined: <icons.QrCodeScannerOutlined />,
    QrCodeScannerRounded: <icons.QrCodeScannerRounded />,
    QrCodeScannerSharp: <icons.QrCodeScannerSharp />,
    QrCodeScannerTwoTone: <icons.QrCodeScannerTwoTone />,
    QrCodeSharp: <icons.QrCodeSharp />,
    QrCodeTwoTone: <icons.QrCodeTwoTone />,
    QueryBuilder: <icons.QueryBuilder />,
    QueryBuilderOutlined: <icons.QueryBuilderOutlined />,
    QueryBuilderRounded: <icons.QueryBuilderRounded />,
    QueryBuilderSharp: <icons.QueryBuilderSharp />,
    QueryBuilderTwoTone: <icons.QueryBuilderTwoTone />,
    QueryStats: <icons.QueryStats />,
    QueryStatsOutlined: <icons.QueryStatsOutlined />,
    QueryStatsRounded: <icons.QueryStatsRounded />,
    QueryStatsSharp: <icons.QueryStatsSharp />,
    QueryStatsTwoTone: <icons.QueryStatsTwoTone />,
    QuestionAnswer: <icons.QuestionAnswer />,
    QuestionAnswerOutlined: <icons.QuestionAnswerOutlined />,
    QuestionAnswerRounded: <icons.QuestionAnswerRounded />,
    QuestionAnswerSharp: <icons.QuestionAnswerSharp />,
    QuestionAnswerTwoTone: <icons.QuestionAnswerTwoTone />,
    QuestionMark: <icons.QuestionMark />,
    QuestionMarkOutlined: <icons.QuestionMarkOutlined />,
    QuestionMarkRounded: <icons.QuestionMarkRounded />,
    QuestionMarkSharp: <icons.QuestionMarkSharp />,
    QuestionMarkTwoTone: <icons.QuestionMarkTwoTone />,
    Queue: <icons.Queue />,
    QueueMusic: <icons.QueueMusic />,
    QueueMusicOutlined: <icons.QueueMusicOutlined />,
    QueueMusicRounded: <icons.QueueMusicRounded />,
    QueueMusicSharp: <icons.QueueMusicSharp />,
    QueueMusicTwoTone: <icons.QueueMusicTwoTone />,
    QueueOutlined: <icons.QueueOutlined />,
    QueuePlayNext: <icons.QueuePlayNext />,
    QueuePlayNextOutlined: <icons.QueuePlayNextOutlined />,
    QueuePlayNextRounded: <icons.QueuePlayNextRounded />,
    QueuePlayNextSharp: <icons.QueuePlayNextSharp />,
    QueuePlayNextTwoTone: <icons.QueuePlayNextTwoTone />,
    QueueRounded: <icons.QueueRounded />,
    QueueSharp: <icons.QueueSharp />,
    QueueTwoTone: <icons.QueueTwoTone />,
    Quickreply: <icons.Quickreply />,
    QuickreplyOutlined: <icons.QuickreplyOutlined />,
    QuickreplyRounded: <icons.QuickreplyRounded />,
    QuickreplySharp: <icons.QuickreplySharp />,
    QuickreplyTwoTone: <icons.QuickreplyTwoTone />,
    Quiz: <icons.Quiz />,
    QuizOutlined: <icons.QuizOutlined />,
    QuizRounded: <icons.QuizRounded />,
    QuizSharp: <icons.QuizSharp />,
    QuizTwoTone: <icons.QuizTwoTone />,
    RMobiledata: <icons.RMobiledata />,
    RMobiledataOutlined: <icons.RMobiledataOutlined />,
    RMobiledataRounded: <icons.RMobiledataRounded />,
    RMobiledataSharp: <icons.RMobiledataSharp />,
    RMobiledataTwoTone: <icons.RMobiledataTwoTone />,
    Radar: <icons.Radar />,
    RadarOutlined: <icons.RadarOutlined />,
    RadarRounded: <icons.RadarRounded />,
    RadarSharp: <icons.RadarSharp />,
    RadarTwoTone: <icons.RadarTwoTone />,
    Radio: <icons.Radio />,
    RadioButtonChecked: <icons.RadioButtonChecked />,
    RadioButtonCheckedOutlined: <icons.RadioButtonCheckedOutlined />,
    RadioButtonCheckedRounded: <icons.RadioButtonCheckedRounded />,
    RadioButtonCheckedSharp: <icons.RadioButtonCheckedSharp />,
    RadioButtonCheckedTwoTone: <icons.RadioButtonCheckedTwoTone />,
    RadioButtonUnchecked: <icons.RadioButtonUnchecked />,
    RadioButtonUncheckedOutlined: <icons.RadioButtonUncheckedOutlined />,
    RadioButtonUncheckedRounded: <icons.RadioButtonUncheckedRounded />,
    RadioButtonUncheckedSharp: <icons.RadioButtonUncheckedSharp />,
    RadioButtonUncheckedTwoTone: <icons.RadioButtonUncheckedTwoTone />,
    RadioOutlined: <icons.RadioOutlined />,
    RadioRounded: <icons.RadioRounded />,
    RadioSharp: <icons.RadioSharp />,
    RadioTwoTone: <icons.RadioTwoTone />,
    RailwayAlert: <icons.RailwayAlert />,
    RailwayAlertOutlined: <icons.RailwayAlertOutlined />,
    RailwayAlertRounded: <icons.RailwayAlertRounded />,
    RailwayAlertSharp: <icons.RailwayAlertSharp />,
    RailwayAlertTwoTone: <icons.RailwayAlertTwoTone />,
    RamenDining: <icons.RamenDining />,
    RamenDiningOutlined: <icons.RamenDiningOutlined />,
    RamenDiningRounded: <icons.RamenDiningRounded />,
    RamenDiningSharp: <icons.RamenDiningSharp />,
    RamenDiningTwoTone: <icons.RamenDiningTwoTone />,
    RampLeft: <icons.RampLeft />,
    RampLeftOutlined: <icons.RampLeftOutlined />,
    RampLeftRounded: <icons.RampLeftRounded />,
    RampLeftSharp: <icons.RampLeftSharp />,
    RampLeftTwoTone: <icons.RampLeftTwoTone />,
    RampRight: <icons.RampRight />,
    RampRightOutlined: <icons.RampRightOutlined />,
    RampRightRounded: <icons.RampRightRounded />,
    RampRightSharp: <icons.RampRightSharp />,
    RampRightTwoTone: <icons.RampRightTwoTone />,
    RateReview: <icons.RateReview />,
    RateReviewOutlined: <icons.RateReviewOutlined />,
    RateReviewRounded: <icons.RateReviewRounded />,
    RateReviewSharp: <icons.RateReviewSharp />,
    RateReviewTwoTone: <icons.RateReviewTwoTone />,
    RawOff: <icons.RawOff />,
    RawOffOutlined: <icons.RawOffOutlined />,
    RawOffRounded: <icons.RawOffRounded />,
    RawOffSharp: <icons.RawOffSharp />,
    RawOffTwoTone: <icons.RawOffTwoTone />,
    RawOn: <icons.RawOn />,
    RawOnOutlined: <icons.RawOnOutlined />,
    RawOnRounded: <icons.RawOnRounded />,
    RawOnSharp: <icons.RawOnSharp />,
    RawOnTwoTone: <icons.RawOnTwoTone />,
    ReadMore: <icons.ReadMore />,
    ReadMoreOutlined: <icons.ReadMoreOutlined />,
    ReadMoreRounded: <icons.ReadMoreRounded />,
    ReadMoreSharp: <icons.ReadMoreSharp />,
    ReadMoreTwoTone: <icons.ReadMoreTwoTone />,
    Receipt: <icons.Receipt />,
    ReceiptLong: <icons.ReceiptLong />,
    ReceiptLongOutlined: <icons.ReceiptLongOutlined />,
    ReceiptLongRounded: <icons.ReceiptLongRounded />,
    ReceiptLongSharp: <icons.ReceiptLongSharp />,
    ReceiptLongTwoTone: <icons.ReceiptLongTwoTone />,
    ReceiptOutlined: <icons.ReceiptOutlined />,
    ReceiptRounded: <icons.ReceiptRounded />,
    ReceiptSharp: <icons.ReceiptSharp />,
    ReceiptTwoTone: <icons.ReceiptTwoTone />,
    RecentActors: <icons.RecentActors />,
    RecentActorsOutlined: <icons.RecentActorsOutlined />,
    RecentActorsRounded: <icons.RecentActorsRounded />,
    RecentActorsSharp: <icons.RecentActorsSharp />,
    RecentActorsTwoTone: <icons.RecentActorsTwoTone />,
    Recommend: <icons.Recommend />,
    RecommendOutlined: <icons.RecommendOutlined />,
    RecommendRounded: <icons.RecommendRounded />,
    RecommendSharp: <icons.RecommendSharp />,
    RecommendTwoTone: <icons.RecommendTwoTone />,
    RecordVoiceOver: <icons.RecordVoiceOver />,
    RecordVoiceOverOutlined: <icons.RecordVoiceOverOutlined />,
    RecordVoiceOverRounded: <icons.RecordVoiceOverRounded />,
    RecordVoiceOverSharp: <icons.RecordVoiceOverSharp />,
    RecordVoiceOverTwoTone: <icons.RecordVoiceOverTwoTone />,
    Rectangle: <icons.Rectangle />,
    RectangleOutlined: <icons.RectangleOutlined />,
    RectangleRounded: <icons.RectangleRounded />,
    RectangleSharp: <icons.RectangleSharp />,
    RectangleTwoTone: <icons.RectangleTwoTone />,
    Recycling: <icons.Recycling />,
    RecyclingOutlined: <icons.RecyclingOutlined />,
    RecyclingRounded: <icons.RecyclingRounded />,
    RecyclingSharp: <icons.RecyclingSharp />,
    RecyclingTwoTone: <icons.RecyclingTwoTone />,
    Reddit: <icons.Reddit />,
    Redeem: <icons.Redeem />,
    RedeemOutlined: <icons.RedeemOutlined />,
    RedeemRounded: <icons.RedeemRounded />,
    RedeemSharp: <icons.RedeemSharp />,
    RedeemTwoTone: <icons.RedeemTwoTone />,
    Redo: <icons.Redo />,
    RedoOutlined: <icons.RedoOutlined />,
    RedoRounded: <icons.RedoRounded />,
    RedoSharp: <icons.RedoSharp />,
    RedoTwoTone: <icons.RedoTwoTone />,
    ReduceCapacity: <icons.ReduceCapacity />,
    ReduceCapacityOutlined: <icons.ReduceCapacityOutlined />,
    ReduceCapacityRounded: <icons.ReduceCapacityRounded />,
    ReduceCapacitySharp: <icons.ReduceCapacitySharp />,
    ReduceCapacityTwoTone: <icons.ReduceCapacityTwoTone />,
    Refresh: <icons.Refresh />,
    RefreshOutlined: <icons.RefreshOutlined />,
    RefreshRounded: <icons.RefreshRounded />,
    RefreshSharp: <icons.RefreshSharp />,
    RefreshTwoTone: <icons.RefreshTwoTone />,
    RememberMe: <icons.RememberMe />,
    RememberMeOutlined: <icons.RememberMeOutlined />,
    RememberMeRounded: <icons.RememberMeRounded />,
    RememberMeSharp: <icons.RememberMeSharp />,
    RememberMeTwoTone: <icons.RememberMeTwoTone />,
    Remove: <icons.Remove />,
    RemoveCircle: <icons.RemoveCircle />,
    RemoveCircleOutline: <icons.RemoveCircleOutline />,
    RemoveCircleOutlineOutlined: <icons.RemoveCircleOutlineOutlined />,
    RemoveCircleOutlineRounded: <icons.RemoveCircleOutlineRounded />,
    RemoveCircleOutlineSharp: <icons.RemoveCircleOutlineSharp />,
    RemoveCircleOutlineTwoTone: <icons.RemoveCircleOutlineTwoTone />,
    RemoveCircleOutlined: <icons.RemoveCircleOutlined />,
    RemoveCircleRounded: <icons.RemoveCircleRounded />,
    RemoveCircleSharp: <icons.RemoveCircleSharp />,
    RemoveCircleTwoTone: <icons.RemoveCircleTwoTone />,
    RemoveDone: <icons.RemoveDone />,
    RemoveDoneOutlined: <icons.RemoveDoneOutlined />,
    RemoveDoneRounded: <icons.RemoveDoneRounded />,
    RemoveDoneSharp: <icons.RemoveDoneSharp />,
    RemoveDoneTwoTone: <icons.RemoveDoneTwoTone />,
    RemoveFromQueue: <icons.RemoveFromQueue />,
    RemoveFromQueueOutlined: <icons.RemoveFromQueueOutlined />,
    RemoveFromQueueRounded: <icons.RemoveFromQueueRounded />,
    RemoveFromQueueSharp: <icons.RemoveFromQueueSharp />,
    RemoveFromQueueTwoTone: <icons.RemoveFromQueueTwoTone />,
    RemoveModerator: <icons.RemoveModerator />,
    RemoveModeratorOutlined: <icons.RemoveModeratorOutlined />,
    RemoveModeratorRounded: <icons.RemoveModeratorRounded />,
    RemoveModeratorSharp: <icons.RemoveModeratorSharp />,
    RemoveModeratorTwoTone: <icons.RemoveModeratorTwoTone />,
    RemoveOutlined: <icons.RemoveOutlined />,
    RemoveRedEye: <icons.RemoveRedEye />,
    RemoveRedEyeOutlined: <icons.RemoveRedEyeOutlined />,
    RemoveRedEyeRounded: <icons.RemoveRedEyeRounded />,
    RemoveRedEyeSharp: <icons.RemoveRedEyeSharp />,
    RemoveRedEyeTwoTone: <icons.RemoveRedEyeTwoTone />,
    RemoveRoad: <icons.RemoveRoad />,
    RemoveRoadOutlined: <icons.RemoveRoadOutlined />,
    RemoveRoadRounded: <icons.RemoveRoadRounded />,
    RemoveRoadSharp: <icons.RemoveRoadSharp />,
    RemoveRoadTwoTone: <icons.RemoveRoadTwoTone />,
    RemoveRounded: <icons.RemoveRounded />,
    RemoveSharp: <icons.RemoveSharp />,
    RemoveShoppingCart: <icons.RemoveShoppingCart />,
    RemoveShoppingCartOutlined: <icons.RemoveShoppingCartOutlined />,
    RemoveShoppingCartRounded: <icons.RemoveShoppingCartRounded />,
    RemoveShoppingCartSharp: <icons.RemoveShoppingCartSharp />,
    RemoveShoppingCartTwoTone: <icons.RemoveShoppingCartTwoTone />,
    RemoveTwoTone: <icons.RemoveTwoTone />,
    Reorder: <icons.Reorder />,
    ReorderOutlined: <icons.ReorderOutlined />,
    ReorderRounded: <icons.ReorderRounded />,
    ReorderSharp: <icons.ReorderSharp />,
    ReorderTwoTone: <icons.ReorderTwoTone />,
    Repartition: <icons.Repartition />,
    RepartitionOutlined: <icons.RepartitionOutlined />,
    RepartitionRounded: <icons.RepartitionRounded />,
    RepartitionSharp: <icons.RepartitionSharp />,
    RepartitionTwoTone: <icons.RepartitionTwoTone />,
    Repeat: <icons.Repeat />,
    RepeatOn: <icons.RepeatOn />,
    RepeatOnOutlined: <icons.RepeatOnOutlined />,
    RepeatOnRounded: <icons.RepeatOnRounded />,
    RepeatOnSharp: <icons.RepeatOnSharp />,
    RepeatOnTwoTone: <icons.RepeatOnTwoTone />,
    RepeatOne: <icons.RepeatOne />,
    RepeatOneOn: <icons.RepeatOneOn />,
    RepeatOneOnOutlined: <icons.RepeatOneOnOutlined />,
    RepeatOneOnRounded: <icons.RepeatOneOnRounded />,
    RepeatOneOnSharp: <icons.RepeatOneOnSharp />,
    RepeatOneOnTwoTone: <icons.RepeatOneOnTwoTone />,
    RepeatOneOutlined: <icons.RepeatOneOutlined />,
    RepeatOneRounded: <icons.RepeatOneRounded />,
    RepeatOneSharp: <icons.RepeatOneSharp />,
    RepeatOneTwoTone: <icons.RepeatOneTwoTone />,
    RepeatOutlined: <icons.RepeatOutlined />,
    RepeatRounded: <icons.RepeatRounded />,
    RepeatSharp: <icons.RepeatSharp />,
    RepeatTwoTone: <icons.RepeatTwoTone />,
    Replay: <icons.Replay />,
    Replay10: <icons.Replay10 />,
    Replay10Outlined: <icons.Replay10Outlined />,
    Replay10Rounded: <icons.Replay10Rounded />,
    Replay10Sharp: <icons.Replay10Sharp />,
    Replay10TwoTone: <icons.Replay10TwoTone />,
    Replay30: <icons.Replay30 />,
    Replay30Outlined: <icons.Replay30Outlined />,
    Replay30Rounded: <icons.Replay30Rounded />,
    Replay30Sharp: <icons.Replay30Sharp />,
    Replay30TwoTone: <icons.Replay30TwoTone />,
    Replay5: <icons.Replay5 />,
    Replay5Outlined: <icons.Replay5Outlined />,
    Replay5Rounded: <icons.Replay5Rounded />,
    Replay5Sharp: <icons.Replay5Sharp />,
    Replay5TwoTone: <icons.Replay5TwoTone />,
    ReplayCircleFilled: <icons.ReplayCircleFilled />,
    ReplayCircleFilledOutlined: <icons.ReplayCircleFilledOutlined />,
    ReplayCircleFilledRounded: <icons.ReplayCircleFilledRounded />,
    ReplayCircleFilledSharp: <icons.ReplayCircleFilledSharp />,
    ReplayCircleFilledTwoTone: <icons.ReplayCircleFilledTwoTone />,
    ReplayOutlined: <icons.ReplayOutlined />,
    ReplayRounded: <icons.ReplayRounded />,
    ReplaySharp: <icons.ReplaySharp />,
    ReplayTwoTone: <icons.ReplayTwoTone />,
    Reply: <icons.Reply />,
    ReplyAll: <icons.ReplyAll />,
    ReplyAllOutlined: <icons.ReplyAllOutlined />,
    ReplyAllRounded: <icons.ReplyAllRounded />,
    ReplyAllSharp: <icons.ReplyAllSharp />,
    ReplyAllTwoTone: <icons.ReplyAllTwoTone />,
    ReplyOutlined: <icons.ReplyOutlined />,
    ReplyRounded: <icons.ReplyRounded />,
    ReplySharp: <icons.ReplySharp />,
    ReplyTwoTone: <icons.ReplyTwoTone />,
    Report: <icons.Report />,
    ReportGmailerrorred: <icons.ReportGmailerrorred />,
    ReportGmailerrorredOutlined: <icons.ReportGmailerrorredOutlined />,
    ReportGmailerrorredRounded: <icons.ReportGmailerrorredRounded />,
    ReportGmailerrorredSharp: <icons.ReportGmailerrorredSharp />,
    ReportGmailerrorredTwoTone: <icons.ReportGmailerrorredTwoTone />,
    ReportOff: <icons.ReportOff />,
    ReportOffOutlined: <icons.ReportOffOutlined />,
    ReportOffRounded: <icons.ReportOffRounded />,
    ReportOffSharp: <icons.ReportOffSharp />,
    ReportOffTwoTone: <icons.ReportOffTwoTone />,
    ReportOutlined: <icons.ReportOutlined />,
    ReportProblem: <icons.ReportProblem />,
    ReportProblemOutlined: <icons.ReportProblemOutlined />,
    ReportProblemRounded: <icons.ReportProblemRounded />,
    ReportProblemSharp: <icons.ReportProblemSharp />,
    ReportProblemTwoTone: <icons.ReportProblemTwoTone />,
    ReportRounded: <icons.ReportRounded />,
    ReportSharp: <icons.ReportSharp />,
    ReportTwoTone: <icons.ReportTwoTone />,
    RequestPage: <icons.RequestPage />,
    RequestPageOutlined: <icons.RequestPageOutlined />,
    RequestPageRounded: <icons.RequestPageRounded />,
    RequestPageSharp: <icons.RequestPageSharp />,
    RequestPageTwoTone: <icons.RequestPageTwoTone />,
    RequestQuote: <icons.RequestQuote />,
    RequestQuoteOutlined: <icons.RequestQuoteOutlined />,
    RequestQuoteRounded: <icons.RequestQuoteRounded />,
    RequestQuoteSharp: <icons.RequestQuoteSharp />,
    RequestQuoteTwoTone: <icons.RequestQuoteTwoTone />,
    ResetTv: <icons.ResetTv />,
    ResetTvOutlined: <icons.ResetTvOutlined />,
    ResetTvRounded: <icons.ResetTvRounded />,
    ResetTvSharp: <icons.ResetTvSharp />,
    ResetTvTwoTone: <icons.ResetTvTwoTone />,
    RestartAlt: <icons.RestartAlt />,
    RestartAltOutlined: <icons.RestartAltOutlined />,
    RestartAltRounded: <icons.RestartAltRounded />,
    RestartAltSharp: <icons.RestartAltSharp />,
    RestartAltTwoTone: <icons.RestartAltTwoTone />,
    Restaurant: <icons.Restaurant />,
    RestaurantMenu: <icons.RestaurantMenu />,
    RestaurantMenuOutlined: <icons.RestaurantMenuOutlined />,
    RestaurantMenuRounded: <icons.RestaurantMenuRounded />,
    RestaurantMenuSharp: <icons.RestaurantMenuSharp />,
    RestaurantMenuTwoTone: <icons.RestaurantMenuTwoTone />,
    RestaurantOutlined: <icons.RestaurantOutlined />,
    RestaurantRounded: <icons.RestaurantRounded />,
    RestaurantSharp: <icons.RestaurantSharp />,
    RestaurantTwoTone: <icons.RestaurantTwoTone />,
    Restore: <icons.Restore />,
    RestoreFromTrash: <icons.RestoreFromTrash />,
    RestoreFromTrashOutlined: <icons.RestoreFromTrashOutlined />,
    RestoreFromTrashRounded: <icons.RestoreFromTrashRounded />,
    RestoreFromTrashSharp: <icons.RestoreFromTrashSharp />,
    RestoreFromTrashTwoTone: <icons.RestoreFromTrashTwoTone />,
    RestoreOutlined: <icons.RestoreOutlined />,
    RestorePage: <icons.RestorePage />,
    RestorePageOutlined: <icons.RestorePageOutlined />,
    RestorePageRounded: <icons.RestorePageRounded />,
    RestorePageSharp: <icons.RestorePageSharp />,
    RestorePageTwoTone: <icons.RestorePageTwoTone />,
    RestoreRounded: <icons.RestoreRounded />,
    RestoreSharp: <icons.RestoreSharp />,
    RestoreTwoTone: <icons.RestoreTwoTone />,
    Reviews: <icons.Reviews />,
    ReviewsOutlined: <icons.ReviewsOutlined />,
    ReviewsRounded: <icons.ReviewsRounded />,
    ReviewsSharp: <icons.ReviewsSharp />,
    ReviewsTwoTone: <icons.ReviewsTwoTone />,
    RiceBowl: <icons.RiceBowl />,
    RiceBowlOutlined: <icons.RiceBowlOutlined />,
    RiceBowlRounded: <icons.RiceBowlRounded />,
    RiceBowlSharp: <icons.RiceBowlSharp />,
    RiceBowlTwoTone: <icons.RiceBowlTwoTone />,
    RingVolume: <icons.RingVolume />,
    RingVolumeOutlined: <icons.RingVolumeOutlined />,
    RingVolumeRounded: <icons.RingVolumeRounded />,
    RingVolumeSharp: <icons.RingVolumeSharp />,
    RingVolumeTwoTone: <icons.RingVolumeTwoTone />,
    Rocket: <icons.Rocket />,
    RocketLaunch: <icons.RocketLaunch />,
    RocketLaunchOutlined: <icons.RocketLaunchOutlined />,
    RocketLaunchRounded: <icons.RocketLaunchRounded />,
    RocketLaunchSharp: <icons.RocketLaunchSharp />,
    RocketLaunchTwoTone: <icons.RocketLaunchTwoTone />,
    RocketOutlined: <icons.RocketOutlined />,
    RocketRounded: <icons.RocketRounded />,
    RocketSharp: <icons.RocketSharp />,
    RocketTwoTone: <icons.RocketTwoTone />,
    RollerShades: <icons.RollerShades />,
    RollerShadesClosed: <icons.RollerShadesClosed />,
    RollerShadesClosedOutlined: <icons.RollerShadesClosedOutlined />,
    RollerShadesClosedRounded: <icons.RollerShadesClosedRounded />,
    RollerShadesClosedSharp: <icons.RollerShadesClosedSharp />,
    RollerShadesClosedTwoTone: <icons.RollerShadesClosedTwoTone />,
    RollerShadesOutlined: <icons.RollerShadesOutlined />,
    RollerShadesRounded: <icons.RollerShadesRounded />,
    RollerShadesSharp: <icons.RollerShadesSharp />,
    RollerShadesTwoTone: <icons.RollerShadesTwoTone />,
    RollerSkating: <icons.RollerSkating />,
    RollerSkatingOutlined: <icons.RollerSkatingOutlined />,
    RollerSkatingRounded: <icons.RollerSkatingRounded />,
    RollerSkatingSharp: <icons.RollerSkatingSharp />,
    RollerSkatingTwoTone: <icons.RollerSkatingTwoTone />,
    Roofing: <icons.Roofing />,
    RoofingOutlined: <icons.RoofingOutlined />,
    RoofingRounded: <icons.RoofingRounded />,
    RoofingSharp: <icons.RoofingSharp />,
    RoofingTwoTone: <icons.RoofingTwoTone />,
    Room: <icons.Room />,
    RoomOutlined: <icons.RoomOutlined />,
    RoomPreferences: <icons.RoomPreferences />,
    RoomPreferencesOutlined: <icons.RoomPreferencesOutlined />,
    RoomPreferencesRounded: <icons.RoomPreferencesRounded />,
    RoomPreferencesSharp: <icons.RoomPreferencesSharp />,
    RoomPreferencesTwoTone: <icons.RoomPreferencesTwoTone />,
    RoomRounded: <icons.RoomRounded />,
    RoomService: <icons.RoomService />,
    RoomServiceOutlined: <icons.RoomServiceOutlined />,
    RoomServiceRounded: <icons.RoomServiceRounded />,
    RoomServiceSharp: <icons.RoomServiceSharp />,
    RoomServiceTwoTone: <icons.RoomServiceTwoTone />,
    RoomSharp: <icons.RoomSharp />,
    RoomTwoTone: <icons.RoomTwoTone />,
    Rotate90DegreesCcw: <icons.Rotate90DegreesCcw />,
    Rotate90DegreesCcwOutlined: <icons.Rotate90DegreesCcwOutlined />,
    Rotate90DegreesCcwRounded: <icons.Rotate90DegreesCcwRounded />,
    Rotate90DegreesCcwSharp: <icons.Rotate90DegreesCcwSharp />,
    Rotate90DegreesCcwTwoTone: <icons.Rotate90DegreesCcwTwoTone />,
    Rotate90DegreesCw: <icons.Rotate90DegreesCw />,
    Rotate90DegreesCwOutlined: <icons.Rotate90DegreesCwOutlined />,
    Rotate90DegreesCwRounded: <icons.Rotate90DegreesCwRounded />,
    Rotate90DegreesCwSharp: <icons.Rotate90DegreesCwSharp />,
    Rotate90DegreesCwTwoTone: <icons.Rotate90DegreesCwTwoTone />,
    RotateLeft: <icons.RotateLeft />,
    RotateLeftOutlined: <icons.RotateLeftOutlined />,
    RotateLeftRounded: <icons.RotateLeftRounded />,
    RotateLeftSharp: <icons.RotateLeftSharp />,
    RotateLeftTwoTone: <icons.RotateLeftTwoTone />,
    RotateRight: <icons.RotateRight />,
    RotateRightOutlined: <icons.RotateRightOutlined />,
    RotateRightRounded: <icons.RotateRightRounded />,
    RotateRightSharp: <icons.RotateRightSharp />,
    RotateRightTwoTone: <icons.RotateRightTwoTone />,
    RoundaboutLeft: <icons.RoundaboutLeft />,
    RoundaboutLeftOutlined: <icons.RoundaboutLeftOutlined />,
    RoundaboutLeftRounded: <icons.RoundaboutLeftRounded />,
    RoundaboutLeftSharp: <icons.RoundaboutLeftSharp />,
    RoundaboutLeftTwoTone: <icons.RoundaboutLeftTwoTone />,
    RoundaboutRight: <icons.RoundaboutRight />,
    RoundaboutRightOutlined: <icons.RoundaboutRightOutlined />,
    RoundaboutRightRounded: <icons.RoundaboutRightRounded />,
    RoundaboutRightSharp: <icons.RoundaboutRightSharp />,
    RoundaboutRightTwoTone: <icons.RoundaboutRightTwoTone />,
    RoundedCorner: <icons.RoundedCorner />,
    RoundedCornerOutlined: <icons.RoundedCornerOutlined />,
    RoundedCornerRounded: <icons.RoundedCornerRounded />,
    RoundedCornerSharp: <icons.RoundedCornerSharp />,
    RoundedCornerTwoTone: <icons.RoundedCornerTwoTone />,
    Route: <icons.Route />,
    RouteOutlined: <icons.RouteOutlined />,
    RouteRounded: <icons.RouteRounded />,
    RouteSharp: <icons.RouteSharp />,
    RouteTwoTone: <icons.RouteTwoTone />,
    Router: <icons.Router />,
    RouterOutlined: <icons.RouterOutlined />,
    RouterRounded: <icons.RouterRounded />,
    RouterSharp: <icons.RouterSharp />,
    RouterTwoTone: <icons.RouterTwoTone />,
    Rowing: <icons.Rowing />,
    RowingOutlined: <icons.RowingOutlined />,
    RowingRounded: <icons.RowingRounded />,
    RowingSharp: <icons.RowingSharp />,
    RowingTwoTone: <icons.RowingTwoTone />,
    RssFeed: <icons.RssFeed />,
    RssFeedOutlined: <icons.RssFeedOutlined />,
    RssFeedRounded: <icons.RssFeedRounded />,
    RssFeedSharp: <icons.RssFeedSharp />,
    RssFeedTwoTone: <icons.RssFeedTwoTone />,
    Rsvp: <icons.Rsvp />,
    RsvpOutlined: <icons.RsvpOutlined />,
    RsvpRounded: <icons.RsvpRounded />,
    RsvpSharp: <icons.RsvpSharp />,
    RsvpTwoTone: <icons.RsvpTwoTone />,
    Rtt: <icons.Rtt />,
    RttOutlined: <icons.RttOutlined />,
    RttRounded: <icons.RttRounded />,
    RttSharp: <icons.RttSharp />,
    RttTwoTone: <icons.RttTwoTone />,
    Rule: <icons.Rule />,
    RuleFolder: <icons.RuleFolder />,
    RuleFolderOutlined: <icons.RuleFolderOutlined />,
    RuleFolderRounded: <icons.RuleFolderRounded />,
    RuleFolderSharp: <icons.RuleFolderSharp />,
    RuleFolderTwoTone: <icons.RuleFolderTwoTone />,
    RuleOutlined: <icons.RuleOutlined />,
    RuleRounded: <icons.RuleRounded />,
    RuleSharp: <icons.RuleSharp />,
    RuleTwoTone: <icons.RuleTwoTone />,
    RunCircle: <icons.RunCircle />,
    RunCircleOutlined: <icons.RunCircleOutlined />,
    RunCircleRounded: <icons.RunCircleRounded />,
    RunCircleSharp: <icons.RunCircleSharp />,
    RunCircleTwoTone: <icons.RunCircleTwoTone />,
    RunningWithErrors: <icons.RunningWithErrors />,
    RunningWithErrorsOutlined: <icons.RunningWithErrorsOutlined />,
    RunningWithErrorsRounded: <icons.RunningWithErrorsRounded />,
    RunningWithErrorsSharp: <icons.RunningWithErrorsSharp />,
    RunningWithErrorsTwoTone: <icons.RunningWithErrorsTwoTone />,
    RvHookup: <icons.RvHookup />,
    RvHookupOutlined: <icons.RvHookupOutlined />,
    RvHookupRounded: <icons.RvHookupRounded />,
    RvHookupSharp: <icons.RvHookupSharp />,
    RvHookupTwoTone: <icons.RvHookupTwoTone />,
    SafetyCheck: <icons.SafetyCheck />,
    SafetyCheckOutlined: <icons.SafetyCheckOutlined />,
    SafetyCheckRounded: <icons.SafetyCheckRounded />,
    SafetyCheckSharp: <icons.SafetyCheckSharp />,
    SafetyCheckTwoTone: <icons.SafetyCheckTwoTone />,
    SafetyDivider: <icons.SafetyDivider />,
    SafetyDividerOutlined: <icons.SafetyDividerOutlined />,
    SafetyDividerRounded: <icons.SafetyDividerRounded />,
    SafetyDividerSharp: <icons.SafetyDividerSharp />,
    SafetyDividerTwoTone: <icons.SafetyDividerTwoTone />,
    Sailing: <icons.Sailing />,
    SailingOutlined: <icons.SailingOutlined />,
    SailingRounded: <icons.SailingRounded />,
    SailingSharp: <icons.SailingSharp />,
    SailingTwoTone: <icons.SailingTwoTone />,
    Sanitizer: <icons.Sanitizer />,
    SanitizerOutlined: <icons.SanitizerOutlined />,
    SanitizerRounded: <icons.SanitizerRounded />,
    SanitizerSharp: <icons.SanitizerSharp />,
    SanitizerTwoTone: <icons.SanitizerTwoTone />,
    Satellite: <icons.Satellite />,
    SatelliteAlt: <icons.SatelliteAlt />,
    SatelliteAltOutlined: <icons.SatelliteAltOutlined />,
    SatelliteAltRounded: <icons.SatelliteAltRounded />,
    SatelliteAltSharp: <icons.SatelliteAltSharp />,
    SatelliteAltTwoTone: <icons.SatelliteAltTwoTone />,
    SatelliteOutlined: <icons.SatelliteOutlined />,
    SatelliteRounded: <icons.SatelliteRounded />,
    SatelliteSharp: <icons.SatelliteSharp />,
    SatelliteTwoTone: <icons.SatelliteTwoTone />,
    Save: <icons.Save />,
    SaveAlt: <icons.SaveAlt />,
    SaveAltOutlined: <icons.SaveAltOutlined />,
    SaveAltRounded: <icons.SaveAltRounded />,
    SaveAltSharp: <icons.SaveAltSharp />,
    SaveAltTwoTone: <icons.SaveAltTwoTone />,
    SaveAs: <icons.SaveAs />,
    SaveAsOutlined: <icons.SaveAsOutlined />,
    SaveAsRounded: <icons.SaveAsRounded />,
    SaveAsSharp: <icons.SaveAsSharp />,
    SaveAsTwoTone: <icons.SaveAsTwoTone />,
    SaveOutlined: <icons.SaveOutlined />,
    SaveRounded: <icons.SaveRounded />,
    SaveSharp: <icons.SaveSharp />,
    SaveTwoTone: <icons.SaveTwoTone />,
    SavedSearch: <icons.SavedSearch />,
    SavedSearchOutlined: <icons.SavedSearchOutlined />,
    SavedSearchRounded: <icons.SavedSearchRounded />,
    SavedSearchSharp: <icons.SavedSearchSharp />,
    SavedSearchTwoTone: <icons.SavedSearchTwoTone />,
    Savings: <icons.Savings />,
    SavingsOutlined: <icons.SavingsOutlined />,
    SavingsRounded: <icons.SavingsRounded />,
    SavingsSharp: <icons.SavingsSharp />,
    SavingsTwoTone: <icons.SavingsTwoTone />,
    Scale: <icons.Scale />,
    ScaleOutlined: <icons.ScaleOutlined />,
    ScaleRounded: <icons.ScaleRounded />,
    ScaleSharp: <icons.ScaleSharp />,
    ScaleTwoTone: <icons.ScaleTwoTone />,
    Scanner: <icons.Scanner />,
    ScannerOutlined: <icons.ScannerOutlined />,
    ScannerRounded: <icons.ScannerRounded />,
    ScannerSharp: <icons.ScannerSharp />,
    ScannerTwoTone: <icons.ScannerTwoTone />,
    ScatterPlot: <icons.ScatterPlot />,
    ScatterPlotOutlined: <icons.ScatterPlotOutlined />,
    ScatterPlotRounded: <icons.ScatterPlotRounded />,
    ScatterPlotSharp: <icons.ScatterPlotSharp />,
    ScatterPlotTwoTone: <icons.ScatterPlotTwoTone />,
    Schedule: <icons.Schedule />,
    ScheduleOutlined: <icons.ScheduleOutlined />,
    ScheduleRounded: <icons.ScheduleRounded />,
    ScheduleSend: <icons.ScheduleSend />,
    ScheduleSendOutlined: <icons.ScheduleSendOutlined />,
    ScheduleSendRounded: <icons.ScheduleSendRounded />,
    ScheduleSendSharp: <icons.ScheduleSendSharp />,
    ScheduleSendTwoTone: <icons.ScheduleSendTwoTone />,
    ScheduleSharp: <icons.ScheduleSharp />,
    ScheduleTwoTone: <icons.ScheduleTwoTone />,
    Schema: <icons.Schema />,
    SchemaOutlined: <icons.SchemaOutlined />,
    SchemaRounded: <icons.SchemaRounded />,
    SchemaSharp: <icons.SchemaSharp />,
    SchemaTwoTone: <icons.SchemaTwoTone />,
    School: <icons.School />,
    SchoolOutlined: <icons.SchoolOutlined />,
    SchoolRounded: <icons.SchoolRounded />,
    SchoolSharp: <icons.SchoolSharp />,
    SchoolTwoTone: <icons.SchoolTwoTone />,
    Science: <icons.Science />,
    ScienceOutlined: <icons.ScienceOutlined />,
    ScienceRounded: <icons.ScienceRounded />,
    ScienceSharp: <icons.ScienceSharp />,
    ScienceTwoTone: <icons.ScienceTwoTone />,
    Score: <icons.Score />,
    ScoreOutlined: <icons.ScoreOutlined />,
    ScoreRounded: <icons.ScoreRounded />,
    ScoreSharp: <icons.ScoreSharp />,
    ScoreTwoTone: <icons.ScoreTwoTone />,
    Scoreboard: <icons.Scoreboard />,
    ScoreboardOutlined: <icons.ScoreboardOutlined />,
    ScoreboardRounded: <icons.ScoreboardRounded />,
    ScoreboardSharp: <icons.ScoreboardSharp />,
    ScoreboardTwoTone: <icons.ScoreboardTwoTone />,
    ScreenLockLandscape: <icons.ScreenLockLandscape />,
    ScreenLockLandscapeOutlined: <icons.ScreenLockLandscapeOutlined />,
    ScreenLockLandscapeRounded: <icons.ScreenLockLandscapeRounded />,
    ScreenLockLandscapeSharp: <icons.ScreenLockLandscapeSharp />,
    ScreenLockLandscapeTwoTone: <icons.ScreenLockLandscapeTwoTone />,
    ScreenLockPortrait: <icons.ScreenLockPortrait />,
    ScreenLockPortraitOutlined: <icons.ScreenLockPortraitOutlined />,
    ScreenLockPortraitRounded: <icons.ScreenLockPortraitRounded />,
    ScreenLockPortraitSharp: <icons.ScreenLockPortraitSharp />,
    ScreenLockPortraitTwoTone: <icons.ScreenLockPortraitTwoTone />,
    ScreenLockRotation: <icons.ScreenLockRotation />,
    ScreenLockRotationOutlined: <icons.ScreenLockRotationOutlined />,
    ScreenLockRotationRounded: <icons.ScreenLockRotationRounded />,
    ScreenLockRotationSharp: <icons.ScreenLockRotationSharp />,
    ScreenLockRotationTwoTone: <icons.ScreenLockRotationTwoTone />,
    ScreenRotation: <icons.ScreenRotation />,
    ScreenRotationAlt: <icons.ScreenRotationAlt />,
    ScreenRotationAltOutlined: <icons.ScreenRotationAltOutlined />,
    ScreenRotationAltRounded: <icons.ScreenRotationAltRounded />,
    ScreenRotationAltSharp: <icons.ScreenRotationAltSharp />,
    ScreenRotationAltTwoTone: <icons.ScreenRotationAltTwoTone />,
    ScreenRotationOutlined: <icons.ScreenRotationOutlined />,
    ScreenRotationRounded: <icons.ScreenRotationRounded />,
    ScreenRotationSharp: <icons.ScreenRotationSharp />,
    ScreenRotationTwoTone: <icons.ScreenRotationTwoTone />,
    ScreenSearchDesktop: <icons.ScreenSearchDesktop />,
    ScreenSearchDesktopOutlined: <icons.ScreenSearchDesktopOutlined />,
    ScreenSearchDesktopRounded: <icons.ScreenSearchDesktopRounded />,
    ScreenSearchDesktopSharp: <icons.ScreenSearchDesktopSharp />,
    ScreenSearchDesktopTwoTone: <icons.ScreenSearchDesktopTwoTone />,
    ScreenShare: <icons.ScreenShare />,
    ScreenShareOutlined: <icons.ScreenShareOutlined />,
    ScreenShareRounded: <icons.ScreenShareRounded />,
    ScreenShareSharp: <icons.ScreenShareSharp />,
    ScreenShareTwoTone: <icons.ScreenShareTwoTone />,
    Screenshot: <icons.Screenshot />,
    ScreenshotMonitor: <icons.ScreenshotMonitor />,
    ScreenshotMonitorOutlined: <icons.ScreenshotMonitorOutlined />,
    ScreenshotMonitorRounded: <icons.ScreenshotMonitorRounded />,
    ScreenshotMonitorSharp: <icons.ScreenshotMonitorSharp />,
    ScreenshotMonitorTwoTone: <icons.ScreenshotMonitorTwoTone />,
    ScreenshotOutlined: <icons.ScreenshotOutlined />,
    ScreenshotRounded: <icons.ScreenshotRounded />,
    ScreenshotSharp: <icons.ScreenshotSharp />,
    ScreenshotTwoTone: <icons.ScreenshotTwoTone />,
    ScubaDiving: <icons.ScubaDiving />,
    ScubaDivingOutlined: <icons.ScubaDivingOutlined />,
    ScubaDivingRounded: <icons.ScubaDivingRounded />,
    ScubaDivingSharp: <icons.ScubaDivingSharp />,
    ScubaDivingTwoTone: <icons.ScubaDivingTwoTone />,
    Sd: <icons.Sd />,
    SdCard: <icons.SdCard />,
    SdCardAlert: <icons.SdCardAlert />,
    SdCardAlertOutlined: <icons.SdCardAlertOutlined />,
    SdCardAlertRounded: <icons.SdCardAlertRounded />,
    SdCardAlertSharp: <icons.SdCardAlertSharp />,
    SdCardAlertTwoTone: <icons.SdCardAlertTwoTone />,
    SdCardOutlined: <icons.SdCardOutlined />,
    SdCardRounded: <icons.SdCardRounded />,
    SdCardSharp: <icons.SdCardSharp />,
    SdCardTwoTone: <icons.SdCardTwoTone />,
    SdOutlined: <icons.SdOutlined />,
    SdRounded: <icons.SdRounded />,
    SdSharp: <icons.SdSharp />,
    SdStorage: <icons.SdStorage />,
    SdStorageOutlined: <icons.SdStorageOutlined />,
    SdStorageRounded: <icons.SdStorageRounded />,
    SdStorageSharp: <icons.SdStorageSharp />,
    SdStorageTwoTone: <icons.SdStorageTwoTone />,
    SdTwoTone: <icons.SdTwoTone />,
    Search: <icons.Search />,
    SearchOff: <icons.SearchOff />,
    SearchOffOutlined: <icons.SearchOffOutlined />,
    SearchOffRounded: <icons.SearchOffRounded />,
    SearchOffSharp: <icons.SearchOffSharp />,
    SearchOffTwoTone: <icons.SearchOffTwoTone />,
    SearchOutlined: <icons.SearchOutlined />,
    SearchRounded: <icons.SearchRounded />,
    SearchSharp: <icons.SearchSharp />,
    SearchTwoTone: <icons.SearchTwoTone />,
    Security: <icons.Security />,
    SecurityOutlined: <icons.SecurityOutlined />,
    SecurityRounded: <icons.SecurityRounded />,
    SecuritySharp: <icons.SecuritySharp />,
    SecurityTwoTone: <icons.SecurityTwoTone />,
    SecurityUpdate: <icons.SecurityUpdate />,
    SecurityUpdateGood: <icons.SecurityUpdateGood />,
    SecurityUpdateGoodOutlined: <icons.SecurityUpdateGoodOutlined />,
    SecurityUpdateGoodRounded: <icons.SecurityUpdateGoodRounded />,
    SecurityUpdateGoodSharp: <icons.SecurityUpdateGoodSharp />,
    SecurityUpdateGoodTwoTone: <icons.SecurityUpdateGoodTwoTone />,
    SecurityUpdateOutlined: <icons.SecurityUpdateOutlined />,
    SecurityUpdateRounded: <icons.SecurityUpdateRounded />,
    SecurityUpdateSharp: <icons.SecurityUpdateSharp />,
    SecurityUpdateTwoTone: <icons.SecurityUpdateTwoTone />,
    SecurityUpdateWarning: <icons.SecurityUpdateWarning />,
    SecurityUpdateWarningOutlined: <icons.SecurityUpdateWarningOutlined />,
    SecurityUpdateWarningRounded: <icons.SecurityUpdateWarningRounded />,
    SecurityUpdateWarningSharp: <icons.SecurityUpdateWarningSharp />,
    SecurityUpdateWarningTwoTone: <icons.SecurityUpdateWarningTwoTone />,
    Segment: <icons.Segment />,
    SegmentOutlined: <icons.SegmentOutlined />,
    SegmentRounded: <icons.SegmentRounded />,
    SegmentSharp: <icons.SegmentSharp />,
    SegmentTwoTone: <icons.SegmentTwoTone />,
    SelectAll: <icons.SelectAll />,
    SelectAllOutlined: <icons.SelectAllOutlined />,
    SelectAllRounded: <icons.SelectAllRounded />,
    SelectAllSharp: <icons.SelectAllSharp />,
    SelectAllTwoTone: <icons.SelectAllTwoTone />,
    SelfImprovement: <icons.SelfImprovement />,
    SelfImprovementOutlined: <icons.SelfImprovementOutlined />,
    SelfImprovementRounded: <icons.SelfImprovementRounded />,
    SelfImprovementSharp: <icons.SelfImprovementSharp />,
    SelfImprovementTwoTone: <icons.SelfImprovementTwoTone />,
    Sell: <icons.Sell />,
    SellOutlined: <icons.SellOutlined />,
    SellRounded: <icons.SellRounded />,
    SellSharp: <icons.SellSharp />,
    SellTwoTone: <icons.SellTwoTone />,
    Send: <icons.Send />,
    SendAndArchive: <icons.SendAndArchive />,
    SendAndArchiveOutlined: <icons.SendAndArchiveOutlined />,
    SendAndArchiveRounded: <icons.SendAndArchiveRounded />,
    SendAndArchiveSharp: <icons.SendAndArchiveSharp />,
    SendAndArchiveTwoTone: <icons.SendAndArchiveTwoTone />,
    SendOutlined: <icons.SendOutlined />,
    SendRounded: <icons.SendRounded />,
    SendSharp: <icons.SendSharp />,
    SendTimeExtension: <icons.SendTimeExtension />,
    SendTimeExtensionOutlined: <icons.SendTimeExtensionOutlined />,
    SendTimeExtensionRounded: <icons.SendTimeExtensionRounded />,
    SendTimeExtensionSharp: <icons.SendTimeExtensionSharp />,
    SendTimeExtensionTwoTone: <icons.SendTimeExtensionTwoTone />,
    SendToMobile: <icons.SendToMobile />,
    SendToMobileOutlined: <icons.SendToMobileOutlined />,
    SendToMobileRounded: <icons.SendToMobileRounded />,
    SendToMobileSharp: <icons.SendToMobileSharp />,
    SendToMobileTwoTone: <icons.SendToMobileTwoTone />,
    SendTwoTone: <icons.SendTwoTone />,
    SensorDoor: <icons.SensorDoor />,
    SensorDoorOutlined: <icons.SensorDoorOutlined />,
    SensorDoorRounded: <icons.SensorDoorRounded />,
    SensorDoorSharp: <icons.SensorDoorSharp />,
    SensorDoorTwoTone: <icons.SensorDoorTwoTone />,
    SensorOccupied: <icons.SensorOccupied />,
    SensorOccupiedOutlined: <icons.SensorOccupiedOutlined />,
    SensorOccupiedRounded: <icons.SensorOccupiedRounded />,
    SensorOccupiedSharp: <icons.SensorOccupiedSharp />,
    SensorOccupiedTwoTone: <icons.SensorOccupiedTwoTone />,
    SensorWindow: <icons.SensorWindow />,
    SensorWindowOutlined: <icons.SensorWindowOutlined />,
    SensorWindowRounded: <icons.SensorWindowRounded />,
    SensorWindowSharp: <icons.SensorWindowSharp />,
    SensorWindowTwoTone: <icons.SensorWindowTwoTone />,
    Sensors: <icons.Sensors />,
    SensorsOff: <icons.SensorsOff />,
    SensorsOffOutlined: <icons.SensorsOffOutlined />,
    SensorsOffRounded: <icons.SensorsOffRounded />,
    SensorsOffSharp: <icons.SensorsOffSharp />,
    SensorsOffTwoTone: <icons.SensorsOffTwoTone />,
    SensorsOutlined: <icons.SensorsOutlined />,
    SensorsRounded: <icons.SensorsRounded />,
    SensorsSharp: <icons.SensorsSharp />,
    SensorsTwoTone: <icons.SensorsTwoTone />,
    SentimentDissatisfied: <icons.SentimentDissatisfied />,
    SentimentDissatisfiedOutlined: <icons.SentimentDissatisfiedOutlined />,
    SentimentDissatisfiedRounded: <icons.SentimentDissatisfiedRounded />,
    SentimentDissatisfiedSharp: <icons.SentimentDissatisfiedSharp />,
    SentimentDissatisfiedTwoTone: <icons.SentimentDissatisfiedTwoTone />,
    SentimentNeutral: <icons.SentimentNeutral />,
    SentimentNeutralOutlined: <icons.SentimentNeutralOutlined />,
    SentimentNeutralRounded: <icons.SentimentNeutralRounded />,
    SentimentNeutralSharp: <icons.SentimentNeutralSharp />,
    SentimentNeutralTwoTone: <icons.SentimentNeutralTwoTone />,
    SentimentSatisfied: <icons.SentimentSatisfied />,
    SentimentSatisfiedAlt: <icons.SentimentSatisfiedAlt />,
    SentimentSatisfiedAltOutlined: <icons.SentimentSatisfiedAltOutlined />,
    SentimentSatisfiedAltRounded: <icons.SentimentSatisfiedAltRounded />,
    SentimentSatisfiedAltSharp: <icons.SentimentSatisfiedAltSharp />,
    SentimentSatisfiedAltTwoTone: <icons.SentimentSatisfiedAltTwoTone />,
    SentimentSatisfiedOutlined: <icons.SentimentSatisfiedOutlined />,
    SentimentSatisfiedRounded: <icons.SentimentSatisfiedRounded />,
    SentimentSatisfiedSharp: <icons.SentimentSatisfiedSharp />,
    SentimentSatisfiedTwoTone: <icons.SentimentSatisfiedTwoTone />,
    SentimentVeryDissatisfied: <icons.SentimentVeryDissatisfied />,
    SentimentVeryDissatisfiedOutlined: (
        <icons.SentimentVeryDissatisfiedOutlined />
    ),
    SentimentVeryDissatisfiedRounded: (
        <icons.SentimentVeryDissatisfiedRounded />
    ),
    SentimentVeryDissatisfiedSharp: <icons.SentimentVeryDissatisfiedSharp />,
    SentimentVeryDissatisfiedTwoTone: (
        <icons.SentimentVeryDissatisfiedTwoTone />
    ),
    SentimentVerySatisfied: <icons.SentimentVerySatisfied />,
    SentimentVerySatisfiedOutlined: <icons.SentimentVerySatisfiedOutlined />,
    SentimentVerySatisfiedRounded: <icons.SentimentVerySatisfiedRounded />,
    SentimentVerySatisfiedSharp: <icons.SentimentVerySatisfiedSharp />,
    SentimentVerySatisfiedTwoTone: <icons.SentimentVerySatisfiedTwoTone />,
    SetMeal: <icons.SetMeal />,
    SetMealOutlined: <icons.SetMealOutlined />,
    SetMealRounded: <icons.SetMealRounded />,
    SetMealSharp: <icons.SetMealSharp />,
    SetMealTwoTone: <icons.SetMealTwoTone />,
    Settings: <icons.Settings />,
    SettingsAccessibility: <icons.SettingsAccessibility />,
    SettingsAccessibilityOutlined: <icons.SettingsAccessibilityOutlined />,
    SettingsAccessibilityRounded: <icons.SettingsAccessibilityRounded />,
    SettingsAccessibilitySharp: <icons.SettingsAccessibilitySharp />,
    SettingsAccessibilityTwoTone: <icons.SettingsAccessibilityTwoTone />,
    SettingsApplications: <icons.SettingsApplications />,
    SettingsApplicationsOutlined: <icons.SettingsApplicationsOutlined />,
    SettingsApplicationsRounded: <icons.SettingsApplicationsRounded />,
    SettingsApplicationsSharp: <icons.SettingsApplicationsSharp />,
    SettingsApplicationsTwoTone: <icons.SettingsApplicationsTwoTone />,
    SettingsBackupRestore: <icons.SettingsBackupRestore />,
    SettingsBackupRestoreOutlined: <icons.SettingsBackupRestoreOutlined />,
    SettingsBackupRestoreRounded: <icons.SettingsBackupRestoreRounded />,
    SettingsBackupRestoreSharp: <icons.SettingsBackupRestoreSharp />,
    SettingsBackupRestoreTwoTone: <icons.SettingsBackupRestoreTwoTone />,
    SettingsBluetooth: <icons.SettingsBluetooth />,
    SettingsBluetoothOutlined: <icons.SettingsBluetoothOutlined />,
    SettingsBluetoothRounded: <icons.SettingsBluetoothRounded />,
    SettingsBluetoothSharp: <icons.SettingsBluetoothSharp />,
    SettingsBluetoothTwoTone: <icons.SettingsBluetoothTwoTone />,
    SettingsBrightness: <icons.SettingsBrightness />,
    SettingsBrightnessOutlined: <icons.SettingsBrightnessOutlined />,
    SettingsBrightnessRounded: <icons.SettingsBrightnessRounded />,
    SettingsBrightnessSharp: <icons.SettingsBrightnessSharp />,
    SettingsBrightnessTwoTone: <icons.SettingsBrightnessTwoTone />,
    SettingsCell: <icons.SettingsCell />,
    SettingsCellOutlined: <icons.SettingsCellOutlined />,
    SettingsCellRounded: <icons.SettingsCellRounded />,
    SettingsCellSharp: <icons.SettingsCellSharp />,
    SettingsCellTwoTone: <icons.SettingsCellTwoTone />,
    SettingsEthernet: <icons.SettingsEthernet />,
    SettingsEthernetOutlined: <icons.SettingsEthernetOutlined />,
    SettingsEthernetRounded: <icons.SettingsEthernetRounded />,
    SettingsEthernetSharp: <icons.SettingsEthernetSharp />,
    SettingsEthernetTwoTone: <icons.SettingsEthernetTwoTone />,
    SettingsInputAntenna: <icons.SettingsInputAntenna />,
    SettingsInputAntennaOutlined: <icons.SettingsInputAntennaOutlined />,
    SettingsInputAntennaRounded: <icons.SettingsInputAntennaRounded />,
    SettingsInputAntennaSharp: <icons.SettingsInputAntennaSharp />,
    SettingsInputAntennaTwoTone: <icons.SettingsInputAntennaTwoTone />,
    SettingsInputComponent: <icons.SettingsInputComponent />,
    SettingsInputComponentOutlined: <icons.SettingsInputComponentOutlined />,
    SettingsInputComponentRounded: <icons.SettingsInputComponentRounded />,
    SettingsInputComponentSharp: <icons.SettingsInputComponentSharp />,
    SettingsInputComponentTwoTone: <icons.SettingsInputComponentTwoTone />,
    SettingsInputComposite: <icons.SettingsInputComposite />,
    SettingsInputCompositeOutlined: <icons.SettingsInputCompositeOutlined />,
    SettingsInputCompositeRounded: <icons.SettingsInputCompositeRounded />,
    SettingsInputCompositeSharp: <icons.SettingsInputCompositeSharp />,
    SettingsInputCompositeTwoTone: <icons.SettingsInputCompositeTwoTone />,
    SettingsInputHdmi: <icons.SettingsInputHdmi />,
    SettingsInputHdmiOutlined: <icons.SettingsInputHdmiOutlined />,
    SettingsInputHdmiRounded: <icons.SettingsInputHdmiRounded />,
    SettingsInputHdmiSharp: <icons.SettingsInputHdmiSharp />,
    SettingsInputHdmiTwoTone: <icons.SettingsInputHdmiTwoTone />,
    SettingsInputSvideo: <icons.SettingsInputSvideo />,
    SettingsInputSvideoOutlined: <icons.SettingsInputSvideoOutlined />,
    SettingsInputSvideoRounded: <icons.SettingsInputSvideoRounded />,
    SettingsInputSvideoSharp: <icons.SettingsInputSvideoSharp />,
    SettingsInputSvideoTwoTone: <icons.SettingsInputSvideoTwoTone />,
    SettingsOutlined: <icons.SettingsOutlined />,
    SettingsOverscan: <icons.SettingsOverscan />,
    SettingsOverscanOutlined: <icons.SettingsOverscanOutlined />,
    SettingsOverscanRounded: <icons.SettingsOverscanRounded />,
    SettingsOverscanSharp: <icons.SettingsOverscanSharp />,
    SettingsOverscanTwoTone: <icons.SettingsOverscanTwoTone />,
    SettingsPhone: <icons.SettingsPhone />,
    SettingsPhoneOutlined: <icons.SettingsPhoneOutlined />,
    SettingsPhoneRounded: <icons.SettingsPhoneRounded />,
    SettingsPhoneSharp: <icons.SettingsPhoneSharp />,
    SettingsPhoneTwoTone: <icons.SettingsPhoneTwoTone />,
    SettingsPower: <icons.SettingsPower />,
    SettingsPowerOutlined: <icons.SettingsPowerOutlined />,
    SettingsPowerRounded: <icons.SettingsPowerRounded />,
    SettingsPowerSharp: <icons.SettingsPowerSharp />,
    SettingsPowerTwoTone: <icons.SettingsPowerTwoTone />,
    SettingsRemote: <icons.SettingsRemote />,
    SettingsRemoteOutlined: <icons.SettingsRemoteOutlined />,
    SettingsRemoteRounded: <icons.SettingsRemoteRounded />,
    SettingsRemoteSharp: <icons.SettingsRemoteSharp />,
    SettingsRemoteTwoTone: <icons.SettingsRemoteTwoTone />,
    SettingsRounded: <icons.SettingsRounded />,
    SettingsSharp: <icons.SettingsSharp />,
    SettingsSuggest: <icons.SettingsSuggest />,
    SettingsSuggestOutlined: <icons.SettingsSuggestOutlined />,
    SettingsSuggestRounded: <icons.SettingsSuggestRounded />,
    SettingsSuggestSharp: <icons.SettingsSuggestSharp />,
    SettingsSuggestTwoTone: <icons.SettingsSuggestTwoTone />,
    SettingsSystemDaydream: <icons.SettingsSystemDaydream />,
    SettingsSystemDaydreamOutlined: <icons.SettingsSystemDaydreamOutlined />,
    SettingsSystemDaydreamRounded: <icons.SettingsSystemDaydreamRounded />,
    SettingsSystemDaydreamSharp: <icons.SettingsSystemDaydreamSharp />,
    SettingsSystemDaydreamTwoTone: <icons.SettingsSystemDaydreamTwoTone />,
    SettingsTwoTone: <icons.SettingsTwoTone />,
    SettingsVoice: <icons.SettingsVoice />,
    SettingsVoiceOutlined: <icons.SettingsVoiceOutlined />,
    SettingsVoiceRounded: <icons.SettingsVoiceRounded />,
    SettingsVoiceSharp: <icons.SettingsVoiceSharp />,
    SettingsVoiceTwoTone: <icons.SettingsVoiceTwoTone />,
    SevenK: <icons.SevenK />,
    SevenKOutlined: <icons.SevenKOutlined />,
    SevenKPlus: <icons.SevenKPlus />,
    SevenKPlusOutlined: <icons.SevenKPlusOutlined />,
    SevenKPlusRounded: <icons.SevenKPlusRounded />,
    SevenKPlusSharp: <icons.SevenKPlusSharp />,
    SevenKPlusTwoTone: <icons.SevenKPlusTwoTone />,
    SevenKRounded: <icons.SevenKRounded />,
    SevenKSharp: <icons.SevenKSharp />,
    SevenKTwoTone: <icons.SevenKTwoTone />,
    SevenMp: <icons.SevenMp />,
    SevenMpOutlined: <icons.SevenMpOutlined />,
    SevenMpRounded: <icons.SevenMpRounded />,
    SevenMpSharp: <icons.SevenMpSharp />,
    SevenMpTwoTone: <icons.SevenMpTwoTone />,
    SeventeenMp: <icons.SeventeenMp />,
    SeventeenMpOutlined: <icons.SeventeenMpOutlined />,
    SeventeenMpRounded: <icons.SeventeenMpRounded />,
    SeventeenMpSharp: <icons.SeventeenMpSharp />,
    SeventeenMpTwoTone: <icons.SeventeenMpTwoTone />,
    SevereCold: <icons.SevereCold />,
    SevereColdOutlined: <icons.SevereColdOutlined />,
    SevereColdRounded: <icons.SevereColdRounded />,
    SevereColdSharp: <icons.SevereColdSharp />,
    SevereColdTwoTone: <icons.SevereColdTwoTone />,
    ShapeLine: <icons.ShapeLine />,
    ShapeLineOutlined: <icons.ShapeLineOutlined />,
    ShapeLineRounded: <icons.ShapeLineRounded />,
    ShapeLineSharp: <icons.ShapeLineSharp />,
    ShapeLineTwoTone: <icons.ShapeLineTwoTone />,
    Share: <icons.Share />,
    ShareLocation: <icons.ShareLocation />,
    ShareLocationOutlined: <icons.ShareLocationOutlined />,
    ShareLocationRounded: <icons.ShareLocationRounded />,
    ShareLocationSharp: <icons.ShareLocationSharp />,
    ShareLocationTwoTone: <icons.ShareLocationTwoTone />,
    ShareOutlined: <icons.ShareOutlined />,
    ShareRounded: <icons.ShareRounded />,
    ShareSharp: <icons.ShareSharp />,
    ShareTwoTone: <icons.ShareTwoTone />,
    Shield: <icons.Shield />,
    ShieldMoon: <icons.ShieldMoon />,
    ShieldMoonOutlined: <icons.ShieldMoonOutlined />,
    ShieldMoonRounded: <icons.ShieldMoonRounded />,
    ShieldMoonSharp: <icons.ShieldMoonSharp />,
    ShieldMoonTwoTone: <icons.ShieldMoonTwoTone />,
    ShieldOutlined: <icons.ShieldOutlined />,
    ShieldRounded: <icons.ShieldRounded />,
    ShieldSharp: <icons.ShieldSharp />,
    ShieldTwoTone: <icons.ShieldTwoTone />,
    Shop: <icons.Shop />,
    Shop2: <icons.Shop2 />,
    Shop2Outlined: <icons.Shop2Outlined />,
    Shop2Rounded: <icons.Shop2Rounded />,
    Shop2Sharp: <icons.Shop2Sharp />,
    Shop2TwoTone: <icons.Shop2TwoTone />,
    ShopOutlined: <icons.ShopOutlined />,
    ShopRounded: <icons.ShopRounded />,
    ShopSharp: <icons.ShopSharp />,
    ShopTwo: <icons.ShopTwo />,
    ShopTwoOutlined: <icons.ShopTwoOutlined />,
    ShopTwoRounded: <icons.ShopTwoRounded />,
    ShopTwoSharp: <icons.ShopTwoSharp />,
    ShopTwoTone: <icons.ShopTwoTone />,
    ShopTwoTwoTone: <icons.ShopTwoTwoTone />,
    ShoppingBag: <icons.ShoppingBag />,
    ShoppingBagOutlined: <icons.ShoppingBagOutlined />,
    ShoppingBagRounded: <icons.ShoppingBagRounded />,
    ShoppingBagSharp: <icons.ShoppingBagSharp />,
    ShoppingBagTwoTone: <icons.ShoppingBagTwoTone />,
    ShoppingBasket: <icons.ShoppingBasket />,
    ShoppingBasketOutlined: <icons.ShoppingBasketOutlined />,
    ShoppingBasketRounded: <icons.ShoppingBasketRounded />,
    ShoppingBasketSharp: <icons.ShoppingBasketSharp />,
    ShoppingBasketTwoTone: <icons.ShoppingBasketTwoTone />,
    ShoppingCart: <icons.ShoppingCart />,
    ShoppingCartCheckout: <icons.ShoppingCartCheckout />,
    ShoppingCartCheckoutOutlined: <icons.ShoppingCartCheckoutOutlined />,
    ShoppingCartCheckoutRounded: <icons.ShoppingCartCheckoutRounded />,
    ShoppingCartCheckoutSharp: <icons.ShoppingCartCheckoutSharp />,
    ShoppingCartCheckoutTwoTone: <icons.ShoppingCartCheckoutTwoTone />,
    ShoppingCartOutlined: <icons.ShoppingCartOutlined />,
    ShoppingCartRounded: <icons.ShoppingCartRounded />,
    ShoppingCartSharp: <icons.ShoppingCartSharp />,
    ShoppingCartTwoTone: <icons.ShoppingCartTwoTone />,
    ShortText: <icons.ShortText />,
    ShortTextOutlined: <icons.ShortTextOutlined />,
    ShortTextRounded: <icons.ShortTextRounded />,
    ShortTextSharp: <icons.ShortTextSharp />,
    ShortTextTwoTone: <icons.ShortTextTwoTone />,
    Shortcut: <icons.Shortcut />,
    ShortcutOutlined: <icons.ShortcutOutlined />,
    ShortcutRounded: <icons.ShortcutRounded />,
    ShortcutSharp: <icons.ShortcutSharp />,
    ShortcutTwoTone: <icons.ShortcutTwoTone />,
    ShowChart: <icons.ShowChart />,
    ShowChartOutlined: <icons.ShowChartOutlined />,
    ShowChartRounded: <icons.ShowChartRounded />,
    ShowChartSharp: <icons.ShowChartSharp />,
    ShowChartTwoTone: <icons.ShowChartTwoTone />,
    Shower: <icons.Shower />,
    ShowerOutlined: <icons.ShowerOutlined />,
    ShowerRounded: <icons.ShowerRounded />,
    ShowerSharp: <icons.ShowerSharp />,
    ShowerTwoTone: <icons.ShowerTwoTone />,
    Shuffle: <icons.Shuffle />,
    ShuffleOn: <icons.ShuffleOn />,
    ShuffleOnOutlined: <icons.ShuffleOnOutlined />,
    ShuffleOnRounded: <icons.ShuffleOnRounded />,
    ShuffleOnSharp: <icons.ShuffleOnSharp />,
    ShuffleOnTwoTone: <icons.ShuffleOnTwoTone />,
    ShuffleOutlined: <icons.ShuffleOutlined />,
    ShuffleRounded: <icons.ShuffleRounded />,
    ShuffleSharp: <icons.ShuffleSharp />,
    ShuffleTwoTone: <icons.ShuffleTwoTone />,
    ShutterSpeed: <icons.ShutterSpeed />,
    ShutterSpeedOutlined: <icons.ShutterSpeedOutlined />,
    ShutterSpeedRounded: <icons.ShutterSpeedRounded />,
    ShutterSpeedSharp: <icons.ShutterSpeedSharp />,
    ShutterSpeedTwoTone: <icons.ShutterSpeedTwoTone />,
    Sick: <icons.Sick />,
    SickOutlined: <icons.SickOutlined />,
    SickRounded: <icons.SickRounded />,
    SickSharp: <icons.SickSharp />,
    SickTwoTone: <icons.SickTwoTone />,
    SignLanguage: <icons.SignLanguage />,
    SignLanguageOutlined: <icons.SignLanguageOutlined />,
    SignLanguageRounded: <icons.SignLanguageRounded />,
    SignLanguageSharp: <icons.SignLanguageSharp />,
    SignLanguageTwoTone: <icons.SignLanguageTwoTone />,
    SignalCellular0Bar: <icons.SignalCellular0Bar />,
    SignalCellular0BarOutlined: <icons.SignalCellular0BarOutlined />,
    SignalCellular0BarRounded: <icons.SignalCellular0BarRounded />,
    SignalCellular0BarSharp: <icons.SignalCellular0BarSharp />,
    SignalCellular0BarTwoTone: <icons.SignalCellular0BarTwoTone />,
    SignalCellular1Bar: <icons.SignalCellular1Bar />,
    SignalCellular1BarOutlined: <icons.SignalCellular1BarOutlined />,
    SignalCellular1BarRounded: <icons.SignalCellular1BarRounded />,
    SignalCellular1BarSharp: <icons.SignalCellular1BarSharp />,
    SignalCellular1BarTwoTone: <icons.SignalCellular1BarTwoTone />,
    SignalCellular2Bar: <icons.SignalCellular2Bar />,
    SignalCellular2BarOutlined: <icons.SignalCellular2BarOutlined />,
    SignalCellular2BarRounded: <icons.SignalCellular2BarRounded />,
    SignalCellular2BarSharp: <icons.SignalCellular2BarSharp />,
    SignalCellular2BarTwoTone: <icons.SignalCellular2BarTwoTone />,
    SignalCellular3Bar: <icons.SignalCellular3Bar />,
    SignalCellular3BarOutlined: <icons.SignalCellular3BarOutlined />,
    SignalCellular3BarRounded: <icons.SignalCellular3BarRounded />,
    SignalCellular3BarSharp: <icons.SignalCellular3BarSharp />,
    SignalCellular3BarTwoTone: <icons.SignalCellular3BarTwoTone />,
    SignalCellular4Bar: <icons.SignalCellular4Bar />,
    SignalCellular4BarOutlined: <icons.SignalCellular4BarOutlined />,
    SignalCellular4BarRounded: <icons.SignalCellular4BarRounded />,
    SignalCellular4BarSharp: <icons.SignalCellular4BarSharp />,
    SignalCellular4BarTwoTone: <icons.SignalCellular4BarTwoTone />,
    SignalCellularAlt: <icons.SignalCellularAlt />,
    SignalCellularAlt1Bar: <icons.SignalCellularAlt1Bar />,
    SignalCellularAlt1BarOutlined: <icons.SignalCellularAlt1BarOutlined />,
    SignalCellularAlt1BarRounded: <icons.SignalCellularAlt1BarRounded />,
    SignalCellularAlt1BarSharp: <icons.SignalCellularAlt1BarSharp />,
    SignalCellularAlt1BarTwoTone: <icons.SignalCellularAlt1BarTwoTone />,
    SignalCellularAlt2Bar: <icons.SignalCellularAlt2Bar />,
    SignalCellularAlt2BarOutlined: <icons.SignalCellularAlt2BarOutlined />,
    SignalCellularAlt2BarRounded: <icons.SignalCellularAlt2BarRounded />,
    SignalCellularAlt2BarSharp: <icons.SignalCellularAlt2BarSharp />,
    SignalCellularAlt2BarTwoTone: <icons.SignalCellularAlt2BarTwoTone />,
    SignalCellularAltOutlined: <icons.SignalCellularAltOutlined />,
    SignalCellularAltRounded: <icons.SignalCellularAltRounded />,
    SignalCellularAltSharp: <icons.SignalCellularAltSharp />,
    SignalCellularAltTwoTone: <icons.SignalCellularAltTwoTone />,
    SignalCellularConnectedNoInternet0Bar: (
        <icons.SignalCellularConnectedNoInternet0Bar />
    ),
    SignalCellularConnectedNoInternet0BarOutlined: (
        <icons.SignalCellularConnectedNoInternet0BarOutlined />
    ),
    SignalCellularConnectedNoInternet0BarRounded: (
        <icons.SignalCellularConnectedNoInternet0BarRounded />
    ),
    SignalCellularConnectedNoInternet0BarSharp: (
        <icons.SignalCellularConnectedNoInternet0BarSharp />
    ),
    SignalCellularConnectedNoInternet0BarTwoTone: (
        <icons.SignalCellularConnectedNoInternet0BarTwoTone />
    ),
    SignalCellularConnectedNoInternet1Bar: (
        <icons.SignalCellularConnectedNoInternet1Bar />
    ),
    SignalCellularConnectedNoInternet1BarOutlined: (
        <icons.SignalCellularConnectedNoInternet1BarOutlined />
    ),
    SignalCellularConnectedNoInternet1BarRounded: (
        <icons.SignalCellularConnectedNoInternet1BarRounded />
    ),
    SignalCellularConnectedNoInternet1BarSharp: (
        <icons.SignalCellularConnectedNoInternet1BarSharp />
    ),
    SignalCellularConnectedNoInternet1BarTwoTone: (
        <icons.SignalCellularConnectedNoInternet1BarTwoTone />
    ),
    SignalCellularConnectedNoInternet2Bar: (
        <icons.SignalCellularConnectedNoInternet2Bar />
    ),
    SignalCellularConnectedNoInternet2BarOutlined: (
        <icons.SignalCellularConnectedNoInternet2BarOutlined />
    ),
    SignalCellularConnectedNoInternet2BarRounded: (
        <icons.SignalCellularConnectedNoInternet2BarRounded />
    ),
    SignalCellularConnectedNoInternet2BarSharp: (
        <icons.SignalCellularConnectedNoInternet2BarSharp />
    ),
    SignalCellularConnectedNoInternet2BarTwoTone: (
        <icons.SignalCellularConnectedNoInternet2BarTwoTone />
    ),
    SignalCellularConnectedNoInternet3Bar: (
        <icons.SignalCellularConnectedNoInternet3Bar />
    ),
    SignalCellularConnectedNoInternet3BarOutlined: (
        <icons.SignalCellularConnectedNoInternet3BarOutlined />
    ),
    SignalCellularConnectedNoInternet3BarRounded: (
        <icons.SignalCellularConnectedNoInternet3BarRounded />
    ),
    SignalCellularConnectedNoInternet3BarSharp: (
        <icons.SignalCellularConnectedNoInternet3BarSharp />
    ),
    SignalCellularConnectedNoInternet3BarTwoTone: (
        <icons.SignalCellularConnectedNoInternet3BarTwoTone />
    ),
    SignalCellularConnectedNoInternet4Bar: (
        <icons.SignalCellularConnectedNoInternet4Bar />
    ),
    SignalCellularConnectedNoInternet4BarOutlined: (
        <icons.SignalCellularConnectedNoInternet4BarOutlined />
    ),
    SignalCellularConnectedNoInternet4BarRounded: (
        <icons.SignalCellularConnectedNoInternet4BarRounded />
    ),
    SignalCellularConnectedNoInternet4BarSharp: (
        <icons.SignalCellularConnectedNoInternet4BarSharp />
    ),
    SignalCellularConnectedNoInternet4BarTwoTone: (
        <icons.SignalCellularConnectedNoInternet4BarTwoTone />
    ),
    SignalCellularNoSim: <icons.SignalCellularNoSim />,
    SignalCellularNoSimOutlined: <icons.SignalCellularNoSimOutlined />,
    SignalCellularNoSimRounded: <icons.SignalCellularNoSimRounded />,
    SignalCellularNoSimSharp: <icons.SignalCellularNoSimSharp />,
    SignalCellularNoSimTwoTone: <icons.SignalCellularNoSimTwoTone />,
    SignalCellularNodata: <icons.SignalCellularNodata />,
    SignalCellularNodataOutlined: <icons.SignalCellularNodataOutlined />,
    SignalCellularNodataRounded: <icons.SignalCellularNodataRounded />,
    SignalCellularNodataSharp: <icons.SignalCellularNodataSharp />,
    SignalCellularNodataTwoTone: <icons.SignalCellularNodataTwoTone />,
    SignalCellularNull: <icons.SignalCellularNull />,
    SignalCellularNullOutlined: <icons.SignalCellularNullOutlined />,
    SignalCellularNullRounded: <icons.SignalCellularNullRounded />,
    SignalCellularNullSharp: <icons.SignalCellularNullSharp />,
    SignalCellularNullTwoTone: <icons.SignalCellularNullTwoTone />,
    SignalCellularOff: <icons.SignalCellularOff />,
    SignalCellularOffOutlined: <icons.SignalCellularOffOutlined />,
    SignalCellularOffRounded: <icons.SignalCellularOffRounded />,
    SignalCellularOffSharp: <icons.SignalCellularOffSharp />,
    SignalCellularOffTwoTone: <icons.SignalCellularOffTwoTone />,
    SignalWifi0Bar: <icons.SignalWifi0Bar />,
    SignalWifi0BarOutlined: <icons.SignalWifi0BarOutlined />,
    SignalWifi0BarRounded: <icons.SignalWifi0BarRounded />,
    SignalWifi0BarSharp: <icons.SignalWifi0BarSharp />,
    SignalWifi0BarTwoTone: <icons.SignalWifi0BarTwoTone />,
    SignalWifi1Bar: <icons.SignalWifi1Bar />,
    SignalWifi1BarLock: <icons.SignalWifi1BarLock />,
    SignalWifi1BarLockOutlined: <icons.SignalWifi1BarLockOutlined />,
    SignalWifi1BarLockRounded: <icons.SignalWifi1BarLockRounded />,
    SignalWifi1BarLockSharp: <icons.SignalWifi1BarLockSharp />,
    SignalWifi1BarLockTwoTone: <icons.SignalWifi1BarLockTwoTone />,
    SignalWifi1BarOutlined: <icons.SignalWifi1BarOutlined />,
    SignalWifi1BarRounded: <icons.SignalWifi1BarRounded />,
    SignalWifi1BarSharp: <icons.SignalWifi1BarSharp />,
    SignalWifi1BarTwoTone: <icons.SignalWifi1BarTwoTone />,
    SignalWifi2Bar: <icons.SignalWifi2Bar />,
    SignalWifi2BarLock: <icons.SignalWifi2BarLock />,
    SignalWifi2BarLockOutlined: <icons.SignalWifi2BarLockOutlined />,
    SignalWifi2BarLockRounded: <icons.SignalWifi2BarLockRounded />,
    SignalWifi2BarLockSharp: <icons.SignalWifi2BarLockSharp />,
    SignalWifi2BarLockTwoTone: <icons.SignalWifi2BarLockTwoTone />,
    SignalWifi2BarOutlined: <icons.SignalWifi2BarOutlined />,
    SignalWifi2BarRounded: <icons.SignalWifi2BarRounded />,
    SignalWifi2BarSharp: <icons.SignalWifi2BarSharp />,
    SignalWifi2BarTwoTone: <icons.SignalWifi2BarTwoTone />,
    SignalWifi3Bar: <icons.SignalWifi3Bar />,
    SignalWifi3BarLock: <icons.SignalWifi3BarLock />,
    SignalWifi3BarLockOutlined: <icons.SignalWifi3BarLockOutlined />,
    SignalWifi3BarLockRounded: <icons.SignalWifi3BarLockRounded />,
    SignalWifi3BarLockSharp: <icons.SignalWifi3BarLockSharp />,
    SignalWifi3BarLockTwoTone: <icons.SignalWifi3BarLockTwoTone />,
    SignalWifi3BarOutlined: <icons.SignalWifi3BarOutlined />,
    SignalWifi3BarRounded: <icons.SignalWifi3BarRounded />,
    SignalWifi3BarSharp: <icons.SignalWifi3BarSharp />,
    SignalWifi3BarTwoTone: <icons.SignalWifi3BarTwoTone />,
    SignalWifi4Bar: <icons.SignalWifi4Bar />,
    SignalWifi4BarLock: <icons.SignalWifi4BarLock />,
    SignalWifi4BarLockOutlined: <icons.SignalWifi4BarLockOutlined />,
    SignalWifi4BarLockRounded: <icons.SignalWifi4BarLockRounded />,
    SignalWifi4BarLockSharp: <icons.SignalWifi4BarLockSharp />,
    SignalWifi4BarLockTwoTone: <icons.SignalWifi4BarLockTwoTone />,
    SignalWifi4BarOutlined: <icons.SignalWifi4BarOutlined />,
    SignalWifi4BarRounded: <icons.SignalWifi4BarRounded />,
    SignalWifi4BarSharp: <icons.SignalWifi4BarSharp />,
    SignalWifi4BarTwoTone: <icons.SignalWifi4BarTwoTone />,
    SignalWifiBad: <icons.SignalWifiBad />,
    SignalWifiBadOutlined: <icons.SignalWifiBadOutlined />,
    SignalWifiBadRounded: <icons.SignalWifiBadRounded />,
    SignalWifiBadSharp: <icons.SignalWifiBadSharp />,
    SignalWifiBadTwoTone: <icons.SignalWifiBadTwoTone />,
    SignalWifiConnectedNoInternet4: <icons.SignalWifiConnectedNoInternet4 />,
    SignalWifiConnectedNoInternet4Outlined: (
        <icons.SignalWifiConnectedNoInternet4Outlined />
    ),
    SignalWifiConnectedNoInternet4Rounded: (
        <icons.SignalWifiConnectedNoInternet4Rounded />
    ),
    SignalWifiConnectedNoInternet4Sharp: (
        <icons.SignalWifiConnectedNoInternet4Sharp />
    ),
    SignalWifiConnectedNoInternet4TwoTone: (
        <icons.SignalWifiConnectedNoInternet4TwoTone />
    ),
    SignalWifiOff: <icons.SignalWifiOff />,
    SignalWifiOffOutlined: <icons.SignalWifiOffOutlined />,
    SignalWifiOffRounded: <icons.SignalWifiOffRounded />,
    SignalWifiOffSharp: <icons.SignalWifiOffSharp />,
    SignalWifiOffTwoTone: <icons.SignalWifiOffTwoTone />,
    SignalWifiStatusbar4Bar: <icons.SignalWifiStatusbar4Bar />,
    SignalWifiStatusbar4BarOutlined: <icons.SignalWifiStatusbar4BarOutlined />,
    SignalWifiStatusbar4BarRounded: <icons.SignalWifiStatusbar4BarRounded />,
    SignalWifiStatusbar4BarSharp: <icons.SignalWifiStatusbar4BarSharp />,
    SignalWifiStatusbar4BarTwoTone: <icons.SignalWifiStatusbar4BarTwoTone />,
    SignalWifiStatusbarConnectedNoInternet4: (
        <icons.SignalWifiStatusbarConnectedNoInternet4 />
    ),
    SignalWifiStatusbarConnectedNoInternet4Outlined: (
        <icons.SignalWifiStatusbarConnectedNoInternet4Outlined />
    ),
    SignalWifiStatusbarConnectedNoInternet4Rounded: (
        <icons.SignalWifiStatusbarConnectedNoInternet4Rounded />
    ),
    SignalWifiStatusbarConnectedNoInternet4Sharp: (
        <icons.SignalWifiStatusbarConnectedNoInternet4Sharp />
    ),
    SignalWifiStatusbarConnectedNoInternet4TwoTone: (
        <icons.SignalWifiStatusbarConnectedNoInternet4TwoTone />
    ),
    SignalWifiStatusbarNull: <icons.SignalWifiStatusbarNull />,
    SignalWifiStatusbarNullOutlined: <icons.SignalWifiStatusbarNullOutlined />,
    SignalWifiStatusbarNullRounded: <icons.SignalWifiStatusbarNullRounded />,
    SignalWifiStatusbarNullSharp: <icons.SignalWifiStatusbarNullSharp />,
    SignalWifiStatusbarNullTwoTone: <icons.SignalWifiStatusbarNullTwoTone />,
    Signpost: <icons.Signpost />,
    SignpostOutlined: <icons.SignpostOutlined />,
    SignpostRounded: <icons.SignpostRounded />,
    SignpostSharp: <icons.SignpostSharp />,
    SignpostTwoTone: <icons.SignpostTwoTone />,
    SimCard: <icons.SimCard />,
    SimCardAlert: <icons.SimCardAlert />,
    SimCardAlertOutlined: <icons.SimCardAlertOutlined />,
    SimCardAlertRounded: <icons.SimCardAlertRounded />,
    SimCardAlertSharp: <icons.SimCardAlertSharp />,
    SimCardAlertTwoTone: <icons.SimCardAlertTwoTone />,
    SimCardDownload: <icons.SimCardDownload />,
    SimCardDownloadOutlined: <icons.SimCardDownloadOutlined />,
    SimCardDownloadRounded: <icons.SimCardDownloadRounded />,
    SimCardDownloadSharp: <icons.SimCardDownloadSharp />,
    SimCardDownloadTwoTone: <icons.SimCardDownloadTwoTone />,
    SimCardOutlined: <icons.SimCardOutlined />,
    SimCardRounded: <icons.SimCardRounded />,
    SimCardSharp: <icons.SimCardSharp />,
    SimCardTwoTone: <icons.SimCardTwoTone />,
    SingleBed: <icons.SingleBed />,
    SingleBedOutlined: <icons.SingleBedOutlined />,
    SingleBedRounded: <icons.SingleBedRounded />,
    SingleBedSharp: <icons.SingleBedSharp />,
    SingleBedTwoTone: <icons.SingleBedTwoTone />,
    Sip: <icons.Sip />,
    SipOutlined: <icons.SipOutlined />,
    SipRounded: <icons.SipRounded />,
    SipSharp: <icons.SipSharp />,
    SipTwoTone: <icons.SipTwoTone />,
    SixK: <icons.SixK />,
    SixKOutlined: <icons.SixKOutlined />,
    SixKPlus: <icons.SixKPlus />,
    SixKPlusOutlined: <icons.SixKPlusOutlined />,
    SixKPlusRounded: <icons.SixKPlusRounded />,
    SixKPlusSharp: <icons.SixKPlusSharp />,
    SixKPlusTwoTone: <icons.SixKPlusTwoTone />,
    SixKRounded: <icons.SixKRounded />,
    SixKSharp: <icons.SixKSharp />,
    SixKTwoTone: <icons.SixKTwoTone />,
    SixMp: <icons.SixMp />,
    SixMpOutlined: <icons.SixMpOutlined />,
    SixMpRounded: <icons.SixMpRounded />,
    SixMpSharp: <icons.SixMpSharp />,
    SixMpTwoTone: <icons.SixMpTwoTone />,
    SixteenMp: <icons.SixteenMp />,
    SixteenMpOutlined: <icons.SixteenMpOutlined />,
    SixteenMpRounded: <icons.SixteenMpRounded />,
    SixteenMpSharp: <icons.SixteenMpSharp />,
    SixteenMpTwoTone: <icons.SixteenMpTwoTone />,
    SixtyFps: <icons.SixtyFps />,
    SixtyFpsOutlined: <icons.SixtyFpsOutlined />,
    SixtyFpsRounded: <icons.SixtyFpsRounded />,
    SixtyFpsSelect: <icons.SixtyFpsSelect />,
    SixtyFpsSelectOutlined: <icons.SixtyFpsSelectOutlined />,
    SixtyFpsSelectRounded: <icons.SixtyFpsSelectRounded />,
    SixtyFpsSelectSharp: <icons.SixtyFpsSelectSharp />,
    SixtyFpsSelectTwoTone: <icons.SixtyFpsSelectTwoTone />,
    SixtyFpsSharp: <icons.SixtyFpsSharp />,
    SixtyFpsTwoTone: <icons.SixtyFpsTwoTone />,
    Skateboarding: <icons.Skateboarding />,
    SkateboardingOutlined: <icons.SkateboardingOutlined />,
    SkateboardingRounded: <icons.SkateboardingRounded />,
    SkateboardingSharp: <icons.SkateboardingSharp />,
    SkateboardingTwoTone: <icons.SkateboardingTwoTone />,
    SkipNext: <icons.SkipNext />,
    SkipNextOutlined: <icons.SkipNextOutlined />,
    SkipNextRounded: <icons.SkipNextRounded />,
    SkipNextSharp: <icons.SkipNextSharp />,
    SkipNextTwoTone: <icons.SkipNextTwoTone />,
    SkipPrevious: <icons.SkipPrevious />,
    SkipPreviousOutlined: <icons.SkipPreviousOutlined />,
    SkipPreviousRounded: <icons.SkipPreviousRounded />,
    SkipPreviousSharp: <icons.SkipPreviousSharp />,
    SkipPreviousTwoTone: <icons.SkipPreviousTwoTone />,
    Sledding: <icons.Sledding />,
    SleddingOutlined: <icons.SleddingOutlined />,
    SleddingRounded: <icons.SleddingRounded />,
    SleddingSharp: <icons.SleddingSharp />,
    SleddingTwoTone: <icons.SleddingTwoTone />,
    Slideshow: <icons.Slideshow />,
    SlideshowOutlined: <icons.SlideshowOutlined />,
    SlideshowRounded: <icons.SlideshowRounded />,
    SlideshowSharp: <icons.SlideshowSharp />,
    SlideshowTwoTone: <icons.SlideshowTwoTone />,
    SlowMotionVideo: <icons.SlowMotionVideo />,
    SlowMotionVideoOutlined: <icons.SlowMotionVideoOutlined />,
    SlowMotionVideoRounded: <icons.SlowMotionVideoRounded />,
    SlowMotionVideoSharp: <icons.SlowMotionVideoSharp />,
    SlowMotionVideoTwoTone: <icons.SlowMotionVideoTwoTone />,
    SmartButton: <icons.SmartButton />,
    SmartButtonOutlined: <icons.SmartButtonOutlined />,
    SmartButtonRounded: <icons.SmartButtonRounded />,
    SmartButtonSharp: <icons.SmartButtonSharp />,
    SmartButtonTwoTone: <icons.SmartButtonTwoTone />,
    SmartDisplay: <icons.SmartDisplay />,
    SmartDisplayOutlined: <icons.SmartDisplayOutlined />,
    SmartDisplayRounded: <icons.SmartDisplayRounded />,
    SmartDisplaySharp: <icons.SmartDisplaySharp />,
    SmartDisplayTwoTone: <icons.SmartDisplayTwoTone />,
    SmartScreen: <icons.SmartScreen />,
    SmartScreenOutlined: <icons.SmartScreenOutlined />,
    SmartScreenRounded: <icons.SmartScreenRounded />,
    SmartScreenSharp: <icons.SmartScreenSharp />,
    SmartScreenTwoTone: <icons.SmartScreenTwoTone />,
    SmartToy: <icons.SmartToy />,
    SmartToyOutlined: <icons.SmartToyOutlined />,
    SmartToyRounded: <icons.SmartToyRounded />,
    SmartToySharp: <icons.SmartToySharp />,
    SmartToyTwoTone: <icons.SmartToyTwoTone />,
    Smartphone: <icons.Smartphone />,
    SmartphoneOutlined: <icons.SmartphoneOutlined />,
    SmartphoneRounded: <icons.SmartphoneRounded />,
    SmartphoneSharp: <icons.SmartphoneSharp />,
    SmartphoneTwoTone: <icons.SmartphoneTwoTone />,
    SmokeFree: <icons.SmokeFree />,
    SmokeFreeOutlined: <icons.SmokeFreeOutlined />,
    SmokeFreeRounded: <icons.SmokeFreeRounded />,
    SmokeFreeSharp: <icons.SmokeFreeSharp />,
    SmokeFreeTwoTone: <icons.SmokeFreeTwoTone />,
    SmokingRooms: <icons.SmokingRooms />,
    SmokingRoomsOutlined: <icons.SmokingRoomsOutlined />,
    SmokingRoomsRounded: <icons.SmokingRoomsRounded />,
    SmokingRoomsSharp: <icons.SmokingRoomsSharp />,
    SmokingRoomsTwoTone: <icons.SmokingRoomsTwoTone />,
    Sms: <icons.Sms />,
    SmsFailed: <icons.SmsFailed />,
    SmsFailedOutlined: <icons.SmsFailedOutlined />,
    SmsFailedRounded: <icons.SmsFailedRounded />,
    SmsFailedSharp: <icons.SmsFailedSharp />,
    SmsFailedTwoTone: <icons.SmsFailedTwoTone />,
    SmsOutlined: <icons.SmsOutlined />,
    SmsRounded: <icons.SmsRounded />,
    SmsSharp: <icons.SmsSharp />,
    SmsTwoTone: <icons.SmsTwoTone />,
    SnippetFolder: <icons.SnippetFolder />,
    SnippetFolderOutlined: <icons.SnippetFolderOutlined />,
    SnippetFolderRounded: <icons.SnippetFolderRounded />,
    SnippetFolderSharp: <icons.SnippetFolderSharp />,
    SnippetFolderTwoTone: <icons.SnippetFolderTwoTone />,
    Snooze: <icons.Snooze />,
    SnoozeOutlined: <icons.SnoozeOutlined />,
    SnoozeRounded: <icons.SnoozeRounded />,
    SnoozeSharp: <icons.SnoozeSharp />,
    SnoozeTwoTone: <icons.SnoozeTwoTone />,
    Snowboarding: <icons.Snowboarding />,
    SnowboardingOutlined: <icons.SnowboardingOutlined />,
    SnowboardingRounded: <icons.SnowboardingRounded />,
    SnowboardingSharp: <icons.SnowboardingSharp />,
    SnowboardingTwoTone: <icons.SnowboardingTwoTone />,
    Snowmobile: <icons.Snowmobile />,
    SnowmobileOutlined: <icons.SnowmobileOutlined />,
    SnowmobileRounded: <icons.SnowmobileRounded />,
    SnowmobileSharp: <icons.SnowmobileSharp />,
    SnowmobileTwoTone: <icons.SnowmobileTwoTone />,
    Snowshoeing: <icons.Snowshoeing />,
    SnowshoeingOutlined: <icons.SnowshoeingOutlined />,
    SnowshoeingRounded: <icons.SnowshoeingRounded />,
    SnowshoeingSharp: <icons.SnowshoeingSharp />,
    SnowshoeingTwoTone: <icons.SnowshoeingTwoTone />,
    Soap: <icons.Soap />,
    SoapOutlined: <icons.SoapOutlined />,
    SoapRounded: <icons.SoapRounded />,
    SoapSharp: <icons.SoapSharp />,
    SoapTwoTone: <icons.SoapTwoTone />,
    SocialDistance: <icons.SocialDistance />,
    SocialDistanceOutlined: <icons.SocialDistanceOutlined />,
    SocialDistanceRounded: <icons.SocialDistanceRounded />,
    SocialDistanceSharp: <icons.SocialDistanceSharp />,
    SocialDistanceTwoTone: <icons.SocialDistanceTwoTone />,
    SolarPower: <icons.SolarPower />,
    SolarPowerOutlined: <icons.SolarPowerOutlined />,
    SolarPowerRounded: <icons.SolarPowerRounded />,
    SolarPowerSharp: <icons.SolarPowerSharp />,
    SolarPowerTwoTone: <icons.SolarPowerTwoTone />,
    Sort: <icons.Sort />,
    SortByAlpha: <icons.SortByAlpha />,
    SortByAlphaOutlined: <icons.SortByAlphaOutlined />,
    SortByAlphaRounded: <icons.SortByAlphaRounded />,
    SortByAlphaSharp: <icons.SortByAlphaSharp />,
    SortByAlphaTwoTone: <icons.SortByAlphaTwoTone />,
    SortOutlined: <icons.SortOutlined />,
    SortRounded: <icons.SortRounded />,
    SortSharp: <icons.SortSharp />,
    SortTwoTone: <icons.SortTwoTone />,
    Sos: <icons.Sos />,
    SosOutlined: <icons.SosOutlined />,
    SosRounded: <icons.SosRounded />,
    SosSharp: <icons.SosSharp />,
    SosTwoTone: <icons.SosTwoTone />,
    SoupKitchen: <icons.SoupKitchen />,
    SoupKitchenOutlined: <icons.SoupKitchenOutlined />,
    SoupKitchenRounded: <icons.SoupKitchenRounded />,
    SoupKitchenSharp: <icons.SoupKitchenSharp />,
    SoupKitchenTwoTone: <icons.SoupKitchenTwoTone />,
    Source: <icons.Source />,
    SourceOutlined: <icons.SourceOutlined />,
    SourceRounded: <icons.SourceRounded />,
    SourceSharp: <icons.SourceSharp />,
    SourceTwoTone: <icons.SourceTwoTone />,
    South: <icons.South />,
    SouthAmerica: <icons.SouthAmerica />,
    SouthAmericaOutlined: <icons.SouthAmericaOutlined />,
    SouthAmericaRounded: <icons.SouthAmericaRounded />,
    SouthAmericaSharp: <icons.SouthAmericaSharp />,
    SouthAmericaTwoTone: <icons.SouthAmericaTwoTone />,
    SouthEast: <icons.SouthEast />,
    SouthEastOutlined: <icons.SouthEastOutlined />,
    SouthEastRounded: <icons.SouthEastRounded />,
    SouthEastSharp: <icons.SouthEastSharp />,
    SouthEastTwoTone: <icons.SouthEastTwoTone />,
    SouthOutlined: <icons.SouthOutlined />,
    SouthRounded: <icons.SouthRounded />,
    SouthSharp: <icons.SouthSharp />,
    SouthTwoTone: <icons.SouthTwoTone />,
    SouthWest: <icons.SouthWest />,
    SouthWestOutlined: <icons.SouthWestOutlined />,
    SouthWestRounded: <icons.SouthWestRounded />,
    SouthWestSharp: <icons.SouthWestSharp />,
    SouthWestTwoTone: <icons.SouthWestTwoTone />,
    Spa: <icons.Spa />,
    SpaOutlined: <icons.SpaOutlined />,
    SpaRounded: <icons.SpaRounded />,
    SpaSharp: <icons.SpaSharp />,
    SpaTwoTone: <icons.SpaTwoTone />,
    SpaceBar: <icons.SpaceBar />,
    SpaceBarOutlined: <icons.SpaceBarOutlined />,
    SpaceBarRounded: <icons.SpaceBarRounded />,
    SpaceBarSharp: <icons.SpaceBarSharp />,
    SpaceBarTwoTone: <icons.SpaceBarTwoTone />,
    SpaceDashboard: <icons.SpaceDashboard />,
    SpaceDashboardOutlined: <icons.SpaceDashboardOutlined />,
    SpaceDashboardRounded: <icons.SpaceDashboardRounded />,
    SpaceDashboardSharp: <icons.SpaceDashboardSharp />,
    SpaceDashboardTwoTone: <icons.SpaceDashboardTwoTone />,
    SpatialAudio: <icons.SpatialAudio />,
    SpatialAudioOff: <icons.SpatialAudioOff />,
    SpatialAudioOffOutlined: <icons.SpatialAudioOffOutlined />,
    SpatialAudioOffRounded: <icons.SpatialAudioOffRounded />,
    SpatialAudioOffSharp: <icons.SpatialAudioOffSharp />,
    SpatialAudioOffTwoTone: <icons.SpatialAudioOffTwoTone />,
    SpatialAudioOutlined: <icons.SpatialAudioOutlined />,
    SpatialAudioRounded: <icons.SpatialAudioRounded />,
    SpatialAudioSharp: <icons.SpatialAudioSharp />,
    SpatialAudioTwoTone: <icons.SpatialAudioTwoTone />,
    SpatialTracking: <icons.SpatialTracking />,
    SpatialTrackingOutlined: <icons.SpatialTrackingOutlined />,
    SpatialTrackingRounded: <icons.SpatialTrackingRounded />,
    SpatialTrackingSharp: <icons.SpatialTrackingSharp />,
    SpatialTrackingTwoTone: <icons.SpatialTrackingTwoTone />,
    Speaker: <icons.Speaker />,
    SpeakerGroup: <icons.SpeakerGroup />,
    SpeakerGroupOutlined: <icons.SpeakerGroupOutlined />,
    SpeakerGroupRounded: <icons.SpeakerGroupRounded />,
    SpeakerGroupSharp: <icons.SpeakerGroupSharp />,
    SpeakerGroupTwoTone: <icons.SpeakerGroupTwoTone />,
    SpeakerNotes: <icons.SpeakerNotes />,
    SpeakerNotesOff: <icons.SpeakerNotesOff />,
    SpeakerNotesOffOutlined: <icons.SpeakerNotesOffOutlined />,
    SpeakerNotesOffRounded: <icons.SpeakerNotesOffRounded />,
    SpeakerNotesOffSharp: <icons.SpeakerNotesOffSharp />,
    SpeakerNotesOffTwoTone: <icons.SpeakerNotesOffTwoTone />,
    SpeakerNotesOutlined: <icons.SpeakerNotesOutlined />,
    SpeakerNotesRounded: <icons.SpeakerNotesRounded />,
    SpeakerNotesSharp: <icons.SpeakerNotesSharp />,
    SpeakerNotesTwoTone: <icons.SpeakerNotesTwoTone />,
    SpeakerOutlined: <icons.SpeakerOutlined />,
    SpeakerPhone: <icons.SpeakerPhone />,
    SpeakerPhoneOutlined: <icons.SpeakerPhoneOutlined />,
    SpeakerPhoneRounded: <icons.SpeakerPhoneRounded />,
    SpeakerPhoneSharp: <icons.SpeakerPhoneSharp />,
    SpeakerPhoneTwoTone: <icons.SpeakerPhoneTwoTone />,
    SpeakerRounded: <icons.SpeakerRounded />,
    SpeakerSharp: <icons.SpeakerSharp />,
    SpeakerTwoTone: <icons.SpeakerTwoTone />,
    Speed: <icons.Speed />,
    SpeedOutlined: <icons.SpeedOutlined />,
    SpeedRounded: <icons.SpeedRounded />,
    SpeedSharp: <icons.SpeedSharp />,
    SpeedTwoTone: <icons.SpeedTwoTone />,
    Spellcheck: <icons.Spellcheck />,
    SpellcheckOutlined: <icons.SpellcheckOutlined />,
    SpellcheckRounded: <icons.SpellcheckRounded />,
    SpellcheckSharp: <icons.SpellcheckSharp />,
    SpellcheckTwoTone: <icons.SpellcheckTwoTone />,
    Splitscreen: <icons.Splitscreen />,
    SplitscreenOutlined: <icons.SplitscreenOutlined />,
    SplitscreenRounded: <icons.SplitscreenRounded />,
    SplitscreenSharp: <icons.SplitscreenSharp />,
    SplitscreenTwoTone: <icons.SplitscreenTwoTone />,
    Spoke: <icons.Spoke />,
    SpokeOutlined: <icons.SpokeOutlined />,
    SpokeRounded: <icons.SpokeRounded />,
    SpokeSharp: <icons.SpokeSharp />,
    SpokeTwoTone: <icons.SpokeTwoTone />,
    Sports: <icons.Sports />,
    SportsBar: <icons.SportsBar />,
    SportsBarOutlined: <icons.SportsBarOutlined />,
    SportsBarRounded: <icons.SportsBarRounded />,
    SportsBarSharp: <icons.SportsBarSharp />,
    SportsBarTwoTone: <icons.SportsBarTwoTone />,
    SportsBaseball: <icons.SportsBaseball />,
    SportsBaseballOutlined: <icons.SportsBaseballOutlined />,
    SportsBaseballRounded: <icons.SportsBaseballRounded />,
    SportsBaseballSharp: <icons.SportsBaseballSharp />,
    SportsBaseballTwoTone: <icons.SportsBaseballTwoTone />,
    SportsBasketball: <icons.SportsBasketball />,
    SportsBasketballOutlined: <icons.SportsBasketballOutlined />,
    SportsBasketballRounded: <icons.SportsBasketballRounded />,
    SportsBasketballSharp: <icons.SportsBasketballSharp />,
    SportsBasketballTwoTone: <icons.SportsBasketballTwoTone />,
    SportsCricket: <icons.SportsCricket />,
    SportsCricketOutlined: <icons.SportsCricketOutlined />,
    SportsCricketRounded: <icons.SportsCricketRounded />,
    SportsCricketSharp: <icons.SportsCricketSharp />,
    SportsCricketTwoTone: <icons.SportsCricketTwoTone />,
    SportsEsports: <icons.SportsEsports />,
    SportsEsportsOutlined: <icons.SportsEsportsOutlined />,
    SportsEsportsRounded: <icons.SportsEsportsRounded />,
    SportsEsportsSharp: <icons.SportsEsportsSharp />,
    SportsEsportsTwoTone: <icons.SportsEsportsTwoTone />,
    SportsFootball: <icons.SportsFootball />,
    SportsFootballOutlined: <icons.SportsFootballOutlined />,
    SportsFootballRounded: <icons.SportsFootballRounded />,
    SportsFootballSharp: <icons.SportsFootballSharp />,
    SportsFootballTwoTone: <icons.SportsFootballTwoTone />,
    SportsGolf: <icons.SportsGolf />,
    SportsGolfOutlined: <icons.SportsGolfOutlined />,
    SportsGolfRounded: <icons.SportsGolfRounded />,
    SportsGolfSharp: <icons.SportsGolfSharp />,
    SportsGolfTwoTone: <icons.SportsGolfTwoTone />,
    SportsGymnastics: <icons.SportsGymnastics />,
    SportsGymnasticsOutlined: <icons.SportsGymnasticsOutlined />,
    SportsGymnasticsRounded: <icons.SportsGymnasticsRounded />,
    SportsGymnasticsSharp: <icons.SportsGymnasticsSharp />,
    SportsGymnasticsTwoTone: <icons.SportsGymnasticsTwoTone />,
    SportsHandball: <icons.SportsHandball />,
    SportsHandballOutlined: <icons.SportsHandballOutlined />,
    SportsHandballRounded: <icons.SportsHandballRounded />,
    SportsHandballSharp: <icons.SportsHandballSharp />,
    SportsHandballTwoTone: <icons.SportsHandballTwoTone />,
    SportsHockey: <icons.SportsHockey />,
    SportsHockeyOutlined: <icons.SportsHockeyOutlined />,
    SportsHockeyRounded: <icons.SportsHockeyRounded />,
    SportsHockeySharp: <icons.SportsHockeySharp />,
    SportsHockeyTwoTone: <icons.SportsHockeyTwoTone />,
    SportsKabaddi: <icons.SportsKabaddi />,
    SportsKabaddiOutlined: <icons.SportsKabaddiOutlined />,
    SportsKabaddiRounded: <icons.SportsKabaddiRounded />,
    SportsKabaddiSharp: <icons.SportsKabaddiSharp />,
    SportsKabaddiTwoTone: <icons.SportsKabaddiTwoTone />,
    SportsMartialArts: <icons.SportsMartialArts />,
    SportsMartialArtsOutlined: <icons.SportsMartialArtsOutlined />,
    SportsMartialArtsRounded: <icons.SportsMartialArtsRounded />,
    SportsMartialArtsSharp: <icons.SportsMartialArtsSharp />,
    SportsMartialArtsTwoTone: <icons.SportsMartialArtsTwoTone />,
    SportsMma: <icons.SportsMma />,
    SportsMmaOutlined: <icons.SportsMmaOutlined />,
    SportsMmaRounded: <icons.SportsMmaRounded />,
    SportsMmaSharp: <icons.SportsMmaSharp />,
    SportsMmaTwoTone: <icons.SportsMmaTwoTone />,
    SportsMotorsports: <icons.SportsMotorsports />,
    SportsMotorsportsOutlined: <icons.SportsMotorsportsOutlined />,
    SportsMotorsportsRounded: <icons.SportsMotorsportsRounded />,
    SportsMotorsportsSharp: <icons.SportsMotorsportsSharp />,
    SportsMotorsportsTwoTone: <icons.SportsMotorsportsTwoTone />,
    SportsOutlined: <icons.SportsOutlined />,
    SportsRounded: <icons.SportsRounded />,
    SportsRugby: <icons.SportsRugby />,
    SportsRugbyOutlined: <icons.SportsRugbyOutlined />,
    SportsRugbyRounded: <icons.SportsRugbyRounded />,
    SportsRugbySharp: <icons.SportsRugbySharp />,
    SportsRugbyTwoTone: <icons.SportsRugbyTwoTone />,
    SportsScore: <icons.SportsScore />,
    SportsScoreOutlined: <icons.SportsScoreOutlined />,
    SportsScoreRounded: <icons.SportsScoreRounded />,
    SportsScoreSharp: <icons.SportsScoreSharp />,
    SportsScoreTwoTone: <icons.SportsScoreTwoTone />,
    SportsSharp: <icons.SportsSharp />,
    SportsSoccer: <icons.SportsSoccer />,
    SportsSoccerOutlined: <icons.SportsSoccerOutlined />,
    SportsSoccerRounded: <icons.SportsSoccerRounded />,
    SportsSoccerSharp: <icons.SportsSoccerSharp />,
    SportsSoccerTwoTone: <icons.SportsSoccerTwoTone />,
    SportsTennis: <icons.SportsTennis />,
    SportsTennisOutlined: <icons.SportsTennisOutlined />,
    SportsTennisRounded: <icons.SportsTennisRounded />,
    SportsTennisSharp: <icons.SportsTennisSharp />,
    SportsTennisTwoTone: <icons.SportsTennisTwoTone />,
    SportsTwoTone: <icons.SportsTwoTone />,
    SportsVolleyball: <icons.SportsVolleyball />,
    SportsVolleyballOutlined: <icons.SportsVolleyballOutlined />,
    SportsVolleyballRounded: <icons.SportsVolleyballRounded />,
    SportsVolleyballSharp: <icons.SportsVolleyballSharp />,
    SportsVolleyballTwoTone: <icons.SportsVolleyballTwoTone />,
    Square: <icons.Square />,
    SquareFoot: <icons.SquareFoot />,
    SquareFootOutlined: <icons.SquareFootOutlined />,
    SquareFootRounded: <icons.SquareFootRounded />,
    SquareFootSharp: <icons.SquareFootSharp />,
    SquareFootTwoTone: <icons.SquareFootTwoTone />,
    SquareOutlined: <icons.SquareOutlined />,
    SquareRounded: <icons.SquareRounded />,
    SquareSharp: <icons.SquareSharp />,
    SquareTwoTone: <icons.SquareTwoTone />,
    SsidChart: <icons.SsidChart />,
    SsidChartOutlined: <icons.SsidChartOutlined />,
    SsidChartRounded: <icons.SsidChartRounded />,
    SsidChartSharp: <icons.SsidChartSharp />,
    SsidChartTwoTone: <icons.SsidChartTwoTone />,
    StackedBarChart: <icons.StackedBarChart />,
    StackedBarChartOutlined: <icons.StackedBarChartOutlined />,
    StackedBarChartRounded: <icons.StackedBarChartRounded />,
    StackedBarChartSharp: <icons.StackedBarChartSharp />,
    StackedBarChartTwoTone: <icons.StackedBarChartTwoTone />,
    StackedLineChart: <icons.StackedLineChart />,
    StackedLineChartOutlined: <icons.StackedLineChartOutlined />,
    StackedLineChartRounded: <icons.StackedLineChartRounded />,
    StackedLineChartSharp: <icons.StackedLineChartSharp />,
    StackedLineChartTwoTone: <icons.StackedLineChartTwoTone />,
    Stadium: <icons.Stadium />,
    StadiumOutlined: <icons.StadiumOutlined />,
    StadiumRounded: <icons.StadiumRounded />,
    StadiumSharp: <icons.StadiumSharp />,
    StadiumTwoTone: <icons.StadiumTwoTone />,
    Stairs: <icons.Stairs />,
    StairsOutlined: <icons.StairsOutlined />,
    StairsRounded: <icons.StairsRounded />,
    StairsSharp: <icons.StairsSharp />,
    StairsTwoTone: <icons.StairsTwoTone />,
    Star: <icons.Star />,
    StarBorder: <icons.StarBorder />,
    StarBorderOutlined: <icons.StarBorderOutlined />,
    StarBorderPurple500: <icons.StarBorderPurple500 />,
    StarBorderPurple500Outlined: <icons.StarBorderPurple500Outlined />,
    StarBorderPurple500Rounded: <icons.StarBorderPurple500Rounded />,
    StarBorderPurple500Sharp: <icons.StarBorderPurple500Sharp />,
    StarBorderPurple500TwoTone: <icons.StarBorderPurple500TwoTone />,
    StarBorderRounded: <icons.StarBorderRounded />,
    StarBorderSharp: <icons.StarBorderSharp />,
    StarBorderTwoTone: <icons.StarBorderTwoTone />,
    StarHalf: <icons.StarHalf />,
    StarHalfOutlined: <icons.StarHalfOutlined />,
    StarHalfRounded: <icons.StarHalfRounded />,
    StarHalfSharp: <icons.StarHalfSharp />,
    StarHalfTwoTone: <icons.StarHalfTwoTone />,
    StarOutline: <icons.StarOutline />,
    StarOutlineOutlined: <icons.StarOutlineOutlined />,
    StarOutlineRounded: <icons.StarOutlineRounded />,
    StarOutlineSharp: <icons.StarOutlineSharp />,
    StarOutlineTwoTone: <icons.StarOutlineTwoTone />,
    StarOutlined: <icons.StarOutlined />,
    StarPurple500: <icons.StarPurple500 />,
    StarPurple500Outlined: <icons.StarPurple500Outlined />,
    StarPurple500Rounded: <icons.StarPurple500Rounded />,
    StarPurple500Sharp: <icons.StarPurple500Sharp />,
    StarPurple500TwoTone: <icons.StarPurple500TwoTone />,
    StarRate: <icons.StarRate />,
    StarRateOutlined: <icons.StarRateOutlined />,
    StarRateRounded: <icons.StarRateRounded />,
    StarRateSharp: <icons.StarRateSharp />,
    StarRateTwoTone: <icons.StarRateTwoTone />,
    StarRounded: <icons.StarRounded />,
    StarSharp: <icons.StarSharp />,
    StarTwoTone: <icons.StarTwoTone />,
    Stars: <icons.Stars />,
    StarsOutlined: <icons.StarsOutlined />,
    StarsRounded: <icons.StarsRounded />,
    StarsSharp: <icons.StarsSharp />,
    StarsTwoTone: <icons.StarsTwoTone />,
    Start: <icons.Start />,
    StartOutlined: <icons.StartOutlined />,
    StartRounded: <icons.StartRounded />,
    StartSharp: <icons.StartSharp />,
    StartTwoTone: <icons.StartTwoTone />,
    StayCurrentLandscape: <icons.StayCurrentLandscape />,
    StayCurrentLandscapeOutlined: <icons.StayCurrentLandscapeOutlined />,
    StayCurrentLandscapeRounded: <icons.StayCurrentLandscapeRounded />,
    StayCurrentLandscapeSharp: <icons.StayCurrentLandscapeSharp />,
    StayCurrentLandscapeTwoTone: <icons.StayCurrentLandscapeTwoTone />,
    StayCurrentPortrait: <icons.StayCurrentPortrait />,
    StayCurrentPortraitOutlined: <icons.StayCurrentPortraitOutlined />,
    StayCurrentPortraitRounded: <icons.StayCurrentPortraitRounded />,
    StayCurrentPortraitSharp: <icons.StayCurrentPortraitSharp />,
    StayCurrentPortraitTwoTone: <icons.StayCurrentPortraitTwoTone />,
    StayPrimaryLandscape: <icons.StayPrimaryLandscape />,
    StayPrimaryLandscapeOutlined: <icons.StayPrimaryLandscapeOutlined />,
    StayPrimaryLandscapeRounded: <icons.StayPrimaryLandscapeRounded />,
    StayPrimaryLandscapeSharp: <icons.StayPrimaryLandscapeSharp />,
    StayPrimaryLandscapeTwoTone: <icons.StayPrimaryLandscapeTwoTone />,
    StayPrimaryPortrait: <icons.StayPrimaryPortrait />,
    StayPrimaryPortraitOutlined: <icons.StayPrimaryPortraitOutlined />,
    StayPrimaryPortraitRounded: <icons.StayPrimaryPortraitRounded />,
    StayPrimaryPortraitSharp: <icons.StayPrimaryPortraitSharp />,
    StayPrimaryPortraitTwoTone: <icons.StayPrimaryPortraitTwoTone />,
    StickyNote2: <icons.StickyNote2 />,
    StickyNote2Outlined: <icons.StickyNote2Outlined />,
    StickyNote2Rounded: <icons.StickyNote2Rounded />,
    StickyNote2Sharp: <icons.StickyNote2Sharp />,
    StickyNote2TwoTone: <icons.StickyNote2TwoTone />,
    Stop: <icons.Stop />,
    StopCircle: <icons.StopCircle />,
    StopCircleOutlined: <icons.StopCircleOutlined />,
    StopCircleRounded: <icons.StopCircleRounded />,
    StopCircleSharp: <icons.StopCircleSharp />,
    StopCircleTwoTone: <icons.StopCircleTwoTone />,
    StopOutlined: <icons.StopOutlined />,
    StopRounded: <icons.StopRounded />,
    StopScreenShare: <icons.StopScreenShare />,
    StopScreenShareOutlined: <icons.StopScreenShareOutlined />,
    StopScreenShareRounded: <icons.StopScreenShareRounded />,
    StopScreenShareSharp: <icons.StopScreenShareSharp />,
    StopScreenShareTwoTone: <icons.StopScreenShareTwoTone />,
    StopSharp: <icons.StopSharp />,
    StopTwoTone: <icons.StopTwoTone />,
    Storage: <icons.Storage />,
    StorageOutlined: <icons.StorageOutlined />,
    StorageRounded: <icons.StorageRounded />,
    StorageSharp: <icons.StorageSharp />,
    StorageTwoTone: <icons.StorageTwoTone />,
    Store: <icons.Store />,
    StoreMallDirectory: <icons.StoreMallDirectory />,
    StoreMallDirectoryOutlined: <icons.StoreMallDirectoryOutlined />,
    StoreMallDirectoryRounded: <icons.StoreMallDirectoryRounded />,
    StoreMallDirectorySharp: <icons.StoreMallDirectorySharp />,
    StoreMallDirectoryTwoTone: <icons.StoreMallDirectoryTwoTone />,
    StoreOutlined: <icons.StoreOutlined />,
    StoreRounded: <icons.StoreRounded />,
    StoreSharp: <icons.StoreSharp />,
    StoreTwoTone: <icons.StoreTwoTone />,
    Storefront: <icons.Storefront />,
    StorefrontOutlined: <icons.StorefrontOutlined />,
    StorefrontRounded: <icons.StorefrontRounded />,
    StorefrontSharp: <icons.StorefrontSharp />,
    StorefrontTwoTone: <icons.StorefrontTwoTone />,
    Storm: <icons.Storm />,
    StormOutlined: <icons.StormOutlined />,
    StormRounded: <icons.StormRounded />,
    StormSharp: <icons.StormSharp />,
    StormTwoTone: <icons.StormTwoTone />,
    Straight: <icons.Straight />,
    StraightOutlined: <icons.StraightOutlined />,
    StraightRounded: <icons.StraightRounded />,
    StraightSharp: <icons.StraightSharp />,
    StraightTwoTone: <icons.StraightTwoTone />,
    Straighten: <icons.Straighten />,
    StraightenOutlined: <icons.StraightenOutlined />,
    StraightenRounded: <icons.StraightenRounded />,
    StraightenSharp: <icons.StraightenSharp />,
    StraightenTwoTone: <icons.StraightenTwoTone />,
    Stream: <icons.Stream />,
    StreamOutlined: <icons.StreamOutlined />,
    StreamRounded: <icons.StreamRounded />,
    StreamSharp: <icons.StreamSharp />,
    StreamTwoTone: <icons.StreamTwoTone />,
    Streetview: <icons.Streetview />,
    StreetviewOutlined: <icons.StreetviewOutlined />,
    StreetviewRounded: <icons.StreetviewRounded />,
    StreetviewSharp: <icons.StreetviewSharp />,
    StreetviewTwoTone: <icons.StreetviewTwoTone />,
    StrikethroughS: <icons.StrikethroughS />,
    StrikethroughSOutlined: <icons.StrikethroughSOutlined />,
    StrikethroughSRounded: <icons.StrikethroughSRounded />,
    StrikethroughSSharp: <icons.StrikethroughSSharp />,
    StrikethroughSTwoTone: <icons.StrikethroughSTwoTone />,
    Stroller: <icons.Stroller />,
    StrollerOutlined: <icons.StrollerOutlined />,
    StrollerRounded: <icons.StrollerRounded />,
    StrollerSharp: <icons.StrollerSharp />,
    StrollerTwoTone: <icons.StrollerTwoTone />,
    Style: <icons.Style />,
    StyleOutlined: <icons.StyleOutlined />,
    StyleRounded: <icons.StyleRounded />,
    StyleSharp: <icons.StyleSharp />,
    StyleTwoTone: <icons.StyleTwoTone />,
    SubdirectoryArrowLeft: <icons.SubdirectoryArrowLeft />,
    SubdirectoryArrowLeftOutlined: <icons.SubdirectoryArrowLeftOutlined />,
    SubdirectoryArrowLeftRounded: <icons.SubdirectoryArrowLeftRounded />,
    SubdirectoryArrowLeftSharp: <icons.SubdirectoryArrowLeftSharp />,
    SubdirectoryArrowLeftTwoTone: <icons.SubdirectoryArrowLeftTwoTone />,
    SubdirectoryArrowRight: <icons.SubdirectoryArrowRight />,
    SubdirectoryArrowRightOutlined: <icons.SubdirectoryArrowRightOutlined />,
    SubdirectoryArrowRightRounded: <icons.SubdirectoryArrowRightRounded />,
    SubdirectoryArrowRightSharp: <icons.SubdirectoryArrowRightSharp />,
    SubdirectoryArrowRightTwoTone: <icons.SubdirectoryArrowRightTwoTone />,
    Subject: <icons.Subject />,
    SubjectOutlined: <icons.SubjectOutlined />,
    SubjectRounded: <icons.SubjectRounded />,
    SubjectSharp: <icons.SubjectSharp />,
    SubjectTwoTone: <icons.SubjectTwoTone />,
    Subscript: <icons.Subscript />,
    SubscriptOutlined: <icons.SubscriptOutlined />,
    SubscriptRounded: <icons.SubscriptRounded />,
    SubscriptSharp: <icons.SubscriptSharp />,
    SubscriptTwoTone: <icons.SubscriptTwoTone />,
    Subscriptions: <icons.Subscriptions />,
    SubscriptionsOutlined: <icons.SubscriptionsOutlined />,
    SubscriptionsRounded: <icons.SubscriptionsRounded />,
    SubscriptionsSharp: <icons.SubscriptionsSharp />,
    SubscriptionsTwoTone: <icons.SubscriptionsTwoTone />,
    Subtitles: <icons.Subtitles />,
    SubtitlesOff: <icons.SubtitlesOff />,
    SubtitlesOffOutlined: <icons.SubtitlesOffOutlined />,
    SubtitlesOffRounded: <icons.SubtitlesOffRounded />,
    SubtitlesOffSharp: <icons.SubtitlesOffSharp />,
    SubtitlesOffTwoTone: <icons.SubtitlesOffTwoTone />,
    SubtitlesOutlined: <icons.SubtitlesOutlined />,
    SubtitlesRounded: <icons.SubtitlesRounded />,
    SubtitlesSharp: <icons.SubtitlesSharp />,
    SubtitlesTwoTone: <icons.SubtitlesTwoTone />,
    Subway: <icons.Subway />,
    SubwayOutlined: <icons.SubwayOutlined />,
    SubwayRounded: <icons.SubwayRounded />,
    SubwaySharp: <icons.SubwaySharp />,
    SubwayTwoTone: <icons.SubwayTwoTone />,
    Summarize: <icons.Summarize />,
    SummarizeOutlined: <icons.SummarizeOutlined />,
    SummarizeRounded: <icons.SummarizeRounded />,
    SummarizeSharp: <icons.SummarizeSharp />,
    SummarizeTwoTone: <icons.SummarizeTwoTone />,
    Superscript: <icons.Superscript />,
    SuperscriptOutlined: <icons.SuperscriptOutlined />,
    SuperscriptRounded: <icons.SuperscriptRounded />,
    SuperscriptSharp: <icons.SuperscriptSharp />,
    SuperscriptTwoTone: <icons.SuperscriptTwoTone />,
    SupervisedUserCircle: <icons.SupervisedUserCircle />,
    SupervisedUserCircleOutlined: <icons.SupervisedUserCircleOutlined />,
    SupervisedUserCircleRounded: <icons.SupervisedUserCircleRounded />,
    SupervisedUserCircleSharp: <icons.SupervisedUserCircleSharp />,
    SupervisedUserCircleTwoTone: <icons.SupervisedUserCircleTwoTone />,
    SupervisorAccount: <icons.SupervisorAccount />,
    SupervisorAccountOutlined: <icons.SupervisorAccountOutlined />,
    SupervisorAccountRounded: <icons.SupervisorAccountRounded />,
    SupervisorAccountSharp: <icons.SupervisorAccountSharp />,
    SupervisorAccountTwoTone: <icons.SupervisorAccountTwoTone />,
    Support: <icons.Support />,
    SupportAgent: <icons.SupportAgent />,
    SupportAgentOutlined: <icons.SupportAgentOutlined />,
    SupportAgentRounded: <icons.SupportAgentRounded />,
    SupportAgentSharp: <icons.SupportAgentSharp />,
    SupportAgentTwoTone: <icons.SupportAgentTwoTone />,
    SupportOutlined: <icons.SupportOutlined />,
    SupportRounded: <icons.SupportRounded />,
    SupportSharp: <icons.SupportSharp />,
    SupportTwoTone: <icons.SupportTwoTone />,
    Surfing: <icons.Surfing />,
    SurfingOutlined: <icons.SurfingOutlined />,
    SurfingRounded: <icons.SurfingRounded />,
    SurfingSharp: <icons.SurfingSharp />,
    SurfingTwoTone: <icons.SurfingTwoTone />,
    SurroundSound: <icons.SurroundSound />,
    SurroundSoundOutlined: <icons.SurroundSoundOutlined />,
    SurroundSoundRounded: <icons.SurroundSoundRounded />,
    SurroundSoundSharp: <icons.SurroundSoundSharp />,
    SurroundSoundTwoTone: <icons.SurroundSoundTwoTone />,
    SwapCalls: <icons.SwapCalls />,
    SwapCallsOutlined: <icons.SwapCallsOutlined />,
    SwapCallsRounded: <icons.SwapCallsRounded />,
    SwapCallsSharp: <icons.SwapCallsSharp />,
    SwapCallsTwoTone: <icons.SwapCallsTwoTone />,
    SwapHoriz: <icons.SwapHoriz />,
    SwapHorizOutlined: <icons.SwapHorizOutlined />,
    SwapHorizRounded: <icons.SwapHorizRounded />,
    SwapHorizSharp: <icons.SwapHorizSharp />,
    SwapHorizTwoTone: <icons.SwapHorizTwoTone />,
    SwapHorizontalCircle: <icons.SwapHorizontalCircle />,
    SwapHorizontalCircleOutlined: <icons.SwapHorizontalCircleOutlined />,
    SwapHorizontalCircleRounded: <icons.SwapHorizontalCircleRounded />,
    SwapHorizontalCircleSharp: <icons.SwapHorizontalCircleSharp />,
    SwapHorizontalCircleTwoTone: <icons.SwapHorizontalCircleTwoTone />,
    SwapVert: <icons.SwapVert />,
    SwapVertOutlined: <icons.SwapVertOutlined />,
    SwapVertRounded: <icons.SwapVertRounded />,
    SwapVertSharp: <icons.SwapVertSharp />,
    SwapVertTwoTone: <icons.SwapVertTwoTone />,
    SwapVerticalCircle: <icons.SwapVerticalCircle />,
    SwapVerticalCircleOutlined: <icons.SwapVerticalCircleOutlined />,
    SwapVerticalCircleRounded: <icons.SwapVerticalCircleRounded />,
    SwapVerticalCircleSharp: <icons.SwapVerticalCircleSharp />,
    SwapVerticalCircleTwoTone: <icons.SwapVerticalCircleTwoTone />,
    Swipe: <icons.Swipe />,
    SwipeDown: <icons.SwipeDown />,
    SwipeDownAlt: <icons.SwipeDownAlt />,
    SwipeDownAltOutlined: <icons.SwipeDownAltOutlined />,
    SwipeDownAltRounded: <icons.SwipeDownAltRounded />,
    SwipeDownAltSharp: <icons.SwipeDownAltSharp />,
    SwipeDownAltTwoTone: <icons.SwipeDownAltTwoTone />,
    SwipeDownOutlined: <icons.SwipeDownOutlined />,
    SwipeDownRounded: <icons.SwipeDownRounded />,
    SwipeDownSharp: <icons.SwipeDownSharp />,
    SwipeDownTwoTone: <icons.SwipeDownTwoTone />,
    SwipeLeft: <icons.SwipeLeft />,
    SwipeLeftAlt: <icons.SwipeLeftAlt />,
    SwipeLeftAltOutlined: <icons.SwipeLeftAltOutlined />,
    SwipeLeftAltRounded: <icons.SwipeLeftAltRounded />,
    SwipeLeftAltSharp: <icons.SwipeLeftAltSharp />,
    SwipeLeftAltTwoTone: <icons.SwipeLeftAltTwoTone />,
    SwipeLeftOutlined: <icons.SwipeLeftOutlined />,
    SwipeLeftRounded: <icons.SwipeLeftRounded />,
    SwipeLeftSharp: <icons.SwipeLeftSharp />,
    SwipeLeftTwoTone: <icons.SwipeLeftTwoTone />,
    SwipeOutlined: <icons.SwipeOutlined />,
    SwipeRight: <icons.SwipeRight />,
    SwipeRightAlt: <icons.SwipeRightAlt />,
    SwipeRightAltOutlined: <icons.SwipeRightAltOutlined />,
    SwipeRightAltRounded: <icons.SwipeRightAltRounded />,
    SwipeRightAltSharp: <icons.SwipeRightAltSharp />,
    SwipeRightAltTwoTone: <icons.SwipeRightAltTwoTone />,
    SwipeRightOutlined: <icons.SwipeRightOutlined />,
    SwipeRightRounded: <icons.SwipeRightRounded />,
    SwipeRightSharp: <icons.SwipeRightSharp />,
    SwipeRightTwoTone: <icons.SwipeRightTwoTone />,
    SwipeRounded: <icons.SwipeRounded />,
    SwipeSharp: <icons.SwipeSharp />,
    SwipeTwoTone: <icons.SwipeTwoTone />,
    SwipeUp: <icons.SwipeUp />,
    SwipeUpAlt: <icons.SwipeUpAlt />,
    SwipeUpAltOutlined: <icons.SwipeUpAltOutlined />,
    SwipeUpAltRounded: <icons.SwipeUpAltRounded />,
    SwipeUpAltSharp: <icons.SwipeUpAltSharp />,
    SwipeUpAltTwoTone: <icons.SwipeUpAltTwoTone />,
    SwipeUpOutlined: <icons.SwipeUpOutlined />,
    SwipeUpRounded: <icons.SwipeUpRounded />,
    SwipeUpSharp: <icons.SwipeUpSharp />,
    SwipeUpTwoTone: <icons.SwipeUpTwoTone />,
    SwipeVertical: <icons.SwipeVertical />,
    SwipeVerticalOutlined: <icons.SwipeVerticalOutlined />,
    SwipeVerticalRounded: <icons.SwipeVerticalRounded />,
    SwipeVerticalSharp: <icons.SwipeVerticalSharp />,
    SwipeVerticalTwoTone: <icons.SwipeVerticalTwoTone />,
    SwitchAccessShortcut: <icons.SwitchAccessShortcut />,
    SwitchAccessShortcutAdd: <icons.SwitchAccessShortcutAdd />,
    SwitchAccessShortcutAddOutlined: <icons.SwitchAccessShortcutAddOutlined />,
    SwitchAccessShortcutAddRounded: <icons.SwitchAccessShortcutAddRounded />,
    SwitchAccessShortcutAddSharp: <icons.SwitchAccessShortcutAddSharp />,
    SwitchAccessShortcutAddTwoTone: <icons.SwitchAccessShortcutAddTwoTone />,
    SwitchAccessShortcutOutlined: <icons.SwitchAccessShortcutOutlined />,
    SwitchAccessShortcutRounded: <icons.SwitchAccessShortcutRounded />,
    SwitchAccessShortcutSharp: <icons.SwitchAccessShortcutSharp />,
    SwitchAccessShortcutTwoTone: <icons.SwitchAccessShortcutTwoTone />,
    SwitchAccount: <icons.SwitchAccount />,
    SwitchAccountOutlined: <icons.SwitchAccountOutlined />,
    SwitchAccountRounded: <icons.SwitchAccountRounded />,
    SwitchAccountSharp: <icons.SwitchAccountSharp />,
    SwitchAccountTwoTone: <icons.SwitchAccountTwoTone />,
    SwitchCamera: <icons.SwitchCamera />,
    SwitchCameraOutlined: <icons.SwitchCameraOutlined />,
    SwitchCameraRounded: <icons.SwitchCameraRounded />,
    SwitchCameraSharp: <icons.SwitchCameraSharp />,
    SwitchCameraTwoTone: <icons.SwitchCameraTwoTone />,
    SwitchLeft: <icons.SwitchLeft />,
    SwitchLeftOutlined: <icons.SwitchLeftOutlined />,
    SwitchLeftRounded: <icons.SwitchLeftRounded />,
    SwitchLeftSharp: <icons.SwitchLeftSharp />,
    SwitchLeftTwoTone: <icons.SwitchLeftTwoTone />,
    SwitchRight: <icons.SwitchRight />,
    SwitchRightOutlined: <icons.SwitchRightOutlined />,
    SwitchRightRounded: <icons.SwitchRightRounded />,
    SwitchRightSharp: <icons.SwitchRightSharp />,
    SwitchRightTwoTone: <icons.SwitchRightTwoTone />,
    SwitchVideo: <icons.SwitchVideo />,
    SwitchVideoOutlined: <icons.SwitchVideoOutlined />,
    SwitchVideoRounded: <icons.SwitchVideoRounded />,
    SwitchVideoSharp: <icons.SwitchVideoSharp />,
    SwitchVideoTwoTone: <icons.SwitchVideoTwoTone />,
    Synagogue: <icons.Synagogue />,
    SynagogueOutlined: <icons.SynagogueOutlined />,
    SynagogueRounded: <icons.SynagogueRounded />,
    SynagogueSharp: <icons.SynagogueSharp />,
    SynagogueTwoTone: <icons.SynagogueTwoTone />,
    Sync: <icons.Sync />,
    SyncAlt: <icons.SyncAlt />,
    SyncAltOutlined: <icons.SyncAltOutlined />,
    SyncAltRounded: <icons.SyncAltRounded />,
    SyncAltSharp: <icons.SyncAltSharp />,
    SyncAltTwoTone: <icons.SyncAltTwoTone />,
    SyncDisabled: <icons.SyncDisabled />,
    SyncDisabledOutlined: <icons.SyncDisabledOutlined />,
    SyncDisabledRounded: <icons.SyncDisabledRounded />,
    SyncDisabledSharp: <icons.SyncDisabledSharp />,
    SyncDisabledTwoTone: <icons.SyncDisabledTwoTone />,
    SyncLock: <icons.SyncLock />,
    SyncLockOutlined: <icons.SyncLockOutlined />,
    SyncLockRounded: <icons.SyncLockRounded />,
    SyncLockSharp: <icons.SyncLockSharp />,
    SyncLockTwoTone: <icons.SyncLockTwoTone />,
    SyncOutlined: <icons.SyncOutlined />,
    SyncProblem: <icons.SyncProblem />,
    SyncProblemOutlined: <icons.SyncProblemOutlined />,
    SyncProblemRounded: <icons.SyncProblemRounded />,
    SyncProblemSharp: <icons.SyncProblemSharp />,
    SyncProblemTwoTone: <icons.SyncProblemTwoTone />,
    SyncRounded: <icons.SyncRounded />,
    SyncSharp: <icons.SyncSharp />,
    SyncTwoTone: <icons.SyncTwoTone />,
    SystemSecurityUpdate: <icons.SystemSecurityUpdate />,
    SystemSecurityUpdateGood: <icons.SystemSecurityUpdateGood />,
    SystemSecurityUpdateGoodOutlined: (
        <icons.SystemSecurityUpdateGoodOutlined />
    ),
    SystemSecurityUpdateGoodRounded: <icons.SystemSecurityUpdateGoodRounded />,
    SystemSecurityUpdateGoodSharp: <icons.SystemSecurityUpdateGoodSharp />,
    SystemSecurityUpdateGoodTwoTone: <icons.SystemSecurityUpdateGoodTwoTone />,
    SystemSecurityUpdateOutlined: <icons.SystemSecurityUpdateOutlined />,
    SystemSecurityUpdateRounded: <icons.SystemSecurityUpdateRounded />,
    SystemSecurityUpdateSharp: <icons.SystemSecurityUpdateSharp />,
    SystemSecurityUpdateTwoTone: <icons.SystemSecurityUpdateTwoTone />,
    SystemSecurityUpdateWarning: <icons.SystemSecurityUpdateWarning />,
    SystemSecurityUpdateWarningOutlined: (
        <icons.SystemSecurityUpdateWarningOutlined />
    ),
    SystemSecurityUpdateWarningRounded: (
        <icons.SystemSecurityUpdateWarningRounded />
    ),
    SystemSecurityUpdateWarningSharp: (
        <icons.SystemSecurityUpdateWarningSharp />
    ),
    SystemSecurityUpdateWarningTwoTone: (
        <icons.SystemSecurityUpdateWarningTwoTone />
    ),
    SystemUpdate: <icons.SystemUpdate />,
    SystemUpdateAlt: <icons.SystemUpdateAlt />,
    SystemUpdateAltOutlined: <icons.SystemUpdateAltOutlined />,
    SystemUpdateAltRounded: <icons.SystemUpdateAltRounded />,
    SystemUpdateAltSharp: <icons.SystemUpdateAltSharp />,
    SystemUpdateAltTwoTone: <icons.SystemUpdateAltTwoTone />,
    SystemUpdateOutlined: <icons.SystemUpdateOutlined />,
    SystemUpdateRounded: <icons.SystemUpdateRounded />,
    SystemUpdateSharp: <icons.SystemUpdateSharp />,
    SystemUpdateTwoTone: <icons.SystemUpdateTwoTone />,
    Tab: <icons.Tab />,
    TabOutlined: <icons.TabOutlined />,
    TabRounded: <icons.TabRounded />,
    TabSharp: <icons.TabSharp />,
    TabTwoTone: <icons.TabTwoTone />,
    TabUnselected: <icons.TabUnselected />,
    TabUnselectedOutlined: <icons.TabUnselectedOutlined />,
    TabUnselectedRounded: <icons.TabUnselectedRounded />,
    TabUnselectedSharp: <icons.TabUnselectedSharp />,
    TabUnselectedTwoTone: <icons.TabUnselectedTwoTone />,
    TableBar: <icons.TableBar />,
    TableBarOutlined: <icons.TableBarOutlined />,
    TableBarRounded: <icons.TableBarRounded />,
    TableBarSharp: <icons.TableBarSharp />,
    TableBarTwoTone: <icons.TableBarTwoTone />,
    TableChart: <icons.TableChart />,
    TableChartOutlined: <icons.TableChartOutlined />,
    TableChartRounded: <icons.TableChartRounded />,
    TableChartSharp: <icons.TableChartSharp />,
    TableChartTwoTone: <icons.TableChartTwoTone />,
    TableRestaurant: <icons.TableRestaurant />,
    TableRestaurantOutlined: <icons.TableRestaurantOutlined />,
    TableRestaurantRounded: <icons.TableRestaurantRounded />,
    TableRestaurantSharp: <icons.TableRestaurantSharp />,
    TableRestaurantTwoTone: <icons.TableRestaurantTwoTone />,
    TableRows: <icons.TableRows />,
    TableRowsOutlined: <icons.TableRowsOutlined />,
    TableRowsRounded: <icons.TableRowsRounded />,
    TableRowsSharp: <icons.TableRowsSharp />,
    TableRowsTwoTone: <icons.TableRowsTwoTone />,
    TableView: <icons.TableView />,
    TableViewOutlined: <icons.TableViewOutlined />,
    TableViewRounded: <icons.TableViewRounded />,
    TableViewSharp: <icons.TableViewSharp />,
    TableViewTwoTone: <icons.TableViewTwoTone />,
    Tablet: <icons.Tablet />,
    TabletAndroid: <icons.TabletAndroid />,
    TabletAndroidOutlined: <icons.TabletAndroidOutlined />,
    TabletAndroidRounded: <icons.TabletAndroidRounded />,
    TabletAndroidSharp: <icons.TabletAndroidSharp />,
    TabletAndroidTwoTone: <icons.TabletAndroidTwoTone />,
    TabletMac: <icons.TabletMac />,
    TabletMacOutlined: <icons.TabletMacOutlined />,
    TabletMacRounded: <icons.TabletMacRounded />,
    TabletMacSharp: <icons.TabletMacSharp />,
    TabletMacTwoTone: <icons.TabletMacTwoTone />,
    TabletOutlined: <icons.TabletOutlined />,
    TabletRounded: <icons.TabletRounded />,
    TabletSharp: <icons.TabletSharp />,
    TabletTwoTone: <icons.TabletTwoTone />,
    Tag: <icons.Tag />,
    TagFaces: <icons.TagFaces />,
    TagFacesOutlined: <icons.TagFacesOutlined />,
    TagFacesRounded: <icons.TagFacesRounded />,
    TagFacesSharp: <icons.TagFacesSharp />,
    TagFacesTwoTone: <icons.TagFacesTwoTone />,
    TagOutlined: <icons.TagOutlined />,
    TagRounded: <icons.TagRounded />,
    TagSharp: <icons.TagSharp />,
    TagTwoTone: <icons.TagTwoTone />,
    TakeoutDining: <icons.TakeoutDining />,
    TakeoutDiningOutlined: <icons.TakeoutDiningOutlined />,
    TakeoutDiningRounded: <icons.TakeoutDiningRounded />,
    TakeoutDiningSharp: <icons.TakeoutDiningSharp />,
    TakeoutDiningTwoTone: <icons.TakeoutDiningTwoTone />,
    TapAndPlay: <icons.TapAndPlay />,
    TapAndPlayOutlined: <icons.TapAndPlayOutlined />,
    TapAndPlayRounded: <icons.TapAndPlayRounded />,
    TapAndPlaySharp: <icons.TapAndPlaySharp />,
    TapAndPlayTwoTone: <icons.TapAndPlayTwoTone />,
    Tapas: <icons.Tapas />,
    TapasOutlined: <icons.TapasOutlined />,
    TapasRounded: <icons.TapasRounded />,
    TapasSharp: <icons.TapasSharp />,
    TapasTwoTone: <icons.TapasTwoTone />,
    Task: <icons.Task />,
    TaskAlt: <icons.TaskAlt />,
    TaskAltOutlined: <icons.TaskAltOutlined />,
    TaskAltRounded: <icons.TaskAltRounded />,
    TaskAltSharp: <icons.TaskAltSharp />,
    TaskAltTwoTone: <icons.TaskAltTwoTone />,
    TaskOutlined: <icons.TaskOutlined />,
    TaskRounded: <icons.TaskRounded />,
    TaskSharp: <icons.TaskSharp />,
    TaskTwoTone: <icons.TaskTwoTone />,
    TaxiAlert: <icons.TaxiAlert />,
    TaxiAlertOutlined: <icons.TaxiAlertOutlined />,
    TaxiAlertRounded: <icons.TaxiAlertRounded />,
    TaxiAlertSharp: <icons.TaxiAlertSharp />,
    TaxiAlertTwoTone: <icons.TaxiAlertTwoTone />,
    Telegram: <icons.Telegram />,
    TempleBuddhist: <icons.TempleBuddhist />,
    TempleBuddhistOutlined: <icons.TempleBuddhistOutlined />,
    TempleBuddhistRounded: <icons.TempleBuddhistRounded />,
    TempleBuddhistSharp: <icons.TempleBuddhistSharp />,
    TempleBuddhistTwoTone: <icons.TempleBuddhistTwoTone />,
    TempleHindu: <icons.TempleHindu />,
    TempleHinduOutlined: <icons.TempleHinduOutlined />,
    TempleHinduRounded: <icons.TempleHinduRounded />,
    TempleHinduSharp: <icons.TempleHinduSharp />,
    TempleHinduTwoTone: <icons.TempleHinduTwoTone />,
    TenMp: <icons.TenMp />,
    TenMpOutlined: <icons.TenMpOutlined />,
    TenMpRounded: <icons.TenMpRounded />,
    TenMpSharp: <icons.TenMpSharp />,
    TenMpTwoTone: <icons.TenMpTwoTone />,
    Terminal: <icons.Terminal />,
    TerminalOutlined: <icons.TerminalOutlined />,
    TerminalRounded: <icons.TerminalRounded />,
    TerminalSharp: <icons.TerminalSharp />,
    TerminalTwoTone: <icons.TerminalTwoTone />,
    Terrain: <icons.Terrain />,
    TerrainOutlined: <icons.TerrainOutlined />,
    TerrainRounded: <icons.TerrainRounded />,
    TerrainSharp: <icons.TerrainSharp />,
    TerrainTwoTone: <icons.TerrainTwoTone />,
    TextDecrease: <icons.TextDecrease />,
    TextDecreaseOutlined: <icons.TextDecreaseOutlined />,
    TextDecreaseRounded: <icons.TextDecreaseRounded />,
    TextDecreaseSharp: <icons.TextDecreaseSharp />,
    TextDecreaseTwoTone: <icons.TextDecreaseTwoTone />,
    TextFields: <icons.TextFields />,
    TextFieldsOutlined: <icons.TextFieldsOutlined />,
    TextFieldsRounded: <icons.TextFieldsRounded />,
    TextFieldsSharp: <icons.TextFieldsSharp />,
    TextFieldsTwoTone: <icons.TextFieldsTwoTone />,
    TextFormat: <icons.TextFormat />,
    TextFormatOutlined: <icons.TextFormatOutlined />,
    TextFormatRounded: <icons.TextFormatRounded />,
    TextFormatSharp: <icons.TextFormatSharp />,
    TextFormatTwoTone: <icons.TextFormatTwoTone />,
    TextIncrease: <icons.TextIncrease />,
    TextIncreaseOutlined: <icons.TextIncreaseOutlined />,
    TextIncreaseRounded: <icons.TextIncreaseRounded />,
    TextIncreaseSharp: <icons.TextIncreaseSharp />,
    TextIncreaseTwoTone: <icons.TextIncreaseTwoTone />,
    TextRotateUp: <icons.TextRotateUp />,
    TextRotateUpOutlined: <icons.TextRotateUpOutlined />,
    TextRotateUpRounded: <icons.TextRotateUpRounded />,
    TextRotateUpSharp: <icons.TextRotateUpSharp />,
    TextRotateUpTwoTone: <icons.TextRotateUpTwoTone />,
    TextRotateVertical: <icons.TextRotateVertical />,
    TextRotateVerticalOutlined: <icons.TextRotateVerticalOutlined />,
    TextRotateVerticalRounded: <icons.TextRotateVerticalRounded />,
    TextRotateVerticalSharp: <icons.TextRotateVerticalSharp />,
    TextRotateVerticalTwoTone: <icons.TextRotateVerticalTwoTone />,
    TextRotationAngledown: <icons.TextRotationAngledown />,
    TextRotationAngledownOutlined: <icons.TextRotationAngledownOutlined />,
    TextRotationAngledownRounded: <icons.TextRotationAngledownRounded />,
    TextRotationAngledownSharp: <icons.TextRotationAngledownSharp />,
    TextRotationAngledownTwoTone: <icons.TextRotationAngledownTwoTone />,
    TextRotationAngleup: <icons.TextRotationAngleup />,
    TextRotationAngleupOutlined: <icons.TextRotationAngleupOutlined />,
    TextRotationAngleupRounded: <icons.TextRotationAngleupRounded />,
    TextRotationAngleupSharp: <icons.TextRotationAngleupSharp />,
    TextRotationAngleupTwoTone: <icons.TextRotationAngleupTwoTone />,
    TextRotationDown: <icons.TextRotationDown />,
    TextRotationDownOutlined: <icons.TextRotationDownOutlined />,
    TextRotationDownRounded: <icons.TextRotationDownRounded />,
    TextRotationDownSharp: <icons.TextRotationDownSharp />,
    TextRotationDownTwoTone: <icons.TextRotationDownTwoTone />,
    TextRotationNone: <icons.TextRotationNone />,
    TextRotationNoneOutlined: <icons.TextRotationNoneOutlined />,
    TextRotationNoneRounded: <icons.TextRotationNoneRounded />,
    TextRotationNoneSharp: <icons.TextRotationNoneSharp />,
    TextRotationNoneTwoTone: <icons.TextRotationNoneTwoTone />,
    TextSnippet: <icons.TextSnippet />,
    TextSnippetOutlined: <icons.TextSnippetOutlined />,
    TextSnippetRounded: <icons.TextSnippetRounded />,
    TextSnippetSharp: <icons.TextSnippetSharp />,
    TextSnippetTwoTone: <icons.TextSnippetTwoTone />,
    Textsms: <icons.Textsms />,
    TextsmsOutlined: <icons.TextsmsOutlined />,
    TextsmsRounded: <icons.TextsmsRounded />,
    TextsmsSharp: <icons.TextsmsSharp />,
    TextsmsTwoTone: <icons.TextsmsTwoTone />,
    Texture: <icons.Texture />,
    TextureOutlined: <icons.TextureOutlined />,
    TextureRounded: <icons.TextureRounded />,
    TextureSharp: <icons.TextureSharp />,
    TextureTwoTone: <icons.TextureTwoTone />,
    TheaterComedy: <icons.TheaterComedy />,
    TheaterComedyOutlined: <icons.TheaterComedyOutlined />,
    TheaterComedyRounded: <icons.TheaterComedyRounded />,
    TheaterComedySharp: <icons.TheaterComedySharp />,
    TheaterComedyTwoTone: <icons.TheaterComedyTwoTone />,
    Theaters: <icons.Theaters />,
    TheatersOutlined: <icons.TheatersOutlined />,
    TheatersRounded: <icons.TheatersRounded />,
    TheatersSharp: <icons.TheatersSharp />,
    TheatersTwoTone: <icons.TheatersTwoTone />,
    Thermostat: <icons.Thermostat />,
    ThermostatAuto: <icons.ThermostatAuto />,
    ThermostatAutoOutlined: <icons.ThermostatAutoOutlined />,
    ThermostatAutoRounded: <icons.ThermostatAutoRounded />,
    ThermostatAutoSharp: <icons.ThermostatAutoSharp />,
    ThermostatAutoTwoTone: <icons.ThermostatAutoTwoTone />,
    ThermostatOutlined: <icons.ThermostatOutlined />,
    ThermostatRounded: <icons.ThermostatRounded />,
    ThermostatSharp: <icons.ThermostatSharp />,
    ThermostatTwoTone: <icons.ThermostatTwoTone />,
    ThirteenMp: <icons.ThirteenMp />,
    ThirteenMpOutlined: <icons.ThirteenMpOutlined />,
    ThirteenMpRounded: <icons.ThirteenMpRounded />,
    ThirteenMpSharp: <icons.ThirteenMpSharp />,
    ThirteenMpTwoTone: <icons.ThirteenMpTwoTone />,
    ThirtyFps: <icons.ThirtyFps />,
    ThirtyFpsOutlined: <icons.ThirtyFpsOutlined />,
    ThirtyFpsRounded: <icons.ThirtyFpsRounded />,
    ThirtyFpsSelect: <icons.ThirtyFpsSelect />,
    ThirtyFpsSelectOutlined: <icons.ThirtyFpsSelectOutlined />,
    ThirtyFpsSelectRounded: <icons.ThirtyFpsSelectRounded />,
    ThirtyFpsSelectSharp: <icons.ThirtyFpsSelectSharp />,
    ThirtyFpsSelectTwoTone: <icons.ThirtyFpsSelectTwoTone />,
    ThirtyFpsSharp: <icons.ThirtyFpsSharp />,
    ThirtyFpsTwoTone: <icons.ThirtyFpsTwoTone />,
    ThreeDRotation: <icons.ThreeDRotation />,
    ThreeDRotationOutlined: <icons.ThreeDRotationOutlined />,
    ThreeDRotationRounded: <icons.ThreeDRotationRounded />,
    ThreeDRotationSharp: <icons.ThreeDRotationSharp />,
    ThreeDRotationTwoTone: <icons.ThreeDRotationTwoTone />,
    ThreeGMobiledata: <icons.ThreeGMobiledata />,
    ThreeGMobiledataOutlined: <icons.ThreeGMobiledataOutlined />,
    ThreeGMobiledataRounded: <icons.ThreeGMobiledataRounded />,
    ThreeGMobiledataSharp: <icons.ThreeGMobiledataSharp />,
    ThreeGMobiledataTwoTone: <icons.ThreeGMobiledataTwoTone />,
    ThreeK: <icons.ThreeK />,
    ThreeKOutlined: <icons.ThreeKOutlined />,
    ThreeKPlus: <icons.ThreeKPlus />,
    ThreeKPlusOutlined: <icons.ThreeKPlusOutlined />,
    ThreeKPlusRounded: <icons.ThreeKPlusRounded />,
    ThreeKPlusSharp: <icons.ThreeKPlusSharp />,
    ThreeKPlusTwoTone: <icons.ThreeKPlusTwoTone />,
    ThreeKRounded: <icons.ThreeKRounded />,
    ThreeKSharp: <icons.ThreeKSharp />,
    ThreeKTwoTone: <icons.ThreeKTwoTone />,
    ThreeMp: <icons.ThreeMp />,
    ThreeMpOutlined: <icons.ThreeMpOutlined />,
    ThreeMpRounded: <icons.ThreeMpRounded />,
    ThreeMpSharp: <icons.ThreeMpSharp />,
    ThreeMpTwoTone: <icons.ThreeMpTwoTone />,
    ThreeP: <icons.ThreeP />,
    ThreePOutlined: <icons.ThreePOutlined />,
    ThreePRounded: <icons.ThreePRounded />,
    ThreePSharp: <icons.ThreePSharp />,
    ThreePTwoTone: <icons.ThreePTwoTone />,
    ThreeSixty: <icons.ThreeSixty />,
    ThreeSixtyOutlined: <icons.ThreeSixtyOutlined />,
    ThreeSixtyRounded: <icons.ThreeSixtyRounded />,
    ThreeSixtySharp: <icons.ThreeSixtySharp />,
    ThreeSixtyTwoTone: <icons.ThreeSixtyTwoTone />,
    ThumbDown: <icons.ThumbDown />,
    ThumbDownAlt: <icons.ThumbDownAlt />,
    ThumbDownAltOutlined: <icons.ThumbDownAltOutlined />,
    ThumbDownAltRounded: <icons.ThumbDownAltRounded />,
    ThumbDownAltSharp: <icons.ThumbDownAltSharp />,
    ThumbDownAltTwoTone: <icons.ThumbDownAltTwoTone />,
    ThumbDownOffAlt: <icons.ThumbDownOffAlt />,
    ThumbDownOffAltOutlined: <icons.ThumbDownOffAltOutlined />,
    ThumbDownOffAltRounded: <icons.ThumbDownOffAltRounded />,
    ThumbDownOffAltSharp: <icons.ThumbDownOffAltSharp />,
    ThumbDownOffAltTwoTone: <icons.ThumbDownOffAltTwoTone />,
    ThumbDownOutlined: <icons.ThumbDownOutlined />,
    ThumbDownRounded: <icons.ThumbDownRounded />,
    ThumbDownSharp: <icons.ThumbDownSharp />,
    ThumbDownTwoTone: <icons.ThumbDownTwoTone />,
    ThumbUp: <icons.ThumbUp />,
    ThumbUpAlt: <icons.ThumbUpAlt />,
    ThumbUpAltOutlined: <icons.ThumbUpAltOutlined />,
    ThumbUpAltRounded: <icons.ThumbUpAltRounded />,
    ThumbUpAltSharp: <icons.ThumbUpAltSharp />,
    ThumbUpAltTwoTone: <icons.ThumbUpAltTwoTone />,
    ThumbUpOffAlt: <icons.ThumbUpOffAlt />,
    ThumbUpOffAltOutlined: <icons.ThumbUpOffAltOutlined />,
    ThumbUpOffAltRounded: <icons.ThumbUpOffAltRounded />,
    ThumbUpOffAltSharp: <icons.ThumbUpOffAltSharp />,
    ThumbUpOffAltTwoTone: <icons.ThumbUpOffAltTwoTone />,
    ThumbUpOutlined: <icons.ThumbUpOutlined />,
    ThumbUpRounded: <icons.ThumbUpRounded />,
    ThumbUpSharp: <icons.ThumbUpSharp />,
    ThumbUpTwoTone: <icons.ThumbUpTwoTone />,
    ThumbsUpDown: <icons.ThumbsUpDown />,
    ThumbsUpDownOutlined: <icons.ThumbsUpDownOutlined />,
    ThumbsUpDownRounded: <icons.ThumbsUpDownRounded />,
    ThumbsUpDownSharp: <icons.ThumbsUpDownSharp />,
    ThumbsUpDownTwoTone: <icons.ThumbsUpDownTwoTone />,
    Thunderstorm: <icons.Thunderstorm />,
    ThunderstormOutlined: <icons.ThunderstormOutlined />,
    ThunderstormRounded: <icons.ThunderstormRounded />,
    ThunderstormSharp: <icons.ThunderstormSharp />,
    ThunderstormTwoTone: <icons.ThunderstormTwoTone />,
    TimeToLeave: <icons.TimeToLeave />,
    TimeToLeaveOutlined: <icons.TimeToLeaveOutlined />,
    TimeToLeaveRounded: <icons.TimeToLeaveRounded />,
    TimeToLeaveSharp: <icons.TimeToLeaveSharp />,
    TimeToLeaveTwoTone: <icons.TimeToLeaveTwoTone />,
    Timelapse: <icons.Timelapse />,
    TimelapseOutlined: <icons.TimelapseOutlined />,
    TimelapseRounded: <icons.TimelapseRounded />,
    TimelapseSharp: <icons.TimelapseSharp />,
    TimelapseTwoTone: <icons.TimelapseTwoTone />,
    Timeline: <icons.Timeline />,
    TimelineOutlined: <icons.TimelineOutlined />,
    TimelineRounded: <icons.TimelineRounded />,
    TimelineSharp: <icons.TimelineSharp />,
    TimelineTwoTone: <icons.TimelineTwoTone />,
    Timer: <icons.Timer />,
    Timer10: <icons.Timer10 />,
    Timer10Outlined: <icons.Timer10Outlined />,
    Timer10Rounded: <icons.Timer10Rounded />,
    Timer10Select: <icons.Timer10Select />,
    Timer10SelectOutlined: <icons.Timer10SelectOutlined />,
    Timer10SelectRounded: <icons.Timer10SelectRounded />,
    Timer10SelectSharp: <icons.Timer10SelectSharp />,
    Timer10SelectTwoTone: <icons.Timer10SelectTwoTone />,
    Timer10Sharp: <icons.Timer10Sharp />,
    Timer10TwoTone: <icons.Timer10TwoTone />,
    Timer3: <icons.Timer3 />,
    Timer3Outlined: <icons.Timer3Outlined />,
    Timer3Rounded: <icons.Timer3Rounded />,
    Timer3Select: <icons.Timer3Select />,
    Timer3SelectOutlined: <icons.Timer3SelectOutlined />,
    Timer3SelectRounded: <icons.Timer3SelectRounded />,
    Timer3SelectSharp: <icons.Timer3SelectSharp />,
    Timer3SelectTwoTone: <icons.Timer3SelectTwoTone />,
    Timer3Sharp: <icons.Timer3Sharp />,
    Timer3TwoTone: <icons.Timer3TwoTone />,
    TimerOff: <icons.TimerOff />,
    TimerOffOutlined: <icons.TimerOffOutlined />,
    TimerOffRounded: <icons.TimerOffRounded />,
    TimerOffSharp: <icons.TimerOffSharp />,
    TimerOffTwoTone: <icons.TimerOffTwoTone />,
    TimerOutlined: <icons.TimerOutlined />,
    TimerRounded: <icons.TimerRounded />,
    TimerSharp: <icons.TimerSharp />,
    TimerTwoTone: <icons.TimerTwoTone />,
    TimesOneMobiledata: <icons.TimesOneMobiledata />,
    TimesOneMobiledataOutlined: <icons.TimesOneMobiledataOutlined />,
    TimesOneMobiledataRounded: <icons.TimesOneMobiledataRounded />,
    TimesOneMobiledataSharp: <icons.TimesOneMobiledataSharp />,
    TimesOneMobiledataTwoTone: <icons.TimesOneMobiledataTwoTone />,
    TipsAndUpdates: <icons.TipsAndUpdates />,
    TipsAndUpdatesOutlined: <icons.TipsAndUpdatesOutlined />,
    TipsAndUpdatesRounded: <icons.TipsAndUpdatesRounded />,
    TipsAndUpdatesSharp: <icons.TipsAndUpdatesSharp />,
    TipsAndUpdatesTwoTone: <icons.TipsAndUpdatesTwoTone />,
    TireRepair: <icons.TireRepair />,
    TireRepairOutlined: <icons.TireRepairOutlined />,
    TireRepairRounded: <icons.TireRepairRounded />,
    TireRepairSharp: <icons.TireRepairSharp />,
    TireRepairTwoTone: <icons.TireRepairTwoTone />,
    Title: <icons.Title />,
    TitleOutlined: <icons.TitleOutlined />,
    TitleRounded: <icons.TitleRounded />,
    TitleSharp: <icons.TitleSharp />,
    TitleTwoTone: <icons.TitleTwoTone />,
    Toc: <icons.Toc />,
    TocOutlined: <icons.TocOutlined />,
    TocRounded: <icons.TocRounded />,
    TocSharp: <icons.TocSharp />,
    TocTwoTone: <icons.TocTwoTone />,
    Today: <icons.Today />,
    TodayOutlined: <icons.TodayOutlined />,
    TodayRounded: <icons.TodayRounded />,
    TodaySharp: <icons.TodaySharp />,
    TodayTwoTone: <icons.TodayTwoTone />,
    ToggleOff: <icons.ToggleOff />,
    ToggleOffOutlined: <icons.ToggleOffOutlined />,
    ToggleOffRounded: <icons.ToggleOffRounded />,
    ToggleOffSharp: <icons.ToggleOffSharp />,
    ToggleOffTwoTone: <icons.ToggleOffTwoTone />,
    ToggleOn: <icons.ToggleOn />,
    ToggleOnOutlined: <icons.ToggleOnOutlined />,
    ToggleOnRounded: <icons.ToggleOnRounded />,
    ToggleOnSharp: <icons.ToggleOnSharp />,
    ToggleOnTwoTone: <icons.ToggleOnTwoTone />,
    Token: <icons.Token />,
    TokenOutlined: <icons.TokenOutlined />,
    TokenRounded: <icons.TokenRounded />,
    TokenSharp: <icons.TokenSharp />,
    TokenTwoTone: <icons.TokenTwoTone />,
    Toll: <icons.Toll />,
    TollOutlined: <icons.TollOutlined />,
    TollRounded: <icons.TollRounded />,
    TollSharp: <icons.TollSharp />,
    TollTwoTone: <icons.TollTwoTone />,
    Tonality: <icons.Tonality />,
    TonalityOutlined: <icons.TonalityOutlined />,
    TonalityRounded: <icons.TonalityRounded />,
    TonalitySharp: <icons.TonalitySharp />,
    TonalityTwoTone: <icons.TonalityTwoTone />,
    Topic: <icons.Topic />,
    TopicOutlined: <icons.TopicOutlined />,
    TopicRounded: <icons.TopicRounded />,
    TopicSharp: <icons.TopicSharp />,
    TopicTwoTone: <icons.TopicTwoTone />,
    Tornado: <icons.Tornado />,
    TornadoOutlined: <icons.TornadoOutlined />,
    TornadoRounded: <icons.TornadoRounded />,
    TornadoSharp: <icons.TornadoSharp />,
    TornadoTwoTone: <icons.TornadoTwoTone />,
    TouchApp: <icons.TouchApp />,
    TouchAppOutlined: <icons.TouchAppOutlined />,
    TouchAppRounded: <icons.TouchAppRounded />,
    TouchAppSharp: <icons.TouchAppSharp />,
    TouchAppTwoTone: <icons.TouchAppTwoTone />,
    Tour: <icons.Tour />,
    TourOutlined: <icons.TourOutlined />,
    TourRounded: <icons.TourRounded />,
    TourSharp: <icons.TourSharp />,
    TourTwoTone: <icons.TourTwoTone />,
    Toys: <icons.Toys />,
    ToysOutlined: <icons.ToysOutlined />,
    ToysRounded: <icons.ToysRounded />,
    ToysSharp: <icons.ToysSharp />,
    ToysTwoTone: <icons.ToysTwoTone />,
    TrackChanges: <icons.TrackChanges />,
    TrackChangesOutlined: <icons.TrackChangesOutlined />,
    TrackChangesRounded: <icons.TrackChangesRounded />,
    TrackChangesSharp: <icons.TrackChangesSharp />,
    TrackChangesTwoTone: <icons.TrackChangesTwoTone />,
    Traffic: <icons.Traffic />,
    TrafficOutlined: <icons.TrafficOutlined />,
    TrafficRounded: <icons.TrafficRounded />,
    TrafficSharp: <icons.TrafficSharp />,
    TrafficTwoTone: <icons.TrafficTwoTone />,
    Train: <icons.Train />,
    TrainOutlined: <icons.TrainOutlined />,
    TrainRounded: <icons.TrainRounded />,
    TrainSharp: <icons.TrainSharp />,
    TrainTwoTone: <icons.TrainTwoTone />,
    Tram: <icons.Tram />,
    TramOutlined: <icons.TramOutlined />,
    TramRounded: <icons.TramRounded />,
    TramSharp: <icons.TramSharp />,
    TramTwoTone: <icons.TramTwoTone />,
    Transcribe: <icons.Transcribe />,
    TranscribeOutlined: <icons.TranscribeOutlined />,
    TranscribeRounded: <icons.TranscribeRounded />,
    TranscribeSharp: <icons.TranscribeSharp />,
    TranscribeTwoTone: <icons.TranscribeTwoTone />,
    TransferWithinAStation: <icons.TransferWithinAStation />,
    TransferWithinAStationOutlined: <icons.TransferWithinAStationOutlined />,
    TransferWithinAStationRounded: <icons.TransferWithinAStationRounded />,
    TransferWithinAStationSharp: <icons.TransferWithinAStationSharp />,
    TransferWithinAStationTwoTone: <icons.TransferWithinAStationTwoTone />,
    Transform: <icons.Transform />,
    TransformOutlined: <icons.TransformOutlined />,
    TransformRounded: <icons.TransformRounded />,
    TransformSharp: <icons.TransformSharp />,
    TransformTwoTone: <icons.TransformTwoTone />,
    Transgender: <icons.Transgender />,
    TransgenderOutlined: <icons.TransgenderOutlined />,
    TransgenderRounded: <icons.TransgenderRounded />,
    TransgenderSharp: <icons.TransgenderSharp />,
    TransgenderTwoTone: <icons.TransgenderTwoTone />,
    TransitEnterexit: <icons.TransitEnterexit />,
    TransitEnterexitOutlined: <icons.TransitEnterexitOutlined />,
    TransitEnterexitRounded: <icons.TransitEnterexitRounded />,
    TransitEnterexitSharp: <icons.TransitEnterexitSharp />,
    TransitEnterexitTwoTone: <icons.TransitEnterexitTwoTone />,
    Translate: <icons.Translate />,
    TranslateOutlined: <icons.TranslateOutlined />,
    TranslateRounded: <icons.TranslateRounded />,
    TranslateSharp: <icons.TranslateSharp />,
    TranslateTwoTone: <icons.TranslateTwoTone />,
    TravelExplore: <icons.TravelExplore />,
    TravelExploreOutlined: <icons.TravelExploreOutlined />,
    TravelExploreRounded: <icons.TravelExploreRounded />,
    TravelExploreSharp: <icons.TravelExploreSharp />,
    TravelExploreTwoTone: <icons.TravelExploreTwoTone />,
    TrendingDown: <icons.TrendingDown />,
    TrendingDownOutlined: <icons.TrendingDownOutlined />,
    TrendingDownRounded: <icons.TrendingDownRounded />,
    TrendingDownSharp: <icons.TrendingDownSharp />,
    TrendingDownTwoTone: <icons.TrendingDownTwoTone />,
    TrendingFlat: <icons.TrendingFlat />,
    TrendingFlatOutlined: <icons.TrendingFlatOutlined />,
    TrendingFlatRounded: <icons.TrendingFlatRounded />,
    TrendingFlatSharp: <icons.TrendingFlatSharp />,
    TrendingFlatTwoTone: <icons.TrendingFlatTwoTone />,
    TrendingUp: <icons.TrendingUp />,
    TrendingUpOutlined: <icons.TrendingUpOutlined />,
    TrendingUpRounded: <icons.TrendingUpRounded />,
    TrendingUpSharp: <icons.TrendingUpSharp />,
    TrendingUpTwoTone: <icons.TrendingUpTwoTone />,
    TripOrigin: <icons.TripOrigin />,
    TripOriginOutlined: <icons.TripOriginOutlined />,
    TripOriginRounded: <icons.TripOriginRounded />,
    TripOriginSharp: <icons.TripOriginSharp />,
    TripOriginTwoTone: <icons.TripOriginTwoTone />,
    Troubleshoot: <icons.Troubleshoot />,
    TroubleshootOutlined: <icons.TroubleshootOutlined />,
    TroubleshootRounded: <icons.TroubleshootRounded />,
    TroubleshootSharp: <icons.TroubleshootSharp />,
    TroubleshootTwoTone: <icons.TroubleshootTwoTone />,
    Try: <icons.Try />,
    TryOutlined: <icons.TryOutlined />,
    TryRounded: <icons.TryRounded />,
    TrySharp: <icons.TrySharp />,
    TryTwoTone: <icons.TryTwoTone />,
    Tsunami: <icons.Tsunami />,
    TsunamiOutlined: <icons.TsunamiOutlined />,
    TsunamiRounded: <icons.TsunamiRounded />,
    TsunamiSharp: <icons.TsunamiSharp />,
    TsunamiTwoTone: <icons.TsunamiTwoTone />,
    Tty: <icons.Tty />,
    TtyOutlined: <icons.TtyOutlined />,
    TtyRounded: <icons.TtyRounded />,
    TtySharp: <icons.TtySharp />,
    TtyTwoTone: <icons.TtyTwoTone />,
    Tune: <icons.Tune />,
    TuneOutlined: <icons.TuneOutlined />,
    TuneRounded: <icons.TuneRounded />,
    TuneSharp: <icons.TuneSharp />,
    TuneTwoTone: <icons.TuneTwoTone />,
    Tungsten: <icons.Tungsten />,
    TungstenOutlined: <icons.TungstenOutlined />,
    TungstenRounded: <icons.TungstenRounded />,
    TungstenSharp: <icons.TungstenSharp />,
    TungstenTwoTone: <icons.TungstenTwoTone />,
    TurnLeft: <icons.TurnLeft />,
    TurnLeftOutlined: <icons.TurnLeftOutlined />,
    TurnLeftRounded: <icons.TurnLeftRounded />,
    TurnLeftSharp: <icons.TurnLeftSharp />,
    TurnLeftTwoTone: <icons.TurnLeftTwoTone />,
    TurnRight: <icons.TurnRight />,
    TurnRightOutlined: <icons.TurnRightOutlined />,
    TurnRightRounded: <icons.TurnRightRounded />,
    TurnRightSharp: <icons.TurnRightSharp />,
    TurnRightTwoTone: <icons.TurnRightTwoTone />,
    TurnSharpLeft: <icons.TurnSharpLeft />,
    TurnSharpLeftOutlined: <icons.TurnSharpLeftOutlined />,
    TurnSharpLeftRounded: <icons.TurnSharpLeftRounded />,
    TurnSharpLeftSharp: <icons.TurnSharpLeftSharp />,
    TurnSharpLeftTwoTone: <icons.TurnSharpLeftTwoTone />,
    TurnSharpRight: <icons.TurnSharpRight />,
    TurnSharpRightOutlined: <icons.TurnSharpRightOutlined />,
    TurnSharpRightRounded: <icons.TurnSharpRightRounded />,
    TurnSharpRightSharp: <icons.TurnSharpRightSharp />,
    TurnSharpRightTwoTone: <icons.TurnSharpRightTwoTone />,
    TurnSlightLeft: <icons.TurnSlightLeft />,
    TurnSlightLeftOutlined: <icons.TurnSlightLeftOutlined />,
    TurnSlightLeftRounded: <icons.TurnSlightLeftRounded />,
    TurnSlightLeftSharp: <icons.TurnSlightLeftSharp />,
    TurnSlightLeftTwoTone: <icons.TurnSlightLeftTwoTone />,
    TurnSlightRight: <icons.TurnSlightRight />,
    TurnSlightRightOutlined: <icons.TurnSlightRightOutlined />,
    TurnSlightRightRounded: <icons.TurnSlightRightRounded />,
    TurnSlightRightSharp: <icons.TurnSlightRightSharp />,
    TurnSlightRightTwoTone: <icons.TurnSlightRightTwoTone />,
    TurnedIn: <icons.TurnedIn />,
    TurnedInNot: <icons.TurnedInNot />,
    TurnedInNotOutlined: <icons.TurnedInNotOutlined />,
    TurnedInNotRounded: <icons.TurnedInNotRounded />,
    TurnedInNotSharp: <icons.TurnedInNotSharp />,
    TurnedInNotTwoTone: <icons.TurnedInNotTwoTone />,
    TurnedInOutlined: <icons.TurnedInOutlined />,
    TurnedInRounded: <icons.TurnedInRounded />,
    TurnedInSharp: <icons.TurnedInSharp />,
    TurnedInTwoTone: <icons.TurnedInTwoTone />,
    Tv: <icons.Tv />,
    TvOff: <icons.TvOff />,
    TvOffOutlined: <icons.TvOffOutlined />,
    TvOffRounded: <icons.TvOffRounded />,
    TvOffSharp: <icons.TvOffSharp />,
    TvOffTwoTone: <icons.TvOffTwoTone />,
    TvOutlined: <icons.TvOutlined />,
    TvRounded: <icons.TvRounded />,
    TvSharp: <icons.TvSharp />,
    TvTwoTone: <icons.TvTwoTone />,
    TwelveMp: <icons.TwelveMp />,
    TwelveMpOutlined: <icons.TwelveMpOutlined />,
    TwelveMpRounded: <icons.TwelveMpRounded />,
    TwelveMpSharp: <icons.TwelveMpSharp />,
    TwelveMpTwoTone: <icons.TwelveMpTwoTone />,
    TwentyFourMp: <icons.TwentyFourMp />,
    TwentyFourMpOutlined: <icons.TwentyFourMpOutlined />,
    TwentyFourMpRounded: <icons.TwentyFourMpRounded />,
    TwentyFourMpSharp: <icons.TwentyFourMpSharp />,
    TwentyFourMpTwoTone: <icons.TwentyFourMpTwoTone />,
    TwentyOneMp: <icons.TwentyOneMp />,
    TwentyOneMpOutlined: <icons.TwentyOneMpOutlined />,
    TwentyOneMpRounded: <icons.TwentyOneMpRounded />,
    TwentyOneMpSharp: <icons.TwentyOneMpSharp />,
    TwentyOneMpTwoTone: <icons.TwentyOneMpTwoTone />,
    TwentyThreeMp: <icons.TwentyThreeMp />,
    TwentyThreeMpOutlined: <icons.TwentyThreeMpOutlined />,
    TwentyThreeMpRounded: <icons.TwentyThreeMpRounded />,
    TwentyThreeMpSharp: <icons.TwentyThreeMpSharp />,
    TwentyThreeMpTwoTone: <icons.TwentyThreeMpTwoTone />,
    TwentyTwoMp: <icons.TwentyTwoMp />,
    TwentyTwoMpOutlined: <icons.TwentyTwoMpOutlined />,
    TwentyTwoMpRounded: <icons.TwentyTwoMpRounded />,
    TwentyTwoMpSharp: <icons.TwentyTwoMpSharp />,
    TwentyTwoMpTwoTone: <icons.TwentyTwoMpTwoTone />,
    TwentyZeroMp: <icons.TwentyZeroMp />,
    TwentyZeroMpOutlined: <icons.TwentyZeroMpOutlined />,
    TwentyZeroMpRounded: <icons.TwentyZeroMpRounded />,
    TwentyZeroMpSharp: <icons.TwentyZeroMpSharp />,
    TwentyZeroMpTwoTone: <icons.TwentyZeroMpTwoTone />,
    Twitter: <icons.Twitter />,
    TwoK: <icons.TwoK />,
    TwoKOutlined: <icons.TwoKOutlined />,
    TwoKPlus: <icons.TwoKPlus />,
    TwoKPlusOutlined: <icons.TwoKPlusOutlined />,
    TwoKPlusRounded: <icons.TwoKPlusRounded />,
    TwoKPlusSharp: <icons.TwoKPlusSharp />,
    TwoKPlusTwoTone: <icons.TwoKPlusTwoTone />,
    TwoKRounded: <icons.TwoKRounded />,
    TwoKSharp: <icons.TwoKSharp />,
    TwoKTwoTone: <icons.TwoKTwoTone />,
    TwoMp: <icons.TwoMp />,
    TwoMpOutlined: <icons.TwoMpOutlined />,
    TwoMpRounded: <icons.TwoMpRounded />,
    TwoMpSharp: <icons.TwoMpSharp />,
    TwoMpTwoTone: <icons.TwoMpTwoTone />,
    TwoWheeler: <icons.TwoWheeler />,
    TwoWheelerOutlined: <icons.TwoWheelerOutlined />,
    TwoWheelerRounded: <icons.TwoWheelerRounded />,
    TwoWheelerSharp: <icons.TwoWheelerSharp />,
    TwoWheelerTwoTone: <icons.TwoWheelerTwoTone />,
    TypeSpecimen: <icons.TypeSpecimen />,
    TypeSpecimenOutlined: <icons.TypeSpecimenOutlined />,
    TypeSpecimenRounded: <icons.TypeSpecimenRounded />,
    TypeSpecimenSharp: <icons.TypeSpecimenSharp />,
    TypeSpecimenTwoTone: <icons.TypeSpecimenTwoTone />,
    UTurnLeft: <icons.UTurnLeft />,
    UTurnLeftOutlined: <icons.UTurnLeftOutlined />,
    UTurnLeftRounded: <icons.UTurnLeftRounded />,
    UTurnLeftSharp: <icons.UTurnLeftSharp />,
    UTurnLeftTwoTone: <icons.UTurnLeftTwoTone />,
    UTurnRight: <icons.UTurnRight />,
    UTurnRightOutlined: <icons.UTurnRightOutlined />,
    UTurnRightRounded: <icons.UTurnRightRounded />,
    UTurnRightSharp: <icons.UTurnRightSharp />,
    UTurnRightTwoTone: <icons.UTurnRightTwoTone />,
    Umbrella: <icons.Umbrella />,
    UmbrellaOutlined: <icons.UmbrellaOutlined />,
    UmbrellaRounded: <icons.UmbrellaRounded />,
    UmbrellaSharp: <icons.UmbrellaSharp />,
    UmbrellaTwoTone: <icons.UmbrellaTwoTone />,
    Unarchive: <icons.Unarchive />,
    UnarchiveOutlined: <icons.UnarchiveOutlined />,
    UnarchiveRounded: <icons.UnarchiveRounded />,
    UnarchiveSharp: <icons.UnarchiveSharp />,
    UnarchiveTwoTone: <icons.UnarchiveTwoTone />,
    Undo: <icons.Undo />,
    UndoOutlined: <icons.UndoOutlined />,
    UndoRounded: <icons.UndoRounded />,
    UndoSharp: <icons.UndoSharp />,
    UndoTwoTone: <icons.UndoTwoTone />,
    UnfoldLess: <icons.UnfoldLess />,
    UnfoldLessDouble: <icons.UnfoldLessDouble />,
    UnfoldLessDoubleOutlined: <icons.UnfoldLessDoubleOutlined />,
    UnfoldLessDoubleRounded: <icons.UnfoldLessDoubleRounded />,
    UnfoldLessDoubleSharp: <icons.UnfoldLessDoubleSharp />,
    UnfoldLessDoubleTwoTone: <icons.UnfoldLessDoubleTwoTone />,
    UnfoldLessOutlined: <icons.UnfoldLessOutlined />,
    UnfoldLessRounded: <icons.UnfoldLessRounded />,
    UnfoldLessSharp: <icons.UnfoldLessSharp />,
    UnfoldLessTwoTone: <icons.UnfoldLessTwoTone />,
    UnfoldMore: <icons.UnfoldMore />,
    UnfoldMoreDouble: <icons.UnfoldMoreDouble />,
    UnfoldMoreDoubleOutlined: <icons.UnfoldMoreDoubleOutlined />,
    UnfoldMoreDoubleRounded: <icons.UnfoldMoreDoubleRounded />,
    UnfoldMoreDoubleSharp: <icons.UnfoldMoreDoubleSharp />,
    UnfoldMoreDoubleTwoTone: <icons.UnfoldMoreDoubleTwoTone />,
    UnfoldMoreOutlined: <icons.UnfoldMoreOutlined />,
    UnfoldMoreRounded: <icons.UnfoldMoreRounded />,
    UnfoldMoreSharp: <icons.UnfoldMoreSharp />,
    UnfoldMoreTwoTone: <icons.UnfoldMoreTwoTone />,
    Unpublished: <icons.Unpublished />,
    UnpublishedOutlined: <icons.UnpublishedOutlined />,
    UnpublishedRounded: <icons.UnpublishedRounded />,
    UnpublishedSharp: <icons.UnpublishedSharp />,
    UnpublishedTwoTone: <icons.UnpublishedTwoTone />,
    Unsubscribe: <icons.Unsubscribe />,
    UnsubscribeOutlined: <icons.UnsubscribeOutlined />,
    UnsubscribeRounded: <icons.UnsubscribeRounded />,
    UnsubscribeSharp: <icons.UnsubscribeSharp />,
    UnsubscribeTwoTone: <icons.UnsubscribeTwoTone />,
    Upcoming: <icons.Upcoming />,
    UpcomingOutlined: <icons.UpcomingOutlined />,
    UpcomingRounded: <icons.UpcomingRounded />,
    UpcomingSharp: <icons.UpcomingSharp />,
    UpcomingTwoTone: <icons.UpcomingTwoTone />,
    Update: <icons.Update />,
    UpdateDisabled: <icons.UpdateDisabled />,
    UpdateDisabledOutlined: <icons.UpdateDisabledOutlined />,
    UpdateDisabledRounded: <icons.UpdateDisabledRounded />,
    UpdateDisabledSharp: <icons.UpdateDisabledSharp />,
    UpdateDisabledTwoTone: <icons.UpdateDisabledTwoTone />,
    UpdateOutlined: <icons.UpdateOutlined />,
    UpdateRounded: <icons.UpdateRounded />,
    UpdateSharp: <icons.UpdateSharp />,
    UpdateTwoTone: <icons.UpdateTwoTone />,
    Upgrade: <icons.Upgrade />,
    UpgradeOutlined: <icons.UpgradeOutlined />,
    UpgradeRounded: <icons.UpgradeRounded />,
    UpgradeSharp: <icons.UpgradeSharp />,
    UpgradeTwoTone: <icons.UpgradeTwoTone />,
    Upload: <icons.Upload />,
    UploadFile: <icons.UploadFile />,
    UploadFileOutlined: <icons.UploadFileOutlined />,
    UploadFileRounded: <icons.UploadFileRounded />,
    UploadFileSharp: <icons.UploadFileSharp />,
    UploadFileTwoTone: <icons.UploadFileTwoTone />,
    UploadOutlined: <icons.UploadOutlined />,
    UploadRounded: <icons.UploadRounded />,
    UploadSharp: <icons.UploadSharp />,
    UploadTwoTone: <icons.UploadTwoTone />,
    Usb: <icons.Usb />,
    UsbOff: <icons.UsbOff />,
    UsbOffOutlined: <icons.UsbOffOutlined />,
    UsbOffRounded: <icons.UsbOffRounded />,
    UsbOffSharp: <icons.UsbOffSharp />,
    UsbOffTwoTone: <icons.UsbOffTwoTone />,
    UsbOutlined: <icons.UsbOutlined />,
    UsbRounded: <icons.UsbRounded />,
    UsbSharp: <icons.UsbSharp />,
    UsbTwoTone: <icons.UsbTwoTone />,
    Vaccines: <icons.Vaccines />,
    VaccinesOutlined: <icons.VaccinesOutlined />,
    VaccinesRounded: <icons.VaccinesRounded />,
    VaccinesSharp: <icons.VaccinesSharp />,
    VaccinesTwoTone: <icons.VaccinesTwoTone />,
    VapeFree: <icons.VapeFree />,
    VapeFreeOutlined: <icons.VapeFreeOutlined />,
    VapeFreeRounded: <icons.VapeFreeRounded />,
    VapeFreeSharp: <icons.VapeFreeSharp />,
    VapeFreeTwoTone: <icons.VapeFreeTwoTone />,
    VapingRooms: <icons.VapingRooms />,
    VapingRoomsOutlined: <icons.VapingRoomsOutlined />,
    VapingRoomsRounded: <icons.VapingRoomsRounded />,
    VapingRoomsSharp: <icons.VapingRoomsSharp />,
    VapingRoomsTwoTone: <icons.VapingRoomsTwoTone />,
    Verified: <icons.Verified />,
    VerifiedOutlined: <icons.VerifiedOutlined />,
    VerifiedRounded: <icons.VerifiedRounded />,
    VerifiedSharp: <icons.VerifiedSharp />,
    VerifiedTwoTone: <icons.VerifiedTwoTone />,
    VerifiedUser: <icons.VerifiedUser />,
    VerifiedUserOutlined: <icons.VerifiedUserOutlined />,
    VerifiedUserRounded: <icons.VerifiedUserRounded />,
    VerifiedUserSharp: <icons.VerifiedUserSharp />,
    VerifiedUserTwoTone: <icons.VerifiedUserTwoTone />,
    VerticalAlignBottom: <icons.VerticalAlignBottom />,
    VerticalAlignBottomOutlined: <icons.VerticalAlignBottomOutlined />,
    VerticalAlignBottomRounded: <icons.VerticalAlignBottomRounded />,
    VerticalAlignBottomSharp: <icons.VerticalAlignBottomSharp />,
    VerticalAlignBottomTwoTone: <icons.VerticalAlignBottomTwoTone />,
    VerticalAlignCenter: <icons.VerticalAlignCenter />,
    VerticalAlignCenterOutlined: <icons.VerticalAlignCenterOutlined />,
    VerticalAlignCenterRounded: <icons.VerticalAlignCenterRounded />,
    VerticalAlignCenterSharp: <icons.VerticalAlignCenterSharp />,
    VerticalAlignCenterTwoTone: <icons.VerticalAlignCenterTwoTone />,
    VerticalAlignTop: <icons.VerticalAlignTop />,
    VerticalAlignTopOutlined: <icons.VerticalAlignTopOutlined />,
    VerticalAlignTopRounded: <icons.VerticalAlignTopRounded />,
    VerticalAlignTopSharp: <icons.VerticalAlignTopSharp />,
    VerticalAlignTopTwoTone: <icons.VerticalAlignTopTwoTone />,
    VerticalShades: <icons.VerticalShades />,
    VerticalShadesClosed: <icons.VerticalShadesClosed />,
    VerticalShadesClosedOutlined: <icons.VerticalShadesClosedOutlined />,
    VerticalShadesClosedRounded: <icons.VerticalShadesClosedRounded />,
    VerticalShadesClosedSharp: <icons.VerticalShadesClosedSharp />,
    VerticalShadesClosedTwoTone: <icons.VerticalShadesClosedTwoTone />,
    VerticalShadesOutlined: <icons.VerticalShadesOutlined />,
    VerticalShadesRounded: <icons.VerticalShadesRounded />,
    VerticalShadesSharp: <icons.VerticalShadesSharp />,
    VerticalShadesTwoTone: <icons.VerticalShadesTwoTone />,
    VerticalSplit: <icons.VerticalSplit />,
    VerticalSplitOutlined: <icons.VerticalSplitOutlined />,
    VerticalSplitRounded: <icons.VerticalSplitRounded />,
    VerticalSplitSharp: <icons.VerticalSplitSharp />,
    VerticalSplitTwoTone: <icons.VerticalSplitTwoTone />,
    Vibration: <icons.Vibration />,
    VibrationOutlined: <icons.VibrationOutlined />,
    VibrationRounded: <icons.VibrationRounded />,
    VibrationSharp: <icons.VibrationSharp />,
    VibrationTwoTone: <icons.VibrationTwoTone />,
    VideoCall: <icons.VideoCall />,
    VideoCallOutlined: <icons.VideoCallOutlined />,
    VideoCallRounded: <icons.VideoCallRounded />,
    VideoCallSharp: <icons.VideoCallSharp />,
    VideoCallTwoTone: <icons.VideoCallTwoTone />,
    VideoCameraBack: <icons.VideoCameraBack />,
    VideoCameraBackOutlined: <icons.VideoCameraBackOutlined />,
    VideoCameraBackRounded: <icons.VideoCameraBackRounded />,
    VideoCameraBackSharp: <icons.VideoCameraBackSharp />,
    VideoCameraBackTwoTone: <icons.VideoCameraBackTwoTone />,
    VideoCameraFront: <icons.VideoCameraFront />,
    VideoCameraFrontOutlined: <icons.VideoCameraFrontOutlined />,
    VideoCameraFrontRounded: <icons.VideoCameraFrontRounded />,
    VideoCameraFrontSharp: <icons.VideoCameraFrontSharp />,
    VideoCameraFrontTwoTone: <icons.VideoCameraFrontTwoTone />,
    VideoChat: <icons.VideoChat />,
    VideoChatOutlined: <icons.VideoChatOutlined />,
    VideoChatRounded: <icons.VideoChatRounded />,
    VideoChatSharp: <icons.VideoChatSharp />,
    VideoChatTwoTone: <icons.VideoChatTwoTone />,
    VideoFile: <icons.VideoFile />,
    VideoFileOutlined: <icons.VideoFileOutlined />,
    VideoFileRounded: <icons.VideoFileRounded />,
    VideoFileSharp: <icons.VideoFileSharp />,
    VideoFileTwoTone: <icons.VideoFileTwoTone />,
    VideoLabel: <icons.VideoLabel />,
    VideoLabelOutlined: <icons.VideoLabelOutlined />,
    VideoLabelRounded: <icons.VideoLabelRounded />,
    VideoLabelSharp: <icons.VideoLabelSharp />,
    VideoLabelTwoTone: <icons.VideoLabelTwoTone />,
    VideoLibrary: <icons.VideoLibrary />,
    VideoLibraryOutlined: <icons.VideoLibraryOutlined />,
    VideoLibraryRounded: <icons.VideoLibraryRounded />,
    VideoLibrarySharp: <icons.VideoLibrarySharp />,
    VideoLibraryTwoTone: <icons.VideoLibraryTwoTone />,
    VideoSettings: <icons.VideoSettings />,
    VideoSettingsOutlined: <icons.VideoSettingsOutlined />,
    VideoSettingsRounded: <icons.VideoSettingsRounded />,
    VideoSettingsSharp: <icons.VideoSettingsSharp />,
    VideoSettingsTwoTone: <icons.VideoSettingsTwoTone />,
    VideoStable: <icons.VideoStable />,
    VideoStableOutlined: <icons.VideoStableOutlined />,
    VideoStableRounded: <icons.VideoStableRounded />,
    VideoStableSharp: <icons.VideoStableSharp />,
    VideoStableTwoTone: <icons.VideoStableTwoTone />,
    Videocam: <icons.Videocam />,
    VideocamOff: <icons.VideocamOff />,
    VideocamOffOutlined: <icons.VideocamOffOutlined />,
    VideocamOffRounded: <icons.VideocamOffRounded />,
    VideocamOffSharp: <icons.VideocamOffSharp />,
    VideocamOffTwoTone: <icons.VideocamOffTwoTone />,
    VideocamOutlined: <icons.VideocamOutlined />,
    VideocamRounded: <icons.VideocamRounded />,
    VideocamSharp: <icons.VideocamSharp />,
    VideocamTwoTone: <icons.VideocamTwoTone />,
    VideogameAsset: <icons.VideogameAsset />,
    VideogameAssetOff: <icons.VideogameAssetOff />,
    VideogameAssetOffOutlined: <icons.VideogameAssetOffOutlined />,
    VideogameAssetOffRounded: <icons.VideogameAssetOffRounded />,
    VideogameAssetOffSharp: <icons.VideogameAssetOffSharp />,
    VideogameAssetOffTwoTone: <icons.VideogameAssetOffTwoTone />,
    VideogameAssetOutlined: <icons.VideogameAssetOutlined />,
    VideogameAssetRounded: <icons.VideogameAssetRounded />,
    VideogameAssetSharp: <icons.VideogameAssetSharp />,
    VideogameAssetTwoTone: <icons.VideogameAssetTwoTone />,
    ViewAgenda: <icons.ViewAgenda />,
    ViewAgendaOutlined: <icons.ViewAgendaOutlined />,
    ViewAgendaRounded: <icons.ViewAgendaRounded />,
    ViewAgendaSharp: <icons.ViewAgendaSharp />,
    ViewAgendaTwoTone: <icons.ViewAgendaTwoTone />,
    ViewArray: <icons.ViewArray />,
    ViewArrayOutlined: <icons.ViewArrayOutlined />,
    ViewArrayRounded: <icons.ViewArrayRounded />,
    ViewArraySharp: <icons.ViewArraySharp />,
    ViewArrayTwoTone: <icons.ViewArrayTwoTone />,
    ViewCarousel: <icons.ViewCarousel />,
    ViewCarouselOutlined: <icons.ViewCarouselOutlined />,
    ViewCarouselRounded: <icons.ViewCarouselRounded />,
    ViewCarouselSharp: <icons.ViewCarouselSharp />,
    ViewCarouselTwoTone: <icons.ViewCarouselTwoTone />,
    ViewColumn: <icons.ViewColumn />,
    ViewColumnOutlined: <icons.ViewColumnOutlined />,
    ViewColumnRounded: <icons.ViewColumnRounded />,
    ViewColumnSharp: <icons.ViewColumnSharp />,
    ViewColumnTwoTone: <icons.ViewColumnTwoTone />,
    ViewComfy: <icons.ViewComfy />,
    ViewComfyAlt: <icons.ViewComfyAlt />,
    ViewComfyAltOutlined: <icons.ViewComfyAltOutlined />,
    ViewComfyAltRounded: <icons.ViewComfyAltRounded />,
    ViewComfyAltSharp: <icons.ViewComfyAltSharp />,
    ViewComfyAltTwoTone: <icons.ViewComfyAltTwoTone />,
    ViewComfyOutlined: <icons.ViewComfyOutlined />,
    ViewComfyRounded: <icons.ViewComfyRounded />,
    ViewComfySharp: <icons.ViewComfySharp />,
    ViewComfyTwoTone: <icons.ViewComfyTwoTone />,
    ViewCompact: <icons.ViewCompact />,
    ViewCompactAlt: <icons.ViewCompactAlt />,
    ViewCompactAltOutlined: <icons.ViewCompactAltOutlined />,
    ViewCompactAltRounded: <icons.ViewCompactAltRounded />,
    ViewCompactAltSharp: <icons.ViewCompactAltSharp />,
    ViewCompactAltTwoTone: <icons.ViewCompactAltTwoTone />,
    ViewCompactOutlined: <icons.ViewCompactOutlined />,
    ViewCompactRounded: <icons.ViewCompactRounded />,
    ViewCompactSharp: <icons.ViewCompactSharp />,
    ViewCompactTwoTone: <icons.ViewCompactTwoTone />,
    ViewCozy: <icons.ViewCozy />,
    ViewCozyOutlined: <icons.ViewCozyOutlined />,
    ViewCozyRounded: <icons.ViewCozyRounded />,
    ViewCozySharp: <icons.ViewCozySharp />,
    ViewCozyTwoTone: <icons.ViewCozyTwoTone />,
    ViewDay: <icons.ViewDay />,
    ViewDayOutlined: <icons.ViewDayOutlined />,
    ViewDayRounded: <icons.ViewDayRounded />,
    ViewDaySharp: <icons.ViewDaySharp />,
    ViewDayTwoTone: <icons.ViewDayTwoTone />,
    ViewHeadline: <icons.ViewHeadline />,
    ViewHeadlineOutlined: <icons.ViewHeadlineOutlined />,
    ViewHeadlineRounded: <icons.ViewHeadlineRounded />,
    ViewHeadlineSharp: <icons.ViewHeadlineSharp />,
    ViewHeadlineTwoTone: <icons.ViewHeadlineTwoTone />,
    ViewInAr: <icons.ViewInAr />,
    ViewInArOutlined: <icons.ViewInArOutlined />,
    ViewInArRounded: <icons.ViewInArRounded />,
    ViewInArSharp: <icons.ViewInArSharp />,
    ViewInArTwoTone: <icons.ViewInArTwoTone />,
    ViewKanban: <icons.ViewKanban />,
    ViewKanbanOutlined: <icons.ViewKanbanOutlined />,
    ViewKanbanRounded: <icons.ViewKanbanRounded />,
    ViewKanbanSharp: <icons.ViewKanbanSharp />,
    ViewKanbanTwoTone: <icons.ViewKanbanTwoTone />,
    ViewList: <icons.ViewList />,
    ViewListOutlined: <icons.ViewListOutlined />,
    ViewListRounded: <icons.ViewListRounded />,
    ViewListSharp: <icons.ViewListSharp />,
    ViewListTwoTone: <icons.ViewListTwoTone />,
    ViewModule: <icons.ViewModule />,
    ViewModuleOutlined: <icons.ViewModuleOutlined />,
    ViewModuleRounded: <icons.ViewModuleRounded />,
    ViewModuleSharp: <icons.ViewModuleSharp />,
    ViewModuleTwoTone: <icons.ViewModuleTwoTone />,
    ViewQuilt: <icons.ViewQuilt />,
    ViewQuiltOutlined: <icons.ViewQuiltOutlined />,
    ViewQuiltRounded: <icons.ViewQuiltRounded />,
    ViewQuiltSharp: <icons.ViewQuiltSharp />,
    ViewQuiltTwoTone: <icons.ViewQuiltTwoTone />,
    ViewSidebar: <icons.ViewSidebar />,
    ViewSidebarOutlined: <icons.ViewSidebarOutlined />,
    ViewSidebarRounded: <icons.ViewSidebarRounded />,
    ViewSidebarSharp: <icons.ViewSidebarSharp />,
    ViewSidebarTwoTone: <icons.ViewSidebarTwoTone />,
    ViewStream: <icons.ViewStream />,
    ViewStreamOutlined: <icons.ViewStreamOutlined />,
    ViewStreamRounded: <icons.ViewStreamRounded />,
    ViewStreamSharp: <icons.ViewStreamSharp />,
    ViewStreamTwoTone: <icons.ViewStreamTwoTone />,
    ViewTimeline: <icons.ViewTimeline />,
    ViewTimelineOutlined: <icons.ViewTimelineOutlined />,
    ViewTimelineRounded: <icons.ViewTimelineRounded />,
    ViewTimelineSharp: <icons.ViewTimelineSharp />,
    ViewTimelineTwoTone: <icons.ViewTimelineTwoTone />,
    ViewWeek: <icons.ViewWeek />,
    ViewWeekOutlined: <icons.ViewWeekOutlined />,
    ViewWeekRounded: <icons.ViewWeekRounded />,
    ViewWeekSharp: <icons.ViewWeekSharp />,
    ViewWeekTwoTone: <icons.ViewWeekTwoTone />,
    Vignette: <icons.Vignette />,
    VignetteOutlined: <icons.VignetteOutlined />,
    VignetteRounded: <icons.VignetteRounded />,
    VignetteSharp: <icons.VignetteSharp />,
    VignetteTwoTone: <icons.VignetteTwoTone />,
    Villa: <icons.Villa />,
    VillaOutlined: <icons.VillaOutlined />,
    VillaRounded: <icons.VillaRounded />,
    VillaSharp: <icons.VillaSharp />,
    VillaTwoTone: <icons.VillaTwoTone />,
    Visibility: <icons.Visibility />,
    VisibilityOff: <icons.VisibilityOff />,
    VisibilityOffOutlined: <icons.VisibilityOffOutlined />,
    VisibilityOffRounded: <icons.VisibilityOffRounded />,
    VisibilityOffSharp: <icons.VisibilityOffSharp />,
    VisibilityOffTwoTone: <icons.VisibilityOffTwoTone />,
    VisibilityOutlined: <icons.VisibilityOutlined />,
    VisibilityRounded: <icons.VisibilityRounded />,
    VisibilitySharp: <icons.VisibilitySharp />,
    VisibilityTwoTone: <icons.VisibilityTwoTone />,
    VoiceChat: <icons.VoiceChat />,
    VoiceChatOutlined: <icons.VoiceChatOutlined />,
    VoiceChatRounded: <icons.VoiceChatRounded />,
    VoiceChatSharp: <icons.VoiceChatSharp />,
    VoiceChatTwoTone: <icons.VoiceChatTwoTone />,
    VoiceOverOff: <icons.VoiceOverOff />,
    VoiceOverOffOutlined: <icons.VoiceOverOffOutlined />,
    VoiceOverOffRounded: <icons.VoiceOverOffRounded />,
    VoiceOverOffSharp: <icons.VoiceOverOffSharp />,
    VoiceOverOffTwoTone: <icons.VoiceOverOffTwoTone />,
    Voicemail: <icons.Voicemail />,
    VoicemailOutlined: <icons.VoicemailOutlined />,
    VoicemailRounded: <icons.VoicemailRounded />,
    VoicemailSharp: <icons.VoicemailSharp />,
    VoicemailTwoTone: <icons.VoicemailTwoTone />,
    Volcano: <icons.Volcano />,
    VolcanoOutlined: <icons.VolcanoOutlined />,
    VolcanoRounded: <icons.VolcanoRounded />,
    VolcanoSharp: <icons.VolcanoSharp />,
    VolcanoTwoTone: <icons.VolcanoTwoTone />,
    VolumeDown: <icons.VolumeDown />,
    VolumeDownOutlined: <icons.VolumeDownOutlined />,
    VolumeDownRounded: <icons.VolumeDownRounded />,
    VolumeDownSharp: <icons.VolumeDownSharp />,
    VolumeDownTwoTone: <icons.VolumeDownTwoTone />,
    VolumeMute: <icons.VolumeMute />,
    VolumeMuteOutlined: <icons.VolumeMuteOutlined />,
    VolumeMuteRounded: <icons.VolumeMuteRounded />,
    VolumeMuteSharp: <icons.VolumeMuteSharp />,
    VolumeMuteTwoTone: <icons.VolumeMuteTwoTone />,
    VolumeOff: <icons.VolumeOff />,
    VolumeOffOutlined: <icons.VolumeOffOutlined />,
    VolumeOffRounded: <icons.VolumeOffRounded />,
    VolumeOffSharp: <icons.VolumeOffSharp />,
    VolumeOffTwoTone: <icons.VolumeOffTwoTone />,
    VolumeUp: <icons.VolumeUp />,
    VolumeUpOutlined: <icons.VolumeUpOutlined />,
    VolumeUpRounded: <icons.VolumeUpRounded />,
    VolumeUpSharp: <icons.VolumeUpSharp />,
    VolumeUpTwoTone: <icons.VolumeUpTwoTone />,
    VolunteerActivism: <icons.VolunteerActivism />,
    VolunteerActivismOutlined: <icons.VolunteerActivismOutlined />,
    VolunteerActivismRounded: <icons.VolunteerActivismRounded />,
    VolunteerActivismSharp: <icons.VolunteerActivismSharp />,
    VolunteerActivismTwoTone: <icons.VolunteerActivismTwoTone />,
    VpnKey: <icons.VpnKey />,
    VpnKeyOff: <icons.VpnKeyOff />,
    VpnKeyOffOutlined: <icons.VpnKeyOffOutlined />,
    VpnKeyOffRounded: <icons.VpnKeyOffRounded />,
    VpnKeyOffSharp: <icons.VpnKeyOffSharp />,
    VpnKeyOffTwoTone: <icons.VpnKeyOffTwoTone />,
    VpnKeyOutlined: <icons.VpnKeyOutlined />,
    VpnKeyRounded: <icons.VpnKeyRounded />,
    VpnKeySharp: <icons.VpnKeySharp />,
    VpnKeyTwoTone: <icons.VpnKeyTwoTone />,
    VpnLock: <icons.VpnLock />,
    VpnLockOutlined: <icons.VpnLockOutlined />,
    VpnLockRounded: <icons.VpnLockRounded />,
    VpnLockSharp: <icons.VpnLockSharp />,
    VpnLockTwoTone: <icons.VpnLockTwoTone />,
    Vrpano: <icons.Vrpano />,
    VrpanoOutlined: <icons.VrpanoOutlined />,
    VrpanoRounded: <icons.VrpanoRounded />,
    VrpanoSharp: <icons.VrpanoSharp />,
    VrpanoTwoTone: <icons.VrpanoTwoTone />,
    Wallet: <icons.Wallet />,
    WalletOutlined: <icons.WalletOutlined />,
    WalletRounded: <icons.WalletRounded />,
    WalletSharp: <icons.WalletSharp />,
    WalletTwoTone: <icons.WalletTwoTone />,
    Wallpaper: <icons.Wallpaper />,
    WallpaperOutlined: <icons.WallpaperOutlined />,
    WallpaperRounded: <icons.WallpaperRounded />,
    WallpaperSharp: <icons.WallpaperSharp />,
    WallpaperTwoTone: <icons.WallpaperTwoTone />,
    Warehouse: <icons.Warehouse />,
    WarehouseOutlined: <icons.WarehouseOutlined />,
    WarehouseRounded: <icons.WarehouseRounded />,
    WarehouseSharp: <icons.WarehouseSharp />,
    WarehouseTwoTone: <icons.WarehouseTwoTone />,
    Warning: <icons.Warning />,
    WarningAmber: <icons.WarningAmber />,
    WarningAmberOutlined: <icons.WarningAmberOutlined />,
    WarningAmberRounded: <icons.WarningAmberRounded />,
    WarningAmberSharp: <icons.WarningAmberSharp />,
    WarningAmberTwoTone: <icons.WarningAmberTwoTone />,
    WarningOutlined: <icons.WarningOutlined />,
    WarningRounded: <icons.WarningRounded />,
    WarningSharp: <icons.WarningSharp />,
    WarningTwoTone: <icons.WarningTwoTone />,
    Wash: <icons.Wash />,
    WashOutlined: <icons.WashOutlined />,
    WashRounded: <icons.WashRounded />,
    WashSharp: <icons.WashSharp />,
    WashTwoTone: <icons.WashTwoTone />,
    Watch: <icons.Watch />,
    WatchLater: <icons.WatchLater />,
    WatchLaterOutlined: <icons.WatchLaterOutlined />,
    WatchLaterRounded: <icons.WatchLaterRounded />,
    WatchLaterSharp: <icons.WatchLaterSharp />,
    WatchLaterTwoTone: <icons.WatchLaterTwoTone />,
    WatchOff: <icons.WatchOff />,
    WatchOffOutlined: <icons.WatchOffOutlined />,
    WatchOffRounded: <icons.WatchOffRounded />,
    WatchOffSharp: <icons.WatchOffSharp />,
    WatchOffTwoTone: <icons.WatchOffTwoTone />,
    WatchOutlined: <icons.WatchOutlined />,
    WatchRounded: <icons.WatchRounded />,
    WatchSharp: <icons.WatchSharp />,
    WatchTwoTone: <icons.WatchTwoTone />,
    Water: <icons.Water />,
    WaterDamage: <icons.WaterDamage />,
    WaterDamageOutlined: <icons.WaterDamageOutlined />,
    WaterDamageRounded: <icons.WaterDamageRounded />,
    WaterDamageSharp: <icons.WaterDamageSharp />,
    WaterDamageTwoTone: <icons.WaterDamageTwoTone />,
    WaterDrop: <icons.WaterDrop />,
    WaterDropOutlined: <icons.WaterDropOutlined />,
    WaterDropRounded: <icons.WaterDropRounded />,
    WaterDropSharp: <icons.WaterDropSharp />,
    WaterDropTwoTone: <icons.WaterDropTwoTone />,
    WaterOutlined: <icons.WaterOutlined />,
    WaterRounded: <icons.WaterRounded />,
    WaterSharp: <icons.WaterSharp />,
    WaterTwoTone: <icons.WaterTwoTone />,
    WaterfallChart: <icons.WaterfallChart />,
    WaterfallChartOutlined: <icons.WaterfallChartOutlined />,
    WaterfallChartRounded: <icons.WaterfallChartRounded />,
    WaterfallChartSharp: <icons.WaterfallChartSharp />,
    WaterfallChartTwoTone: <icons.WaterfallChartTwoTone />,
    Waves: <icons.Waves />,
    WavesOutlined: <icons.WavesOutlined />,
    WavesRounded: <icons.WavesRounded />,
    WavesSharp: <icons.WavesSharp />,
    WavesTwoTone: <icons.WavesTwoTone />,
    WavingHand: <icons.WavingHand />,
    WavingHandOutlined: <icons.WavingHandOutlined />,
    WavingHandRounded: <icons.WavingHandRounded />,
    WavingHandSharp: <icons.WavingHandSharp />,
    WavingHandTwoTone: <icons.WavingHandTwoTone />,
    WbAuto: <icons.WbAuto />,
    WbAutoOutlined: <icons.WbAutoOutlined />,
    WbAutoRounded: <icons.WbAutoRounded />,
    WbAutoSharp: <icons.WbAutoSharp />,
    WbAutoTwoTone: <icons.WbAutoTwoTone />,
    WbCloudy: <icons.WbCloudy />,
    WbCloudyOutlined: <icons.WbCloudyOutlined />,
    WbCloudyRounded: <icons.WbCloudyRounded />,
    WbCloudySharp: <icons.WbCloudySharp />,
    WbCloudyTwoTone: <icons.WbCloudyTwoTone />,
    WbIncandescent: <icons.WbIncandescent />,
    WbIncandescentOutlined: <icons.WbIncandescentOutlined />,
    WbIncandescentRounded: <icons.WbIncandescentRounded />,
    WbIncandescentSharp: <icons.WbIncandescentSharp />,
    WbIncandescentTwoTone: <icons.WbIncandescentTwoTone />,
    WbIridescent: <icons.WbIridescent />,
    WbIridescentOutlined: <icons.WbIridescentOutlined />,
    WbIridescentRounded: <icons.WbIridescentRounded />,
    WbIridescentSharp: <icons.WbIridescentSharp />,
    WbIridescentTwoTone: <icons.WbIridescentTwoTone />,
    WbShade: <icons.WbShade />,
    WbShadeOutlined: <icons.WbShadeOutlined />,
    WbShadeRounded: <icons.WbShadeRounded />,
    WbShadeSharp: <icons.WbShadeSharp />,
    WbShadeTwoTone: <icons.WbShadeTwoTone />,
    WbSunny: <icons.WbSunny />,
    WbSunnyOutlined: <icons.WbSunnyOutlined />,
    WbSunnyRounded: <icons.WbSunnyRounded />,
    WbSunnySharp: <icons.WbSunnySharp />,
    WbSunnyTwoTone: <icons.WbSunnyTwoTone />,
    WbTwilight: <icons.WbTwilight />,
    WbTwilightOutlined: <icons.WbTwilightOutlined />,
    WbTwilightRounded: <icons.WbTwilightRounded />,
    WbTwilightSharp: <icons.WbTwilightSharp />,
    WbTwilightTwoTone: <icons.WbTwilightTwoTone />,
    Wc: <icons.Wc />,
    WcOutlined: <icons.WcOutlined />,
    WcRounded: <icons.WcRounded />,
    WcSharp: <icons.WcSharp />,
    WcTwoTone: <icons.WcTwoTone />,
    Web: <icons.Web />,
    WebAsset: <icons.WebAsset />,
    WebAssetOff: <icons.WebAssetOff />,
    WebAssetOffOutlined: <icons.WebAssetOffOutlined />,
    WebAssetOffRounded: <icons.WebAssetOffRounded />,
    WebAssetOffSharp: <icons.WebAssetOffSharp />,
    WebAssetOffTwoTone: <icons.WebAssetOffTwoTone />,
    WebAssetOutlined: <icons.WebAssetOutlined />,
    WebAssetRounded: <icons.WebAssetRounded />,
    WebAssetSharp: <icons.WebAssetSharp />,
    WebAssetTwoTone: <icons.WebAssetTwoTone />,
    WebOutlined: <icons.WebOutlined />,
    WebRounded: <icons.WebRounded />,
    WebSharp: <icons.WebSharp />,
    WebStories: <icons.WebStories />,
    WebStoriesOutlined: <icons.WebStoriesOutlined />,
    WebStoriesRounded: <icons.WebStoriesRounded />,
    WebStoriesSharp: <icons.WebStoriesSharp />,
    WebStoriesTwoTone: <icons.WebStoriesTwoTone />,
    WebTwoTone: <icons.WebTwoTone />,
    Webhook: <icons.Webhook />,
    WebhookOutlined: <icons.WebhookOutlined />,
    WebhookRounded: <icons.WebhookRounded />,
    WebhookSharp: <icons.WebhookSharp />,
    WebhookTwoTone: <icons.WebhookTwoTone />,
    Weekend: <icons.Weekend />,
    WeekendOutlined: <icons.WeekendOutlined />,
    WeekendRounded: <icons.WeekendRounded />,
    WeekendSharp: <icons.WeekendSharp />,
    WeekendTwoTone: <icons.WeekendTwoTone />,
    West: <icons.West />,
    WestOutlined: <icons.WestOutlined />,
    WestRounded: <icons.WestRounded />,
    WestSharp: <icons.WestSharp />,
    WestTwoTone: <icons.WestTwoTone />,
    WhatsApp: <icons.WhatsApp />,
    Whatshot: <icons.Whatshot />,
    WhatshotOutlined: <icons.WhatshotOutlined />,
    WhatshotRounded: <icons.WhatshotRounded />,
    WhatshotSharp: <icons.WhatshotSharp />,
    WhatshotTwoTone: <icons.WhatshotTwoTone />,
    WheelchairPickup: <icons.WheelchairPickup />,
    WheelchairPickupOutlined: <icons.WheelchairPickupOutlined />,
    WheelchairPickupRounded: <icons.WheelchairPickupRounded />,
    WheelchairPickupSharp: <icons.WheelchairPickupSharp />,
    WheelchairPickupTwoTone: <icons.WheelchairPickupTwoTone />,
    WhereToVote: <icons.WhereToVote />,
    WhereToVoteOutlined: <icons.WhereToVoteOutlined />,
    WhereToVoteRounded: <icons.WhereToVoteRounded />,
    WhereToVoteSharp: <icons.WhereToVoteSharp />,
    WhereToVoteTwoTone: <icons.WhereToVoteTwoTone />,
    Widgets: <icons.Widgets />,
    WidgetsOutlined: <icons.WidgetsOutlined />,
    WidgetsRounded: <icons.WidgetsRounded />,
    WidgetsSharp: <icons.WidgetsSharp />,
    WidgetsTwoTone: <icons.WidgetsTwoTone />,
    WidthFull: <icons.WidthFull />,
    WidthFullOutlined: <icons.WidthFullOutlined />,
    WidthFullRounded: <icons.WidthFullRounded />,
    WidthFullSharp: <icons.WidthFullSharp />,
    WidthFullTwoTone: <icons.WidthFullTwoTone />,
    WidthNormal: <icons.WidthNormal />,
    WidthNormalOutlined: <icons.WidthNormalOutlined />,
    WidthNormalRounded: <icons.WidthNormalRounded />,
    WidthNormalSharp: <icons.WidthNormalSharp />,
    WidthNormalTwoTone: <icons.WidthNormalTwoTone />,
    WidthWide: <icons.WidthWide />,
    WidthWideOutlined: <icons.WidthWideOutlined />,
    WidthWideRounded: <icons.WidthWideRounded />,
    WidthWideSharp: <icons.WidthWideSharp />,
    WidthWideTwoTone: <icons.WidthWideTwoTone />,
    Wifi: <icons.Wifi />,
    Wifi1Bar: <icons.Wifi1Bar />,
    Wifi1BarOutlined: <icons.Wifi1BarOutlined />,
    Wifi1BarRounded: <icons.Wifi1BarRounded />,
    Wifi1BarSharp: <icons.Wifi1BarSharp />,
    Wifi1BarTwoTone: <icons.Wifi1BarTwoTone />,
    Wifi2Bar: <icons.Wifi2Bar />,
    Wifi2BarOutlined: <icons.Wifi2BarOutlined />,
    Wifi2BarRounded: <icons.Wifi2BarRounded />,
    Wifi2BarSharp: <icons.Wifi2BarSharp />,
    Wifi2BarTwoTone: <icons.Wifi2BarTwoTone />,
    WifiCalling: <icons.WifiCalling />,
    WifiCalling3: <icons.WifiCalling3 />,
    WifiCalling3Outlined: <icons.WifiCalling3Outlined />,
    WifiCalling3Rounded: <icons.WifiCalling3Rounded />,
    WifiCalling3Sharp: <icons.WifiCalling3Sharp />,
    WifiCalling3TwoTone: <icons.WifiCalling3TwoTone />,
    WifiCallingOutlined: <icons.WifiCallingOutlined />,
    WifiCallingRounded: <icons.WifiCallingRounded />,
    WifiCallingSharp: <icons.WifiCallingSharp />,
    WifiCallingTwoTone: <icons.WifiCallingTwoTone />,
    WifiChannel: <icons.WifiChannel />,
    WifiChannelOutlined: <icons.WifiChannelOutlined />,
    WifiChannelRounded: <icons.WifiChannelRounded />,
    WifiChannelSharp: <icons.WifiChannelSharp />,
    WifiChannelTwoTone: <icons.WifiChannelTwoTone />,
    WifiFind: <icons.WifiFind />,
    WifiFindOutlined: <icons.WifiFindOutlined />,
    WifiFindRounded: <icons.WifiFindRounded />,
    WifiFindSharp: <icons.WifiFindSharp />,
    WifiFindTwoTone: <icons.WifiFindTwoTone />,
    WifiLock: <icons.WifiLock />,
    WifiLockOutlined: <icons.WifiLockOutlined />,
    WifiLockRounded: <icons.WifiLockRounded />,
    WifiLockSharp: <icons.WifiLockSharp />,
    WifiLockTwoTone: <icons.WifiLockTwoTone />,
    WifiOff: <icons.WifiOff />,
    WifiOffOutlined: <icons.WifiOffOutlined />,
    WifiOffRounded: <icons.WifiOffRounded />,
    WifiOffSharp: <icons.WifiOffSharp />,
    WifiOffTwoTone: <icons.WifiOffTwoTone />,
    WifiOutlined: <icons.WifiOutlined />,
    WifiPassword: <icons.WifiPassword />,
    WifiPasswordOutlined: <icons.WifiPasswordOutlined />,
    WifiPasswordRounded: <icons.WifiPasswordRounded />,
    WifiPasswordSharp: <icons.WifiPasswordSharp />,
    WifiPasswordTwoTone: <icons.WifiPasswordTwoTone />,
    WifiProtectedSetup: <icons.WifiProtectedSetup />,
    WifiProtectedSetupOutlined: <icons.WifiProtectedSetupOutlined />,
    WifiProtectedSetupRounded: <icons.WifiProtectedSetupRounded />,
    WifiProtectedSetupSharp: <icons.WifiProtectedSetupSharp />,
    WifiProtectedSetupTwoTone: <icons.WifiProtectedSetupTwoTone />,
    WifiRounded: <icons.WifiRounded />,
    WifiSharp: <icons.WifiSharp />,
    WifiTethering: <icons.WifiTethering />,
    WifiTetheringError: <icons.WifiTetheringError />,
    WifiTetheringErrorOutlined: <icons.WifiTetheringErrorOutlined />,
    WifiTetheringErrorRounded: <icons.WifiTetheringErrorRounded />,
    WifiTetheringErrorRoundedOutlined: (
        <icons.WifiTetheringErrorRoundedOutlined />
    ),
    WifiTetheringErrorRoundedRounded: (
        <icons.WifiTetheringErrorRoundedRounded />
    ),
    WifiTetheringErrorRoundedSharp: <icons.WifiTetheringErrorRoundedSharp />,
    WifiTetheringErrorRoundedTwoTone: (
        <icons.WifiTetheringErrorRoundedTwoTone />
    ),
    WifiTetheringErrorSharp: <icons.WifiTetheringErrorSharp />,
    WifiTetheringErrorTwoTone: <icons.WifiTetheringErrorTwoTone />,
    WifiTetheringOff: <icons.WifiTetheringOff />,
    WifiTetheringOffOutlined: <icons.WifiTetheringOffOutlined />,
    WifiTetheringOffRounded: <icons.WifiTetheringOffRounded />,
    WifiTetheringOffSharp: <icons.WifiTetheringOffSharp />,
    WifiTetheringOffTwoTone: <icons.WifiTetheringOffTwoTone />,
    WifiTetheringOutlined: <icons.WifiTetheringOutlined />,
    WifiTetheringRounded: <icons.WifiTetheringRounded />,
    WifiTetheringSharp: <icons.WifiTetheringSharp />,
    WifiTetheringTwoTone: <icons.WifiTetheringTwoTone />,
    WifiTwoTone: <icons.WifiTwoTone />,
    WindPower: <icons.WindPower />,
    WindPowerOutlined: <icons.WindPowerOutlined />,
    WindPowerRounded: <icons.WindPowerRounded />,
    WindPowerSharp: <icons.WindPowerSharp />,
    WindPowerTwoTone: <icons.WindPowerTwoTone />,
    Window: <icons.Window />,
    WindowOutlined: <icons.WindowOutlined />,
    WindowRounded: <icons.WindowRounded />,
    WindowSharp: <icons.WindowSharp />,
    WindowTwoTone: <icons.WindowTwoTone />,
    WineBar: <icons.WineBar />,
    WineBarOutlined: <icons.WineBarOutlined />,
    WineBarRounded: <icons.WineBarRounded />,
    WineBarSharp: <icons.WineBarSharp />,
    WineBarTwoTone: <icons.WineBarTwoTone />,
    Woman: <icons.Woman />,
    Woman2: <icons.Woman2 />,
    Woman2Outlined: <icons.Woman2Outlined />,
    Woman2Rounded: <icons.Woman2Rounded />,
    Woman2Sharp: <icons.Woman2Sharp />,
    Woman2TwoTone: <icons.Woman2TwoTone />,
    WomanOutlined: <icons.WomanOutlined />,
    WomanRounded: <icons.WomanRounded />,
    WomanSharp: <icons.WomanSharp />,
    WomanTwoTone: <icons.WomanTwoTone />,
    Work: <icons.Work />,
    WorkHistory: <icons.WorkHistory />,
    WorkHistoryOutlined: <icons.WorkHistoryOutlined />,
    WorkHistoryRounded: <icons.WorkHistoryRounded />,
    WorkHistorySharp: <icons.WorkHistorySharp />,
    WorkHistoryTwoTone: <icons.WorkHistoryTwoTone />,
    WorkOff: <icons.WorkOff />,
    WorkOffOutlined: <icons.WorkOffOutlined />,
    WorkOffRounded: <icons.WorkOffRounded />,
    WorkOffSharp: <icons.WorkOffSharp />,
    WorkOffTwoTone: <icons.WorkOffTwoTone />,
    WorkOutline: <icons.WorkOutline />,
    WorkOutlineOutlined: <icons.WorkOutlineOutlined />,
    WorkOutlineRounded: <icons.WorkOutlineRounded />,
    WorkOutlineSharp: <icons.WorkOutlineSharp />,
    WorkOutlineTwoTone: <icons.WorkOutlineTwoTone />,
    WorkOutlined: <icons.WorkOutlined />,
    WorkRounded: <icons.WorkRounded />,
    WorkSharp: <icons.WorkSharp />,
    WorkTwoTone: <icons.WorkTwoTone />,
    WorkspacePremium: <icons.WorkspacePremium />,
    WorkspacePremiumOutlined: <icons.WorkspacePremiumOutlined />,
    WorkspacePremiumRounded: <icons.WorkspacePremiumRounded />,
    WorkspacePremiumSharp: <icons.WorkspacePremiumSharp />,
    WorkspacePremiumTwoTone: <icons.WorkspacePremiumTwoTone />,
    Workspaces: <icons.Workspaces />,
    WorkspacesOutlined: <icons.WorkspacesOutlined />,
    WorkspacesRounded: <icons.WorkspacesRounded />,
    WorkspacesSharp: <icons.WorkspacesSharp />,
    WorkspacesTwoTone: <icons.WorkspacesTwoTone />,
    WrapText: <icons.WrapText />,
    WrapTextOutlined: <icons.WrapTextOutlined />,
    WrapTextRounded: <icons.WrapTextRounded />,
    WrapTextSharp: <icons.WrapTextSharp />,
    WrapTextTwoTone: <icons.WrapTextTwoTone />,
    WrongLocation: <icons.WrongLocation />,
    WrongLocationOutlined: <icons.WrongLocationOutlined />,
    WrongLocationRounded: <icons.WrongLocationRounded />,
    WrongLocationSharp: <icons.WrongLocationSharp />,
    WrongLocationTwoTone: <icons.WrongLocationTwoTone />,
    Wysiwyg: <icons.Wysiwyg />,
    WysiwygOutlined: <icons.WysiwygOutlined />,
    WysiwygRounded: <icons.WysiwygRounded />,
    WysiwygSharp: <icons.WysiwygSharp />,
    WysiwygTwoTone: <icons.WysiwygTwoTone />,
    Yard: <icons.Yard />,
    YardOutlined: <icons.YardOutlined />,
    YardRounded: <icons.YardRounded />,
    YardSharp: <icons.YardSharp />,
    YardTwoTone: <icons.YardTwoTone />,
    YouTube: <icons.YouTube />,
    YoutubeSearchedFor: <icons.YoutubeSearchedFor />,
    YoutubeSearchedForOutlined: <icons.YoutubeSearchedForOutlined />,
    YoutubeSearchedForRounded: <icons.YoutubeSearchedForRounded />,
    YoutubeSearchedForSharp: <icons.YoutubeSearchedForSharp />,
    YoutubeSearchedForTwoTone: <icons.YoutubeSearchedForTwoTone />,
    ZoomIn: <icons.ZoomIn />,
    ZoomInMap: <icons.ZoomInMap />,
    ZoomInMapOutlined: <icons.ZoomInMapOutlined />,
    ZoomInMapRounded: <icons.ZoomInMapRounded />,
    ZoomInMapSharp: <icons.ZoomInMapSharp />,
    ZoomInMapTwoTone: <icons.ZoomInMapTwoTone />,
    ZoomInOutlined: <icons.ZoomInOutlined />,
    ZoomInRounded: <icons.ZoomInRounded />,
    ZoomInSharp: <icons.ZoomInSharp />,
    ZoomInTwoTone: <icons.ZoomInTwoTone />,
    ZoomOut: <icons.ZoomOut />,
    ZoomOutMap: <icons.ZoomOutMap />,
    ZoomOutMapOutlined: <icons.ZoomOutMapOutlined />,
    ZoomOutMapRounded: <icons.ZoomOutMapRounded />,
    ZoomOutMapSharp: <icons.ZoomOutMapSharp />,
    ZoomOutMapTwoTone: <icons.ZoomOutMapTwoTone />,
    ZoomOutOutlined: <icons.ZoomOutOutlined />,
    ZoomOutRounded: <icons.ZoomOutRounded />,
    ZoomOutSharp: <icons.ZoomOutSharp />,
    ZoomOutTwoTone: <icons.ZoomOutTwoTone />,
}
